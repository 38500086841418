import React from "react";

const PersonIcon = ({ fill, size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      minWidth={size}
      minHeight={size}
      viewBox="0 0 48 48"
      fill={fill}
      style={{ minWidth: size, minHeight: size }}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23.992 27.2839C17.3769 27.2839 11.7871 30.9229 11.7871 35.2303C11.7871 40.8002 20.9725 40.8002 23.992 40.8002C29.2327 40.8002 36.2003 40.2263 36.2003 35.2657C36.2003 30.9381 30.6089 27.2839 23.992 27.2839Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23.9937 23.6196H24.0488C26.2115 23.6129 28.2423 22.7538 29.7655 21.2026C31.2902 19.6515 32.1253 17.594 32.1169 15.4116C32.1169 10.8831 28.4745 7.2002 23.9937 7.2002C19.5145 7.2002 15.8721 10.8831 15.8721 15.4116C15.8721 19.9367 19.5145 23.6196 23.9937 23.6196Z"
      />
    </svg>
  );
};

export default PersonIcon;
