import React, { useEffect, useState } from "react";
import { colors } from "../../config/style";
import TextFieldIcon from "../TextFieldIcon/TextFieldIcon";
import ButtonTextIcon from "../buttons/ButtonTextIcon/ButtonTextIcon";
import { useNavigate } from "react-router-dom";
import TextField from "../TextField/TextField";
import { useTranslation } from "react-i18next";

const ResetPwdForm = ({ handleReset, errorMsg, mail, setError }) => {
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [passwordCopy, setPasswordCopy] = useState("");

  const { t } = useTranslation();

  useEffect(() => {
    setError("");
  }, [code, password, passwordCopy]);

  const handleSubmit = () => {
    handleReset(code, password, passwordCopy);
  };

  const navigate = useNavigate();

  return (
    <div
      style={{
        display: "flex",
        alignItems: "start",
        flexDirection: "column",
        justifyContent: "space-between",
        background: "white",
        width: "26vw",
        height: "33.1vw",
        borderRadius: "0.62vw",
        boxShadow: "0px 0.2vw 2.4vw 0.6vw rgba(113, 109, 242, 0.10)",
        padding: "1.87vw",
        boxSizing: "border-box",
        position: "relative",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <img
          src={
            process.env.REACT_APP_RESOURCES_BUCKET+"logo.png"
          }
          style={{ width: "10vw", marginBottom: "1.8vw" }}
        />

        <p
          style={{
            color: colors.text,
            fontFamily: "Atkinson Hyperlegible",
            fontSize: "1.64vw",
            margin: "0",
            fontWeight: "800",
          }}
        >
          {t("resetTitle")}
        </p>
        <p
          style={{
            color: colors.text,
            fontFamily: "Atkinson Hyperlegible",
            fontSize: "0.9vw",
            fontWeight: "400",
            margin: "0",
            width: "100%",
            textAlign: "start",

            marginTop: "0.5vw",
            width: "90%",
          }}
        >
          {t("resetCodeSentSubP1")}
          <b>{mail}</b>
          {t("resetCodeSentSubP2")}
        </p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "end",
          alignItems: "start",
          gap: "0.9vw",
          width: "100%",
        }}
      >
        <p
          style={{
            color: colors.negative,
            fontFamily: "Atkinson Hyperlegible",
            fontSize: "0.8vw",
            fontWeight: "800",
            margin: "0",
            textAlign: "end",
            marginTop: "0vw",
            width: "100%",
            boxSizing: "border-box",
            visibility: errorMsg ? "" : "hidden",
          }}
        >
          {/* {errorMsg.includes("code")
            ? "Codice non corretto"
            : errorMsg.includes("corrispondono")
            ? errorMsg
            : "Password non valida"} */}
          {errorMsg}
        </p>
        <TextField
          onChange={(e) => setCode(e.target.value)}
          icon={
            process.env.REACT_APP_RESOURCES_BUCKET+"Message.png"
          }
          placeholder={t("resetInsert6digit")}
          title={t("resetCode")}
          value={code}
          type={"text"}
          error={errorMsg}
        />
        <TextField
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          icon={
            process.env.REACT_APP_RESOURCES_BUCKET+"Key2.png"
          }
          title={t("loginPsw")}
          type={"password"}
          placeholder={t("resetNewPass")}
          value={password}
          error={errorMsg}
        />
        <TextField
          onChange={(e) => {
            setPasswordCopy(e.target.value);
          }}
          icon={
            process.env.REACT_APP_RESOURCES_BUCKET+"Key2.png"
          }
          title={t("loginPsw")}
          type={"password"}
          placeholder={t("resetConfirmPass")}
          value={passwordCopy}
          error={errorMsg}
        />
        <ButtonTextIcon
          backgroundH={colors.mainH}
          background={colors.main}
          label={t("resetPsw")}
          onClick={handleSubmit}
          disabled={!code || !password || !passwordCopy}
          style={{
            width: "100%",
            justifyContent: "center",
            height: "2.5vw",
            marginBottom: "0.5vw",
          }}
        />

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            gap: "0.5vw",
          }}
        >
          {" "}
          <p
            onClick={() => navigate("/auth/sign")}
            style={{
              color: colors.main,
              fontFamily: "Atkinson Hyperlegible",
              fontSize: "0.83vw",
              fontWeight: "700",
              margin: "0",
              width: "100%",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            {t("resetGo2Login")}
          </p>
          <p
            onClick={() => {}}
            style={{
              color: colors.text,
              fontFamily: "Atkinson Hyperlegible",
              fontSize: "0.83vw",
              fontWeight: "400",
              margin: "0",
              width: "100%",
            }}
          >
            {t("loginRegister")}
            <a href="mailto:info@gymnasio.it">
              <span
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                  fontWeight: "700",
                  color: colors.main,
                }}
              >
                {t("loginRequestHere")}
              </span>
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ResetPwdForm;
