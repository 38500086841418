import React from "react";

const PeopleIcon = ({ fill, size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      minWidth={size}
      minHeight={size}
      viewBox="0 0 48 48"
      fill={fill}
      style={{ minWidth: size, minHeight: size }}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M33.0115 24.4333C31.4249 24.4333 29.9357 24.7569 28.6832 25.3054C33.0671 26.5854 36.1707 29.6516 36.2542 33.2383C38.5366 32.9429 41 32.0709 41 29.6938C41 26.8385 37.3397 24.4333 33.0115 24.4333Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M32.9697 22.4079H32.9975C35.9619 22.4079 38.3835 19.9606 38.3835 16.9787C38.3835 13.9827 35.9619 11.5353 32.9975 11.5353C32.413 11.5353 31.8563 11.6338 31.3274 11.8026C32.2877 13.2373 32.8444 14.9673 32.8444 16.838C32.8583 18.7369 32.2738 20.5654 31.1744 22.0844C31.745 22.2954 32.3434 22.4079 32.9697 22.4079Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.3029 25.2913C18.0503 24.7569 16.5612 24.4333 14.9885 24.4333C10.6603 24.4333 7 26.8385 7 29.6938C7 32.0709 9.44945 32.9289 11.7319 33.2383C11.8015 29.6516 14.905 26.5854 19.3029 25.2913Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.9885 22.4079H15.0303C15.6566 22.4079 16.255 22.2954 16.8256 22.0844C15.7679 20.6216 15.1416 18.8072 15.1416 16.838C15.1416 14.9673 15.6983 13.2373 16.6586 11.8026C16.1298 11.6338 15.5731 11.5353 14.9885 11.5353C12.0242 11.5353 9.61646 13.9827 9.61646 16.9787C9.61646 19.9606 12.0242 22.4079 14.9885 22.4079Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23.9925 26.7359C18.4798 26.7359 13.8217 29.7684 13.8217 33.3579C13.8217 37.9995 21.4762 37.9995 23.9925 37.9995C28.3597 37.9995 34.166 37.5213 34.166 33.3874C34.166 29.7811 29.5065 26.7359 23.9925 26.7359Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23.9939 23.6824H24.0398C25.8421 23.6768 27.5344 22.9608 28.8037 21.6682C30.0743 20.3756 30.7702 18.661 30.7632 16.8424C30.7632 13.0686 27.7279 9.99951 23.9939 9.99951C20.2612 9.99951 17.2259 13.0686 17.2259 16.8424C17.2259 20.6133 20.2612 23.6824 23.9939 23.6824Z"
      />
    </svg>
  );
};

export default PeopleIcon;
