// Route path

export const nav_paths = {
  inspections: "/inspections",
  inspections_old: "/inspections_old",
  structure: "/structure",
  analysis: "/analysis",
  review: "/review",
  auth: "/auth",
  signIn: "/auth/signin",
};
