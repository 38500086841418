import React from "react";

const InfoIcon = ({ fill, size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 36 36"
      fill={fill}
      style={{ minWidth: size, minHeight: size }}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18 30C24.6274 30 30 24.6274 30 18C30 11.3726 24.6274 6 18 6C11.3726 6 6 11.3726 6 18C6 24.6274 11.3726 30 18 30ZM19.4762 12.9926C19.4762 12.1774 18.8153 11.5166 18.0002 11.5166C17.185 11.5166 16.5242 12.1774 16.5242 12.9926C16.5242 13.8078 17.185 14.4686 18.0002 14.4686C18.8153 14.4686 19.4762 13.8078 19.4762 12.9926ZM19.1072 24.2766C19.1072 24.888 18.6115 25.3836 18.0002 25.3836C17.3888 25.3836 16.8932 24.888 16.8932 24.2766V17.4206C16.8932 16.8092 17.3888 16.3136 18.0002 16.3136C18.6115 16.3136 19.1072 16.8092 19.1072 17.4206V24.2766Z"
      />
    </svg>
  );
};

export default InfoIcon;
