export function filterArrayDiscrete(arr, threshold) {
  let result = [...arr]; // Crea una copia dell'array per evitare di modificare l'originale
  let count = 0;
  let start = -1;

  for (let i = 0; i < arr.length; i++) {
    if (arr[i] === 0) {
      count++;
      if (start === -1) {
        start = i;
      }
    } else {
      if (count > 0 && count < threshold) {
        for (let j = start; j < start + count; j++) {
          result[j] = 1;
        }
      }
      count = 0;
      start = -1;
    }
  }

  // Gestire il caso in cui l'array termina con 0
  if (count > 0 && count < threshold) {
    for (let j = start; j < start + count; j++) {
      result[j] = 1;
    }
  }

  return result;
}

function butterLowpass(cutoff, fs) {
  const nyq = 0.5 * fs;
  const normalCutoff = cutoff / nyq;
  const k = Math.tan(Math.PI * normalCutoff);
  const q = Math.sqrt(2);

  const a0 = 1 / (1 + q * k + k * k);
  const a1 = 2 * a0;
  const a2 = a0;
  const b1 = 2 * a0 * (1 - k * k);
  const b2 = a0 * (1 - q * k + k * k);

  return { a0, a1, a2, b1, b2 };
}

// Funzione per applicare il filtro passa-basso ai dati
export function butterLowpassFilter(data, cutoff, fs) {
  const { a0, a1, a2, b1, b2 } = butterLowpass(cutoff, fs);
  const filteredData = [];
  let x1 = 0,
    x2 = 0,
    y1 = 0,
    y2 = 0;

  for (let i = 0; i < data.length; i++) {
    const x = data[i];
    const y = a0 * x + a1 * x1 + a2 * x2 - b1 * y1 - b2 * y2;
    filteredData.push(y);
    x2 = x1;
    x1 = x;
    y2 = y1;
    y1 = y;
  }

  return filteredData;
}

export function extractVisibilityIntervals(arr, labels) {
  const visibility = [];
  let start = null;

  for (let i = 0; i < arr.length; i++) {
    if (arr[i] === 0 && start === null) {
      start = labels[i];
    } else if (arr[i] !== 0 && start !== null) {
      visibility.push({ start: start, end: labels[i - 1] });
      start = null;
    }
  }

  // Gestire il caso in cui l'array termina con un batch di 0
  if (start !== null) {
    visibility.push({ start: start, end: labels[arr.length - 1] });
  }

  return visibility;
}
