import { colors } from "../../config/style";
import { tips } from "../../config/tips";
import MagicIcon from "../../icons/MagicIcon";
import { mappingNiosh } from "../../utils/Protocols/NIOSH/NIOSH";
import { mappingOcra } from "../../utils/Protocols/OCRA/OCRA";
import { AnalysisSelectorLateral } from "../AnalysisSelector/AnalysisSelector";
import BodySchema from "../BodySchema/BodySchema";
import { InfoButton, ScoreTab } from "../InfoTab/InfoTab";
import PillSelector from "../PillSelector/PillSelector";
import { LeftColumn } from "./AnalysisContainer.styled";
import { DataSelector, LeftHeader } from "./AnalysisContainerComponents";

export const AnalysisContainerLeftColumn = ({
  saving,
  savingEnabled,
  popupRef,
  onClose,
  selectedTask,
  availableProtocols,
  selectedProtocol,
  setSelectedProtocol,
  t,
  screen,
  setScreen,
  formattaData,
  ocraRes,
  ocraMemory,
  checklistRef,
  setChecklist,
  checklists_menu,
  analysis,
  setAnalysis,
  setSelectedGraph,
  availableAnalysis,
  vliMemory,
  setVliMemory,
  vliRes,
}) => {
  return (
    <LeftColumn>
      <LeftHeader
        disabled={saving}
        onClose={
          savingEnabled
            ? () => {
                popupRef.current.open();
              }
            : () => {
                onClose();
              }
        }
        name={
          selectedTask?.taskDetail?.name
            ? selectedTask?.taskDetail?.name
            : "Task"
        }
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "end",
          width: "100%",
          padding: "0.9vw",
          boxSizing: "border-box",
          gap: "1vw",
        }}
      >
        <p
          style={{
            width: "5vw",
            color: colors.text,
            fontFamily: "Atkinson Hyperlegible",
            fontSize: "0.8vw",
            fontWeight: "400",
            margin: "0px",
            textAlign: "right",
          }}
        >
          {"Protocolli"}
        </p>
        <PillSelector
          color={colors.main}
          colorBackground={colors.background}
          options={availableProtocols?.map((protocol) => {
            return { label: protocol.mid };
          })}
          width={13.1}
          option={selectedProtocol}
          setOption={setSelectedProtocol}
        />
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          border: "0.136vw solid " + colors.background,
          borderRadius: "1.875vw",
          width: "100%",
          padding: "0.9vw",
          boxSizing: "border-box",
          marginBottom: "2vw",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "end",
            width: "100%",
            gap: "1vw",
            marginBottom: "1vw",
          }}
        >
          <p
            style={{
              width: "5vw",
              color: colors.text,
              fontFamily: "Atkinson Hyperlegible",
              fontSize: "0.8vw",
              fontWeight: "400",
              margin: "0px",
              textAlign: "right",
            }}
          >
            {formattaData(
              selectedTask?.taskDetail?.inspection_date
                ? selectedTask?.taskDetail?.inspection_date
                : selectedTask?.taskDetail?.last_update,
              t
            )}
          </p>
          <PillSelector
            color={screen == 1 ? colors.tertiary : colors.main}
            colorBackground={
              screen == 1 ? colors.tertiaryBackground : colors.background
            }
            options={[
              {
                label: t("data"),
              },
              {
                label: t("aianalysis"),
                disabled: !availableProtocols[selectedProtocol].ai,
                icon: (fill, size) => <MagicIcon fill={fill} size={size} />,
              },
            ]}
            width={13.1}
            option={screen}
            setOption={setScreen}
          />
        </div>
        {screen == 0 ? (
          <DataSelector
            checklists_menu={checklists_menu({
              ocraRes: ocraRes,
              ocraMemory: ocraMemory,
              vliRes: vliRes,
              vliMemory: vliMemory,
              t: t,
              selectedProtocol: availableProtocols[selectedProtocol],
            })}
            checklistRef={checklistRef}
            setChecklist={setChecklist}
          />
        ) : (
          <AnalysisSelectorLateral
            setVideoLoaded={(x) => {}}
            setAnalysis={(analysis) => {
              setAnalysis(analysis);
              setSelectedGraph(0);
            }}
            analysis={analysis}
            items={availableAnalysis}
          />
        )}
      </div>
      {screen == 0 ? (
        availableProtocols[selectedProtocol].ref == "ocra" ? (
          <ScoreTab
            title={t("SCORE")}
            style={{
              width: "100%",
            }}
            mapping={mappingOcra["prec"]}
            styleInternal={{
              borderRadius: "1.875vw",
            }}
            scores={[
              {
                label: t("PARTIAL"),
                info: tips[document.language].parziale,
                dx: ocraRes?.output.punteggio_parziale_dx,
                sx: ocraRes?.output.punteggio_parziale_sx,
              },
            ]}
          />
        ) : (
          <ScoreTab
            title={"LIFTING INDEX"}
            mapping={mappingNiosh["vli"]}
            style={{
              width: "100%",
            }}
            styleInternal={{
              borderRadius: "1.875vw",
            }}
            labels={["18-45", "<18 o >45"]}
            scores={[
              {
                label: "MASCHI",
                info: "",
                score: [vliRes?.m?.mid, vliRes?.m?.ext],
              },
              {
                label: "FEMMINE",
                info: "",
                score: [vliRes?.f?.mid, vliRes?.f?.ext],
              },
            ]}
          />
        )
      ) : (
        <BodySchema
          items={[
            {
              analysis: {
                shDx: {
                  value: ocraRes.output.rischi_totali.spalla_dx,
                  label: t("shoulder") + " DX",
                  map: "dist",
                },
                shLx: {
                  value: ocraRes.output.rischi_totali.spalla_sx,
                  label: t("shoulder") + " SX",
                  map: "dist",
                },
                elDx: {
                  value: ocraRes.output.rischi_totali.gomito_dx,
                  label: t("elbow") + " DX",
                  map: "dist",
                },
                elLx: {
                  value: ocraRes.output.rischi_totali.gomito_sx,
                  label: t("elbow") + " SX",
                  map: "dist",
                },
                wrDx: {
                  value: ocraRes.output.rischi_totali.polso_dx,
                  label: t("wrist") + " DX",
                  map: "dist",
                },
                wrLx: {
                  value: ocraRes.output.rischi_totali.polso_sx,
                  label: t("wrist") + " SX",
                  map: "dist",
                },
                haDx: {
                  value: ocraRes.output.rischi_totali.mano_dx,
                  label: t("hand") + " DX",
                  map: "dist",
                },
                haLx: {
                  value: ocraRes.output.rischi_totali.mano_sx,
                  label: t("hand") + " SX",
                  map: "dist",
                },
                stDx: {
                  value: ocraRes.output.rischio_AT_dx,
                  label: t("azTec") + " DX",
                  map: "stereo",
                },
                stLx: {
                  value: ocraRes.output.rischio_AT_sx,
                  label: t("azTec") + " SX",
                  map: "stereo",
                },
              },
            },
          ]}
        />
      )}
    </LeftColumn>
  );
};
