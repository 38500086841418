import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  ButtonNavigatorContainer,
  ButtonNavigatorIndicator,
  IconButtonNavigator,
  LabelButtonNavigator,
} from "./ButtonNavigation.styled";
import { colors } from "../../../config/style";

const ButtonNavigation = ({
  onClick,
  icon,
  iconH,
  toggle,
  label,
  disabled,
}) => {
  const [hover, setHover] = useState(false);
  return (
    <ButtonNavigatorContainer
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={disabled ? () => alert("FEATURE IN SVILUPPO") : onClick}
    >
      <ButtonNavigatorIndicator
        style={{ visibility: toggle ? "hidden" : "hidden" }}
      />
      <IconButtonNavigator src={hover || toggle ? iconH : icon} />
      <LabelButtonNavigator
        style={{
          color: disabled
            ? colors.details
            : hover || toggle
            ? colors.main
            : colors.text,
          fontWeight: disabled ? 400 : toggle ? 800 : 400,
        }}
      >
        {label}
      </LabelButtonNavigator>
    </ButtonNavigatorContainer>
  );
};

ButtonNavigation.propTypes = {
  dataTestId: PropTypes.string, // Definisci il tipo di prop "dataTestId"
  onClick: PropTypes.func, // Definisci il tipo di prop "onClick"
  children: PropTypes.node, // Definisci il tipo di prop "children"
};

ButtonNavigation.defaultProps = {
  dataTestId: "default-data-testid", // Imposta un valore predefinito per "dataTestId"
  onClick: () => {}, // Imposta una funzione vuota come valore predefinito per "onClick"
};

export default ButtonNavigation;
