import React from "react";

const BoxIcon = ({ fill, size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      minWidth={size}
      minHeight={size}
      viewBox="0 0 48 48"
      fill={fill}
      style={{ minWidth: size, minHeight: size }}
    >
      <path d="M33.6674 10.9015L30.2007 9.07347C27.1576 7.46882 25.6361 6.6665 23.9993 6.6665C22.3626 6.6665 20.8411 7.46882 17.798 9.07346L17.2403 9.36752L32.7069 18.2482L39.6692 14.7503C38.5491 13.4756 36.7419 12.5227 33.6674 10.9015Z" />
      <path d="M40.8965 17.0546L33.966 20.5365V25.8251C33.966 26.5465 33.384 27.1314 32.666 27.1314C31.948 27.1314 31.366 26.5465 31.366 25.8251V21.8428L25.2993 24.8907V41.3332C26.5437 41.0217 27.9596 40.2751 30.2007 39.0934L33.6674 37.2654C37.3969 35.2988 39.2617 34.3155 40.2972 32.5485C41.3327 30.7815 41.3327 28.5828 41.3327 24.1853V23.9816C41.3327 20.6851 41.3327 18.6242 40.8965 17.0546Z" />
      <path d="M22.6993 41.3332V24.8907L7.10219 17.0546C6.66602 18.6242 6.66602 20.6851 6.66602 23.9816V24.1853C6.66602 28.5828 6.66602 30.7815 7.7015 32.5485C8.73699 34.3155 10.6018 35.2988 14.3313 37.2654L17.798 39.0934C20.0391 40.2751 21.455 41.0217 22.6993 41.3332Z" />
      <path d="M8.32948 14.7503L23.9993 22.623L29.912 19.6524L14.5086 10.808L14.3313 10.9015C11.2568 12.5227 9.44958 13.4756 8.32948 14.7503Z" />
    </svg>
  );
};

export default BoxIcon;
