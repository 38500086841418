import {
  COMPRESSION_PREFIX,
  COMPRESSION_PREFIX_URI,
} from "../config/generalConst";
import LZString from "lz-string";

export function compressObjectLZ(obj) {
  const jsonString = JSON.stringify(obj);
  return COMPRESSION_PREFIX + LZString.compressToUTF16(jsonString);
}

export function compressObjectLZURI(obj) {
  const jsonString = JSON.stringify(obj);
  return (
    COMPRESSION_PREFIX_URI + LZString.compressToEncodedURIComponent(jsonString)
  );
}

// Funzione per decomprimere un oggetto
export function decompressObjectLZ(compressedStr) {
  if (typeof compressedStr === "object") {
    console.log("NO COMPRESSION");
    return compressedStr;
  }
  if (compressedStr.startsWith(COMPRESSION_PREFIX)) {
    console.log("UTF16 COMPRESSION");
    const compressedData = compressedStr.slice(COMPRESSION_PREFIX.length);
    const jsonString = LZString.decompressFromUTF16(compressedData);
    return JSON.parse(jsonString);
  }
  if (compressedStr.startsWith(COMPRESSION_PREFIX_URI)) {
    console.log("URI COMPRESSION");
    const compressedData = compressedStr.slice(COMPRESSION_PREFIX_URI.length);
    const jsonString =
      LZString.decompressFromEncodedURIComponent(compressedData);
    console.log("DECOMPRESSED", JSON.parse(jsonString));
    return JSON.parse(jsonString);
  }
}
