import React from "react";

const NotepadIcon = ({ fill, size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      minWidth={size}
      minHeight={size}
      viewBox="0 0 49 48"
      fill={fill}
      style={{ minWidth: size, minHeight: size }}s
    >
      <path d="M20.5906 8.00024C19.2406 8.00024 18.1462 9.07485 18.1462 10.4005V12.0006C18.1462 13.3262 19.2406 14.4008 20.5906 14.4008H28.7388C30.0888 14.4008 31.1832 13.3262 31.1832 12.0006V10.4005C31.1832 9.07485 30.0888 8.00024 28.7388 8.00024H20.5906Z" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.7018 11.2591C13.6518 11.372 12.3577 11.6928 11.43 12.6038C9.99805 14.0098 9.99805 16.2727 9.99805 20.7986V30.3994C9.99805 34.9253 9.99805 37.1882 11.43 38.5942C12.8619 40.0002 15.1665 40.0002 19.7758 40.0002H29.5536C34.1629 40.0002 36.4675 40.0002 37.8995 38.5942C39.3314 37.1882 39.3314 34.9253 39.3314 30.3994V20.7986C39.3314 16.2727 39.3314 14.0098 37.8995 12.6038C36.9717 11.6928 35.6776 11.372 33.6277 11.2591V12.0006C33.6277 14.6518 31.4388 16.801 28.7388 16.801H20.5906C17.8906 16.801 15.7018 14.6518 15.7018 12.0006V11.2591ZM30.3876 24.879C30.881 24.4267 30.9077 23.6673 30.4471 23.1828C29.9865 22.6982 29.2131 22.6721 28.7197 23.1243L22.5695 28.7606L20.6098 26.9646C20.1163 26.5124 19.3429 26.5386 18.8823 27.0231C18.4217 27.5077 18.4484 28.2671 18.9419 28.7193L21.7355 31.2795C22.2051 31.7099 22.9338 31.7099 23.4034 31.2795L30.3876 24.879Z"
      />
    </svg>
  );
};

export default NotepadIcon;
