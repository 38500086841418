import { getToken } from "../userManagement/utilities";
import { getDepartment, getLocation } from "./structure";

export const handleLoadDepartment = async (
  departmentId,
  onSuccess,
  departmentDetail,
  session,
  locationId,
  setDepartmentDetail
) => {
  let updatedDetail = { ...departmentDetail };
  let depLoaded = await getDepartment(
    locationId,
    departmentId,
    await getToken(session)
  );



  updatedDetail[departmentId] = filterInvalidEntries(depLoaded);
  setDepartmentDetail(updatedDetail);
  if (onSuccess) onSuccess(depLoaded);
};

export const handleLoadDepartments = async (location, onSuccess, session) => {
  let locationUpdated = await getLocation(location, await getToken(session));

  if (onSuccess) onSuccess(locationUpdated);
};

export function filterInvalidTasks(tasks) {
  return tasks.filter((task) => task.valid !== false);
}

function filterInvalidEntries(data) {
  // Filtrare i workshifts
  const validWorkshifts = {};
  for (const key in data.workshifts) {
    if (data.workshifts[key].valid !== false) {
      validWorkshifts[key] = data.workshifts[key];
    }
  }

  // Filtrare i jobs
  const validJobs = {};
  for (const key in data.jobs) {
    if (data.jobs[key].valid !== false) {
      validJobs[key] = {
        ...data.jobs[key],
        tasks: filterInvalidTasks(data.jobs[key].tasks),
      };
    }
  }

  // Filtrare le postazioni in ogni linea
  const validLines = {};
  for (const lineKey in data.lines) {
    validLines[lineKey] = {};
    for (const stationKey in data.lines[lineKey]) {
      if (data.lines[lineKey][stationKey].valid !== false) {
        validLines[lineKey][stationKey] = {
          ...data.lines[lineKey][stationKey],
          tasks: filterInvalidTasks(data.lines[lineKey][stationKey].tasks),
        };
      }
    }
  }

  return {
    ...data,
    workshifts: validWorkshifts,
    jobs: validJobs,
    lines: validLines,
  };
}
