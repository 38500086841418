import { useClerk, useUser } from "@clerk/clerk-react";
import { useEffect, useRef, useState } from "react";

import InfoTab, {
  BasicText,
  CheckBox,
  InfoBox,
  InfoTabScroller,
  Row,
  ScoreTab,
  Selector,
  TextBox,
} from "../components/InfoTab/InfoTab";
import { PopupAdd, PopupCreate } from "../components/Popup/Popup";
import { PopupTask } from "../components/Popup/PopupContent";
import ReportGenerator from "../components/ReportGenerator/ReportGenerator";
import { TreeStructure } from "../components/Tree/TreeStructure";
import ButtonIcon from "../components/buttons/ButtonIcon/ButtonIcon";
import ButtonTextIcon from "../components/buttons/ButtonTextIcon/ButtonTextIcon";
import { colors } from "../config/style";
import { tips } from "../config/tips";
import {
  deleteJob,
  deleteJobTask,
  deleteStation,
  deleteTask,
  deleteWorkshift,
  editWorkshift,
  getTaskPerStation,
  updateJob,
  updateJobTask,
  updateStation,
} from "../remote/structure";
import { filterInvalidTasks, handleLoadDepartment } from "../remote/utils";
import { useStateValue } from "../stores/services/StateProvider";
import { getToken } from "../userManagement/utilities";
import { multitask_global } from "../utils/Protocols/OCRA/multiTask";
import {
  shift_global,
  trova_moltiplicatore_durata,
} from "../utils/Protocols/OCRA/singleTask";
import { JobTable } from "./MainInspections";
import Spinner from "../components/Spinner/Spinner";
import { useTranslation } from "react-i18next";
import {
  formatDecimal,
  isPositiveInteger,
} from "../components/AnalysisContainer/AnalysisContainerFunctions";
import { mappingOcra } from "../utils/Protocols/OCRA/OCRA";
import ExcelDownloader from "../components/functionals/Excel";

const ShiftScreen = ({
  id,
  setDepartmentDetail,
  departmentDetail,
  currentDepartment,
  setData,
}) => {
  const [shift, setShift] = useState(
    departmentDetail[currentDepartment].workshifts[id]
  );
  const [savingEnabled, setSavingEnabled] = useState(false);
  const [saving, setSaving] = useState(false);

  const { session } = useClerk();
  const { t } = useTranslation();

  const deleted = useRef(false);
  const shiftRef = useRef();
  shiftRef.current = shift;

  let shiftRes = shift_global(shift);

  useEffect(() => {
    //Create an updated version of the department
    const updatedCurrentDepartment = {
      ...departmentDetail[currentDepartment],
      workshifts: {
        ...departmentDetail[currentDepartment].workshifts,
        [id]: shift,
      },
    };

    //Update local department details
    const updatedDepartmentDetails = {
      ...departmentDetail,
      [currentDepartment]: updatedCurrentDepartment,
    };
    setDepartmentDetail(updatedDepartmentDetails);

    // setSavingEnabled(true);
  }, [shift]);

  useEffect(() => {
    return () => {
      if (!deleted.current) {
        const updatedCurrentDepartment = {
          ...departmentDetail[currentDepartment],
          workshifts: {
            ...departmentDetail[currentDepartment].workshifts,
            [id]: shiftRef.current,
          },
        };

        //Update local department details
        const updatedDepartmentDetails = {
          ...departmentDetail,
          [currentDepartment]: updatedCurrentDepartment,
        };
        setDepartmentDetail(updatedDepartmentDetails);

        handleSaving(id, shiftRef.current);
      } else {
      }
    };
  }, []);

  const handleSaving = async (id, shift) => {
    setSaving(true);
    let request = await editWorkshift(id, shift, await getToken(session));
    setSavingEnabled(false);
    setSaving(false);
  };

  const handleDelete = async () => {
    deleted.current = true;
    let request = await deleteWorkshift(id, await getToken(session));
    // Crea una copia degli attuali workshifts
    const updatedWorkshifts = {
      ...departmentDetail[currentDepartment].workshifts,
    };

    // Rimuovi lo shift specifico
    delete updatedWorkshifts[id];

    // Crea l'oggetto updatedCurrentDepartment senza lo shift rimosso
    const updatedCurrentDepartment = {
      ...departmentDetail[currentDepartment],
      workshifts: updatedWorkshifts,
    };

    // Aggiorna i dettagli del dipartimento nel contesto globale o nello stato
    const updatedDepartmentDetails = {
      ...departmentDetail,
      [currentDepartment]: updatedCurrentDepartment,
    };
    setData({ type: "delete", element: shiftRef.current.name });

    setDepartmentDetail(updatedDepartmentDetails);
  };

  return (
    <>
      <div
        style={{
          height: "10%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          width: "60.3vw",
        }}
      >
        {" "}
        <p
          style={{
            color: colors.text,
            fontFamily: "Atkinson Hyperlegible",
            fontSize: "1.6vw",
            fontWeight: "400",
            marginLeft: "0vw",
            marginTop: "1.9vw",
            boxSizing: "border-box",
            width: "65%",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          <b>{shift.name}</b>
        </p>
        <div
          style={{
            height: "10%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "1vw",
            marginRight: "0.1vw",
          }}
        >
          <ButtonIcon
            icon={process.env.REACT_APP_RESOURCES_BUCKET + "Delete.png"}
            iconH={process.env.REACT_APP_RESOURCES_BUCKET + "DeleteHw.png"}
            onClick={handleDelete}
            style={{ width: "1.875vw" }}
          />
          <ButtonTextIcon
            disabled={!savingEnabled}
            label={t("save")}
            loading={saving}
            loadingLabel={""}
            loadingStyle={{}}
            style={{
              width: "6.2vw",
              justifyContent: saving ? "center" : "start",
            }}
            icon={process.env.REACT_APP_RESOURCES_BUCKET + "Save.png"}
            backgroundH={colors.details}
            background={"white"}
            color={colors.main}
            onClick={() => {
              handleSaving(id, shiftRef.current);
            }}
          />
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "start",
          width: "100%",
          gap: "0.5vw",
          alignItems: "stretch",
        }}
      >
        <InfoTab
          width={"29.8vw"}
          height={""}
          title={[t("shiftScreenDetails")]}
          justifyContent={"start"}
          gap={"0.5vw"}
          justifyContentMain={"start"}
          content={[
            <>
              <Row justifyContent={"space-between"}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                  }}
                >
                  <img
                    src={
                      process.env.REACT_APP_RESOURCES_BUCKET + "Timetables2.png"
                    }
                    style={{
                      width: "1.875vw",
                      height: "1.875vw",
                      margin: "0px",
                      marginRight: "0.2vw",
                    }}
                  />
                  <BasicText
                    style={{
                      width: "5vw",
                      color: colors.text,
                    }}
                  >
                    {t("name")}
                  </BasicText>
                </div>
                <TextBox
                  width={"16.4vw"}
                  value={shift.name}
                  onChange={(value) => {
                    setSavingEnabled(true);
                    setShift({ ...shift, name: value });
                  }}
                  locked={false}
                />
              </Row>
              <Row justifyContent={"space-between"}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                  }}
                >
                  <img
                    src={process.env.REACT_APP_RESOURCES_BUCKET + "Factory.png"}
                    style={{
                      width: "1.875vw",
                      height: "1.875vw",
                      margin: "0px",
                      marginRight: "0.2vw",
                    }}
                  />
                  <BasicText
                    style={{
                      width: "5vw",
                      color: colors.text,
                    }}
                  >
                    {t("department")}
                  </BasicText>
                </div>
                <TextBox
                  width={"16.4vw"}
                  value={departmentDetail[currentDepartment].name}
                  onChange={(value) => {}}
                  locked={true}
                />
              </Row>
              <Row justifyContent={"space-between"}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                  }}
                >
                  <img
                    src={process.env.REACT_APP_RESOURCES_BUCKET + "Time.png"}
                    style={{
                      width: "1.875vw",
                      height: "1.875vw",
                      margin: "0px",
                      marginRight: "0.2vw",
                    }}
                  />
                  <BasicText
                    style={{
                      width: "8vw",
                      color: colors.text,
                    }}
                  >
                    {t("shiftScreenShiftLenght")}
                  </BasicText>
                </div>
                <TextBox
                  width={"16.4vw"}
                  value={shift.durata}
                  err={shift.durata <= 0}
                  textAlign={"start"}
                  onChange={(value) => {
                    if (
                      isPositiveInteger(value) &&
                      (parseInt(value) < 1000 || !value)
                    ) {
                      setSavingEnabled(true);
                      setShift({
                        ...shift,
                        durata: value ? parseInt(value) : 0,
                      });
                    }
                  }}
                  locked={false}
                  unit={"m"}
                  unitMargin={"-7vw"}
                />
              </Row>
            </>,
          ]}
        />
        <InfoTab
          width={"29.8vw"}
          height={""}
          title={[t("shiftScreenResults")]}
          justifyContent={"start"}
          gap={"0.5vw"}
          justifyContentMain={"start"}
          content={
            <>
              <Row justifyContent={"space-between"}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                  }}
                >
                  <BasicText
                    style={{
                      width: "16vw",
                      color: colors.text,
                    }}
                  >
                    <b>{t("shiftScreenDurationP1")}</b>
                    {t("shiftScreenDurationP2")}
                  </BasicText>
                </div>
                <InfoBox
                  value={shiftRes.output.durata_media_netta}
                  err={shiftRes.output.durata_media_netta <= 0}
                  unit={"m"}
                  ai={false}
                  info={tips[document.language].durata_media_netta}
                />
              </Row>
              <Row justifyContent={"space-between"}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                  }}
                >
                  <BasicText
                    style={{
                      width: "16vw",
                      color: colors.text,
                    }}
                  >
                    {t("shiftScreenRecoveryP1")}
                    <b>{t("shiftScreenRecoveryP2")}</b>
                  </BasicText>
                </div>
                <InfoBox
                  value={shiftRes.output.numero_ore_senza_recupero_automatico}
                  unit={"h"}
                  ai={false}
                  info={
                    tips[document.language].numero_ore_senza_recupero_automatico
                  }
                />
              </Row>
              <Row justifyContent={"space-between"}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                  }}
                >
                  <BasicText
                    style={{
                      width: "16vw",
                      color: colors.text,
                    }}
                  >
                    {t("shiftScreenRecoveryMulti")}
                  </BasicText>
                </div>
                <InfoBox
                  value={shiftRes.output.moltiplicatore_recupero}
                  decimals={2}
                  unit={""}
                  ai={false}
                  info={tips[document.language].moltiplicatore_recupero}
                />
              </Row>
              <Row justifyContent={"space-between"}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                  }}
                >
                  <BasicText
                    style={{
                      width: "17vw",
                      color: colors.text,
                    }}
                  >
                    {t("shiftScreenRecoveryManual")}
                  </BasicText>
                </div>
                <TextBox
                  value={shift.d_no_rec <= -1 ? "-" : shift.d_no_rec}
                  onChange={(valueIn) => {
                    let value = valueIn.replace(/-/g, "");
                    if (!value) {
                      setShift({
                        ...shift,
                        d_no_rec: -1,
                      });
                    } else if (formatDecimal(value)) {
                      setSavingEnabled(true);
                      console.log(
                        "formatDecimal(value)formatDecimal(value)",
                        formatDecimal(value),
                        parseFloat(formatDecimal(value))
                      );
                      setShift({
                        ...shift,
                        d_no_rec: formatDecimal(value)
                          ? formatDecimal(value)
                          : 0,
                      });
                    }
                  }}
                  locked={false}
                  unit={"h"}
                  info={
                    tips[document.language].numero_ore_senza_recupero_manuale
                  }
                  infoBox={true}
                  unitMargin={"0.2vw"}
                />
              </Row>
            </>
          }
        />
      </div>
      <InfoTabScroller
        style={{ width: "60.1vw" }}
        infoTab={
          <InfoTab
            fullWidth={false}
            style={{
              borderRadius: "0px",
              background: "transparent",
              border: "0px solid white",
              boxShadow: "0px 0px 0px 0px white",
            }}
            width={"100%"}
            height={"100%"}
            title={[t("shiftScreenPauses"), t("shiftScreenNotRepetitive")]}
            justifyContent={"start"}
            gap={"0.5vw"}
            justifyContentMain={"start"}
            content={[
              <>
                <Row justifyContent={"space-between"}>
                  <BasicText
                    style={{
                      width: "18vw",
                      color: colors.text,
                    }}
                  >
                    {t("shiftScreenOffPausesP1")}
                    <b>{t("shiftScreenOffPausesP2")}</b>
                    {t("shiftScreenOffPausesP3")}
                  </BasicText>
                  <TextBox
                    value={shift.n_pause_ufficiali}
                    onChange={(value) => {
                      if (isPositiveInteger(value)) {
                        setSavingEnabled(true);
                        setShift({
                          ...shift,
                          n_pause_ufficiali: value ? parseInt(value) : 0,
                        });
                      }
                    }}
                    locked={false}
                    infoBox={true}
                  />
                  <BasicText
                    style={{
                      width: "18vw",
                      color: colors.text,
                    }}
                  >
                    <b>{t("shiftScreenLunchPausesP1")}</b>
                    {t("shiftScreenLunchPausesP2")}
                  </BasicText>
                  <TextBox
                    value={shift.d_pause_mensa}
                    onChange={(value) => {
                      if (isPositiveInteger(value)) {
                        setSavingEnabled(true);
                        setShift({
                          ...shift,
                          d_pause_mensa: value ? parseInt(value) : 0,
                        });
                      }
                    }}
                    locked={false}
                    unit={"m"}
                    unitMargin={"0.2vw"}
                    infoBox={true}
                  />
                </Row>
                <Row justifyContent={"space-between"}>
                  <BasicText
                    style={{
                      width: "18vw",
                      color: colors.text,
                    }}
                  >
                    {t("shiftScreenPausesEffP1")}
                    <b>{t("shiftScreenPausesEffP2")}</b>
                    {t("shiftScreenPausesEffP3")}
                  </BasicText>
                  <TextBox
                    value={shift.n_pause_effettive}
                    onChange={(value) => {
                      if (isPositiveInteger(value)) {
                        setSavingEnabled(true);
                        setShift({
                          ...shift,
                          n_pause_effettive: value ? parseInt(value) : 0,
                        });
                      }
                    }}
                    locked={false}
                    infoBox={true}
                  />
                  <BasicText
                    style={{
                      width: "18vw",
                      color: colors.text,
                    }}
                  >
                    {t("shiftScreenPausesExtra")}
                  </BasicText>
                  <TextBox
                    value={shift.n_pause_extra}
                    onChange={(value) => {
                      if (isPositiveInteger(value)) {
                        setSavingEnabled(true);
                        setShift({
                          ...shift,
                          n_pause_extra: value ? parseInt(value) : 0,
                        });
                      }
                    }}
                    locked={false}
                    info={""}
                    infoBox={true}
                  />
                </Row>
                <Row justifyContent={"space-between"}>
                  <BasicText
                    style={{
                      width: "18vw",
                      color: colors.text,
                    }}
                  >
                    <b>{t("shiftScreenPausesNoLunchP1")}</b>
                    {t("shiftScreenPausesNoLunchP2")}
                  </BasicText>
                  <TextBox
                    value={shift.d_pause}
                    onChange={(value) => {
                      if (isPositiveInteger(value)) {
                        setSavingEnabled(true);
                        setShift({
                          ...shift,
                          d_pause: value ? parseInt(value) : 0,
                        });
                      }
                    }}
                    unit={"m"}
                    unitMargin={"0.2vw"}
                    locked={false}
                    infoBox={true}
                  />
                  <BasicText
                    style={{
                      width: "18vw",
                      color: colors.text,
                    }}
                  >
                    <b>{t("shiftScreenPausesEffHoursP1")}</b>
                    {t("shiftScreenPausesEffHoursP2")}
                  </BasicText>
                  <InfoBox
                    value={shiftRes.output.durata_complessiva_pause_mensa}
                    unit={"m"}
                    ai={false}
                    info={
                      tips[document.language]
                        .somma_delle_pause_in_orario_di_lavoro
                    }
                    infoBox={true}
                  />
                </Row>
              </>,
              <>
                <Row justifyContent={"space-between"}>
                  <BasicText
                    style={{
                      width: "18vw",
                      color: colors.text,
                    }}
                  >
                    {t("shiftScreenRefeptitive")}
                  </BasicText>
                  <CheckBox
                    label={t("present")}
                    value={shift.task_non_ripetitivi}
                    onClick={() => {
                      setSavingEnabled(true);
                      setShift({
                        ...shift,
                        task_non_ripetitivi: !shift.task_non_ripetitivi,
                      });
                    }}
                    infoBox={true}
                  />
                  <BasicText
                    style={{
                      width: "18vw",
                      color: colors.text,
                    }}
                  >
                    {t("shiftScreenTimeReach")}
                  </BasicText>
                  <TextBox
                    value={shift.task_non_ripetitivi ? shift.t_ragg_post : "-"}
                    onChange={(value) => {
                      if (isPositiveInteger(value)) {
                        setSavingEnabled(true);
                        setShift({
                          ...shift,
                          t_ragg_post: value ? parseInt(value) : 0,
                        });
                      }
                    }}
                    locked={!shift.task_non_ripetitivi}
                    unit={"m"}
                    unitMargin={"0.2vw"}
                    infoBox={true}
                  />
                </Row>
                <Row justifyContent={"space-between"}>
                  <BasicText
                    style={{
                      width: "18vw",
                      color: colors.text,
                    }}
                  >
                    {t("shiftScreenTimeDPI")}
                  </BasicText>
                  <TextBox
                    value={shift.task_non_ripetitivi ? shift.t_vestirsi : "-"}
                    onChange={(value) => {
                      if (isPositiveInteger(value)) {
                        setSavingEnabled(true);
                        setShift({
                          ...shift,
                          t_vestirsi: value ? parseInt(value) : 0,
                        });
                      }
                    }}
                    unit={"m"}
                    unitMargin={"0.2vw"}
                    locked={!shift.task_non_ripetitivi}
                    infoBox={true}
                  />
                  <BasicText
                    style={{
                      width: "18vw",
                      color: colors.text,
                    }}
                  >
                    {t("shiftScreenOther")}
                  </BasicText>
                  <TextBox
                    value={shift.task_non_ripetitivi ? shift.t_altro : "-"}
                    onChange={(value) => {
                      if (isPositiveInteger(value)) {
                        setSavingEnabled(true);
                        setShift({
                          ...shift,
                          t_altro: value ? parseInt(value) : 0,
                        });
                      }
                    }}
                    locked={!shift.task_non_ripetitivi}
                    unit={"m"}
                    unitMargin={"0.2vw"}
                    infoBox={true}
                  />
                </Row>
                <Row justifyContent={"space-between"}>
                  <BasicText
                    style={{
                      width: "18vw",
                      color: colors.text,
                    }}
                  >
                    {t("shiftScreenCleaning")}
                  </BasicText>
                  <TextBox
                    value={shift.task_non_ripetitivi ? shift.t_pulizie : "-"}
                    onChange={(value) => {
                      if (isPositiveInteger(value)) {
                        setSavingEnabled(true);
                        setShift({
                          ...shift,
                          t_pulizie: value ? parseInt(value) : 0,
                        });
                      }
                    }}
                    unit={"m"}
                    unitMargin={"0.2vw"}
                    locked={!shift.task_non_ripetitivi}
                    infoBox={true}
                  />
                  <BasicText
                    style={{
                      width: "18vw",
                      color: colors.text,
                    }}
                  >
                    <b>{t("shiftScreenNonRepP1")}</b>
                    {t("shiftScreenNonRepP2")}
                  </BasicText>
                  <InfoBox
                    value={shiftRes.output.durata_non_ripetitivi}
                    unit={"m"}
                    ai={false}
                    info={tips[document.language].durata_lavori_non_ripetitivi}
                    infoBox={true}
                  />
                </Row>
              </>,
            ]}
          />
        }
      />
    </>
  );
};

const JobScreen = ({
  id,
  setDepartmentDetail,
  departmentDetail,
  currentDepartment,
  setData,
  data,
  location,
}) => {
  for (
    let i = 0;
    i < departmentDetail[currentDepartment].jobs[id].tasks.length;
    i++
  ) {
    departmentDetail[currentDepartment].jobs[id].tasks[i] = {
      ...departmentDetail[currentDepartment].jobs[id].tasks[i],
    };
  }
  // END STATIC
  const [state, dispatch] = useStateValue();

  const [job, setJob] = useState({
    ...departmentDetail[currentDepartment].jobs[id],
  });
  const [shift, setShift] = useState(
    departmentDetail[currentDepartment].workshifts[
      departmentDetail[currentDepartment].jobs[id].workshift
    ]
  );
  const [tasks, setTasks] = useState(
    departmentDetail[currentDepartment].jobs[id].tasks
  );
  const [savingEnabled, setSavingEnabled] = useState(false);
  const [loadedJobs, setLoadedJobs] = useState(false);
  const [shiftRes, setShiftRes] = useState();
  const [saving, setSaving] = useState(false);
  const [generating, setGenerating] = useState(false);
  const [resultComponent, setResultComponent] = useState(
    <Spinner size={"3vw"} />
  );

  const popupRef = useRef();
  const reportRef = useRef();
  const reportEnabledRef = useRef(false);
  const jobRef = useRef();
  const deleted = useRef(false);
  const resultComponentRef = useRef();
  const multitaskRef = useRef();
  const singletaskRef = useRef();

  const { user } = useUser();
  const { t } = useTranslation();

  resultComponentRef.current = resultComponent;
  jobRef.current = job;
  const { session } = useClerk();
  const shiftOptions = Object.keys(
    departmentDetail[currentDepartment].workshifts
  ).map((key) => ({
    value: key,
    label: departmentDetail[currentDepartment].workshifts[key].name,
  }));

  const extractTaskList = (tasks, shiftRes) => {
    return tasks.map((x, id) => {
      return {
        percentuale_originale: x.durata / 100,
        min_lavorati_per_compito: 0,
        percentuale_su_480_min: 0,
        minuti_turno: 0,
        moltiplicatore_recupero: shiftRes.moltiplicatore_recupero,
        durata_media_netta: shiftRes.durata_media_netta,
        n_ore_senza_recupero: shiftRes.numero_ore_senza_recupero_automatico,
        sx: x.task?.ocra?.sx,
        dx: x.task?.ocra?.dx,
      };
    });
  };

  useEffect(() => {
    const updatedCurrentDepartment = {
      ...departmentDetail[currentDepartment],
      jobs: {
        ...departmentDetail[currentDepartment].jobs,
        [id]: job,
      },
    };

    //Update local department details
    const updatedDepartmentDetails = {
      ...departmentDetail,
      [currentDepartment]: updatedCurrentDepartment,
    };
    setDepartmentDetail(updatedDepartmentDetails);
    setSavingEnabled(true);
  }, [job]);

  useEffect(() => {
    if (shift) setShiftRes(shift_global(shift));
  }, [shift]);

  useEffect(() => {
    if (loadedJobs) {
      let res = multitaskResult(shift, tasks);
      setResultComponent(res);
    }
  }, [loadedJobs, tasks]);

  useEffect(() => {
    handleLoadDepartment(
      currentDepartment,
      (newDetail) => {
        setData({ ...data });

        setTasks(filterInvalidTasks(newDetail.jobs[id].tasks));
      },
      departmentDetail,
      session,
      departmentDetail[currentDepartment].locationId,
      (x) => setDepartmentDetail(x)
    );
    return () => {
      if (!deleted.current) {
        //Create an updated version of the department
        const updatedCurrentDepartment = {
          ...departmentDetail[currentDepartment],
          jobs: {
            ...departmentDetail[currentDepartment].jobs,
            [id]: jobRef.current,
          },
        };

        //Update local department details
        const updatedDepartmentDetails = {
          ...departmentDetail,
          [currentDepartment]: updatedCurrentDepartment,
        };
        setDepartmentDetail(updatedDepartmentDetails);

        //Update online department details
        handleSaving(id, jobRef.current);
      }
    };
  }, []);

  const handleSaving = async (id, job, onSuccess) => {
    setSaving(true);
    if (job.workshift) {
      let request = await updateJob(
        departmentDetail[currentDepartment].locationId,
        departmentDetail[currentDepartment].id,
        {
          name: job.name,
          workshift: job.workshift,
          n_employees: job.n_employees,
        },
        id,
        await getToken(session)
      );
      setSaving(false);
    }
    setSavingEnabled(false);
    if (onSuccess) onSuccess();
  };

  const handleDelete = async () => {
    deleted.current = true;

    let request = await deleteJob(
      departmentDetail[currentDepartment].locationId,
      departmentDetail[currentDepartment].id,
      id,
      await getToken(session)
    );
    // Crea una copia degli attuali workshifts
    const updatedJobs = {
      ...departmentDetail[currentDepartment].jobs,
    };

    // Rimuovi lo shift specifico
    delete updatedJobs[data.id];

    // Crea l'oggetto updatedCurrentDepartment senza lo shift rimosso
    const updatedCurrentDepartment = {
      ...departmentDetail[currentDepartment],
      jobs: updatedJobs,
    };

    // Aggiorna i dettagli del dipartimento nel contesto globale o nello stato
    const updatedDepartmentDetails = {
      ...departmentDetail,
      [currentDepartment]: updatedCurrentDepartment,
    };
    setData({ type: "delete", element: jobRef.current.name });
    setDepartmentDetail(updatedDepartmentDetails);
  };

  const handleDeleteJobTask = async (ids) => {
    const updatedJob = {
      ...departmentDetail[currentDepartment].jobs[data.id],
    };

    for (const id of ids) {
      let request = await deleteJobTask(
        departmentDetail[currentDepartment].locationId,
        departmentDetail[currentDepartment].id,
        data.id,
        id,
        await getToken(session)
      );

      // Rimuovi lo shift specifico
      updatedJob.tasks = updatedJob.tasks.filter((task) => task.id !== id);
    }

    handleLoadDepartment(
      currentDepartment,
      (newDetail) => {
        setData({ ...data });

        setTasks(filterInvalidTasks(newDetail.jobs[id].tasks));
        popupRef.current.close();
      },
      departmentDetail,
      session,
      departmentDetail[currentDepartment].locationId,
      (x) => setDepartmentDetail(x)
    );
  };

  const handleChangeTime = async (id, value) => {
    let index = departmentDetail[currentDepartment].jobs[
      data.id
    ].tasks.findIndex((task) => task.id === id);
    departmentDetail[currentDepartment].jobs[data.id].tasks[index].durata =
      value;
    let updatedTasks = [...tasks];
    updatedTasks[index].durata = value;

    setTasks(updatedTasks);

    let request = await updateJobTask(
      departmentDetail[currentDepartment].locationId,
      departmentDetail[currentDepartment].id,
      data.id,
      id,
      { durata: value },
      await getToken(session)
    );
  };

  function estraiTaskConDurata(arrayOggetti) {
    return arrayOggetti.map((oggetto) => {
      // Estrae le chiavi di task e la durata
      return {
        ...oggetto.task,
        durata: oggetto.durata,
        idLink: oggetto.id,
      };
    });
  }

  function areScoresValid(array) {
    for (let element of array) {
      if (element?.task?.scores?.dx >= 0 || element?.task?.scores?.sx >= 0) {
        return false;
      }
    }
    return true;
  }

  function multitaskResult(shift, tasks) {
    reportEnabledRef.current = false;
    let multitask;
    let singleTask;
    if (shift) {
      let shiftUpdated = shift_global(shift);
      setShiftRes(shiftUpdated);
      try {
        multitask = multitask_global(extractTaskList(tasks, shiftRes.output));
      } catch {
        multitask = {};
      }
      try {
        let task = tasks[0].task;
        let cycleNumber = Math.round(
          (shiftRes.output.durata_media_netta * 60) /
            task.ocra.sx.otherTemp.tempoCiclo
        );
        let minuti_recupero_ciclo = Math.round(
          (cycleNumber * task.ocra.sx.otherTemp.totale_secondi_tempo_passivo) /
            60
        );
        let durata_netta_esclusi_rec =
          shiftRes.output.durata_media_netta - minuti_recupero_ciclo; //Ho usato la durata calcolata come sensato diverso da excel, da verificare
        let moltiplicatore_durata = trova_moltiplicatore_durata(
          durata_netta_esclusi_rec
        );
        let moltiplicatore_recupero =
          task.ocra.sx.otherTemp.moltiplicatore_recupero == 1
            ? task.ocra.sx.otherTemp.moltiplicatore_recupero
            : shiftRes.output.moltiplicatore_recupero;
        singleTask = {
          dx: {
            intrinseco:
              Math.round(task.scores.dx * moltiplicatore_recupero * 10) / 10,
            ponderato:
              Math.round(
                task.scores.dx *
                  moltiplicatore_recupero *
                  moltiplicatore_durata *
                  10
              ) / 10,
          },
          sx: {
            intrinseco:
              Math.round(task.scores.sx * moltiplicatore_recupero * 10) / 10,
            ponderato:
              Math.round(
                task.scores.sx *
                  moltiplicatore_recupero *
                  moltiplicatore_durata *
                  10
              ) / 10,
          },
          moltiplicatore_recupero: moltiplicatore_recupero,
          molti_overwritten:
            task.ocra.sx.otherTemp.moltiplicatore_recupero == 1,
          cycleNumber: cycleNumber,
        };
      } catch {
        singleTask = {};
      }
    }
    singletaskRef.current = singleTask;
    multitaskRef.current = multitask;

    if (!shift)
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <p
            style={{
              color: colors.disabledText,
              fontFamily: "Atkinson Hyperlegible",
              fontSize: "0.9vw",
              fontWeight: "400",
              boxSizing: "border-box",
              margin: "0",
            }}
          >
            {t("jobScreenNoShift")}
          </p>
        </div>
      );
    if (tasks.length == 0)
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <p
            style={{
              color: colors.disabledText,
              fontFamily: "Atkinson Hyperlegible",
              fontSize: "0.9vw",
              fontWeight: "400",
              boxSizing: "border-box",
              margin: "0",
              width: "80%",
              textAlign: "center",
            }}
          >
            {t("jobScreenNoTask")}
          </p>
        </div>
      );

    if (
      areScoresValid(tasks) ||
      !multitask?.mtUnder90?.dx ||
      !multitask?.mtUnder90?.sx ||
      !multitask?.mtOver90?.dx ||
      !multitask?.mtOver90?.sx
    )
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <p
            style={{
              color: colors.disabledText,
              fontFamily: "Atkinson Hyperlegible",
              fontSize: "0.9vw",
              fontWeight: "400",
              boxSizing: "border-box",
              margin: "0",
              width: "80%",
              textAlign: "center",
            }}
          >
            {t("jobScreenNoRes")}
          </p>
        </div>
      );

    if (tasks.reduce((acc, curr) => acc + curr.durata, 0) != 100)
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <p
            style={{
              color: colors.disabledText,
              fontFamily: "Atkinson Hyperlegible",
              fontSize: "0.9vw",
              fontWeight: "400",
              boxSizing: "border-box",
              margin: "0",
              width: "80%",
              textAlign: "center",
            }}
          >
            {t("jobScreenPerc")}
          </p>
        </div>
      );
    reportEnabledRef.current = true;
    if (tasks.length == 1)
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "start",
            width: "100%",

            gap: "2vw",
            marginBottom: singleTask.molti_overwritten && "-2vw",
          }}
        >
          <ScoreTab
            mapping={mappingOcra["prec"]}
            scores={[
              {
                label: "INTRINSECO",
                info: tips[document.language].finale_intrinseco,
                dx: singleTask.dx.intrinseco,
                sx: singleTask.sx.intrinseco,
              },
              {
                label: "FINALE PONDERATO",
                info: tips[document.language].finale_ponderato,
                dx: singleTask.dx.ponderato,
                sx: singleTask.sx.ponderato,
              },
            ]}
          />
          {singleTask.molti_overwritten && (
            <Row justifyContent={"start"}>
              <BasicText
                style={{
                  width: "24vw",
                }}
              >
                {t("jobScreenMoltiRec")}
              </BasicText>
              <InfoBox
                value={1}
                ai={false}
                unit={""}
                info={tips[document.language].moltiplicatore_recupero}
              />
            </Row>
          )}
        </div>
      );
    return (
      <ScoreTab
        mapping={mappingOcra["prec"]}
        scores={[
          {
            label: t("jobScreenLess90"),
            info: tips[document.language].rotazione_minore_90,
            dx: multitask.mtUnder90.dx,
            sx: multitask.mtUnder90.sx,
          },
          {
            label: t("jobScreenMore90"),
            info: tips[document.language].rotazione_maggiore_90,
            dx: multitask.mtOver90.dx,
            sx: multitask.mtOver90.sx,
          },
        ]}
      />
    );
  }

  return (
    <>
      <div
        style={{
          height: "10%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          width: "60.3vw",
        }}
      >
        <p
          style={{
            color: colors.text,
            fontFamily: "Atkinson Hyperlegible",
            fontSize: "1.6vw",
            fontWeight: "400",
            marginLeft: "0vw",
            marginTop: "1.9vw",
            boxSizing: "border-box",
            width: "65%",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          <b>{job.name}</b>
        </p>
        <div
          style={{
            height: "10%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "1vw",
          }}
        >
          <ButtonIcon
            icon={process.env.REACT_APP_RESOURCES_BUCKET + "Delete.png"}
            iconH={process.env.REACT_APP_RESOURCES_BUCKET + "DeleteHw.png"}
            onClick={handleDelete}
            style={{ width: "1.875vw" }}
          />
          <ButtonTextIcon
            disabled={!savingEnabled}
            label={t("save")}
            loading={saving}
            loadingLabel={""}
            loadingStyle={{}}
            style={{
              width: "6.2vw",
              justifyContent: saving ? "center" : "start",
            }}
            icon={process.env.REACT_APP_RESOURCES_BUCKET + "Save.png"}
            backgroundH={colors.details}
            background={"white"}
            color={colors.main}
            onClick={() => {
              handleSaving(id, jobRef.current);
            }}
          />
          <ButtonTextIcon
            label={t("jobScreenReport")}
            icon={process.env.REACT_APP_RESOURCES_BUCKET + "download.png"}
            loadingStyle={{}}
            style={{
              width: "11.5vw",
              justifyContent: "center",
              marginRight: "0.2vw",
            }}
            backgroundH={colors.mainH}
            disabled={!reportEnabledRef.current}
            background={colors.main}
            loading={generating}
            loadingLabel={""}
            onClick={() => {
              ReportGenerator({
                job: job,
                tasks: tasks,
                shift: shift,
                multitask: multitaskRef.current,
                singleTask: singletaskRef.current,
                department: departmentDetail[currentDepartment],
                location: location,
                user: user,
                session: session,
                setGenerating: setGenerating,
                t: t,
              });

              const exampleData = {
                info: {
                  company: location.company,
                  workstation: job.name,
                  department: departmentDetail[currentDepartment].name,
                },
                tasks: tasks,
                shift: shift,
                left_video: {
                  ocra: {
                    shoulder: { left_wrong_time: 5.4 },
                    elbow: { left_wrong_time: 3.1 },
                    wrist: { left_wrong_time: 2.8 },
                    azioni_tecniche: { left_dynamic_actions: 7 },
                  },
                },
                right_video: {
                  ocra: {
                    shoulder: { right_wrong_time: 6.2 },
                    elbow: { right_wrong_time: 4.5 },
                    wrist: { right_wrong_time: 3.9 },
                    azioni_tecniche: { right_dynamic_actions: 8 },
                  },
                },
                hands: {
                  ocra: {
                    grabs: {
                      left_wrong_time: 1.2,
                      right_wrong_time: 1.5,
                    },
                  },
                },
              };

              const fileName = job.name;

              ExcelDownloader(exampleData, fileName, session);
            }}
            // {ref={reportRef}
            // job={job}
            // shift={shift}
            // department={departmentDetail[currentDepartment]}
            // location={location}
            // departmentDetail={departmentDetail}
            // currentDepartment={currentDepartment}
            // multitask={multitask}
            // info={""}
            // title={"REPORT "}
            // actionLabel={"action"}
            // onSuccess={() => {}})}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "start",
          width: "100%",
          gap: "0.5vw",
          alignItems: "stretch",
        }}
      >
        <InfoTab
          width={"29.8vw"}
          height={""}
          title={[t("jobScreenDetails")]}
          justifyContent={"start"}
          gap={"0.5vw"}
          justifyContentMain={"start"}
          content={[
            <>
              <Row justifyContent={"space-between"}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                  }}
                >
                  <img
                    src={process.env.REACT_APP_RESOURCES_BUCKET + "Worker.png"}
                    style={{
                      width: "1.875vw",
                      height: "1.875vw",
                      margin: "0px",
                      marginRight: "0.2vw",
                    }}
                  />
                  <BasicText
                    style={{
                      width: "5vw",
                      color: colors.text,
                    }}
                  >
                    {t("name")}
                  </BasicText>
                </div>
                <TextBox
                  width={"16.4vw"}
                  value={job.name}
                  onChange={(value) => {
                    setJob({ ...job, name: value });
                  }}
                  locked={false}
                />
              </Row>

              <Row justifyContent={"space-between"}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                  }}
                >
                  <img
                    src={
                      process.env.REACT_APP_RESOURCES_BUCKET + "Timetables2.png"
                    }
                    style={{
                      width: "1.875vw",
                      height: "1.875vw",
                      margin: "0px",
                      marginRight: "0.2vw",
                    }}
                  />
                  <BasicText
                    style={{
                      width: "",
                      color: colors.text,
                    }}
                  >
                    {t("jobScreenShift")}
                  </BasicText>
                </div>
                <Selector
                  value={shiftOptions.find((x) => x.value == job.workshift)}
                  options={shiftOptions}
                  onChange={(value) => {
                    setJob({ ...job, workshift: value.value });
                    setShift(
                      departmentDetail[currentDepartment].workshifts[
                        value.value
                      ]
                    );
                  }}
                  single={true}
                  width={"16.4vw"}
                  height={"2.5vw"}
                />
              </Row>
              <Row justifyContent={"start"}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                  }}
                >
                  <img
                    src={process.env.REACT_APP_RESOURCES_BUCKET + "Time.png"}
                    style={{
                      width: "1.875vw",
                      height: "1.875vw",
                      margin: "0px",
                      marginRight: "0.2vw",
                    }}
                  />
                  <BasicText
                    style={{
                      width: "8.3vw",
                      color: colors.text,
                    }}
                  >
                    {t("jobScreenShiftDuration")}
                  </BasicText>
                </div>
                <InfoBox
                  value={
                    shiftRes?.output?.durata_media_netta
                      ? shiftRes.output?.durata_media_netta
                      : "-"
                  }
                  unit={"m"}
                  ai={false}
                  info={tips[document.language].durata_media_netta}
                />
              </Row>
              <Row justifyContent={"start"}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                  }}
                >
                  <img
                    src={process.env.REACT_APP_RESOURCES_BUCKET + "People.png"}
                    style={{
                      width: "1.875vw",
                      height: "1.875vw",
                      margin: "0px",
                      marginRight: "0.2vw",
                    }}
                  />
                  <BasicText
                    style={{
                      width: "8.3vw",
                      color: colors.text,
                    }}
                  >
                    {t("jobScreenWorkersNumber")}
                  </BasicText>
                </div>
                <TextBox
                  value={job.n_employees}
                  onChange={(value) => {
                    if (isPositiveInteger(value)) {
                      setJob({ ...job, n_employees: value });
                    }
                  }}
                  locked={false}
                  infoBox={true}
                />
              </Row>
            </>,
          ]}
        />
        <InfoTab
          width={"29.8vw"}
          height={""}
          title={
            tasks.length == 1
              ? [t("jobScreenScoreSingle")]
              : [t("jobScreenScoreMulti")]
          }
          justifyContent={"start"}
          gap={"0.5vw"}
          justifyContentMain={"start"}
          content={resultComponentRef.current}
        />
      </div>
      <InfoTabScroller
        style={{ width: "60.1vw", overflow: "hidden" }}
        key={job.id}
        infoTab={
          <InfoTab
            fullWidth={true}
            width={"100%"}
            height={"100%"}
            title={[t("jobScreenTasksJob")]}
            justifyContent={"start"}
            gap={"0.5vw"}
            justifyContentMain={"start"}
            // style={{ flex: 0.95 }}
            content={
              <JobTable
                loadedJobs={loadedJobs}
                setLoadedJobs={() => setLoadedJobs(true)}
                tasks={estraiTaskConDurata(tasks)}
                unlinkIcon={true}
                onSave={() => {
                  handleLoadDepartment(
                    currentDepartment,
                    (newDetail) => {
                      setData({ ...data });
                      setTasks(filterInvalidTasks(newDetail.jobs[id].tasks));
                      popupRef.current.close();
                    },
                    departmentDetail,
                    session,
                    departmentDetail[currentDepartment].locationId,
                    (x) => setDepartmentDetail(x)
                  );
                }}
                columns={["name", "duration", "protocol", "state", "score"]}
                duration={
                  shiftRes?.output?.durata_media_netta
                    ? shiftRes?.output?.durata_media_netta
                    : 0
                }
                structure={true}
                deleteBtn={(x) => handleDeleteJobTask(x)}
                onChangeTime={handleChangeTime}
                empty={
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      height: "100%",
                      justifyContent: "center",
                      gap: "0.5vw",
                    }}
                  >
                    <img
                      src={
                        process.env.REACT_APP_RESOURCES_BUCKET +
                        "JobPlaceholder.png"
                      }
                      style={{
                        width: "11.7vw",
                        marginTop: "-2vw",
                      }}
                    />{" "}
                    <p
                      style={{
                        color: colors.text,
                        fontFamily: "Atkinson Hyperlegible",
                        fontSize: "0.9vw",
                        fontWeight: "400",
                        marginLeft: "0vw",
                        marginTop: "0vw",
                        boxSizing: "border-box",
                        textAlign: "center",
                        width: "12vw",
                      }}
                    >
                      {t("jobScreenNoTasks")}
                    </p>
                    <ButtonTextIcon
                      label={t("add")}
                      icon={
                        process.env.REACT_APP_RESOURCES_BUCKET + "CrossWh.png"
                      }
                      backgroundH={colors.tertiaryH}
                      background={colors.tertiary}
                      color={"white"}
                      onClick={() => {
                        popupRef.current.open();
                      }}
                      style={{ paddingTop: "0.3vw", paddingBottom: "0.3vw" }}
                    />
                  </div>
                }
              />
            }
            titleButton={
              <ButtonTextIcon
                label={t("jobScreenAddTask")}
                color={colors.main}
                backgroundH={colors.details}
                background={"transparent"}
                icon={process.env.REACT_APP_RESOURCES_BUCKET + "Plus.png"}
                iconH={process.env.REACT_APP_RESOURCES_BUCKET + "Plus.png"}
                onClick={() => {
                  popupRef.current.open();
                }}
                style={{
                  fontSize: "0.82vw",
                  boxShadow: "0px 0px 0px 0px white",
                  height: "2vw",
                  fontWeight: "700",
                  marginRight: "0.5vw",
                  padding: "0.3vw",
                  paddingRight: "0.4vw",

                  boxSizing: "border-box",
                }}
              />
            }
          />
        }
      />
      <PopupAdd
        MainOwnerCompany={state.current_location.MainOwnerCompany}
        ref={popupRef}
        info={""}
        department={departmentDetail[currentDepartment]}
        id={id}
        tasksAssigned={tasks}
        title={t("jobTasksPopupTitle")}
        jobName={job.name}
        actionLabel={"action"}
        onSuccess={() => {
          handleLoadDepartment(
            currentDepartment,
            (newDetail) => {
              setData({ ...data });
              setTasks(filterInvalidTasks(newDetail.jobs[id].tasks));
              popupRef.current.close();
            },
            departmentDetail,
            session,
            departmentDetail[currentDepartment].locationId,
            (x) => setDepartmentDetail(x)
          );
        }}
      />
    </>
  );
};

const WsScreen = ({
  data,
  setDepartmentDetail,
  departmentDetail,
  currentDepartment,
  setData,
}) => {
  const [state, dispatch] = useStateValue();
  const [ws, setWs] = useState({
    ...departmentDetail[currentDepartment].lines[data.line][data.id],
  });
  const [tasks, setTasks] = useState([]);
  const [savingEnabled, setSavingEnabled] = useState(false);
  const [saving, setSaving] = useState(false);

  const deleted = useRef(false);
  const popupRef = useRef();
  const wsRef = useRef();

  wsRef.current = ws;

  const { session } = useClerk();
  const { t } = useTranslation();

  const loadTasks = async () => {
    let tasks = await getTaskPerStation(
      departmentDetail[currentDepartment].locationId,
      currentDepartment,
      data.line,
      data.id,
      await getToken(session)
    );
    let taskDep = tasks.map((x) => {
      return {
        ...x,
        department: currentDepartment,
        station: x.stationsObject.id,
      };
    });
    let validTask = filterInvalidTasks(taskDep);
    console.log("validTask", validTask);
    setTasks(
      filterInvalidTasks(taskDep).sort((a, b) => a.name.localeCompare(b.name))
    );
  };

  useEffect(() => {
    loadTasks();
  }, []);

  useEffect(() => {
    //Create an updated version of the department
    const updatedCurrentDepartment = {
      ...departmentDetail[currentDepartment],
      lines: {
        ...departmentDetail[currentDepartment].lines,
        [data.line]: {
          ...departmentDetail[currentDepartment].lines[data.line],
          [data.id]: ws,
        },
      },
    };

    //Update local department details
    const updatedDepartmentDetails = {
      ...departmentDetail,
      [currentDepartment]: updatedCurrentDepartment,
    };
    setDepartmentDetail(updatedDepartmentDetails);
    setSavingEnabled(true);
  }, [ws]);

  useEffect(() => {
    return () => {
      if (!deleted.current) {
        const updatedCurrentDepartment = {
          ...departmentDetail[currentDepartment],
          lines: {
            ...departmentDetail[currentDepartment].lines,
            [data.line]: {
              ...departmentDetail[currentDepartment].lines,
              [data.id]: wsRef.current,
            },
          },
        };

        //Update local department details
        const updatedDepartmentDetails = {
          ...departmentDetail,
          [currentDepartment]: updatedCurrentDepartment,
        };
        setDepartmentDetail(updatedDepartmentDetails);

        handleSaving();
      }
    };
  }, []);

  const handleSaving = async () => {
    setSaving(true);
    let request = await updateStation(
      departmentDetail[currentDepartment].locationId,
      departmentDetail[currentDepartment].id,
      data.line,
      data.id,
      { name: wsRef.current.name },
      await getToken(session)
    );
    setSavingEnabled(false);
    setSaving(false);
  };

  const handleDelete = async () => {
    deleted.current = true;

    let request = await deleteStation(
      departmentDetail[currentDepartment].locationId,
      departmentDetail[currentDepartment].id,
      data.line,
      data.id,
      await getToken(session)
    );

    // Crea una copia degli attuali workshifts
    const updatedWs = {
      ...departmentDetail[currentDepartment].lines[data.line],
    };

    // Rimuovi lo shift specifico
    delete updatedWs[data.id];

    // Crea l'oggetto updatedCurrentDepartment senza lo shift rimosso
    const updatedCurrentDepartment = {
      ...departmentDetail[currentDepartment],
      lines: {
        ...departmentDetail[currentDepartment].lines,
        [data.line]: updatedWs,
      },
    };

    // Aggiorna i dettagli del dipartimento nel contesto globale o nello stato
    const updatedDepartmentDetails = {
      ...departmentDetail,
      [currentDepartment]: updatedCurrentDepartment,
    };
    setData({ type: "delete", element: wsRef.current.name });
    setDepartmentDetail(updatedDepartmentDetails);
  };

  const handleDeleteTask = async (ids) => {
    for (const id of ids) {
      let request = await deleteTask(id, await getToken(session));
    }

    handleLoadDepartment(
      currentDepartment,
      () => {
        loadTasks();
        popupRef.current.close();
      },
      departmentDetail,
      session,
      departmentDetail[currentDepartment].locationId,
      (x) => setDepartmentDetail(x)
    );
  };

  return (
    <>
      <div
        style={{
          height: "10%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          width: "60.3vw",
        }}
      >
        <p
          style={{
            color: colors.text,
            fontFamily: "Atkinson Hyperlegible",
            fontSize: "1.6vw",
            fontWeight: "400",
            marginLeft: "0vw",
            marginTop: "1.9vw",
            boxSizing: "border-box",
            width: "65%",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          <b>{ws.name}</b>
        </p>
        <div
          style={{
            height: "10%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "1vw",
            marginRight: "0.1vw",
          }}
        >
          {/* <ButtonIcon
            icon={
              process.env.REACT_APP_RESOURCES_BUCKET+"Delete.png"
            }
            iconH={
              process.env.REACT_APP_RESOURCES_BUCKET+"DeleteHw.png"
            }
            onClick={handleDelete}
            style={{ width: "1.875vw" }}
          /> */}
          <ButtonTextIcon
            disabled={!savingEnabled}
            label={t("save")}
            loading={saving}
            loadingLabel={""}
            loadingStyle={{}}
            style={{
              width: "6.2vw",
              justifyContent: saving ? "center" : "start",
            }}
            icon={process.env.REACT_APP_RESOURCES_BUCKET + "Save.png"}
            backgroundH={colors.details}
            background={"white"}
            color={colors.main}
            onClick={() => {
              handleSaving();
            }}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "start",
          width: "100%",
          gap: "0.5vw",
          alignItems: "stretch",
        }}
      >
        <InfoTab
          width={"60.1vw"}
          height={""}
          title={[t("workspaceScreenDetails")]}
          justifyContent={"start"}
          gap={"0.5vw"}
          justifyContentMain={"start"}
          content={[
            <>
              <Row justifyContent={"start"}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                  }}
                >
                  <img
                    src={process.env.REACT_APP_RESOURCES_BUCKET + "Table.png"}
                    style={{
                      width: "1.875vw",
                      height: "1.875vw",
                      margin: "0px",
                      marginRight: "0.2vw",
                    }}
                  />
                  <BasicText
                    style={{
                      width: "10vw",
                      color: colors.text,
                    }}
                  >
                    {t("name")}
                  </BasicText>
                </div>
                <TextBox
                  width={"16.4vw"}
                  value={ws.name}
                  onChange={(value) => {
                    // ws.name = value;
                    setWs({ ...ws, name: value });
                  }}
                  locked={false}
                />
              </Row>
              <Row justifyContent={"start"}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                  }}
                >
                  <img
                    src={process.env.REACT_APP_RESOURCES_BUCKET + "Factory.png"}
                    style={{
                      width: "1.875vw",
                      height: "1.875vw",
                      margin: "0px",
                      marginRight: "0.2vw",
                    }}
                  />
                  <BasicText
                    style={{
                      width: "10vw",
                      color: colors.text,
                    }}
                  >
                    {t("department")}
                  </BasicText>
                </div>
                <TextBox
                  width={"16.4vw"}
                  value={departmentDetail[currentDepartment].name}
                  onChange={() => {}}
                  locked={true}
                />
              </Row>
              <Row justifyContent={"start"}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                  }}
                >
                  <img
                    src={process.env.REACT_APP_RESOURCES_BUCKET + "Area.png"}
                    style={{
                      width: "1.875vw",
                      height: "1.875vw",
                      margin: "0px",
                      marginRight: "0.2vw",
                    }}
                  />
                  <BasicText
                    style={{
                      width: "10vw",
                      color: colors.text,
                    }}
                  >
                    {t("area")}
                  </BasicText>
                </div>
                <TextBox
                  width={"16.4vw"}
                  value={ws.line}
                  onChange={() => {}}
                  locked={true}
                />
              </Row>
            </>,
          ]}
        />
      </div>
      <InfoTabScroller
        style={{ width: "60.1vw" }}
        key={data.id}
        infoTab={
          <InfoTab
            fullWidth={true}
            width={"60.1vw"}
            height={"100%"}
            title={[t("workspaceScreenTasks")]}
            justifyContent={"start"}
            gap={"0.5vw"}
            justifyContentMain={"start"}
            // style={{ flex: 0.95 }}
            content={
              <JobTable
                tasks={tasks}
                onSave={() => loadTasks()}
                structure={true}
                columns={["name", "protocol", "state", "score"]}
                deleteBtn={(x) => handleDeleteTask(x)}
                empty={
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      height: "100%",
                      justifyContent: "center",
                      gap: "0.5vw",
                    }}
                  >
                    <img
                      src={
                        process.env.REACT_APP_RESOURCES_BUCKET +
                        "WsPlaceholder.png"
                      }
                      style={{
                        width: "11.7vw",
                        marginTop: "-0.5vw",
                      }}
                    />{" "}
                    <p
                      style={{
                        color: colors.text,
                        fontFamily: "Atkinson Hyperlegible",
                        fontSize: "0.9vw",
                        fontWeight: "400",
                        marginLeft: "0vw",
                        marginTop: "0vw",
                        boxSizing: "border-box",
                        textAlign: "center",
                        width: "12vw",
                      }}
                    >
                      {t("workspaceScreenNoTasksAssigned")}
                    </p>
                    <ButtonTextIcon
                      label={t("workspaceScreenCreate")}
                      icon={
                        process.env.REACT_APP_RESOURCES_BUCKET + "CrossWh.png"
                      }
                      backgroundH={colors.tertiaryH}
                      background={colors.tertiary}
                      color={"white"}
                      onClick={() => {
                        popupRef.current.open();
                      }}
                      style={{
                        paddingTop: "0.3vw",
                        paddingBottom: "0.3vw",
                      }}
                    />
                  </div>
                }
              />
            }
            titleButton={
              <ButtonTextIcon
                label={t("workspaceScreenCreate")}
                color={colors.main}
                backgroundH={colors.details}
                background={"transparent"}
                icon={process.env.REACT_APP_RESOURCES_BUCKET + "Plus.png"}
                iconH={process.env.REACT_APP_RESOURCES_BUCKET + "Plus.png"}
                onClick={() => {
                  popupRef.current.open();
                }}
                style={{
                  fontSize: "0.82vw",
                  boxShadow: "0px 0px 0px 0px white",
                  height: "2vw",
                  fontWeight: "700",
                  marginRight: "0.5vw",
                  padding: "0.3vw",
                  paddingRight: "0.4vw",
                  boxSizing: "border-box",
                }}
              />
            }
          />
        }
      />
      <PopupCreate
        ref={popupRef}
        info={"info"}
        title={t("taskPopupTitle")}
        children={
          <PopupTask
            MainOwnerCompany={state.current_location.MainOwnerCompany}
            location={departmentDetail[currentDepartment].locationId}
            department={currentDepartment}
            line={data.line}
            station={data.id}
            onSuccess={() => {
              handleLoadDepartment(
                currentDepartment,
                () => {
                  loadTasks();
                  popupRef.current.close();
                },
                departmentDetail,
                session,
                departmentDetail[currentDepartment].locationId,
                (x) => setDepartmentDetail(x)
              );
            }}
          />
        }
      />
    </>
  );
};

const EmptyScreen = ({ data }) => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "start",

        width: "100%",
        height: "100%",
      }}
    >
      {" "}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          borderRadius: "0.3vw",
          boxShadow: "0px 0.2vw 0.8vw 0.2vw rgba(113, 109, 242, 0.1)",
          background: "white",
          width: "60vw",
          height: "39vw",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={
            data?.type == "delete"
              ? process.env.REACT_APP_RESOURCES_BUCKET + "DeletePh.png"
              : process.env.REACT_APP_RESOURCES_BUCKET + "WorkstationsPh.png"
          }
          style={{
            width: "22.3vw",
          }}
        />{" "}
        <p
          style={{
            color: colors.text,
            fontFamily: "Atkinson Hyperlegible",
            fontSize: "1.25vw",
            fontWeight: "400",
            marginLeft: "0vw",
            marginTop: "0vw",
            boxSizing: "border-box",
            textAlign: "center",
            width: "25vw",
            marginTop: "1vw",
          }}
        >
          {data?.type == "delete" ? (
            <>
              {t("deleteScreenTitleP1")}
              <b>'{data.element}'</b>
              {t("deleteScreenTitleP2")}
            </>
          ) : (
            t("deleteScreenMissingDep")
          )}
        </p>
        {data?.type != "delete" && (
          <ButtonTextIcon
            label={t("deleteScreenCreateDep")}
            icon={process.env.REACT_APP_RESOURCES_BUCKET + "FactoryWh.png"}
            backgroundH={colors.tertiaryH}
            background={colors.tertiary}
            color={"white"}
            onClick={() => {
              let childElements =
                document.getElementById("newDepartment").children;
              for (var i = 0; i < childElements.length; i++) {
                childElements[i].click();
              }
            }}
          />
        )}
      </div>
    </div>
  );
};

export const MainStructure = ({ dataTestId }) => {
  const [state, dispatch] = useStateValue();
  const [data, setData] = useState(null); // {type:"info"/"job"/"ws", id:[], dep:{}}
  const [screen, setScreen] = useState(null);
  const [departmentDetail, setDepartmentDetail] = useState({});
  const [currentDepartment, setCurrentDepartment] = useState({});

  const departmentDetailRef = useRef();
  const currentDepartmentRef = useRef();
  const dataPreviusRef = useRef();

  const { t } = useTranslation();

  departmentDetailRef.current = departmentDetail;
  currentDepartmentRef.current = currentDepartment;

  useEffect(() => {}, [screen]);

  useEffect(() => {
    setScreen(<EmptyScreen />);
  }, [state.current_location]);

  useEffect(() => {
    if (data?.id != dataPreviusRef?.current?.id) {
    } else {
      if (data?.type == "shift") {
        setScreen(
          <ShiftScreen
            key={data.id}
            id={data.id}
            departmentDetail={departmentDetailRef.current}
            setDepartmentDetail={(x) => setDepartmentDetail(x)}
            currentDepartment={currentDepartmentRef.current}
            setData={(x) => setData(x)}
          />
        );
      } else if (data?.type == "job") {
        setScreen(
          <JobScreen
            key={data.id}
            id={data.id}
            setData={(x) => setData(x)}
            data={data}
            departmentDetail={departmentDetailRef.current}
            setDepartmentDetail={(x) => setDepartmentDetail(x)}
            currentDepartment={currentDepartmentRef.current}
            location={state.current_location}
          />
        );
      } else if (data?.type == "ws") {
        setScreen(
          <WsScreen
            key={data.id}
            data={data}
            departmentDetail={departmentDetailRef.current}
            setDepartmentDetail={(x) => setDepartmentDetail(x)}
            currentDepartment={currentDepartmentRef.current}
            setData={(x) => setData(x)}
          />
        );
      } else if (data?.type == "delete") {
        setScreen(<EmptyScreen data={data} />);
      } else {
        setScreen(<EmptyScreen />);
      }
    }
  }, [data]);

  return (
    <>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div
          style={{
            width: "20.8vw",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              height: "10%",
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              width: "100%",
            }}
          >
            <p
              style={{
                color: colors.text,
                fontFamily: "Atkinson Hyperlegible",
                fontSize: "1.6vw",
                fontWeight: "700",
                marginLeft: "2.4vw",
                marginTop: "1.9vw",
                boxSizing: "border-box",
              }}
            >
              {t("structure")}
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
              height: "90%",
            }}
          >
            {state.current_location.id && (
              <TreeStructure
                width={"18vw"}
                setDepartmentDetail={(x) => setDepartmentDetail(x)}
                departmentDetail={departmentDetailRef.current}
                departmentDetaiState={departmentDetail}
                setCurrentDepartment={(x) => setCurrentDepartment(x)}
                setData={(x) => {
                  dataPreviusRef.current = x;
                  setData(x);
                }}
              />
            )}
          </div>
        </div>
        <div
          style={{
            width: "67.2vw",
            display: "flex",
            flexDirection: "column",
            gap: "0.5vw",
          }}
        >
          {screen}
        </div>
      </div>
    </>
  );
};
