/**
 * Object represeting the whole initial state of the app on which reducers will operate
 */

import { NONE } from "../config/generalConst";

const InitialState = {
  current_data: {
    left_video: NONE,
    right_video: NONE,
    config: NONE,
  },
  current_location: {},
};

export default InitialState;
