import React from "react";

const RulerIcon = ({ fill, size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      minWidth={size}
      minHeight={size}
      viewBox="0 0 49 48"
      fill={fill}
      style={{ minWidth: size, minHeight: size }}
    >
      <path d="M8.66602 29.7851C8.66602 31.1406 9.75685 32.2315 11.9385 34.4132L14.2525 36.7272C16.4342 38.9089 17.5251 39.9997 18.8806 39.9997C20.2361 39.9997 21.3269 38.9089 23.5086 36.7272L37.3927 22.8431C39.5744 20.6614 40.6652 19.5706 40.6652 18.215C40.6652 16.8595 39.5744 15.7687 37.3927 13.587L35.0787 11.273C32.897 9.09133 31.8062 8.00049 30.4507 8.00049C29.2451 8.00049 28.2489 8.86328 26.5086 10.5889C26.5563 10.6241 26.602 10.6634 26.6452 10.7066L28.9079 12.9693C29.3765 13.4379 29.3765 14.1977 28.9079 14.6663C28.4393 15.1349 27.6795 15.1349 27.2109 14.6663L24.9482 12.4036C24.9047 12.3601 24.8652 12.314 24.8297 12.2659L23.1135 13.9821C23.1616 14.0176 23.2077 14.0571 23.2512 14.1006L26.6452 17.4946C27.1139 17.9633 27.1139 18.723 26.6452 19.1916C26.1766 19.6603 25.4168 19.6603 24.9482 19.1916L21.5542 15.7976C21.5107 15.7541 21.4712 15.708 21.4357 15.6599L19.7195 17.3761C19.7676 17.4116 19.8136 17.4511 19.8572 17.4946L22.1199 19.7573C22.5885 20.2259 22.5885 20.9857 22.1199 21.4543C21.6512 21.923 20.8915 21.923 20.4228 21.4543L18.1602 19.1916C18.1166 19.1481 18.0771 19.1021 18.0417 19.054L16.3254 20.7702C16.3736 20.8056 16.4196 20.8451 16.4631 20.8887L19.8572 24.2827C20.3258 24.7513 20.3258 25.5111 19.8572 25.9797C19.3886 26.4483 18.6288 26.4483 18.1602 25.9797L14.7661 22.5857C14.7226 22.5421 14.6831 22.4961 14.6476 22.448L12.9314 24.1642C12.9795 24.1997 13.0256 24.2392 13.0691 24.2827L15.3318 26.5454C15.8004 27.014 15.8004 27.7738 15.3318 28.2424C14.8632 28.711 14.1034 28.711 13.6348 28.2424L11.3721 25.9797C11.3289 25.9365 11.2897 25.8908 11.2544 25.8431C9.52881 27.5834 8.66602 28.5796 8.66602 29.7851Z" />
    </svg>
  );
};

export default RulerIcon;
