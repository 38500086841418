import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import { PopupWrapper } from "./Popup.styled";
import { DividerSmall } from "../AnalysisSelector/AnalysisSelector";
import InfoTab, {
  BasicText,
  CheckBox,
  InfoBox,
  InfoButton,
  Row,
  TextBox,
} from "../InfoTab/InfoTab";
import { colors } from "../../config/style";
import ButtonIcon from "../buttons/ButtonIcon/ButtonIcon";
import ButtonTextIcon from "../buttons/ButtonTextIcon/ButtonTextIcon";
import { TreeSmallStructure } from "../Tree/TreeSmallStructure";
import { useClerk } from "@clerk/clerk-react";
import { getTaskPerStation, newJobTask } from "../../remote/structure";
import { getToken } from "../../userManagement/utilities";
import { TaskTable } from "../../screens/MainInspections";
import { useTranslation } from "react-i18next";
import { IMPERSONATION } from "../../config/generalConst";
import WeightIcon from "../../icons/WeightIcon";
import {
  increaseSaturation,
  isValidTime,
  setAlpha,
} from "../../utils/generalFunctions";
import PeopleIcon from "../../icons/PeopleIcon";
import ClockIcon from "../../icons/ClockIcon";
import Timer2Icon from "../../icons/Timer2Icon";
import TimerIcon from "../../icons/TimerIcon";
import TimeTableIcon from "../../icons/TimeTableIcon";
import TimeTable2Icon from "../../icons/TimeTable2Icon";
import FlagIcon from "../../icons/FlagIcon";
import { RowChronogram } from "../AnalysisContainer/AnalysisContainerTables";
import {
  durataOpt,
  phasesVli,
  updateChronogram,
} from "../../utils/Protocols/NIOSH/vli";
import { tips } from "../../config/tips";

export const Popup = forwardRef(({ children, onExit, onConfirm }, ref) => {
  const [isOpen, setIsOpen] = useState(false);

  // Metodi esposti all'esterno tramite ref
  useImperativeHandle(ref, () => ({
    open: () => setIsOpen(true),
    close: () => setIsOpen(false),
  }));

  if (!isOpen) {
    return null;
  }

  return <PopupWrapper>{children}</PopupWrapper>;
});

export const PopupError = forwardRef(({ errors, onSelection }, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [popupPosition, setPopupPosition] = useState({
    top: -9999,
    left: -9999,
  });
  const [firstClick, setFirstClick] = useState(true);
  const popupRef = useRef(null);
  const [initialEvent, setInitialEvent] = useState(null);

  // Metodi esposti all'esterno tramite ref
  useImperativeHandle(ref, () => ({
    open: (e) => {
      setInitialEvent(e);
      setIsOpen(true);
      setFirstClick(true); // Indica che è il primo click di apertura
    },
    close: () => setIsOpen(false),
  }));

  useEffect(() => {
    if (isOpen && initialEvent) {
      const offset = 10; // Offset from the mouse pointer
      const popupHeight = popupRef.current ? popupRef.current.offsetHeight : 0;
      setPopupPosition({
        top: initialEvent.clientY - offset - popupHeight, // Adjusted for top-right positioning
        left: initialEvent.clientX + offset, // Adjusted for top-right positioning
      });
    }
  }, [isOpen, initialEvent]);

  const handleClickOutside = (e) => {
    if (isOpen && !document.getElementById("popup").contains(e.target)) {
      const popup = document.getElementById("popup");
      if (!firstClick) {
        popup.classList.add("shake");
        setTimeout(() => {
          popup.classList.remove("shake");
        }, 500);
      }
      setFirstClick(false); // Indica che i successivi click non sono il primo click
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isOpen, firstClick]);

  if (!isOpen) {
    return null;
  }

  console.log("errors", errors);
  console.log("onSelection", onSelection);

  return (
    <div
      style={{
        position: "absolute",
        width: "100vw",
        height: "100vh",
        top: 0,
        left: 0,
        zIndex: 99,
        background: "transparent",
      }}
    >
      <div
        id="popup"
        ref={popupRef}
        style={{
          top: popupPosition.top,
          left: popupPosition.left,

          background: "white",
          position: "absolute",
          display: "flex",
          flexDirection: "column",
          padding: "0.2vw",
          boxShadow: "0vw 0.2vw 0.5vw 0vw rgba(113, 109, 242, 0.30)",
          borderRadius: "0.3vw",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
          }}
        >
          <img
            src={process.env.REACT_APP_RESOURCES_BUCKET + "InfoGrey.png"}
            style={{
              width: "1.4vw",
              marginRight: "0.2vw",
            }}
          />
          <p
            style={{
              color: colors.disabledText,
              fontFamily: "Atkinson Hyperlegible",
              fontSize: "0.8vw",
              fontWeight: "400",

              margin: 0,
              whiteSpace: "nowrap",
            }}
          >
            Tipologia
          </p>
        </div>
        <DividerSmall margin={"0.1vw"} />
        {errors.map((x, id) => {
          return (
            <ButtonTextIcon
              icon={
                process.env.REACT_APP_RESOURCES_BUCKET +
                "ErrorIndicator" +
                String(id) +
                ".png"
              }
              backgroundH={colors.details}
              background={"white"}
              color={colors.main}
              onClick={() => onSelection(id)}
              label={x}
              loadingLabel={""}
              loadingStyle={{}}
              style={{
                color: colors.text,
                fontWeight: "400",
                fontSize: "0.8vw",
                width: "12vw",
                margin: "0",
                padding: "0",
                boxShadow: "0vw 0vw 0vw 0vw rgba(113, 109, 242, 0.30)",
                height: "1.5vw",
                borderRadius: "0.3vw",
              }}
              iconHeight={"1.4vw"}
            />
          );
        })}
      </div>{" "}
    </div>
  );
});

export const PopupCreate = forwardRef(
  ({ children, onExit, onConfirm, title, info, actionLabel }, ref) => {
    return (
      <Popup ref={ref} onExit={onExit} onConfirm={onConfirm}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "31vw",
            // height: "15.6vw",
            background: "white",
            borderRadius: "1.25vw",
            justifyContent: "start",
            alignItems: "center",
            padding: "1.25vw",
            boxSizing: "border-box",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              height: "10%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "0.7vw",
              }}
            >
              {info && (
                <InfoButton ai={false} err={false} std={true} info={info} />
              )}
              <p
                style={{
                  color: colors.text,
                  fontFamily: "Atkinson Hyperlegible",
                  fontSize: "1.25vw",
                  fontWeight: "700",
                  boxSizing: "border-box",
                  width: info ? "92%" : "100%",
                  overflow: "hidden",
                  margin: 0,
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                {title}
              </p>
            </div>
            <ButtonIcon
              icon={process.env.REACT_APP_RESOURCES_BUCKET + "Exit.png"}
              iconH={process.env.REACT_APP_RESOURCES_BUCKET + "ExitHw.png"}
              onClick={() => {
                ref.current.close();
              }}
              style={{ width: "1.875vw" }}
            />
          </div>
          <DividerSmall margin={"1vw 0vw 0vw 0vw"} />
          <div style={{ width: "100%", height: "80%" }}>{children}</div>
        </div>
      </Popup>
    );
  }
);

export const PopupAdd = forwardRef(
  (
    {
      onExit,
      onConfirm,
      title,
      jobName,
      info,
      department,
      id,
      onSuccess,
      tasksAssigned,
      MainOwnerCompany,
    },
    ref
  ) => {
    const [selectedStation, setSelectedStation] = useState();
    const [tasks, setTasks] = useState([]);
    const [loading, setLoading] = useState(false);
    const [ownerCompany, setOwnerCompany] = useState(false);
    const [selectedTasks, setSelectedTasks] = useState([]);

    useEffect(() => {
      if (selectedStation) loadTasks();
    }, [selectedStation]);

    const { session, user } = useClerk();
    const { t } = useTranslation();

    const reset = () => {
      setSelectedStation();
      setTasks([]);
      setSelectedTasks([]);
      setLoading(false);
    };

    const loadTasks = async () => {
      let tasks = await getTaskPerStation(
        department.locationId,
        department.id,
        selectedStation.line,
        selectedStation.ws.id,
        await getToken(session)
      );

      let validTasks = tasks.filter((x) => x.valid !== false);
      // let validTasks = tasks;

      // Estrai gli id da escludere.
      let excludedIds = Object.values(tasksAssigned).map(
        (jobTask) => jobTask.task.id
      );

      // Filtra i task.
      let filteredTasks = validTasks.filter(
        (task) => !excludedIds.includes(task.id)
      );
      setTasks(filteredTasks.sort((a, b) => a.name.localeCompare(b.name)));
    };

    const matchTasks = async (tasks, onSuccess) => {
      setLoading(true);

      if (ownerCompany) {
        tasks = tasks.map((obj) => {
          return { ...obj, ownerCompany: MainOwnerCompany };
        });
      }

      let response = await newJobTask(
        department.locationId,
        department.id,
        id,
        tasks,
        await getToken(session)
      );
      onSuccess();
    };

    return (
      <Popup
        ref={ref}
        onExit={() => {
          onExit();
          reset();
        }}
        onConfirm={onConfirm}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "75vw",
            height: "41.25vw",
            background: "white",
            borderRadius: "1.25vw",
            justifyContent: "start",
            alignItems: "center",
            padding: "1.25vw",
            boxSizing: "border-box",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              height: "1vw",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "0.7vw",
              }}
            >
              {info && (
                <InfoButton ai={false} err={false} std={true} info={info} />
              )}
              <p
                style={{
                  color: colors.text,
                  fontFamily: "Atkinson Hyperlegible",
                  fontSize: "1.25vw",
                  fontWeight: "700",
                  boxSizing: "border-box",
                  margin: 0,
                }}
              >
                {title}
                <span style={{ fontWeight: "400" }}>{"| " + jobName}</span>
              </p>
            </div>
            <ButtonIcon
              icon={process.env.REACT_APP_RESOURCES_BUCKET + "Exit.png"}
              iconH={process.env.REACT_APP_RESOURCES_BUCKET + "ExitHw.png"}
              onClick={() => {
                setLoading(false);
                setSelectedTasks([]);
                setSelectedStation();
                ref.current.close();
              }}
              style={{ width: "1.875vw" }}
            />
          </div>
          {/* <DividerSmall margin={"1vw 0vw 0vw 0vw"} /> */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "start",
              width: "100%",
              height: "36.5vw",
              marginTop: "1vw",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "start",

                height: "100%",
                background: colors.mainBackground,
                borderRadius: "0.3vw",
                padding: "1vw",
                boxSizing: "border-box",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "20.5vw",
                  height: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    width: "100%",
                    marginBottom: "1vw",
                  }}
                >
                  <img
                    src={process.env.REACT_APP_RESOURCES_BUCKET + "Factory.png"}
                    style={{
                      width: "1.875vw",
                      height: "1.875vw",
                      margin: "0px",
                      marginRight: "0.2vw",
                    }}
                  />
                  <p
                    style={{
                      color: colors.text,
                      fontFamily: "Atkinson Hyperlegible",
                      fontSize: "0.9vw",
                      fontWeight: "700",
                      boxSizing: "border-box",
                      margin: 0,
                    }}
                  >
                    {department?.name}
                  </p>
                </div>
                <TreeSmallStructure
                  setSelectedStation={(x) => setSelectedStation(x)}
                  width={"80%"}
                  department={department}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "20.5vw",
                  height: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    width: "100%",
                    marginBottom: "1vw",
                  }}
                >
                  <img
                    src={process.env.REACT_APP_RESOURCES_BUCKET + "Table.png"}
                    style={{
                      width: "1.875vw",
                      height: "1.875vw",
                      margin: "0px",
                      marginRight: "0.2vw",
                    }}
                  />
                  <p
                    style={{
                      color: colors.text,
                      fontFamily: "Atkinson Hyperlegible",
                      fontSize: "0.9vw",
                      fontWeight: "700",
                      boxSizing: "border-box",
                      margin: 0,
                    }}
                  >
                    {selectedStation?.ws.name}
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    background: "white",
                    width: "95%",
                    height: "90%",
                    overflowY: "hidden",
                    marginBottom: "1vw",
                    borderRadius: "0.3vw",
                    boxShadow:
                      "box-shadow: 0px 0.2vw 0.8vw 0.2vw rgba(113, 109, 242, 0.10)",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      background: "white",
                      width: "100%",
                      height: "95%",
                      overflowY: "scroll",
                      marginBottom: "1vw",
                      borderRadius: "0.3vw",
                      paddingTop: "0.5vw",
                      boxSizing: "border-box",
                      boxShadow:
                        "box-shadow: 0px 0.2vw 0.8vw 0.2vw rgba(113, 109, 242, 0.10)",
                    }}
                  >
                    {tasks.map((x) => {
                      return (
                        <TaskTable
                          task={x}
                          loaded={true}
                          setLoaded={() => {}}
                          columns={["name"]}
                          deleteBtn={() => {}}
                          excludeControl={true}
                          selected={Object.keys(selectedTasks).includes(x.id)}
                          setSelected={() => {
                            if (Object.keys(selectedTasks).includes(x.id)) {
                              // Crea una copia dell'oggetto stato
                              const newSelectedTasks = { ...selectedTasks };
                              // Rimuove la chiave
                              delete newSelectedTasks[x.id];
                              // Aggiorna lo stato con il nuovo oggetto
                              setSelectedTasks(newSelectedTasks);
                            } else {
                              // Aggiungi una nuova chiave e valore
                              setSelectedTasks({
                                ...selectedTasks,
                                [x.id]: {
                                  task: {
                                    ...x,
                                    ws: selectedStation?.ws.name,
                                    line: selectedStation?.line,
                                  },
                                  station: selectedStation,
                                },
                              });
                            }
                          }}
                        />
                      );
                    })}
                  </div>
                  <p
                    style={{
                      color: colors.disabledText,
                      fontFamily: "Atkinson Hyperlegible",
                      fontSize: "0.9vw",
                      fontWeight: "400",
                      boxSizing: "border-box",
                      margin: "0",
                      flex: 1.65,
                      width: "80%",
                      textAlign: "center",
                      marginBottom: "1vw",
                    }}
                  >
                    I compiti già assegnati a questa mansione non sono visibili.
                  </p>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                height: "100%",
                flex: 1,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "start",
                  alignItems: "start",
                  height: "80%",
                  width: "100%",
                }}
              >
                {" "}
                <DividerSmall margin={"0vw 0.4vw 0vw 0.8vw"} />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    alignItems: "center",
                    height: "3.2vw",
                    padding: "0.8vw",
                    boxSizing: "border-box",
                    width: "100%",
                  }}
                >
                  <p
                    style={{
                      color: colors.text,
                      fontFamily: "Atkinson Hyperlegible",
                      fontSize: "0.9vw",
                      fontWeight: "700",
                      boxSizing: "border-box",
                      margin: "0",
                      flex: 2,
                    }}
                  >
                    {t("jobTasksSelectedTask")}
                  </p>
                  <p
                    style={{
                      color: colors.text,
                      fontFamily: "Atkinson Hyperlegible",
                      fontSize: "0.9vw",
                      fontWeight: "400",
                      boxSizing: "border-box",
                      margin: "0",
                      flex: 1.2,
                    }}
                  >
                    {t("jobTasksWorspace")}
                  </p>
                  <p
                    style={{
                      color: colors.text,
                      fontFamily: "Atkinson Hyperlegible",
                      fontSize: "0.9vw",
                      fontWeight: "400",
                      boxSizing: "border-box",
                      margin: "0",
                      flex: 1.2,
                    }}
                  >
                    {t("jobTasksArea")}
                  </p>
                </div>
                <DividerSmall margin={"0vw 0.4vw 0vw 0.8vw"} />
                {Object.keys(selectedTasks).map((x) => {
                  return (
                    <TaskTable
                      task={selectedTasks[x].task}
                      loaded={true}
                      deleteBtn={() => {}}
                      setLoaded={() => {}}
                      dividerMargin={"0vw 0.4vw 0vw 0.8vw"}
                      columns={["name", "ws", "line"]}
                      excludeControl={true}
                      selected={Object.keys(selectedTasks).includes(
                        selectedTasks[x].task.id
                      )}
                      setSelected={() => {
                        const newSelectedTasks = { ...selectedTasks };
                        // Rimuove la chiave
                        delete newSelectedTasks[x];
                        // Aggiorna lo stato con il nuovo oggetto
                        setSelectedTasks(newSelectedTasks);
                      }}
                    />
                  );
                })}
              </div>
              {user?.publicMetadata?.role == "admin" && IMPERSONATION && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                  }}
                >
                  <CheckBox
                    label={t("ownerCompany")}
                    value={ownerCompany}
                    onClick={() => {
                      setOwnerCompany(!ownerCompany);
                    }}
                  />
                </div>
              )}
              <ButtonTextIcon
                label={t("jobTasksAssignTasks")}
                loading={loading}
                disabled={Object.keys(selectedTasks).length > 0 ? false : true}
                backgroundH={colors.mainH}
                background={colors.main}
                onClick={() => {
                  let durationDefault = Math.round(
                    100 / Object.keys(selectedTasks).length
                  );
                  let matchTasksList = Object.keys(selectedTasks).map(
                    (x, id) => {
                      if (id < Object.keys(selectedTasks).length - 1)
                        return {
                          durata: durationDefault,
                          task: selectedTasks[x].task.id,
                        };
                      else
                        return {
                          durata: 100 - durationDefault * id,
                          task: selectedTasks[x].task.id,
                        };
                    }
                  );

                  matchTasks(matchTasksList, () => {
                    onSuccess();
                    reset();
                  });
                }}
                style={{ width: "16vw", justifyContent: "center" }}
              />
            </div>
          </div>
        </div>
      </Popup>
    );
  }
);

export const PopupCronogram = forwardRef(
  ({ onExit, onConfirm, title, info, vliMemory, setVliMemory }, ref) => {
    const [selected, setSelected] = useState(0);
    const [cronogrammaLocale, setCronogrammaLocale] = useState(
      JSON.parse(JSON.stringify(vliMemory.cronogramma))
    );
    const [cronoRes, setCronoRes] = useState(
      updateChronogram(cronogrammaLocale.fasi)
    );

    const widthScale = 0.09;

    useEffect(() => {
      setCronoRes(updateChronogram(cronogrammaLocale.fasi));
    }, [cronogrammaLocale]);

    console.log("cronoRes", cronoRes);

    function addDuration(fasi, index, startTime) {
      // Funzione per convertire la stringa "HH:MM" in ore e minuti
      function parseTime(timeStr) {
        const [hours, minutes] = timeStr.split(":").map(Number);
        return { hours, minutes };
      }

      // Funzione per formattare ore e minuti nel formato "HH:MM"
      function formatTime(hours, minutes) {
        const formattedHours = String(hours).padStart(2, "0");
        const formattedMinutes = String(minutes).padStart(2, "0");
        return `${formattedHours}:${formattedMinutes}`;
      }

      // Calcola la somma delle durate fino all'indice specificato
      let totalMinutes = 0;
      for (let i = 0; i <= index; i++) {
        totalMinutes += parseInt(fasi[i].duration);
      }

      // Converti l'ora d'inizio in ore e minuti
      const { hours: startHours, minutes: startMinutes } = parseTime(startTime);

      // Aggiungi i minuti totali calcolati all'ora d'inizio
      let totalHours =
        startHours + Math.floor((startMinutes + totalMinutes) / 60);
      let totalFinalMinutes = (startMinutes + totalMinutes) % 60;

      // Se le ore superano 24, torna a 0 (formato 24 ore)
      totalHours = totalHours % 24;

      // Restituisci l'ora finale nel formato "HH:MM"
      return formatTime(totalHours, totalFinalMinutes);
    }

    return (
      <Popup ref={ref} onExit={onExit} onConfirm={onConfirm}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "78vw",
            height: "50vw",
            background: "white",
            borderRadius: "1.25vw",
            justifyContent: "start",
            alignItems: "center",
            padding: "1.25vw",
            boxSizing: "border-box",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              height: "1vw",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "0.7vw",
              }}
            >
              {info && (
                <InfoButton ai={false} err={false} std={true} info={info} />
              )}
              <p
                style={{
                  color: colors.text,
                  fontFamily: "Atkinson Hyperlegible",
                  fontSize: "1.25vw",
                  fontWeight: "700",
                  boxSizing: "border-box",
                  width: info ? "92%" : "100%",
                  overflow: "hidden",
                  margin: 0,
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                {title}
              </p>
            </div>
            <ButtonIcon
              icon={process.env.REACT_APP_RESOURCES_BUCKET + "Exit.png"}
              iconH={process.env.REACT_APP_RESOURCES_BUCKET + "ExitHw.png"}
              onClick={() => {
                setCronogrammaLocale(
                  JSON.parse(JSON.stringify(vliMemory.cronogramma))
                );
                ref.current.close();
              }}
              style={{ width: "1.875vw" }}
            />
          </div>
          <DividerSmall margin={"1vw 0vw 0vw 0vw"} />
          <div
            style={{
              width: "100%",
              height: "47vw",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
                borderRadius: "0.95vw",
                marginTop: "1vw",
                background: colors.background,
                gap: "0.5vw",
                width: "61.5vw",
                overflowX: "auto",
                padding: "0.5vw",
                border: "0.5vw solid " + colors.background,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "start",
                  width: "61.5vw",
                  gap: "0.3vw",
                }}
              >
                {cronogrammaLocale.fasi.map((slot, slotId) => {
                  return (
                    <div
                      style={{
                        background:
                          selected == slotId
                            ? increaseSaturation(
                                phasesVli[slot.phase].fillColor,
                                30
                              )
                            : phasesVli[slot.phase].fillColor,
                        height: selected == slotId ? "6.1vw" : "5.3vw",
                        width: slot.duration * widthScale + "vw",
                        minWidth: slot.duration * widthScale + "vw",
                        border:
                          "0.15vw solid " +
                          setAlpha(phasesVli[slot.phase].borderColor, 0.7),
                        borderRadius: "0.62vw",
                        boxSizing: "border-box",
                        cursor: selected != slotId && "pointer",
                      }}
                      onClick={() => setSelected(slotId)}
                    ></div>
                  );
                })}
              </div>
              <div
                style={{
                  width: "61.5vw",

                  boxSizing: "border-box",
                  // marginBottom: "0.2vw",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                  gap: "0.3vw",
                }}
              >
                {cronogrammaLocale.fasi.map((slot, slotId) => {
                  console.log("herrr");
                  return (
                    <div
                      style={{
                        background: "white",
                        height: "1.38vw",
                        width: slot.duration * widthScale + "vw",
                        minWidth: slot.duration * widthScale + "vw",
                        borderRadius: "0.92vw",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "start",
                        paddingLeft: "0.35vw",
                        boxSizing: "border-box",
                      }}
                    >
                      {slot.duration * widthScale >= 2.7 && (
                        <p
                          style={{
                            color: colors.text,
                            fontFamily: "Atkinson Hyperlegible",
                            fontSize: "0.9vw",
                            fontWeight: "400",
                            boxSizing: "border-box",
                            margin: 0,
                            overflow: "hidden",
                          }}
                        >
                          {addDuration(
                            cronogrammaLocale.fasi,
                            slotId - 1,
                            cronogrammaLocale.ora_inizio_turno
                          )}
                        </p>
                      )}
                    </div>
                  );
                })}
                <div
                  style={{
                    background: "white",
                    height: "1.38vw",
                    width: "7.5vw",
                    minWidth: "7.5vw",
                    borderRadius: "0.92vw",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",

                    boxSizing: "border-box",
                  }}
                >
                  <p
                    style={{
                      color: colors.text,
                      fontFamily: "Atkinson Hyperlegible",
                      fontSize: "0.9vw",
                      fontWeight: "400",
                      boxSizing: "border-box",
                      margin: 0,
                      overflow: "hidden",
                    }}
                  >
                    {"Fine turno: " +
                      addDuration(
                        cronogrammaLocale.fasi,
                        cronogrammaLocale.fasi.length - 1,
                        cronogrammaLocale.ora_inizio_turno
                      )}
                  </p>
                </div>
              </div>
            </div>
            <div
              style={{
                width: "100%",
                height: "30vw",
                background: colors.background,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "start",
                borderRadius: "0.3vw",
                padding: "0.9vw",
                gap: "0.9vw",
                boxSizing: "border-box",
              }}
            >
              <div
                style={{
                  width: "61.5vw",
                  height: "8vw",
                  background: "white",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-around",
                  borderRadius: "0.3vw",
                  padding: "0.65vw",

                  boxSizing: "border-box",
                  boxShadow: "0px 0.2vw 0.78vw 0.2vw rgba(113, 109, 242, 0.10)",
                }}
              >
                <div
                  style={{
                    width: "48%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "0.65vw",
                    justifyContent: "start",
                  }}
                >
                  <Row justifyContent={"start"}>
                    <FlagIcon size={"1.875vw"} />
                    <BasicText
                      style={{
                        width: "24vw",
                      }}
                    >
                      {"Orario inizio turno"}
                    </BasicText>
                    <TextBox
                      value={cronogrammaLocale.ora_inizio_turno}
                      err={false}
                      unit={""}
                      infoBox={true}
                      checkFunction={isValidTime}
                      onBlur={(valueIn) => {
                        setCronogrammaLocale({
                          ...cronogrammaLocale,
                          ora_inizio_turno: valueIn,
                        });
                      }}
                    />
                  </Row>
                  <Row justifyContent={"start"}>
                    <TimeTable2Icon size={"1.875vw"} />
                    <BasicText
                      style={{
                        width: "24vw",
                      }}
                    >
                      {"Durata totale del turno"}
                    </BasicText>
                    <InfoBox
                      value={cronoRes.durata_totale_turno}
                      unit={"m"}
                      ai={false}
                      info={tips[document.language].durata_totale_turno}
                      decimals={0}
                    />
                  </Row>
                </div>
                <div
                  style={{
                    width: "48%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "0.65vw",
                    justifyContent: "start",
                  }}
                >
                  <Row justifyContent={"start"}>
                    <ClockIcon size={"1.875vw"} />
                    <BasicText
                      style={{
                        width: "24vw",
                      }}
                    >
                      {"Durata netta sollevamento manuale"}
                    </BasicText>
                    <InfoBox
                      value={cronoRes.durata_netta_sollevamento}
                      unit={"m"}
                      ai={false}
                      info={tips[document.language].durata_sollevamento}
                      decimals={1}
                    />
                  </Row>
                  <Row justifyContent={"start"}>
                    <TimerIcon size={"1.875vw"} />
                    <BasicText
                      style={{
                        width: "24vw",
                      }}
                    >
                      {"Tipo di durata "}
                    </BasicText>
                    <InfoBox
                      value={durataOpt[cronoRes.tipo_durata].label}
                      unit={" "}
                      ai={false}
                      info={tips[document.language].tipo_durata}
                      decimals={0}
                      marginLeft={"0vw"}
                    />
                  </Row>
                </div>
              </div>

              <InfoTab
                fullWidth={true}
                divider={true}
                width={"61.5vw"}
                height={"20vw"}
                title={"LISTA DELLE ATTIVITA' LAVORATIVE"}
                justifyContent={"start"}
                gap={"0.5vw"}
                justifyContentMain={"start"}
                // style={{ flex: 0.95 }}
                content={[
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      overflow: "auto",
                      width: "99%",
                      height: "16.5vw",
                    }}
                  >
                    {cronogrammaLocale.fasi.map((activity, activityId) => {
                      return (
                        <RowChronogram
                          key={activityId}
                          activity={activity}
                          activityId={activityId}
                          chronogram={cronogrammaLocale.fasi}
                          setChronogram={(x) =>
                            setCronogrammaLocale({
                              ...cronogrammaLocale,
                              fasi: x,
                            })
                          }
                          phases={phasesVli}
                          selected={selected == activityId}
                          setSelected={() => setSelected(activityId)}
                        />
                      );
                    })}
                  </div>,
                ]}
                titleButton={
                  <ButtonTextIcon
                    label={"Aggiungi attività"}
                    color={colors.main}
                    backgroundH={colors.details}
                    background={"transparent"}
                    icon={process.env.REACT_APP_RESOURCES_BUCKET + "Plus.png"}
                    iconH={process.env.REACT_APP_RESOURCES_BUCKET + "Plus.png"}
                    onClick={() => {
                      setCronogrammaLocale({
                        ...cronogrammaLocale,
                        fasi: [
                          ...cronogrammaLocale.fasi,
                          { phase: 0, duration: 30 },
                        ],
                      });
                    }}
                    style={{
                      fontSize: "0.82vw",
                      boxShadow: "0px 0px 0px 0px white",
                      height: "2vw",
                      fontWeight: "700",
                      marginRight: "0.5vw",
                      padding: "0.3vw",
                      paddingRight: "0.4vw",

                      boxSizing: "border-box",
                    }}
                  />
                }
              />
            </div>
            <ButtonTextIcon
              label={"Salva modifiche ed esci"}
              backgroundH={colors.mainH}
              background={colors.main}
              disabled={false}
              color={"white"}
              iconHeight={"60%"}
              onClick={() => {
                setVliMemory({
                  ...vliMemory,
                  cronogramma: { ...cronogrammaLocale, ...cronoRes },
                });
                ref.current.close();
              }}
            />
          </div>
        </div>
      </Popup>
    );
  }
);

Popup.propTypes = {
  children: PropTypes.node,
  onExit: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default Popup;
