import { SignedIn, SignedOut, useClerk } from "@clerk/clerk-react";
import { useEffect, useRef } from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import LateralNavigation from "./components/LateralNavigation/LateralNavigation";
// import MainInspection from "./components/MainInspection/MainInspection";
import { useUser } from "@clerk/clerk-react";
import Spinner from "./components/Spinner/Spinner";
import { DEFAULT_LANGUAGE, NONE } from "./config/generalConst";
import { nav_paths } from "./config/navigationConst";
import { colors } from "./config/style";
import { MainInspections } from "./screens/MainInspections";
import { MainStructure } from "./screens/MainStructure";
import { useStateValue } from "./stores/services/StateProvider";
import Auth from "./userManagement/auth";
import { useTranslation } from "react-i18next";
import Intercom from "@intercom/messenger-js-sdk";
import { shutdown } from "@intercom/messenger-js-sdk";
import { MainReview } from "./screens/MainReview";

const MainRouter = () => {
  const [state, dispatch] = useStateValue();
  const { session } = useClerk();
  const { isSignedIn, user } = useUser();
  const { i18n } = useTranslation();

  const stateRef = useRef();
  stateRef.current = stateRef;

  console.log("STATE:", state);

  useEffect(() => {
    if (user?.publicMetadata?.test == true) {
      document.stage = true;
    } else {
      document.stage = false;
    }
    document.language = DEFAULT_LANGUAGE;
    if (user?.publicMetadata?.language) {
      i18n.changeLanguage(user?.publicMetadata?.language);
      document.language = user?.publicMetadata?.language;
    } else {
      i18n.changeLanguage(document.language);
    }
    console.log("user", user);
    if (user) {
      Intercom({
        app_id: "o47lgmq8",
        user_id: user.id, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
        name: user.fullName, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
        email: user.emailAddresses[0].emailAddress, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
        created_at: user.createdAt, // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
      });
    } else {
      shutdown();
    }
  }, [user]);

  const Overlay = () => (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: colors.background, // Sfondo semitrasparente, ad esempio
        zIndex: 1000, // Assicurati che sia sopra gli altri elementi
      }}
    >
      <Spinner />
    </div>
  );
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        background: colors.mainBackground,
      }}
    >
      <SignedIn>
        {(!state.current_location || state.current_location == NONE) &&
          !(
            user?.publicMetadata?.expertise == "reviewer" ||
            user?.publicMetadata?.expertise == "dataset"
          ) && <Overlay />}
      </SignedIn>
      <Router>
        <Routes>
          <Route
            path={nav_paths.auth + "/*"}
            element={
              <>
                <SignedIn>
                  <Navigate
                    to={
                      user?.publicMetadata?.expertise == "reviewer" ||
                      user?.publicMetadata?.expertise == "dataset"
                        ? nav_paths.review
                        : nav_paths.inspections
                    }
                    replace={true}
                  />
                </SignedIn>
                <SignedOut>
                  <Auth />
                </SignedOut>
              </>
            }
          />
          <Route
            path={nav_paths.inspections}
            element={
              <>
                <SignedIn>
                  <LateralNavigation />
                  <MainInspections />
                  {/* <MainInspection /> */}
                </SignedIn>
                <SignedOut>
                  <Navigate to={nav_paths.signIn} replace={true} />
                </SignedOut>
              </>
            }
          />

          <Route
            path={nav_paths.structure}
            element={
              <>
                <SignedIn>
                  <LateralNavigation />
                  <MainStructure />
                </SignedIn>
                <SignedOut>
                  <Navigate to={nav_paths.signIn} replace={true} />
                </SignedOut>
              </>
            }
          />

          <Route
            path={nav_paths.review}
            element={
              <>
                <SignedIn>
                  {user?.publicMetadata?.expertise == "reviewer" ||
                  user?.publicMetadata?.expertise == "dataset" ? (
                    <>
                      <LateralNavigation hideLocation={true} />
                      <MainReview />
                    </>
                  ) : (
                    <Navigate to={nav_paths.inspections} replace={true} />
                  )}
                </SignedIn>
                <SignedOut>
                  <Navigate to={nav_paths.signIn} replace={true} />
                </SignedOut>
              </>
            }
          />

          <Route
            path="/"
            element={
              <>
                <SignedIn>
                  <Navigate to={nav_paths.inspections} replace={true} />
                </SignedIn>
                <SignedOut>
                  <Navigate to={nav_paths.signIn} replace={true} />
                </SignedOut>
              </>
            }
          />

          <Route path="*" element={<Navigate to="/" replace={true} />} />
        </Routes>
      </Router>
    </div>
  );
};

export default MainRouter;
