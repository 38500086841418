import { mappingNiosh } from "../utils/Protocols/NIOSH/NIOSH";
import { mappingOcra } from "../utils/Protocols/OCRA/OCRA";

export const OCRA_ID = 0;
export const NIOSH_ID = 1;

export const protocols = [
  {
    name: "OCRA",
    short: "O",
    mid: "OCRA",
    ref: "ocra",
    topLabel: ["Parziale"],
    labels: ["DX", "SX"],
    category: ["dx", "sx"],
    score: ["parziale"],
    ai: true,
    mapping: mappingOcra["prec"],
  },
  {
    name: "Variable Lifting Index",
    short: "VLI",
    mid: "NIOSH VLI",
    ref: "niosh",
    topLabel: ["18-45", "<18/>45>"],
    labels: ["M", "F"],
    category: ["m", "f"],
    score: ["mid", "ext"],
    ai: false,
    mapping: mappingNiosh["vli"],
  },
];
