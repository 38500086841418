export const tips = {
  it: {
    durata_media_netta:
      "Durata del turno meno durata complessiva delle pause e dei lavori non ripetitivi all’interno del turno di lavoro.",
    numero_ore_senza_recupero_automatico:
      "Un'ora è considerata recuperata quando include pause di 8-10 minuti che permettono il riposo degli arti superiori.",
    moltiplicatore_recupero:
      "Il moltiplicatore di recupero modifica l'indice di rischio ergonomico finale, basato sul numero di ore lavorative senza adeguato recupero.",
    numero_ore_senza_recupero_manuale:
      "Se inserito manualmente, questo dato aggiorna l'indice di rischio ergonomico, sostituendosi al valore calcolato del numero ore senza adeguato recupero.",
    somma_delle_pause_in_orario_di_lavoro:
      "Somma di tutte le pause regolamentate, incluse le pause brevi e la pausa pranzo, durante l'orario lavorativo.",
    durata_lavori_non_ripetitivi:
      "Somma delle durate di tutte le attività non ripetitive presenti all’interno del turno di lavoro.",
    rotazione_maggiore_90:
      "Punteggio finale dell’analisi ottenuto ipotizzando una rotazione maggiore di 90 minuti dei compiti assegnati alla mansione.",
    rotazione_minore_90:
      "Punteggio finale dell’analisi ottenuto ipotizzando una rotazione inferiore a 90 minuti dei compiti assegnati alla mansione.",
    punteggio_intrinseco:
      "Calcolato considerando un turno standard di 8h con 1h di pausa mensa e due pause da 10 m.",
    punteggio_parziale:
      "Calcolato senza tener conto della durata del turno e dei recuperi.",
    durata_percentuale:
      "Durata del compito rispetto alla durata netta del turno di lavoro, la somma deve dare 100%.",
    tempo_ciclo_osservato:
      "Media della durata dei video di analisi compito caricati sulla piattaforma",
    totale_secondi_tempo_passivo:
      "Sottrazione tra il tempo totale di ciclo osservato e il tempo di attività totale osservato.",
    minuti_recupero_ciclo:
      "Moltiplicazione del tempo di inattività totale osservato per numero di cicli all’interno del turno. ",
    rapporto_tempo_attivo_passivo:
      "Per essere presente il tempo di recupero interno al ciclo, il coefficiente di controllo deve essere uguale o inferiore a 6,5",
    durata_media_netta_esclusi_recuperi:
      "Durata totale netta del turno di lavoro escluso il tempo totale di recupero nel turno dovuto ai tempi di recupero interni al ciclo.",
    durata_ciclo_netto_secondi:
      "Durata totale netta del turno ripetitivo divisa per il numero di cicli o pezzi lavorati durante il turno stesso.",
    percentuale_differenza_ciclo:
      "Una differenza maggiore al 5% tra tempo di ciclo netto calcolato e quello osservato è da considerarsi invalidante per l’analisi.",
    minuti_non_giustificati:
      "Differenza in minuti tra il turno di lavoro basato sul tempo di ciclo osservato e quello calcolato.",
    ai_analysis_braccia:
      "Conteggio dei secondi con spalla >80° di flessione o abduzione  >45°.  Per modifiche, vai ad AI Analysis.",
    ai_analysis_mano:
      "Somma dei secondi trascorsi in prese incongure. Per modifiche, vai ad AI Analysis.",
    ai_analysis_polso:
      "Deviazioni estreme in flessione o deviazioni radio-ulnari sono conteggiate con un valore di 0,5s ciascuna. Per modifiche, vai ad AI Analysis..",
    ai_analysis_gomito:
      "Estensioni o flessioni del gomito e prono-supinazioni dell’avambraccio  >60° sono conteggiati con un valore di 0,5s ciascuna. Per modifiche, vai ad AI Analysis.",
    ai_analysis_azioni:
      "Calcolate dall'algoritmo per ogni azione tecnica osservata. Per modifiche, vai ad AI Analysis.",
    frequenza_azioni:
      "Quante azioni tecniche vengono compiute in un minuto di lavoro",
    percentuale_t_ciclo:
      "Percentuale rispetto alla durata del tempo di ciclo calcolato",
    parziale:
      "Valutazione parziale che non tiene conto di alcun recupero o durata.",
    finale_intrinseco: "Valutazione completa indipendente dalla durata.",
    finale_ponderato:
      "Valutazione finale aggiustata in base alla durata netta.",
    postazione:
      "Luogo fisico specifico in cui un lavoratore svolge i propri compiti quotidiani. È parte di una area produttiva e può racchiudere uno o più compiti.",
    area: "Sequenza di processi e attività per la produzione, composta da postazioni fisiche. Costituisce una parte del dipartimento.",
    turno:
      "Periodo di lavoro definito, parte della giornata lavorativa, comprendente attività, pause e compiti, associato a specifiche mansioni.",
    mansione:
      "Gruppo omogeneo di lavoratori che svolge gli stessi compiti nello stesso turno lavorativo, anche su diverse postazioni.",
    dipartimento:
      "Divisione all'interno di una sede aziendale, che racchiudono varie aree produttive, distinte per funzioni e attività.",
    dati_incerti:
      "I dati evidenziati in grigio sul grafico sono incerti a causa della mancanza di alcune informazioni nel video. Controlla la qualità dell’analisi.",
    dati_incerti_trigger:
      "Questa analisi presenta un elevato numero di dati incerti a causa della mancanza di  informazioni nel video. Considera di registrare nuovamente il video.",
    edit_completo:
      "Aggiungi per segnalare posture incongrue. Ignora per escluderle dal conteggio. Doppio click sul grafico per fare le stesse azioni.",
    edit_parziale: "Premi il tasto di modifica per editare il grafico.",
    protocollo:
      "In questa sezione puoi vedere i protocolli disponibili per ciascun compito e selezionare quello di cui vuoi vedere i risultati.",
    // NIOSH
    cronogramma: (
      <>
        Compila il cronogramma con le pause e attività svolte durante il turno.
        <br></br>
        <br></br>
        La durata netta del sollevamento e il tipo di durata verranno
        determinate automaticamente.
      </>
    ),
    tipo_durata: (
      <>
        La durata del compito MMC è classificata, a partire dal cronogramma, in
        base al rapporto tra il tempo di sollevamento e il tempo di recupero:{" "}
        <br></br>
        <ul>
          <li>
            <b>Durata breve:</b> Sollevamento min 30 minuti fino a 1 ora, con un
            recupero pari ad almeno al 100% della durata del compito di
            sollevamento continuo e ripetitivo.
          </li>
          <li>
            <b>Durata media:</b> Sollevamento tra 1 e 2 ore, con un recupero
            pari ad almeno il 30% della durata del compito di sollevamento
            continuo e ripetitivo.
          </li>
          <li>
            <b>Durata lunga:</b> Sollevamento tra 2 e 8 ore, con le normali
            pause mattutine, pomeridiane e pranzo.
          </li>
        </ul>
      </>
    ),
    durata_sollevamento: "La somma delle durate dei sollevamenti manuali MMC.",
    durata_totale_turno: "La durata di tutte le attività del turno.",
    reale_numero_oggetti: (
      <>
        N. di oggetti realmente sollevati da tutto il gruppo di lavoratori
        coinvolti.
        <br></br>
        <br></br>
        Si ottiene moltiplicando il numero di oggetti sollevati nel turno da
        tutto il gruppo di lavoratori per il numero di sollevamenti necessari
        per ciascun oggetto.
      </>
    ),
    massa_cumulata: (
      <>
        Calcolo della massa cumulata (ISO 11228-1).
        <br></br>
        <br></br>
        Ottenuto moltiplicando il numero di oggetti realmente sollevati da tutto
        il gruppo omogeneo per il peso del carico.
      </>
    ),
    tot_oggetti_sollevati:
      "Somma del reale numero di oggetti  sollevati per ogni classe di peso.",
    oggetti_per_lavoratore:
      "N. tot. oggetti sollevati durante il compito diviso per il numero di lavorati coinvolti.",
    frequenza_sollevamento_minuto:
      "La frequenza di sollevamento è calcolata automaticamente considerando il numero di oggetti sollevati da ciascun lavoratore e la durata netta del sollevamento manuale (incluso il trasporto).",
    pesi_medi_categoria:
      "Peso medio della categoria, ponderato in base al reale numero di oggetti sollevati per ogni sottocategoria.",
    perc_pesi_trasportati:
      "% pesi trasportati per calcolo peso cumulato. Un numero inferiore al 100% andrà a diminuire la massa cumulata.",
    n_lavoratori_coinvolti: (
      <>
        Inserire un numero maggiore di 1 se questa classe di peso è
        prevalentemente sollevata da piu' lavoratori: inserire il numero di
        operatori addetti a sollevare in contemporanea lo stesso carico. I
        carichi sono pressoché sempre sollevati da più operatori.
        <br></br>
        <br></br>
        Il peso medio della categoria sarà automaticamente ricalcolato.
      </>
    ),
  },
  en: {
    durata_media_netta:
      "Shift duration minus the total duration of breaks and non-repetitive tasks within the work shift.",
    numero_ore_senza_recupero_automatico:
      "An hour is considered recovered when it includes 8-10 minute breaks that allow for rest of the upper limbs.",
    moltiplicatore_recupero:
      "The recovery multiplier modifies the final ergonomic risk index, based on the number of working hours without adequate recovery.",
    numero_ore_senza_recupero_manuale:
      "If manually entered, this data updates the ergonomic risk index, replacing the calculated value of the number of hours without adequate recovery.",
    somma_delle_pause_in_orario_di_lavoro:
      "Sum of all regulated breaks, including short breaks and lunch break, during working hours.",
    durata_lavori_non_ripetitivi:
      "Sum of the durations of all non-repetitive activities within the work shift.",
    rotazione_maggiore_90:
      "Final score of the analysis assuming a rotation greater than 90 minutes of the tasks assigned to the job.",
    rotazione_minore_90:
      "Final score of the analysis obtained by assuming a rotation of less than 90 minutes for the tasks assigned to the job.",
    punteggio_intrinseco:
      "Calculated considering a standard 8-hour shift with a 1-hour lunch break and two 10-minute breaks.",
    punteggio_parziale:
      "Calculated without taking into account the duration of the shift and recoveries.",
    durata_percentuale:
      "Task duration relative to the net duration of the work shift, the sum must equal 100%.",
    tempo_ciclo_osservato:
      "Average duration of the task analysis videos uploaded to the platform",
    totale_secondi_tempo_passivo:
      "Subtraction between the total observed cycle time and the total observed activity time.",
    minuti_recupero_ciclo:
      "Multiplication of the total observed inactivity time by the number of cycles within the shift. ",
    rapporto_tempo_attivo_passivo:
      "For the recovery time within the cycle to be present, the control coefficient must be equal to or less than 6.5",
    durata_media_netta_esclusi_recuperi:
      "Net total duration of the work shift excluding the total recovery time in the shift due to recovery times within the cycle.",
    durata_ciclo_netto_secondi:
      "Net total duration of the repetitive shift divided by the number of cycles or pieces worked during the shift itself.",
    percentuale_differenza_ciclo:
      "A difference greater than 5% between the calculated net cycle time and the observed one is to be considered invalidating for the analysis.",
    minuti_non_giustificati:
      "Difference in minutes between the work shift based on the observed cycle time and the calculated one.",
    ai_analysis_braccia:
      "Counting seconds with shoulder >80° of flexion or abduction >45°. For modifications, go to AI Analysis.",
    ai_analysis_mano:
      "Sum of seconds spent in awkward grips. For modifications, go to AI Analysis.",
    ai_analysis_polso:
      "Extreme deviations in flexion or radio-ulnar deviations are counted with a value of 0.5s each. For modifications, go to AI Analysis.",
    ai_analysis_gomito:
      "Elbow extensions or flexions and forearm pronation-supinations >60° are counted with a value of 0.5s each. For modifications, go to AI Analysis.",
    ai_analysis_azioni:
      "Calculated by the algorithm for each observed technical action. For modifications, go to AI Analysis.",
    frequenza_azioni:
      "How many technical actions are performed in one minute of work.",
    percentuale_t_ciclo:
      "Percentage relative to the calculated cycle time duration.",
    parziale:
      "Partial assessment that does not take into account any recovery or duration.",
    finale_intrinseco: "Valutazione completa indipendente dalla durata.",
    finale_ponderato: "Final assessment adjusted based on net duration.",
    postazione:
      "Specific physical location where a worker performs their daily tasks. It is part of a production area and may encompass one or more tasks.",
    area: "Sequence of processes and activities for production, composed of physical workstations. It constitutes a part of the department.",
    turno:
      "Defined work period, part of the workday, comprising activities, breaks, and tasks, associated with specific jobs.",
    mansione:
      "Homogeneous group of workers performing the same tasks during the same shift, even across different workstations.",
    dipartimento:
      "Division within a company site, encompassing various production areas, distinguished by functions and activities.",
    dati_incerti:
      "The data highlighted in gray on the chart is uncertain due to missing information in the video. Check the quality of the analysis.",
    dati_incerti_trigger:
      "This analysis has a high number of uncertain data due to missing information in the video. Consider recording the video again.",
    edit_completo:
      "Add to mark incongruous postures. Ignore to exclude them from the count. Double-click on the chart to perform the same actions.",
    edit_parziale: "Press the edit button to edit the chart.",
    protocollo:
      "In this section, you can view the available protocols for each task and select the one you want to see the results for.",
  },
};
