export const niosh_lookup = [
  {
    AZ: "0.000",
    BA: "1.000",
    BB: "1.000",
    BC: "1.000",
    BD: "1.000",
    BE: "1.000",
    BF: "1.000",
  },
  {
    AZ: "0.010",
    BA: "1.000",
    BB: "1.000",
    BC: "1.000",
    BD: "1.000",
    BE: "1.000",
    BF: "1.000",
  },
  {
    AZ: "0.020",
    BA: "1.000",
    BB: "1.000",
    BC: "1.000",
    BD: "1.000",
    BE: "1.000",
    BF: "1.000",
  },
  {
    AZ: "0.030",
    BA: "1.000",
    BB: "1.000",
    BC: "1.000",
    BD: "1.000",
    BE: "1.000",
    BF: "1.000",
  },
  {
    AZ: "0.040",
    BA: "1.000",
    BB: "1.000",
    BC: "1.000",
    BD: "1.000",
    BE: "1.000",
    BF: "1.000",
  },
  {
    AZ: "0.050",
    BA: "1.000",
    BB: "1.000",
    BC: "1.000",
    BD: "1.000",
    BE: "1.000",
    BF: "1.000",
  },
  {
    AZ: "0.060",
    BA: "1.000",
    BB: "1.000",
    BC: "1.000",
    BD: "1.000",
    BE: "1.000",
    BF: "1.000",
  },
  {
    AZ: "0.070",
    BA: "1.000",
    BB: "1.000",
    BC: "1.000",
    BD: "1.000",
    BE: "1.000",
    BF: "1.000",
  },
  {
    AZ: "0.080",
    BA: "1.000",
    BB: "1.000",
    BC: "1.000",
    BD: "1.000",
    BE: "1.000",
    BF: "1.000",
  },
  {
    AZ: "0.090",
    BA: "1.000",
    BB: "1.000",
    BC: "1.000",
    BD: "1.000",
    BE: "1.000",
    BF: "1.000",
  },
  {
    AZ: "0.100",
    BA: "1.000",
    BB: "0.950",
    BC: "0.850",
    BD: "1.000",
    BE: "0.950",
    BF: "0.850",
  },
  {
    AZ: "0.110",
    BA: "1.000",
    BB: "0.950",
    BC: "0.850",
    BD: "1.000",
    BE: "0.950",
    BF: "0.850",
  },
  {
    AZ: "0.120",
    BA: "1.000",
    BB: "0.950",
    BC: "0.850",
    BD: "1.000",
    BE: "0.950",
    BF: "0.850",
  },
  {
    AZ: "0.130",
    BA: "1.000",
    BB: "0.950",
    BC: "0.850",
    BD: "1.000",
    BE: "0.950",
    BF: "0.850",
  },
  {
    AZ: "0.140",
    BA: "1.000",
    BB: "0.950",
    BC: "0.850",
    BD: "1.000",
    BE: "0.950",
    BF: "0.850",
  },
  {
    AZ: "0.150",
    BA: "1.000",
    BB: "0.950",
    BC: "0.850",
    BD: "1.000",
    BE: "0.950",
    BF: "0.850",
  },
  {
    AZ: "0.160",
    BA: "1.000",
    BB: "0.950",
    BC: "0.850",
    BD: "1.000",
    BE: "0.950",
    BF: "0.850",
  },
  {
    AZ: "0.170",
    BA: "1.000",
    BB: "0.950",
    BC: "0.850",
    BD: "1.000",
    BE: "0.950",
    BF: "0.850",
  },
  {
    AZ: "0.180",
    BA: "1.000",
    BB: "0.950",
    BC: "0.850",
    BD: "1.000",
    BE: "0.950",
    BF: "0.850",
  },
  {
    AZ: "0.190",
    BA: "1.000",
    BB: "0.950",
    BC: "0.850",
    BD: "1.000",
    BE: "0.950",
    BF: "0.850",
  },
  {
    AZ: "0.200",
    BA: "1.000",
    BB: "0.950",
    BC: "0.850",
    BD: "1.000",
    BE: "0.950",
    BF: "0.850",
  },
  {
    AZ: "0.210",
    BA: "0.999",
    BB: "0.949",
    BC: "0.849",
    BD: "0.999",
    BE: "0.949",
    BF: "0.849",
  },
  {
    AZ: "0.220",
    BA: "0.998",
    BB: "0.948",
    BC: "0.847",
    BD: "0.998",
    BE: "0.948",
    BF: "0.847",
  },
  {
    AZ: "0.230",
    BA: "0.997",
    BB: "0.947",
    BC: "0.846",
    BD: "0.997",
    BE: "0.947",
    BF: "0.846",
  },
  {
    AZ: "0.240",
    BA: "0.996",
    BB: "0.946",
    BC: "0.845",
    BD: "0.996",
    BE: "0.946",
    BF: "0.845",
  },
  {
    AZ: "0.250",
    BA: "0.995",
    BB: "0.945",
    BC: "0.843",
    BD: "0.995",
    BE: "0.945",
    BF: "0.843",
  },
  {
    AZ: "0.260",
    BA: "0.994",
    BB: "0.944",
    BC: "0.842",
    BD: "0.994",
    BE: "0.944",
    BF: "0.842",
  },
  {
    AZ: "0.270",
    BA: "0.993",
    BB: "0.943",
    BC: "0.841",
    BD: "0.993",
    BE: "0.943",
    BF: "0.841",
  },
  {
    AZ: "0.280",
    BA: "0.992",
    BB: "0.942",
    BC: "0.839",
    BD: "0.992",
    BE: "0.942",
    BF: "0.839",
  },
  {
    AZ: "0.290",
    BA: "0.991",
    BB: "0.941",
    BC: "0.838",
    BD: "0.991",
    BE: "0.941",
    BF: "0.838",
  },
  {
    AZ: "0.300",
    BA: "0.990",
    BB: "0.940",
    BC: "0.837",
    BD: "0.990",
    BE: "0.940",
    BF: "0.837",
  },
  {
    AZ: "0.310",
    BA: "0.989",
    BB: "0.939",
    BC: "0.835",
    BD: "0.989",
    BE: "0.939",
    BF: "0.835",
  },
  {
    AZ: "0.320",
    BA: "0.988",
    BB: "0.938",
    BC: "0.834",
    BD: "0.988",
    BE: "0.938",
    BF: "0.834",
  },
  {
    AZ: "0.330",
    BA: "0.987",
    BB: "0.937",
    BC: "0.833",
    BD: "0.987",
    BE: "0.937",
    BF: "0.833",
  },
  {
    AZ: "0.340",
    BA: "0.986",
    BB: "0.936",
    BC: "0.831",
    BD: "0.986",
    BE: "0.936",
    BF: "0.831",
  },
  {
    AZ: "0.350",
    BA: "0.985",
    BB: "0.935",
    BC: "0.830",
    BD: "0.985",
    BE: "0.935",
    BF: "0.830",
  },
  {
    AZ: "0.360",
    BA: "0.984",
    BB: "0.934",
    BC: "0.829",
    BD: "0.984",
    BE: "0.934",
    BF: "0.829",
  },
  {
    AZ: "0.370",
    BA: "0.983",
    BB: "0.933",
    BC: "0.827",
    BD: "0.983",
    BE: "0.933",
    BF: "0.827",
  },
  {
    AZ: "0.380",
    BA: "0.982",
    BB: "0.932",
    BC: "0.826",
    BD: "0.982",
    BE: "0.932",
    BF: "0.826",
  },
  {
    AZ: "0.390",
    BA: "0.981",
    BB: "0.931",
    BC: "0.825",
    BD: "0.981",
    BE: "0.931",
    BF: "0.825",
  },
  {
    AZ: "0.400",
    BA: "0.980",
    BB: "0.930",
    BC: "0.823",
    BD: "0.980",
    BE: "0.930",
    BF: "0.823",
  },
  {
    AZ: "0.410",
    BA: "0.979",
    BB: "0.929",
    BC: "0.822",
    BD: "0.979",
    BE: "0.929",
    BF: "0.822",
  },
  {
    AZ: "0.420",
    BA: "0.978",
    BB: "0.928",
    BC: "0.821",
    BD: "0.978",
    BE: "0.928",
    BF: "0.821",
  },
  {
    AZ: "0.430",
    BA: "0.977",
    BB: "0.927",
    BC: "0.819",
    BD: "0.977",
    BE: "0.927",
    BF: "0.819",
  },
  {
    AZ: "0.440",
    BA: "0.976",
    BB: "0.926",
    BC: "0.818",
    BD: "0.976",
    BE: "0.926",
    BF: "0.818",
  },
  {
    AZ: "0.450",
    BA: "0.975",
    BB: "0.925",
    BC: "0.817",
    BD: "0.975",
    BE: "0.925",
    BF: "0.817",
  },
  {
    AZ: "0.460",
    BA: "0.974",
    BB: "0.924",
    BC: "0.815",
    BD: "0.974",
    BE: "0.924",
    BF: "0.815",
  },
  {
    AZ: "0.470",
    BA: "0.973",
    BB: "0.923",
    BC: "0.814",
    BD: "0.973",
    BE: "0.923",
    BF: "0.814",
  },
  {
    AZ: "0.480",
    BA: "0.972",
    BB: "0.922",
    BC: "0.813",
    BD: "0.972",
    BE: "0.922",
    BF: "0.813",
  },
  {
    AZ: "0.490",
    BA: "0.971",
    BB: "0.921",
    BC: "0.811",
    BD: "0.971",
    BE: "0.921",
    BF: "0.811",
  },
  {
    AZ: "0.500",
    BA: "0.970",
    BB: "0.920",
    BC: "0.810",
    BD: "0.970",
    BE: "0.920",
    BF: "0.810",
  },
  {
    AZ: "0.510",
    BA: "0.969",
    BB: "0.919",
    BC: "0.809",
    BD: "0.969",
    BE: "0.919",
    BF: "0.809",
  },
  {
    AZ: "0.520",
    BA: "0.969",
    BB: "0.918",
    BC: "0.808",
    BD: "0.969",
    BE: "0.918",
    BF: "0.808",
  },
  {
    AZ: "0.530",
    BA: "0.968",
    BB: "0.918",
    BC: "0.806",
    BD: "0.968",
    BE: "0.918",
    BF: "0.806",
  },
  {
    AZ: "0.540",
    BA: "0.968",
    BB: "0.917",
    BC: "0.805",
    BD: "0.968",
    BE: "0.917",
    BF: "0.805",
  },
  {
    AZ: "0.550",
    BA: "0.967",
    BB: "0.916",
    BC: "0.804",
    BD: "0.967",
    BE: "0.916",
    BF: "0.804",
  },
  {
    AZ: "0.560",
    BA: "0.966",
    BB: "0.915",
    BC: "0.803",
    BD: "0.966",
    BE: "0.915",
    BF: "0.803",
  },
  {
    AZ: "0.570",
    BA: "0.966",
    BB: "0.914",
    BC: "0.802",
    BD: "0.966",
    BE: "0.914",
    BF: "0.802",
  },
  {
    AZ: "0.580",
    BA: "0.965",
    BB: "0.914",
    BC: "0.800",
    BD: "0.965",
    BE: "0.914",
    BF: "0.800",
  },
  {
    AZ: "0.590",
    BA: "0.965",
    BB: "0.913",
    BC: "0.799",
    BD: "0.965",
    BE: "0.913",
    BF: "0.799",
  },
  {
    AZ: "0.600",
    BA: "0.964",
    BB: "0.912",
    BC: "0.798",
    BD: "0.964",
    BE: "0.912",
    BF: "0.798",
  },
  {
    AZ: "0.610",
    BA: "0.963",
    BB: "0.911",
    BC: "0.797",
    BD: "0.963",
    BE: "0.911",
    BF: "0.797",
  },
  {
    AZ: "0.620",
    BA: "0.963",
    BB: "0.910",
    BC: "0.796",
    BD: "0.963",
    BE: "0.910",
    BF: "0.796",
  },
  {
    AZ: "0.630",
    BA: "0.962",
    BB: "0.910",
    BC: "0.794",
    BD: "0.962",
    BE: "0.910",
    BF: "0.794",
  },
  {
    AZ: "0.640",
    BA: "0.962",
    BB: "0.909",
    BC: "0.793",
    BD: "0.962",
    BE: "0.909",
    BF: "0.793",
  },
  {
    AZ: "0.650",
    BA: "0.961",
    BB: "0.908",
    BC: "0.792",
    BD: "0.961",
    BE: "0.908",
    BF: "0.792",
  },
  {
    AZ: "0.660",
    BA: "0.960",
    BB: "0.907",
    BC: "0.791",
    BD: "0.960",
    BE: "0.907",
    BF: "0.791",
  },
  {
    AZ: "0.670",
    BA: "0.960",
    BB: "0.906",
    BC: "0.790",
    BD: "0.960",
    BE: "0.906",
    BF: "0.790",
  },
  {
    AZ: "0.680",
    BA: "0.959",
    BB: "0.906",
    BC: "0.788",
    BD: "0.959",
    BE: "0.906",
    BF: "0.788",
  },
  {
    AZ: "0.690",
    BA: "0.959",
    BB: "0.905",
    BC: "0.787",
    BD: "0.959",
    BE: "0.905",
    BF: "0.787",
  },
  {
    AZ: "0.700",
    BA: "0.958",
    BB: "0.904",
    BC: "0.786",
    BD: "0.958",
    BE: "0.904",
    BF: "0.786",
  },
  {
    AZ: "0.710",
    BA: "0.957",
    BB: "0.903",
    BC: "0.785",
    BD: "0.957",
    BE: "0.903",
    BF: "0.785",
  },
  {
    AZ: "0.720",
    BA: "0.957",
    BB: "0.902",
    BC: "0.784",
    BD: "0.957",
    BE: "0.902",
    BF: "0.784",
  },
  {
    AZ: "0.730",
    BA: "0.956",
    BB: "0.902",
    BC: "0.782",
    BD: "0.956",
    BE: "0.902",
    BF: "0.782",
  },
  {
    AZ: "0.740",
    BA: "0.956",
    BB: "0.901",
    BC: "0.781",
    BD: "0.956",
    BE: "0.901",
    BF: "0.781",
  },
  {
    AZ: "0.750",
    BA: "0.955",
    BB: "0.900",
    BC: "0.780",
    BD: "0.955",
    BE: "0.900",
    BF: "0.780",
  },
  {
    AZ: "0.760",
    BA: "0.954",
    BB: "0.899",
    BC: "0.779",
    BD: "0.954",
    BE: "0.899",
    BF: "0.779",
  },
  {
    AZ: "0.770",
    BA: "0.954",
    BB: "0.898",
    BC: "0.778",
    BD: "0.954",
    BE: "0.898",
    BF: "0.778",
  },
  {
    AZ: "0.780",
    BA: "0.953",
    BB: "0.898",
    BC: "0.776",
    BD: "0.953",
    BE: "0.898",
    BF: "0.776",
  },
  {
    AZ: "0.790",
    BA: "0.953",
    BB: "0.897",
    BC: "0.775",
    BD: "0.953",
    BE: "0.897",
    BF: "0.775",
  },
  {
    AZ: "0.800",
    BA: "0.952",
    BB: "0.896",
    BC: "0.774",
    BD: "0.952",
    BE: "0.896",
    BF: "0.774",
  },
  {
    AZ: "0.810",
    BA: "0.951",
    BB: "0.895",
    BC: "0.773",
    BD: "0.951",
    BE: "0.895",
    BF: "0.773",
  },
  {
    AZ: "0.820",
    BA: "0.951",
    BB: "0.894",
    BC: "0.772",
    BD: "0.951",
    BE: "0.894",
    BF: "0.772",
  },
  {
    AZ: "0.830",
    BA: "0.950",
    BB: "0.894",
    BC: "0.770",
    BD: "0.950",
    BE: "0.894",
    BF: "0.770",
  },
  {
    AZ: "0.840",
    BA: "0.950",
    BB: "0.893",
    BC: "0.769",
    BD: "0.950",
    BE: "0.893",
    BF: "0.769",
  },
  {
    AZ: "0.850",
    BA: "0.949",
    BB: "0.892",
    BC: "0.768",
    BD: "0.949",
    BE: "0.892",
    BF: "0.768",
  },
  {
    AZ: "0.860",
    BA: "0.948",
    BB: "0.891",
    BC: "0.767",
    BD: "0.948",
    BE: "0.891",
    BF: "0.767",
  },
  {
    AZ: "0.870",
    BA: "0.948",
    BB: "0.890",
    BC: "0.766",
    BD: "0.948",
    BE: "0.890",
    BF: "0.766",
  },
  {
    AZ: "0.880",
    BA: "0.947",
    BB: "0.890",
    BC: "0.764",
    BD: "0.947",
    BE: "0.890",
    BF: "0.764",
  },
  {
    AZ: "0.890",
    BA: "0.947",
    BB: "0.889",
    BC: "0.763",
    BD: "0.947",
    BE: "0.889",
    BF: "0.763",
  },
  {
    AZ: "0.900",
    BA: "0.946",
    BB: "0.888",
    BC: "0.762",
    BD: "0.946",
    BE: "0.888",
    BF: "0.762",
  },
  {
    AZ: "0.910",
    BA: "0.945",
    BB: "0.887",
    BC: "0.761",
    BD: "0.945",
    BE: "0.887",
    BF: "0.761",
  },
  {
    AZ: "0.920",
    BA: "0.945",
    BB: "0.886",
    BC: "0.760",
    BD: "0.945",
    BE: "0.886",
    BF: "0.760",
  },
  {
    AZ: "0.930",
    BA: "0.944",
    BB: "0.886",
    BC: "0.758",
    BD: "0.944",
    BE: "0.886",
    BF: "0.758",
  },
  {
    AZ: "0.940",
    BA: "0.944",
    BB: "0.885",
    BC: "0.757",
    BD: "0.944",
    BE: "0.885",
    BF: "0.757",
  },
  {
    AZ: "0.950",
    BA: "0.943",
    BB: "0.884",
    BC: "0.756",
    BD: "0.943",
    BE: "0.884",
    BF: "0.756",
  },
  {
    AZ: "0.960",
    BA: "0.942",
    BB: "0.883",
    BC: "0.755",
    BD: "0.942",
    BE: "0.883",
    BF: "0.755",
  },
  {
    AZ: "0.970",
    BA: "0.942",
    BB: "0.882",
    BC: "0.754",
    BD: "0.942",
    BE: "0.882",
    BF: "0.754",
  },
  {
    AZ: "0.980",
    BA: "0.941",
    BB: "0.882",
    BC: "0.752",
    BD: "0.941",
    BE: "0.882",
    BF: "0.752",
  },
  {
    AZ: "0.990",
    BA: "0.941",
    BB: "0.881",
    BC: "0.751",
    BD: "0.941",
    BE: "0.881",
    BF: "0.751",
  },
  {
    AZ: "1.000",
    BA: "0.940",
    BB: "0.880",
    BC: "0.750",
    BD: "0.940",
    BE: "0.880",
    BF: "0.750",
  },
  {
    AZ: "1.010",
    BA: "0.940",
    BB: "0.880",
    BC: "0.749",
    BD: "0.940",
    BE: "0.880",
    BF: "0.749",
  },
  {
    AZ: "1.020",
    BA: "0.939",
    BB: "0.879",
    BC: "0.748",
    BD: "0.939",
    BE: "0.879",
    BF: "0.748",
  },
  {
    AZ: "1.030",
    BA: "0.939",
    BB: "0.879",
    BC: "0.747",
    BD: "0.939",
    BE: "0.879",
    BF: "0.747",
  },
  {
    AZ: "1.040",
    BA: "0.939",
    BB: "0.878",
    BC: "0.746",
    BD: "0.939",
    BE: "0.878",
    BF: "0.746",
  },
  {
    AZ: "1.050",
    BA: "0.939",
    BB: "0.878",
    BC: "0.745",
    BD: "0.939",
    BE: "0.878",
    BF: "0.745",
  },
  {
    AZ: "1.060",
    BA: "0.938",
    BB: "0.878",
    BC: "0.744",
    BD: "0.938",
    BE: "0.878",
    BF: "0.744",
  },
  {
    AZ: "1.070",
    BA: "0.938",
    BB: "0.877",
    BC: "0.743",
    BD: "0.938",
    BE: "0.877",
    BF: "0.743",
  },
  {
    AZ: "1.080",
    BA: "0.938",
    BB: "0.877",
    BC: "0.742",
    BD: "0.938",
    BE: "0.877",
    BF: "0.742",
  },
  {
    AZ: "1.090",
    BA: "0.937",
    BB: "0.876",
    BC: "0.741",
    BD: "0.937",
    BE: "0.876",
    BF: "0.741",
  },
  {
    AZ: "1.100",
    BA: "0.937",
    BB: "0.876",
    BC: "0.740",
    BD: "0.937",
    BE: "0.876",
    BF: "0.740",
  },
  {
    AZ: "1.110",
    BA: "0.937",
    BB: "0.876",
    BC: "0.739",
    BD: "0.937",
    BE: "0.876",
    BF: "0.739",
  },
  {
    AZ: "1.120",
    BA: "0.936",
    BB: "0.875",
    BC: "0.738",
    BD: "0.936",
    BE: "0.875",
    BF: "0.738",
  },
  {
    AZ: "1.130",
    BA: "0.936",
    BB: "0.875",
    BC: "0.737",
    BD: "0.936",
    BE: "0.875",
    BF: "0.737",
  },
  {
    AZ: "1.140",
    BA: "0.936",
    BB: "0.874",
    BC: "0.736",
    BD: "0.936",
    BE: "0.874",
    BF: "0.736",
  },
  {
    AZ: "1.150",
    BA: "0.936",
    BB: "0.874",
    BC: "0.735",
    BD: "0.936",
    BE: "0.874",
    BF: "0.735",
  },
  {
    AZ: "1.160",
    BA: "0.935",
    BB: "0.874",
    BC: "0.734",
    BD: "0.935",
    BE: "0.874",
    BF: "0.734",
  },
  {
    AZ: "1.170",
    BA: "0.935",
    BB: "0.873",
    BC: "0.733",
    BD: "0.935",
    BE: "0.873",
    BF: "0.733",
  },
  {
    AZ: "1.180",
    BA: "0.935",
    BB: "0.873",
    BC: "0.732",
    BD: "0.935",
    BE: "0.873",
    BF: "0.732",
  },
  {
    AZ: "1.190",
    BA: "0.934",
    BB: "0.872",
    BC: "0.731",
    BD: "0.934",
    BE: "0.872",
    BF: "0.731",
  },
  {
    AZ: "1.200",
    BA: "0.934",
    BB: "0.872",
    BC: "0.730",
    BD: "0.934",
    BE: "0.872",
    BF: "0.730",
  },
  {
    AZ: "1.210",
    BA: "0.934",
    BB: "0.872",
    BC: "0.729",
    BD: "0.934",
    BE: "0.872",
    BF: "0.729",
  },
  {
    AZ: "1.220",
    BA: "0.933",
    BB: "0.871",
    BC: "0.728",
    BD: "0.933",
    BE: "0.871",
    BF: "0.728",
  },
  {
    AZ: "1.230",
    BA: "0.933",
    BB: "0.871",
    BC: "0.727",
    BD: "0.933",
    BE: "0.871",
    BF: "0.727",
  },
  {
    AZ: "1.240",
    BA: "0.933",
    BB: "0.870",
    BC: "0.726",
    BD: "0.933",
    BE: "0.870",
    BF: "0.726",
  },
  {
    AZ: "1.250",
    BA: "0.933",
    BB: "0.870",
    BC: "0.725",
    BD: "0.933",
    BE: "0.870",
    BF: "0.725",
  },
  {
    AZ: "1.260",
    BA: "0.932",
    BB: "0.870",
    BC: "0.724",
    BD: "0.932",
    BE: "0.870",
    BF: "0.724",
  },
  {
    AZ: "1.270",
    BA: "0.932",
    BB: "0.869",
    BC: "0.723",
    BD: "0.932",
    BE: "0.869",
    BF: "0.723",
  },
  {
    AZ: "1.280",
    BA: "0.932",
    BB: "0.869",
    BC: "0.722",
    BD: "0.932",
    BE: "0.869",
    BF: "0.722",
  },
  {
    AZ: "1.290",
    BA: "0.931",
    BB: "0.868",
    BC: "0.721",
    BD: "0.931",
    BE: "0.868",
    BF: "0.721",
  },
  {
    AZ: "1.300",
    BA: "0.931",
    BB: "0.868",
    BC: "0.720",
    BD: "0.931",
    BE: "0.868",
    BF: "0.720",
  },
  {
    AZ: "1.310",
    BA: "0.931",
    BB: "0.868",
    BC: "0.719",
    BD: "0.931",
    BE: "0.868",
    BF: "0.719",
  },
  {
    AZ: "1.320",
    BA: "0.930",
    BB: "0.867",
    BC: "0.718",
    BD: "0.930",
    BE: "0.867",
    BF: "0.718",
  },
  {
    AZ: "1.330",
    BA: "0.930",
    BB: "0.867",
    BC: "0.717",
    BD: "0.930",
    BE: "0.867",
    BF: "0.717",
  },
  {
    AZ: "1.340",
    BA: "0.930",
    BB: "0.866",
    BC: "0.716",
    BD: "0.930",
    BE: "0.866",
    BF: "0.716",
  },
  {
    AZ: "1.350",
    BA: "0.930",
    BB: "0.866",
    BC: "0.715",
    BD: "0.930",
    BE: "0.866",
    BF: "0.715",
  },
  {
    AZ: "1.360",
    BA: "0.929",
    BB: "0.866",
    BC: "0.714",
    BD: "0.929",
    BE: "0.866",
    BF: "0.714",
  },
  {
    AZ: "1.370",
    BA: "0.929",
    BB: "0.865",
    BC: "0.713",
    BD: "0.929",
    BE: "0.865",
    BF: "0.713",
  },
  {
    AZ: "1.380",
    BA: "0.929",
    BB: "0.865",
    BC: "0.712",
    BD: "0.929",
    BE: "0.865",
    BF: "0.712",
  },
  {
    AZ: "1.390",
    BA: "0.928",
    BB: "0.864",
    BC: "0.711",
    BD: "0.928",
    BE: "0.864",
    BF: "0.711",
  },
  {
    AZ: "1.400",
    BA: "0.928",
    BB: "0.864",
    BC: "0.710",
    BD: "0.928",
    BE: "0.864",
    BF: "0.710",
  },
  {
    AZ: "1.410",
    BA: "0.928",
    BB: "0.864",
    BC: "0.709",
    BD: "0.928",
    BE: "0.864",
    BF: "0.709",
  },
  {
    AZ: "1.420",
    BA: "0.927",
    BB: "0.863",
    BC: "0.708",
    BD: "0.927",
    BE: "0.863",
    BF: "0.708",
  },
  {
    AZ: "1.430",
    BA: "0.927",
    BB: "0.863",
    BC: "0.707",
    BD: "0.927",
    BE: "0.863",
    BF: "0.707",
  },
  {
    AZ: "1.440",
    BA: "0.927",
    BB: "0.862",
    BC: "0.706",
    BD: "0.927",
    BE: "0.862",
    BF: "0.706",
  },
  {
    AZ: "1.450",
    BA: "0.927",
    BB: "0.862",
    BC: "0.705",
    BD: "0.927",
    BE: "0.862",
    BF: "0.705",
  },
  {
    AZ: "1.460",
    BA: "0.926",
    BB: "0.862",
    BC: "0.704",
    BD: "0.926",
    BE: "0.862",
    BF: "0.704",
  },
  {
    AZ: "1.470",
    BA: "0.926",
    BB: "0.861",
    BC: "0.703",
    BD: "0.926",
    BE: "0.861",
    BF: "0.703",
  },
  {
    AZ: "1.480",
    BA: "0.926",
    BB: "0.861",
    BC: "0.702",
    BD: "0.926",
    BE: "0.861",
    BF: "0.702",
  },
  {
    AZ: "1.490",
    BA: "0.925",
    BB: "0.860",
    BC: "0.701",
    BD: "0.925",
    BE: "0.860",
    BF: "0.701",
  },
  {
    AZ: "1.500",
    BA: "0.925",
    BB: "0.860",
    BC: "0.700",
    BD: "0.925",
    BE: "0.860",
    BF: "0.700",
  },
  {
    AZ: "1.510",
    BA: "0.925",
    BB: "0.860",
    BC: "0.699",
    BD: "0.925",
    BE: "0.860",
    BF: "0.699",
  },
  {
    AZ: "1.520",
    BA: "0.924",
    BB: "0.859",
    BC: "0.698",
    BD: "0.924",
    BE: "0.859",
    BF: "0.698",
  },
  {
    AZ: "1.530",
    BA: "0.924",
    BB: "0.859",
    BC: "0.697",
    BD: "0.924",
    BE: "0.859",
    BF: "0.697",
  },
  {
    AZ: "1.540",
    BA: "0.924",
    BB: "0.858",
    BC: "0.696",
    BD: "0.924",
    BE: "0.858",
    BF: "0.696",
  },
  {
    AZ: "1.550",
    BA: "0.924",
    BB: "0.858",
    BC: "0.695",
    BD: "0.924",
    BE: "0.858",
    BF: "0.695",
  },
  {
    AZ: "1.560",
    BA: "0.923",
    BB: "0.858",
    BC: "0.694",
    BD: "0.923",
    BE: "0.858",
    BF: "0.694",
  },
  {
    AZ: "1.570",
    BA: "0.923",
    BB: "0.857",
    BC: "0.693",
    BD: "0.923",
    BE: "0.857",
    BF: "0.693",
  },
  {
    AZ: "1.580",
    BA: "0.923",
    BB: "0.857",
    BC: "0.692",
    BD: "0.923",
    BE: "0.857",
    BF: "0.692",
  },
  {
    AZ: "1.590",
    BA: "0.922",
    BB: "0.856",
    BC: "0.691",
    BD: "0.922",
    BE: "0.856",
    BF: "0.691",
  },
  {
    AZ: "1.600",
    BA: "0.922",
    BB: "0.856",
    BC: "0.690",
    BD: "0.922",
    BE: "0.856",
    BF: "0.690",
  },
  {
    AZ: "1.610",
    BA: "0.922",
    BB: "0.856",
    BC: "0.689",
    BD: "0.922",
    BE: "0.856",
    BF: "0.689",
  },
  {
    AZ: "1.620",
    BA: "0.921",
    BB: "0.855",
    BC: "0.688",
    BD: "0.921",
    BE: "0.855",
    BF: "0.688",
  },
  {
    AZ: "1.630",
    BA: "0.921",
    BB: "0.855",
    BC: "0.687",
    BD: "0.921",
    BE: "0.855",
    BF: "0.687",
  },
  {
    AZ: "1.640",
    BA: "0.921",
    BB: "0.854",
    BC: "0.686",
    BD: "0.921",
    BE: "0.854",
    BF: "0.686",
  },
  {
    AZ: "1.650",
    BA: "0.921",
    BB: "0.854",
    BC: "0.685",
    BD: "0.921",
    BE: "0.854",
    BF: "0.685",
  },
  {
    AZ: "1.660",
    BA: "0.920",
    BB: "0.854",
    BC: "0.684",
    BD: "0.920",
    BE: "0.854",
    BF: "0.684",
  },
  {
    AZ: "1.670",
    BA: "0.920",
    BB: "0.853",
    BC: "0.683",
    BD: "0.920",
    BE: "0.853",
    BF: "0.683",
  },
  {
    AZ: "1.680",
    BA: "0.920",
    BB: "0.853",
    BC: "0.682",
    BD: "0.920",
    BE: "0.853",
    BF: "0.682",
  },
  {
    AZ: "1.690",
    BA: "0.919",
    BB: "0.852",
    BC: "0.681",
    BD: "0.919",
    BE: "0.852",
    BF: "0.681",
  },
  {
    AZ: "1.700",
    BA: "0.919",
    BB: "0.852",
    BC: "0.680",
    BD: "0.919",
    BE: "0.852",
    BF: "0.680",
  },
  {
    AZ: "1.710",
    BA: "0.919",
    BB: "0.852",
    BC: "0.679",
    BD: "0.919",
    BE: "0.852",
    BF: "0.679",
  },
  {
    AZ: "1.720",
    BA: "0.918",
    BB: "0.851",
    BC: "0.678",
    BD: "0.918",
    BE: "0.851",
    BF: "0.678",
  },
  {
    AZ: "1.730",
    BA: "0.918",
    BB: "0.851",
    BC: "0.677",
    BD: "0.918",
    BE: "0.851",
    BF: "0.677",
  },
  {
    AZ: "1.740",
    BA: "0.918",
    BB: "0.850",
    BC: "0.676",
    BD: "0.918",
    BE: "0.850",
    BF: "0.676",
  },
  {
    AZ: "1.750",
    BA: "0.918",
    BB: "0.850",
    BC: "0.675",
    BD: "0.918",
    BE: "0.850",
    BF: "0.675",
  },
  {
    AZ: "1.760",
    BA: "0.917",
    BB: "0.850",
    BC: "0.674",
    BD: "0.917",
    BE: "0.850",
    BF: "0.674",
  },
  {
    AZ: "1.770",
    BA: "0.917",
    BB: "0.849",
    BC: "0.673",
    BD: "0.917",
    BE: "0.849",
    BF: "0.673",
  },
  {
    AZ: "1.780",
    BA: "0.917",
    BB: "0.849",
    BC: "0.672",
    BD: "0.917",
    BE: "0.849",
    BF: "0.672",
  },
  {
    AZ: "1.790",
    BA: "0.916",
    BB: "0.848",
    BC: "0.671",
    BD: "0.916",
    BE: "0.848",
    BF: "0.671",
  },
  {
    AZ: "1.800",
    BA: "0.916",
    BB: "0.848",
    BC: "0.670",
    BD: "0.916",
    BE: "0.848",
    BF: "0.670",
  },
  {
    AZ: "1.810",
    BA: "0.916",
    BB: "0.848",
    BC: "0.669",
    BD: "0.916",
    BE: "0.848",
    BF: "0.669",
  },
  {
    AZ: "1.820",
    BA: "0.915",
    BB: "0.847",
    BC: "0.668",
    BD: "0.915",
    BE: "0.847",
    BF: "0.668",
  },
  {
    AZ: "1.830",
    BA: "0.915",
    BB: "0.847",
    BC: "0.667",
    BD: "0.915",
    BE: "0.847",
    BF: "0.667",
  },
  {
    AZ: "1.840",
    BA: "0.915",
    BB: "0.846",
    BC: "0.666",
    BD: "0.915",
    BE: "0.846",
    BF: "0.666",
  },
  {
    AZ: "1.850",
    BA: "0.915",
    BB: "0.846",
    BC: "0.665",
    BD: "0.915",
    BE: "0.846",
    BF: "0.665",
  },
  {
    AZ: "1.860",
    BA: "0.914",
    BB: "0.846",
    BC: "0.664",
    BD: "0.914",
    BE: "0.846",
    BF: "0.664",
  },
  {
    AZ: "1.870",
    BA: "0.914",
    BB: "0.845",
    BC: "0.663",
    BD: "0.914",
    BE: "0.845",
    BF: "0.663",
  },
  {
    AZ: "1.880",
    BA: "0.914",
    BB: "0.845",
    BC: "0.662",
    BD: "0.914",
    BE: "0.845",
    BF: "0.662",
  },
  {
    AZ: "1.890",
    BA: "0.913",
    BB: "0.844",
    BC: "0.661",
    BD: "0.913",
    BE: "0.844",
    BF: "0.661",
  },
  {
    AZ: "1.900",
    BA: "0.913",
    BB: "0.844",
    BC: "0.660",
    BD: "0.913",
    BE: "0.844",
    BF: "0.660",
  },
  {
    AZ: "1.910",
    BA: "0.913",
    BB: "0.844",
    BC: "0.659",
    BD: "0.913",
    BE: "0.844",
    BF: "0.659",
  },
  {
    AZ: "1.920",
    BA: "0.912",
    BB: "0.843",
    BC: "0.658",
    BD: "0.912",
    BE: "0.843",
    BF: "0.658",
  },
  {
    AZ: "1.930",
    BA: "0.912",
    BB: "0.843",
    BC: "0.657",
    BD: "0.912",
    BE: "0.843",
    BF: "0.657",
  },
  {
    AZ: "1.940",
    BA: "0.912",
    BB: "0.842",
    BC: "0.656",
    BD: "0.912",
    BE: "0.842",
    BF: "0.656",
  },
  {
    AZ: "1.950",
    BA: "0.912",
    BB: "0.842",
    BC: "0.655",
    BD: "0.912",
    BE: "0.842",
    BF: "0.655",
  },
  {
    AZ: "1.960",
    BA: "0.911",
    BB: "0.842",
    BC: "0.654",
    BD: "0.911",
    BE: "0.842",
    BF: "0.654",
  },
  {
    AZ: "1.970",
    BA: "0.911",
    BB: "0.841",
    BC: "0.653",
    BD: "0.911",
    BE: "0.841",
    BF: "0.653",
  },
  {
    AZ: "1.980",
    BA: "0.911",
    BB: "0.841",
    BC: "0.652",
    BD: "0.911",
    BE: "0.841",
    BF: "0.652",
  },
  {
    AZ: "1.990",
    BA: "0.910",
    BB: "0.840",
    BC: "0.651",
    BD: "0.910",
    BE: "0.840",
    BF: "0.651",
  },
  {
    AZ: "2.000",
    BA: "0.910",
    BB: "0.840",
    BC: "0.650",
    BD: "0.910",
    BE: "0.840",
    BF: "0.650",
  },
  {
    AZ: "2.010",
    BA: "0.910",
    BB: "0.840",
    BC: "0.649",
    BD: "0.910",
    BE: "0.840",
    BF: "0.649",
  },
  {
    AZ: "2.020",
    BA: "0.909",
    BB: "0.839",
    BC: "0.648",
    BD: "0.909",
    BE: "0.839",
    BF: "0.648",
  },
  {
    AZ: "2.030",
    BA: "0.909",
    BB: "0.839",
    BC: "0.647",
    BD: "0.909",
    BE: "0.839",
    BF: "0.647",
  },
  {
    AZ: "2.040",
    BA: "0.909",
    BB: "0.838",
    BC: "0.646",
    BD: "0.909",
    BE: "0.838",
    BF: "0.646",
  },
  {
    AZ: "2.050",
    BA: "0.909",
    BB: "0.838",
    BC: "0.645",
    BD: "0.909",
    BE: "0.838",
    BF: "0.645",
  },
  {
    AZ: "2.060",
    BA: "0.908",
    BB: "0.837",
    BC: "0.644",
    BD: "0.908",
    BE: "0.837",
    BF: "0.644",
  },
  {
    AZ: "2.070",
    BA: "0.908",
    BB: "0.837",
    BC: "0.643",
    BD: "0.908",
    BE: "0.837",
    BF: "0.643",
  },
  {
    AZ: "2.080",
    BA: "0.908",
    BB: "0.836",
    BC: "0.642",
    BD: "0.908",
    BE: "0.836",
    BF: "0.642",
  },
  {
    AZ: "2.090",
    BA: "0.907",
    BB: "0.836",
    BC: "0.641",
    BD: "0.907",
    BE: "0.836",
    BF: "0.641",
  },
  {
    AZ: "2.100",
    BA: "0.907",
    BB: "0.835",
    BC: "0.640",
    BD: "0.907",
    BE: "0.835",
    BF: "0.640",
  },
  {
    AZ: "2.110",
    BA: "0.907",
    BB: "0.835",
    BC: "0.639",
    BD: "0.907",
    BE: "0.835",
    BF: "0.639",
  },
  {
    AZ: "2.120",
    BA: "0.906",
    BB: "0.834",
    BC: "0.638",
    BD: "0.906",
    BE: "0.834",
    BF: "0.638",
  },
  {
    AZ: "2.130",
    BA: "0.906",
    BB: "0.834",
    BC: "0.637",
    BD: "0.906",
    BE: "0.834",
    BF: "0.637",
  },
  {
    AZ: "2.140",
    BA: "0.906",
    BB: "0.833",
    BC: "0.636",
    BD: "0.906",
    BE: "0.833",
    BF: "0.636",
  },
  {
    AZ: "2.150",
    BA: "0.906",
    BB: "0.833",
    BC: "0.635",
    BD: "0.906",
    BE: "0.833",
    BF: "0.635",
  },
  {
    AZ: "2.160",
    BA: "0.905",
    BB: "0.832",
    BC: "0.634",
    BD: "0.905",
    BE: "0.832",
    BF: "0.634",
  },
  {
    AZ: "2.170",
    BA: "0.905",
    BB: "0.832",
    BC: "0.633",
    BD: "0.905",
    BE: "0.832",
    BF: "0.633",
  },
  {
    AZ: "2.180",
    BA: "0.905",
    BB: "0.831",
    BC: "0.632",
    BD: "0.905",
    BE: "0.831",
    BF: "0.632",
  },
  {
    AZ: "2.190",
    BA: "0.904",
    BB: "0.831",
    BC: "0.631",
    BD: "0.904",
    BE: "0.831",
    BF: "0.631",
  },
  {
    AZ: "2.200",
    BA: "0.904",
    BB: "0.830",
    BC: "0.630",
    BD: "0.904",
    BE: "0.830",
    BF: "0.630",
  },
  {
    AZ: "2.210",
    BA: "0.904",
    BB: "0.830",
    BC: "0.629",
    BD: "0.904",
    BE: "0.830",
    BF: "0.629",
  },
  {
    AZ: "2.220",
    BA: "0.903",
    BB: "0.829",
    BC: "0.628",
    BD: "0.903",
    BE: "0.829",
    BF: "0.628",
  },
  {
    AZ: "2.230",
    BA: "0.903",
    BB: "0.829",
    BC: "0.627",
    BD: "0.903",
    BE: "0.829",
    BF: "0.627",
  },
  {
    AZ: "2.240",
    BA: "0.903",
    BB: "0.828",
    BC: "0.626",
    BD: "0.903",
    BE: "0.828",
    BF: "0.626",
  },
  {
    AZ: "2.250",
    BA: "0.903",
    BB: "0.828",
    BC: "0.625",
    BD: "0.903",
    BE: "0.828",
    BF: "0.625",
  },
  {
    AZ: "2.260",
    BA: "0.902",
    BB: "0.827",
    BC: "0.624",
    BD: "0.902",
    BE: "0.827",
    BF: "0.624",
  },
  {
    AZ: "2.270",
    BA: "0.902",
    BB: "0.827",
    BC: "0.623",
    BD: "0.902",
    BE: "0.827",
    BF: "0.623",
  },
  {
    AZ: "2.280",
    BA: "0.902",
    BB: "0.826",
    BC: "0.622",
    BD: "0.902",
    BE: "0.826",
    BF: "0.622",
  },
  {
    AZ: "2.290",
    BA: "0.901",
    BB: "0.826",
    BC: "0.621",
    BD: "0.901",
    BE: "0.826",
    BF: "0.621",
  },
  {
    AZ: "2.300",
    BA: "0.901",
    BB: "0.825",
    BC: "0.620",
    BD: "0.901",
    BE: "0.825",
    BF: "0.620",
  },
  {
    AZ: "2.310",
    BA: "0.901",
    BB: "0.825",
    BC: "0.619",
    BD: "0.901",
    BE: "0.825",
    BF: "0.619",
  },
  {
    AZ: "2.320",
    BA: "0.900",
    BB: "0.824",
    BC: "0.618",
    BD: "0.900",
    BE: "0.824",
    BF: "0.618",
  },
  {
    AZ: "2.330",
    BA: "0.900",
    BB: "0.824",
    BC: "0.617",
    BD: "0.900",
    BE: "0.824",
    BF: "0.617",
  },
  {
    AZ: "2.340",
    BA: "0.900",
    BB: "0.823",
    BC: "0.616",
    BD: "0.900",
    BE: "0.823",
    BF: "0.616",
  },
  {
    AZ: "2.350",
    BA: "0.900",
    BB: "0.823",
    BC: "0.615",
    BD: "0.900",
    BE: "0.823",
    BF: "0.615",
  },
  {
    AZ: "2.360",
    BA: "0.899",
    BB: "0.822",
    BC: "0.614",
    BD: "0.899",
    BE: "0.822",
    BF: "0.614",
  },
  {
    AZ: "2.370",
    BA: "0.899",
    BB: "0.822",
    BC: "0.613",
    BD: "0.899",
    BE: "0.822",
    BF: "0.613",
  },
  {
    AZ: "2.380",
    BA: "0.899",
    BB: "0.821",
    BC: "0.612",
    BD: "0.899",
    BE: "0.821",
    BF: "0.612",
  },
  {
    AZ: "2.390",
    BA: "0.898",
    BB: "0.821",
    BC: "0.611",
    BD: "0.898",
    BE: "0.821",
    BF: "0.611",
  },
  {
    AZ: "2.400",
    BA: "0.898",
    BB: "0.820",
    BC: "0.610",
    BD: "0.898",
    BE: "0.820",
    BF: "0.610",
  },
  {
    AZ: "2.410",
    BA: "0.898",
    BB: "0.820",
    BC: "0.609",
    BD: "0.898",
    BE: "0.820",
    BF: "0.609",
  },
  {
    AZ: "2.420",
    BA: "0.897",
    BB: "0.819",
    BC: "0.608",
    BD: "0.897",
    BE: "0.819",
    BF: "0.608",
  },
  {
    AZ: "2.430",
    BA: "0.897",
    BB: "0.819",
    BC: "0.607",
    BD: "0.897",
    BE: "0.819",
    BF: "0.607",
  },
  {
    AZ: "2.440",
    BA: "0.897",
    BB: "0.818",
    BC: "0.606",
    BD: "0.897",
    BE: "0.818",
    BF: "0.606",
  },
  {
    AZ: "2.450",
    BA: "0.897",
    BB: "0.818",
    BC: "0.605",
    BD: "0.897",
    BE: "0.818",
    BF: "0.605",
  },
  {
    AZ: "2.460",
    BA: "0.896",
    BB: "0.817",
    BC: "0.604",
    BD: "0.896",
    BE: "0.817",
    BF: "0.604",
  },
  {
    AZ: "2.470",
    BA: "0.896",
    BB: "0.817",
    BC: "0.603",
    BD: "0.896",
    BE: "0.817",
    BF: "0.603",
  },
  {
    AZ: "2.480",
    BA: "0.896",
    BB: "0.816",
    BC: "0.602",
    BD: "0.896",
    BE: "0.816",
    BF: "0.602",
  },
  {
    AZ: "2.490",
    BA: "0.895",
    BB: "0.816",
    BC: "0.601",
    BD: "0.895",
    BE: "0.816",
    BF: "0.601",
  },
  {
    AZ: "2.500",
    BA: "0.895",
    BB: "0.815",
    BC: "0.600",
    BD: "0.895",
    BE: "0.815",
    BF: "0.600",
  },
  {
    AZ: "2.510",
    BA: "0.895",
    BB: "0.815",
    BC: "0.599",
    BD: "0.895",
    BE: "0.815",
    BF: "0.599",
  },
  {
    AZ: "2.520",
    BA: "0.894",
    BB: "0.814",
    BC: "0.598",
    BD: "0.894",
    BE: "0.814",
    BF: "0.598",
  },
  {
    AZ: "2.530",
    BA: "0.894",
    BB: "0.814",
    BC: "0.597",
    BD: "0.894",
    BE: "0.814",
    BF: "0.597",
  },
  {
    AZ: "2.540",
    BA: "0.894",
    BB: "0.813",
    BC: "0.596",
    BD: "0.894",
    BE: "0.813",
    BF: "0.596",
  },
  {
    AZ: "2.550",
    BA: "0.894",
    BB: "0.813",
    BC: "0.595",
    BD: "0.894",
    BE: "0.813",
    BF: "0.595",
  },
  {
    AZ: "2.560",
    BA: "0.893",
    BB: "0.812",
    BC: "0.594",
    BD: "0.893",
    BE: "0.812",
    BF: "0.594",
  },
  {
    AZ: "2.570",
    BA: "0.893",
    BB: "0.812",
    BC: "0.593",
    BD: "0.893",
    BE: "0.812",
    BF: "0.593",
  },
  {
    AZ: "2.580",
    BA: "0.893",
    BB: "0.811",
    BC: "0.592",
    BD: "0.893",
    BE: "0.811",
    BF: "0.592",
  },
  {
    AZ: "2.590",
    BA: "0.892",
    BB: "0.811",
    BC: "0.591",
    BD: "0.892",
    BE: "0.811",
    BF: "0.591",
  },
  {
    AZ: "2.600",
    BA: "0.892",
    BB: "0.810",
    BC: "0.590",
    BD: "0.892",
    BE: "0.810",
    BF: "0.590",
  },
  {
    AZ: "2.610",
    BA: "0.892",
    BB: "0.810",
    BC: "0.589",
    BD: "0.892",
    BE: "0.810",
    BF: "0.589",
  },
  {
    AZ: "2.620",
    BA: "0.891",
    BB: "0.809",
    BC: "0.588",
    BD: "0.891",
    BE: "0.809",
    BF: "0.588",
  },
  {
    AZ: "2.630",
    BA: "0.891",
    BB: "0.809",
    BC: "0.587",
    BD: "0.891",
    BE: "0.809",
    BF: "0.587",
  },
  {
    AZ: "2.640",
    BA: "0.891",
    BB: "0.808",
    BC: "0.586",
    BD: "0.891",
    BE: "0.808",
    BF: "0.586",
  },
  {
    AZ: "2.650",
    BA: "0.891",
    BB: "0.808",
    BC: "0.585",
    BD: "0.891",
    BE: "0.808",
    BF: "0.585",
  },
  {
    AZ: "2.660",
    BA: "0.890",
    BB: "0.807",
    BC: "0.584",
    BD: "0.890",
    BE: "0.807",
    BF: "0.584",
  },
  {
    AZ: "2.670",
    BA: "0.890",
    BB: "0.807",
    BC: "0.583",
    BD: "0.890",
    BE: "0.807",
    BF: "0.583",
  },
  {
    AZ: "2.680",
    BA: "0.890",
    BB: "0.806",
    BC: "0.582",
    BD: "0.890",
    BE: "0.806",
    BF: "0.582",
  },
  {
    AZ: "2.690",
    BA: "0.889",
    BB: "0.806",
    BC: "0.581",
    BD: "0.889",
    BE: "0.806",
    BF: "0.581",
  },
  {
    AZ: "2.700",
    BA: "0.889",
    BB: "0.805",
    BC: "0.580",
    BD: "0.889",
    BE: "0.805",
    BF: "0.580",
  },
  {
    AZ: "2.710",
    BA: "0.889",
    BB: "0.805",
    BC: "0.579",
    BD: "0.889",
    BE: "0.805",
    BF: "0.579",
  },
  {
    AZ: "2.720",
    BA: "0.888",
    BB: "0.804",
    BC: "0.578",
    BD: "0.888",
    BE: "0.804",
    BF: "0.578",
  },
  {
    AZ: "2.730",
    BA: "0.888",
    BB: "0.804",
    BC: "0.577",
    BD: "0.888",
    BE: "0.804",
    BF: "0.577",
  },
  {
    AZ: "2.740",
    BA: "0.888",
    BB: "0.803",
    BC: "0.576",
    BD: "0.888",
    BE: "0.803",
    BF: "0.576",
  },
  {
    AZ: "2.750",
    BA: "0.888",
    BB: "0.803",
    BC: "0.575",
    BD: "0.888",
    BE: "0.803",
    BF: "0.575",
  },
  {
    AZ: "2.760",
    BA: "0.887",
    BB: "0.802",
    BC: "0.574",
    BD: "0.887",
    BE: "0.802",
    BF: "0.574",
  },
  {
    AZ: "2.770",
    BA: "0.887",
    BB: "0.802",
    BC: "0.573",
    BD: "0.887",
    BE: "0.802",
    BF: "0.573",
  },
  {
    AZ: "2.780",
    BA: "0.887",
    BB: "0.801",
    BC: "0.572",
    BD: "0.887",
    BE: "0.801",
    BF: "0.572",
  },
  {
    AZ: "2.790",
    BA: "0.886",
    BB: "0.801",
    BC: "0.571",
    BD: "0.886",
    BE: "0.801",
    BF: "0.571",
  },
  {
    AZ: "2.800",
    BA: "0.886",
    BB: "0.800",
    BC: "0.570",
    BD: "0.886",
    BE: "0.800",
    BF: "0.570",
  },
  {
    AZ: "2.810",
    BA: "0.886",
    BB: "0.800",
    BC: "0.569",
    BD: "0.886",
    BE: "0.800",
    BF: "0.569",
  },
  {
    AZ: "2.820",
    BA: "0.885",
    BB: "0.799",
    BC: "0.568",
    BD: "0.885",
    BE: "0.799",
    BF: "0.568",
  },
  {
    AZ: "2.830",
    BA: "0.885",
    BB: "0.799",
    BC: "0.567",
    BD: "0.885",
    BE: "0.799",
    BF: "0.567",
  },
  {
    AZ: "2.840",
    BA: "0.885",
    BB: "0.798",
    BC: "0.566",
    BD: "0.885",
    BE: "0.798",
    BF: "0.566",
  },
  {
    AZ: "2.850",
    BA: "0.885",
    BB: "0.798",
    BC: "0.565",
    BD: "0.885",
    BE: "0.798",
    BF: "0.565",
  },
  {
    AZ: "2.860",
    BA: "0.884",
    BB: "0.797",
    BC: "0.564",
    BD: "0.884",
    BE: "0.797",
    BF: "0.564",
  },
  {
    AZ: "2.870",
    BA: "0.884",
    BB: "0.797",
    BC: "0.563",
    BD: "0.884",
    BE: "0.797",
    BF: "0.563",
  },
  {
    AZ: "2.880",
    BA: "0.884",
    BB: "0.796",
    BC: "0.562",
    BD: "0.884",
    BE: "0.796",
    BF: "0.562",
  },
  {
    AZ: "2.890",
    BA: "0.883",
    BB: "0.796",
    BC: "0.561",
    BD: "0.883",
    BE: "0.796",
    BF: "0.561",
  },
  {
    AZ: "2.900",
    BA: "0.883",
    BB: "0.795",
    BC: "0.560",
    BD: "0.883",
    BE: "0.795",
    BF: "0.560",
  },
  {
    AZ: "2.910",
    BA: "0.883",
    BB: "0.795",
    BC: "0.559",
    BD: "0.883",
    BE: "0.795",
    BF: "0.559",
  },
  {
    AZ: "2.920",
    BA: "0.882",
    BB: "0.794",
    BC: "0.558",
    BD: "0.882",
    BE: "0.794",
    BF: "0.558",
  },
  {
    AZ: "2.930",
    BA: "0.882",
    BB: "0.794",
    BC: "0.557",
    BD: "0.882",
    BE: "0.794",
    BF: "0.557",
  },
  {
    AZ: "2.940",
    BA: "0.882",
    BB: "0.793",
    BC: "0.556",
    BD: "0.882",
    BE: "0.793",
    BF: "0.556",
  },
  {
    AZ: "2.950",
    BA: "0.882",
    BB: "0.793",
    BC: "0.555",
    BD: "0.882",
    BE: "0.793",
    BF: "0.555",
  },
  {
    AZ: "2.960",
    BA: "0.881",
    BB: "0.792",
    BC: "0.554",
    BD: "0.881",
    BE: "0.792",
    BF: "0.554",
  },
  {
    AZ: "2.970",
    BA: "0.881",
    BB: "0.792",
    BC: "0.553",
    BD: "0.881",
    BE: "0.792",
    BF: "0.553",
  },
  {
    AZ: "2.980",
    BA: "0.881",
    BB: "0.791",
    BC: "0.552",
    BD: "0.881",
    BE: "0.791",
    BF: "0.552",
  },
  {
    AZ: "2.990",
    BA: "0.880",
    BB: "0.791",
    BC: "0.551",
    BD: "0.880",
    BE: "0.791",
    BF: "0.551",
  },
  {
    AZ: "3.000",
    BA: "0.880",
    BB: "0.790",
    BC: "0.550",
    BD: "0.880",
    BE: "0.790",
    BF: "0.550",
  },
  {
    AZ: "3.010",
    BA: "0.880",
    BB: "0.789",
    BC: "0.549",
    BD: "0.880",
    BE: "0.789",
    BF: "0.549",
  },
  {
    AZ: "3.020",
    BA: "0.879",
    BB: "0.789",
    BC: "0.548",
    BD: "0.879",
    BE: "0.789",
    BF: "0.548",
  },
  {
    AZ: "3.030",
    BA: "0.879",
    BB: "0.788",
    BC: "0.547",
    BD: "0.879",
    BE: "0.788",
    BF: "0.547",
  },
  {
    AZ: "3.040",
    BA: "0.878",
    BB: "0.787",
    BC: "0.546",
    BD: "0.878",
    BE: "0.787",
    BF: "0.546",
  },
  {
    AZ: "3.050",
    BA: "0.878",
    BB: "0.787",
    BC: "0.545",
    BD: "0.878",
    BE: "0.787",
    BF: "0.545",
  },
  {
    AZ: "3.060",
    BA: "0.878",
    BB: "0.786",
    BC: "0.544",
    BD: "0.878",
    BE: "0.786",
    BF: "0.544",
  },
  {
    AZ: "3.070",
    BA: "0.877",
    BB: "0.785",
    BC: "0.543",
    BD: "0.877",
    BE: "0.785",
    BF: "0.543",
  },
  {
    AZ: "3.080",
    BA: "0.877",
    BB: "0.784",
    BC: "0.542",
    BD: "0.877",
    BE: "0.784",
    BF: "0.542",
  },
  {
    AZ: "3.090",
    BA: "0.876",
    BB: "0.784",
    BC: "0.541",
    BD: "0.876",
    BE: "0.784",
    BF: "0.541",
  },
  {
    AZ: "3.100",
    BA: "0.876",
    BB: "0.783",
    BC: "0.540",
    BD: "0.876",
    BE: "0.783",
    BF: "0.540",
  },
  {
    AZ: "3.110",
    BA: "0.876",
    BB: "0.782",
    BC: "0.539",
    BD: "0.876",
    BE: "0.782",
    BF: "0.539",
  },
  {
    AZ: "3.120",
    BA: "0.875",
    BB: "0.782",
    BC: "0.538",
    BD: "0.875",
    BE: "0.782",
    BF: "0.538",
  },
  {
    AZ: "3.130",
    BA: "0.875",
    BB: "0.781",
    BC: "0.537",
    BD: "0.875",
    BE: "0.781",
    BF: "0.537",
  },
  {
    AZ: "3.140",
    BA: "0.874",
    BB: "0.780",
    BC: "0.536",
    BD: "0.874",
    BE: "0.780",
    BF: "0.536",
  },
  {
    AZ: "3.150",
    BA: "0.874",
    BB: "0.780",
    BC: "0.535",
    BD: "0.874",
    BE: "0.780",
    BF: "0.535",
  },
  {
    AZ: "3.160",
    BA: "0.874",
    BB: "0.779",
    BC: "0.534",
    BD: "0.874",
    BE: "0.779",
    BF: "0.534",
  },
  {
    AZ: "3.170",
    BA: "0.873",
    BB: "0.778",
    BC: "0.533",
    BD: "0.873",
    BE: "0.778",
    BF: "0.533",
  },
  {
    AZ: "3.180",
    BA: "0.873",
    BB: "0.777",
    BC: "0.532",
    BD: "0.873",
    BE: "0.777",
    BF: "0.532",
  },
  {
    AZ: "3.190",
    BA: "0.872",
    BB: "0.777",
    BC: "0.531",
    BD: "0.872",
    BE: "0.777",
    BF: "0.531",
  },
  {
    AZ: "3.200",
    BA: "0.872",
    BB: "0.776",
    BC: "0.530",
    BD: "0.872",
    BE: "0.776",
    BF: "0.530",
  },
  {
    AZ: "3.210",
    BA: "0.872",
    BB: "0.775",
    BC: "0.529",
    BD: "0.872",
    BE: "0.775",
    BF: "0.529",
  },
  {
    AZ: "3.220",
    BA: "0.871",
    BB: "0.775",
    BC: "0.528",
    BD: "0.871",
    BE: "0.775",
    BF: "0.528",
  },
  {
    AZ: "3.230",
    BA: "0.871",
    BB: "0.774",
    BC: "0.527",
    BD: "0.871",
    BE: "0.774",
    BF: "0.527",
  },
  {
    AZ: "3.240",
    BA: "0.870",
    BB: "0.773",
    BC: "0.526",
    BD: "0.870",
    BE: "0.773",
    BF: "0.526",
  },
  {
    AZ: "3.250",
    BA: "0.870",
    BB: "0.772",
    BC: "0.525",
    BD: "0.870",
    BE: "0.772",
    BF: "0.525",
  },
  {
    AZ: "3.260",
    BA: "0.870",
    BB: "0.772",
    BC: "0.524",
    BD: "0.870",
    BE: "0.772",
    BF: "0.524",
  },
  {
    AZ: "3.270",
    BA: "0.869",
    BB: "0.771",
    BC: "0.523",
    BD: "0.869",
    BE: "0.771",
    BF: "0.523",
  },
  {
    AZ: "3.280",
    BA: "0.869",
    BB: "0.770",
    BC: "0.522",
    BD: "0.869",
    BE: "0.770",
    BF: "0.522",
  },
  {
    AZ: "3.290",
    BA: "0.868",
    BB: "0.770",
    BC: "0.521",
    BD: "0.868",
    BE: "0.770",
    BF: "0.521",
  },
  {
    AZ: "3.300",
    BA: "0.868",
    BB: "0.769",
    BC: "0.520",
    BD: "0.868",
    BE: "0.769",
    BF: "0.520",
  },
  {
    AZ: "3.310",
    BA: "0.868",
    BB: "0.768",
    BC: "0.519",
    BD: "0.868",
    BE: "0.768",
    BF: "0.519",
  },
  {
    AZ: "3.320",
    BA: "0.867",
    BB: "0.768",
    BC: "0.518",
    BD: "0.867",
    BE: "0.768",
    BF: "0.518",
  },
  {
    AZ: "3.330",
    BA: "0.867",
    BB: "0.767",
    BC: "0.517",
    BD: "0.867",
    BE: "0.767",
    BF: "0.517",
  },
  {
    AZ: "3.340",
    BA: "0.866",
    BB: "0.766",
    BC: "0.516",
    BD: "0.866",
    BE: "0.766",
    BF: "0.516",
  },
  {
    AZ: "3.350",
    BA: "0.866",
    BB: "0.765",
    BC: "0.515",
    BD: "0.866",
    BE: "0.765",
    BF: "0.515",
  },
  {
    AZ: "3.360",
    BA: "0.866",
    BB: "0.765",
    BC: "0.514",
    BD: "0.866",
    BE: "0.765",
    BF: "0.514",
  },
  {
    AZ: "3.370",
    BA: "0.865",
    BB: "0.764",
    BC: "0.513",
    BD: "0.865",
    BE: "0.764",
    BF: "0.513",
  },
  {
    AZ: "3.380",
    BA: "0.865",
    BB: "0.763",
    BC: "0.512",
    BD: "0.865",
    BE: "0.763",
    BF: "0.512",
  },
  {
    AZ: "3.390",
    BA: "0.864",
    BB: "0.763",
    BC: "0.511",
    BD: "0.864",
    BE: "0.763",
    BF: "0.511",
  },
  {
    AZ: "3.400",
    BA: "0.864",
    BB: "0.762",
    BC: "0.510",
    BD: "0.864",
    BE: "0.762",
    BF: "0.510",
  },
  {
    AZ: "3.410",
    BA: "0.864",
    BB: "0.761",
    BC: "0.509",
    BD: "0.864",
    BE: "0.761",
    BF: "0.509",
  },
  {
    AZ: "3.420",
    BA: "0.863",
    BB: "0.761",
    BC: "0.508",
    BD: "0.863",
    BE: "0.761",
    BF: "0.508",
  },
  {
    AZ: "3.430",
    BA: "0.863",
    BB: "0.760",
    BC: "0.507",
    BD: "0.863",
    BE: "0.760",
    BF: "0.507",
  },
  {
    AZ: "3.440",
    BA: "0.862",
    BB: "0.759",
    BC: "0.506",
    BD: "0.862",
    BE: "0.759",
    BF: "0.506",
  },
  {
    AZ: "3.450",
    BA: "0.862",
    BB: "0.758",
    BC: "0.505",
    BD: "0.862",
    BE: "0.758",
    BF: "0.505",
  },
  {
    AZ: "3.460",
    BA: "0.862",
    BB: "0.758",
    BC: "0.504",
    BD: "0.862",
    BE: "0.758",
    BF: "0.504",
  },
  {
    AZ: "3.470",
    BA: "0.861",
    BB: "0.757",
    BC: "0.503",
    BD: "0.861",
    BE: "0.757",
    BF: "0.503",
  },
  {
    AZ: "3.480",
    BA: "0.861",
    BB: "0.756",
    BC: "0.502",
    BD: "0.861",
    BE: "0.756",
    BF: "0.502",
  },
  {
    AZ: "3.490",
    BA: "0.860",
    BB: "0.756",
    BC: "0.501",
    BD: "0.860",
    BE: "0.756",
    BF: "0.501",
  },
  {
    AZ: "3.500",
    BA: "0.860",
    BB: "0.755",
    BC: "0.500",
    BD: "0.860",
    BE: "0.755",
    BF: "0.500",
  },
  {
    AZ: "3.510",
    BA: "0.860",
    BB: "0.754",
    BC: "0.499",
    BD: "0.860",
    BE: "0.754",
    BF: "0.499",
  },
  {
    AZ: "3.520",
    BA: "0.859",
    BB: "0.754",
    BC: "0.498",
    BD: "0.859",
    BE: "0.754",
    BF: "0.498",
  },
  {
    AZ: "3.530",
    BA: "0.859",
    BB: "0.753",
    BC: "0.497",
    BD: "0.859",
    BE: "0.753",
    BF: "0.497",
  },
  {
    AZ: "3.540",
    BA: "0.858",
    BB: "0.752",
    BC: "0.496",
    BD: "0.858",
    BE: "0.752",
    BF: "0.496",
  },
  {
    AZ: "3.550",
    BA: "0.858",
    BB: "0.751",
    BC: "0.495",
    BD: "0.858",
    BE: "0.751",
    BF: "0.495",
  },
  {
    AZ: "3.560",
    BA: "0.858",
    BB: "0.751",
    BC: "0.494",
    BD: "0.858",
    BE: "0.751",
    BF: "0.494",
  },
  {
    AZ: "3.570",
    BA: "0.857",
    BB: "0.750",
    BC: "0.493",
    BD: "0.857",
    BE: "0.750",
    BF: "0.493",
  },
  {
    AZ: "3.580",
    BA: "0.857",
    BB: "0.749",
    BC: "0.492",
    BD: "0.857",
    BE: "0.749",
    BF: "0.492",
  },
  {
    AZ: "3.590",
    BA: "0.856",
    BB: "0.749",
    BC: "0.491",
    BD: "0.856",
    BE: "0.749",
    BF: "0.491",
  },
  {
    AZ: "3.600",
    BA: "0.856",
    BB: "0.748",
    BC: "0.490",
    BD: "0.856",
    BE: "0.748",
    BF: "0.490",
  },
  {
    AZ: "3.610",
    BA: "0.856",
    BB: "0.747",
    BC: "0.489",
    BD: "0.856",
    BE: "0.747",
    BF: "0.489",
  },
  {
    AZ: "3.620",
    BA: "0.855",
    BB: "0.747",
    BC: "0.488",
    BD: "0.855",
    BE: "0.747",
    BF: "0.488",
  },
  {
    AZ: "3.630",
    BA: "0.855",
    BB: "0.746",
    BC: "0.487",
    BD: "0.855",
    BE: "0.746",
    BF: "0.487",
  },
  {
    AZ: "3.640",
    BA: "0.854",
    BB: "0.745",
    BC: "0.486",
    BD: "0.854",
    BE: "0.745",
    BF: "0.486",
  },
  {
    AZ: "3.650",
    BA: "0.854",
    BB: "0.744",
    BC: "0.485",
    BD: "0.854",
    BE: "0.744",
    BF: "0.485",
  },
  {
    AZ: "3.660",
    BA: "0.854",
    BB: "0.744",
    BC: "0.484",
    BD: "0.854",
    BE: "0.744",
    BF: "0.484",
  },
  {
    AZ: "3.670",
    BA: "0.853",
    BB: "0.743",
    BC: "0.483",
    BD: "0.853",
    BE: "0.743",
    BF: "0.483",
  },
  {
    AZ: "3.680",
    BA: "0.853",
    BB: "0.742",
    BC: "0.482",
    BD: "0.853",
    BE: "0.742",
    BF: "0.482",
  },
  {
    AZ: "3.690",
    BA: "0.852",
    BB: "0.742",
    BC: "0.481",
    BD: "0.852",
    BE: "0.742",
    BF: "0.481",
  },
  {
    AZ: "3.700",
    BA: "0.852",
    BB: "0.741",
    BC: "0.480",
    BD: "0.852",
    BE: "0.741",
    BF: "0.480",
  },
  {
    AZ: "3.710",
    BA: "0.852",
    BB: "0.740",
    BC: "0.479",
    BD: "0.852",
    BE: "0.740",
    BF: "0.479",
  },
  {
    AZ: "3.720",
    BA: "0.851",
    BB: "0.740",
    BC: "0.478",
    BD: "0.851",
    BE: "0.740",
    BF: "0.478",
  },
  {
    AZ: "3.730",
    BA: "0.851",
    BB: "0.739",
    BC: "0.477",
    BD: "0.851",
    BE: "0.739",
    BF: "0.477",
  },
  {
    AZ: "3.740",
    BA: "0.850",
    BB: "0.738",
    BC: "0.476",
    BD: "0.850",
    BE: "0.738",
    BF: "0.476",
  },
  {
    AZ: "3.750",
    BA: "0.850",
    BB: "0.737",
    BC: "0.475",
    BD: "0.850",
    BE: "0.737",
    BF: "0.475",
  },
  {
    AZ: "3.760",
    BA: "0.850",
    BB: "0.737",
    BC: "0.474",
    BD: "0.850",
    BE: "0.737",
    BF: "0.474",
  },
  {
    AZ: "3.770",
    BA: "0.849",
    BB: "0.736",
    BC: "0.473",
    BD: "0.849",
    BE: "0.736",
    BF: "0.473",
  },
  {
    AZ: "3.780",
    BA: "0.849",
    BB: "0.735",
    BC: "0.472",
    BD: "0.849",
    BE: "0.735",
    BF: "0.472",
  },
  {
    AZ: "3.790",
    BA: "0.848",
    BB: "0.735",
    BC: "0.471",
    BD: "0.848",
    BE: "0.735",
    BF: "0.471",
  },
  {
    AZ: "3.800",
    BA: "0.848",
    BB: "0.734",
    BC: "0.470",
    BD: "0.848",
    BE: "0.734",
    BF: "0.470",
  },
  {
    AZ: "3.810",
    BA: "0.848",
    BB: "0.733",
    BC: "0.469",
    BD: "0.848",
    BE: "0.733",
    BF: "0.469",
  },
  {
    AZ: "3.820",
    BA: "0.847",
    BB: "0.733",
    BC: "0.468",
    BD: "0.847",
    BE: "0.733",
    BF: "0.468",
  },
  {
    AZ: "3.830",
    BA: "0.847",
    BB: "0.732",
    BC: "0.467",
    BD: "0.847",
    BE: "0.732",
    BF: "0.467",
  },
  {
    AZ: "3.840",
    BA: "0.846",
    BB: "0.731",
    BC: "0.466",
    BD: "0.846",
    BE: "0.731",
    BF: "0.466",
  },
  {
    AZ: "3.850",
    BA: "0.846",
    BB: "0.730",
    BC: "0.465",
    BD: "0.846",
    BE: "0.730",
    BF: "0.465",
  },
  {
    AZ: "3.860",
    BA: "0.846",
    BB: "0.730",
    BC: "0.464",
    BD: "0.846",
    BE: "0.730",
    BF: "0.464",
  },
  {
    AZ: "3.870",
    BA: "0.845",
    BB: "0.729",
    BC: "0.463",
    BD: "0.845",
    BE: "0.729",
    BF: "0.463",
  },
  {
    AZ: "3.880",
    BA: "0.845",
    BB: "0.728",
    BC: "0.462",
    BD: "0.845",
    BE: "0.728",
    BF: "0.462",
  },
  {
    AZ: "3.890",
    BA: "0.844",
    BB: "0.728",
    BC: "0.461",
    BD: "0.844",
    BE: "0.728",
    BF: "0.461",
  },
  {
    AZ: "3.900",
    BA: "0.844",
    BB: "0.727",
    BC: "0.460",
    BD: "0.844",
    BE: "0.727",
    BF: "0.460",
  },
  {
    AZ: "3.910",
    BA: "0.844",
    BB: "0.726",
    BC: "0.459",
    BD: "0.844",
    BE: "0.726",
    BF: "0.459",
  },
  {
    AZ: "3.920",
    BA: "0.843",
    BB: "0.726",
    BC: "0.458",
    BD: "0.843",
    BE: "0.726",
    BF: "0.458",
  },
  {
    AZ: "3.930",
    BA: "0.843",
    BB: "0.725",
    BC: "0.457",
    BD: "0.843",
    BE: "0.725",
    BF: "0.457",
  },
  {
    AZ: "3.940",
    BA: "0.842",
    BB: "0.724",
    BC: "0.456",
    BD: "0.842",
    BE: "0.724",
    BF: "0.456",
  },
  {
    AZ: "3.950",
    BA: "0.842",
    BB: "0.723",
    BC: "0.455",
    BD: "0.842",
    BE: "0.723",
    BF: "0.455",
  },
  {
    AZ: "3.960",
    BA: "0.842",
    BB: "0.723",
    BC: "0.454",
    BD: "0.842",
    BE: "0.723",
    BF: "0.454",
  },
  {
    AZ: "3.970",
    BA: "0.841",
    BB: "0.722",
    BC: "0.453",
    BD: "0.841",
    BE: "0.722",
    BF: "0.453",
  },
  {
    AZ: "3.980",
    BA: "0.841",
    BB: "0.721",
    BC: "0.452",
    BD: "0.841",
    BE: "0.721",
    BF: "0.452",
  },
  {
    AZ: "3.990",
    BA: "0.840",
    BB: "0.721",
    BC: "0.451",
    BD: "0.840",
    BE: "0.721",
    BF: "0.451",
  },
  {
    AZ: "4.000",
    BA: "0.840",
    BB: "0.720",
    BC: "0.450",
    BD: "0.840",
    BE: "0.720",
    BF: "0.450",
  },
  {
    AZ: "4.010",
    BA: "0.840",
    BB: "0.719",
    BC: "0.449",
    BD: "0.840",
    BE: "0.719",
    BF: "0.449",
  },
  {
    AZ: "4.020",
    BA: "0.839",
    BB: "0.718",
    BC: "0.448",
    BD: "0.839",
    BE: "0.718",
    BF: "0.448",
  },
  {
    AZ: "4.030",
    BA: "0.839",
    BB: "0.716",
    BC: "0.447",
    BD: "0.839",
    BE: "0.716",
    BF: "0.447",
  },
  {
    AZ: "4.040",
    BA: "0.838",
    BB: "0.715",
    BC: "0.446",
    BD: "0.838",
    BE: "0.715",
    BF: "0.446",
  },
  {
    AZ: "4.050",
    BA: "0.838",
    BB: "0.714",
    BC: "0.445",
    BD: "0.838",
    BE: "0.714",
    BF: "0.445",
  },
  {
    AZ: "4.060",
    BA: "0.838",
    BB: "0.713",
    BC: "0.444",
    BD: "0.838",
    BE: "0.713",
    BF: "0.444",
  },
  {
    AZ: "4.070",
    BA: "0.837",
    BB: "0.712",
    BC: "0.443",
    BD: "0.837",
    BE: "0.712",
    BF: "0.443",
  },
  {
    AZ: "4.080",
    BA: "0.837",
    BB: "0.710",
    BC: "0.442",
    BD: "0.837",
    BE: "0.710",
    BF: "0.442",
  },
  {
    AZ: "4.090",
    BA: "0.836",
    BB: "0.709",
    BC: "0.441",
    BD: "0.836",
    BE: "0.709",
    BF: "0.441",
  },
  {
    AZ: "4.100",
    BA: "0.836",
    BB: "0.708",
    BC: "0.440",
    BD: "0.836",
    BE: "0.708",
    BF: "0.440",
  },
  {
    AZ: "4.110",
    BA: "0.836",
    BB: "0.707",
    BC: "0.439",
    BD: "0.836",
    BE: "0.707",
    BF: "0.439",
  },
  {
    AZ: "4.120",
    BA: "0.835",
    BB: "0.706",
    BC: "0.438",
    BD: "0.835",
    BE: "0.706",
    BF: "0.438",
  },
  {
    AZ: "4.130",
    BA: "0.835",
    BB: "0.704",
    BC: "0.437",
    BD: "0.835",
    BE: "0.704",
    BF: "0.437",
  },
  {
    AZ: "4.140",
    BA: "0.834",
    BB: "0.703",
    BC: "0.436",
    BD: "0.834",
    BE: "0.703",
    BF: "0.436",
  },
  {
    AZ: "4.150",
    BA: "0.834",
    BB: "0.702",
    BC: "0.435",
    BD: "0.834",
    BE: "0.702",
    BF: "0.435",
  },
  {
    AZ: "4.160",
    BA: "0.834",
    BB: "0.701",
    BC: "0.434",
    BD: "0.834",
    BE: "0.701",
    BF: "0.434",
  },
  {
    AZ: "4.170",
    BA: "0.833",
    BB: "0.700",
    BC: "0.433",
    BD: "0.833",
    BE: "0.700",
    BF: "0.433",
  },
  {
    AZ: "4.180",
    BA: "0.833",
    BB: "0.698",
    BC: "0.432",
    BD: "0.833",
    BE: "0.698",
    BF: "0.432",
  },
  {
    AZ: "4.190",
    BA: "0.832",
    BB: "0.697",
    BC: "0.431",
    BD: "0.832",
    BE: "0.697",
    BF: "0.431",
  },
  {
    AZ: "4.200",
    BA: "0.832",
    BB: "0.696",
    BC: "0.430",
    BD: "0.832",
    BE: "0.696",
    BF: "0.430",
  },
  {
    AZ: "4.210",
    BA: "0.832",
    BB: "0.695",
    BC: "0.429",
    BD: "0.832",
    BE: "0.695",
    BF: "0.429",
  },
  {
    AZ: "4.220",
    BA: "0.831",
    BB: "0.694",
    BC: "0.428",
    BD: "0.831",
    BE: "0.694",
    BF: "0.428",
  },
  {
    AZ: "4.230",
    BA: "0.831",
    BB: "0.692",
    BC: "0.427",
    BD: "0.831",
    BE: "0.692",
    BF: "0.427",
  },
  {
    AZ: "4.240",
    BA: "0.830",
    BB: "0.691",
    BC: "0.426",
    BD: "0.830",
    BE: "0.691",
    BF: "0.426",
  },
  {
    AZ: "4.250",
    BA: "0.830",
    BB: "0.690",
    BC: "0.425",
    BD: "0.830",
    BE: "0.690",
    BF: "0.425",
  },
  {
    AZ: "4.260",
    BA: "0.830",
    BB: "0.689",
    BC: "0.424",
    BD: "0.830",
    BE: "0.689",
    BF: "0.424",
  },
  {
    AZ: "4.270",
    BA: "0.829",
    BB: "0.688",
    BC: "0.423",
    BD: "0.829",
    BE: "0.688",
    BF: "0.423",
  },
  {
    AZ: "4.280",
    BA: "0.829",
    BB: "0.686",
    BC: "0.422",
    BD: "0.829",
    BE: "0.686",
    BF: "0.422",
  },
  {
    AZ: "4.290",
    BA: "0.828",
    BB: "0.685",
    BC: "0.421",
    BD: "0.828",
    BE: "0.685",
    BF: "0.421",
  },
  {
    AZ: "4.300",
    BA: "0.828",
    BB: "0.684",
    BC: "0.420",
    BD: "0.828",
    BE: "0.684",
    BF: "0.420",
  },
  {
    AZ: "4.310",
    BA: "0.828",
    BB: "0.683",
    BC: "0.419",
    BD: "0.828",
    BE: "0.683",
    BF: "0.419",
  },
  {
    AZ: "4.320",
    BA: "0.827",
    BB: "0.682",
    BC: "0.418",
    BD: "0.827",
    BE: "0.682",
    BF: "0.418",
  },
  {
    AZ: "4.330",
    BA: "0.827",
    BB: "0.680",
    BC: "0.417",
    BD: "0.827",
    BE: "0.680",
    BF: "0.417",
  },
  {
    AZ: "4.340",
    BA: "0.826",
    BB: "0.679",
    BC: "0.416",
    BD: "0.826",
    BE: "0.679",
    BF: "0.416",
  },
  {
    AZ: "4.350",
    BA: "0.826",
    BB: "0.678",
    BC: "0.415",
    BD: "0.826",
    BE: "0.678",
    BF: "0.415",
  },
  {
    AZ: "4.360",
    BA: "0.826",
    BB: "0.677",
    BC: "0.414",
    BD: "0.826",
    BE: "0.677",
    BF: "0.414",
  },
  {
    AZ: "4.370",
    BA: "0.825",
    BB: "0.676",
    BC: "0.413",
    BD: "0.825",
    BE: "0.676",
    BF: "0.413",
  },
  {
    AZ: "4.380",
    BA: "0.825",
    BB: "0.674",
    BC: "0.412",
    BD: "0.825",
    BE: "0.674",
    BF: "0.412",
  },
  {
    AZ: "4.390",
    BA: "0.824",
    BB: "0.673",
    BC: "0.411",
    BD: "0.824",
    BE: "0.673",
    BF: "0.411",
  },
  {
    AZ: "4.400",
    BA: "0.824",
    BB: "0.672",
    BC: "0.410",
    BD: "0.824",
    BE: "0.672",
    BF: "0.410",
  },
  {
    AZ: "4.410",
    BA: "0.824",
    BB: "0.671",
    BC: "0.409",
    BD: "0.824",
    BE: "0.671",
    BF: "0.409",
  },
  {
    AZ: "4.420",
    BA: "0.823",
    BB: "0.670",
    BC: "0.408",
    BD: "0.823",
    BE: "0.670",
    BF: "0.408",
  },
  {
    AZ: "4.430",
    BA: "0.823",
    BB: "0.668",
    BC: "0.407",
    BD: "0.823",
    BE: "0.668",
    BF: "0.407",
  },
  {
    AZ: "4.440",
    BA: "0.822",
    BB: "0.667",
    BC: "0.406",
    BD: "0.822",
    BE: "0.667",
    BF: "0.406",
  },
  {
    AZ: "4.450",
    BA: "0.822",
    BB: "0.666",
    BC: "0.405",
    BD: "0.822",
    BE: "0.666",
    BF: "0.405",
  },
  {
    AZ: "4.460",
    BA: "0.822",
    BB: "0.665",
    BC: "0.404",
    BD: "0.822",
    BE: "0.665",
    BF: "0.404",
  },
  {
    AZ: "4.470",
    BA: "0.821",
    BB: "0.664",
    BC: "0.403",
    BD: "0.821",
    BE: "0.664",
    BF: "0.403",
  },
  {
    AZ: "4.480",
    BA: "0.821",
    BB: "0.662",
    BC: "0.402",
    BD: "0.821",
    BE: "0.662",
    BF: "0.402",
  },
  {
    AZ: "4.490",
    BA: "0.820",
    BB: "0.661",
    BC: "0.401",
    BD: "0.820",
    BE: "0.661",
    BF: "0.401",
  },
  {
    AZ: "4.500",
    BA: "0.820",
    BB: "0.660",
    BC: "0.400",
    BD: "0.820",
    BE: "0.660",
    BF: "0.400",
  },
  {
    AZ: "4.510",
    BA: "0.820",
    BB: "0.659",
    BC: "0.399",
    BD: "0.820",
    BE: "0.659",
    BF: "0.399",
  },
  {
    AZ: "4.520",
    BA: "0.819",
    BB: "0.658",
    BC: "0.398",
    BD: "0.819",
    BE: "0.658",
    BF: "0.398",
  },
  {
    AZ: "4.530",
    BA: "0.819",
    BB: "0.656",
    BC: "0.397",
    BD: "0.819",
    BE: "0.656",
    BF: "0.397",
  },
  {
    AZ: "4.540",
    BA: "0.818",
    BB: "0.655",
    BC: "0.396",
    BD: "0.818",
    BE: "0.655",
    BF: "0.396",
  },
  {
    AZ: "4.550",
    BA: "0.818",
    BB: "0.654",
    BC: "0.395",
    BD: "0.818",
    BE: "0.654",
    BF: "0.395",
  },
  {
    AZ: "4.560",
    BA: "0.818",
    BB: "0.653",
    BC: "0.394",
    BD: "0.818",
    BE: "0.653",
    BF: "0.394",
  },
  {
    AZ: "4.570",
    BA: "0.817",
    BB: "0.652",
    BC: "0.393",
    BD: "0.817",
    BE: "0.652",
    BF: "0.393",
  },
  {
    AZ: "4.580",
    BA: "0.817",
    BB: "0.650",
    BC: "0.392",
    BD: "0.817",
    BE: "0.650",
    BF: "0.392",
  },
  {
    AZ: "4.590",
    BA: "0.816",
    BB: "0.649",
    BC: "0.391",
    BD: "0.816",
    BE: "0.649",
    BF: "0.391",
  },
  {
    AZ: "4.600",
    BA: "0.816",
    BB: "0.648",
    BC: "0.390",
    BD: "0.816",
    BE: "0.648",
    BF: "0.390",
  },
  {
    AZ: "4.610",
    BA: "0.816",
    BB: "0.647",
    BC: "0.389",
    BD: "0.816",
    BE: "0.647",
    BF: "0.389",
  },
  {
    AZ: "4.620",
    BA: "0.815",
    BB: "0.646",
    BC: "0.388",
    BD: "0.815",
    BE: "0.646",
    BF: "0.388",
  },
  {
    AZ: "4.630",
    BA: "0.815",
    BB: "0.644",
    BC: "0.387",
    BD: "0.815",
    BE: "0.644",
    BF: "0.387",
  },
  {
    AZ: "4.640",
    BA: "0.814",
    BB: "0.643",
    BC: "0.386",
    BD: "0.814",
    BE: "0.643",
    BF: "0.386",
  },
  {
    AZ: "4.650",
    BA: "0.814",
    BB: "0.642",
    BC: "0.385",
    BD: "0.814",
    BE: "0.642",
    BF: "0.385",
  },
  {
    AZ: "4.660",
    BA: "0.814",
    BB: "0.641",
    BC: "0.384",
    BD: "0.814",
    BE: "0.641",
    BF: "0.384",
  },
  {
    AZ: "4.670",
    BA: "0.813",
    BB: "0.640",
    BC: "0.383",
    BD: "0.813",
    BE: "0.640",
    BF: "0.383",
  },
  {
    AZ: "4.680",
    BA: "0.813",
    BB: "0.638",
    BC: "0.382",
    BD: "0.813",
    BE: "0.638",
    BF: "0.382",
  },
  {
    AZ: "4.690",
    BA: "0.812",
    BB: "0.637",
    BC: "0.381",
    BD: "0.812",
    BE: "0.637",
    BF: "0.381",
  },
  {
    AZ: "4.700",
    BA: "0.812",
    BB: "0.636",
    BC: "0.380",
    BD: "0.812",
    BE: "0.636",
    BF: "0.380",
  },
  {
    AZ: "4.710",
    BA: "0.812",
    BB: "0.635",
    BC: "0.379",
    BD: "0.812",
    BE: "0.635",
    BF: "0.379",
  },
  {
    AZ: "4.720",
    BA: "0.811",
    BB: "0.634",
    BC: "0.378",
    BD: "0.811",
    BE: "0.634",
    BF: "0.378",
  },
  {
    AZ: "4.730",
    BA: "0.811",
    BB: "0.632",
    BC: "0.377",
    BD: "0.811",
    BE: "0.632",
    BF: "0.377",
  },
  {
    AZ: "4.740",
    BA: "0.810",
    BB: "0.631",
    BC: "0.376",
    BD: "0.810",
    BE: "0.631",
    BF: "0.376",
  },
  {
    AZ: "4.750",
    BA: "0.810",
    BB: "0.630",
    BC: "0.375",
    BD: "0.810",
    BE: "0.630",
    BF: "0.375",
  },
  {
    AZ: "4.760",
    BA: "0.810",
    BB: "0.629",
    BC: "0.374",
    BD: "0.810",
    BE: "0.629",
    BF: "0.374",
  },
  {
    AZ: "4.770",
    BA: "0.809",
    BB: "0.628",
    BC: "0.373",
    BD: "0.809",
    BE: "0.628",
    BF: "0.373",
  },
  {
    AZ: "4.780",
    BA: "0.809",
    BB: "0.626",
    BC: "0.372",
    BD: "0.809",
    BE: "0.626",
    BF: "0.372",
  },
  {
    AZ: "4.790",
    BA: "0.808",
    BB: "0.625",
    BC: "0.371",
    BD: "0.808",
    BE: "0.625",
    BF: "0.371",
  },
  {
    AZ: "4.800",
    BA: "0.808",
    BB: "0.624",
    BC: "0.370",
    BD: "0.808",
    BE: "0.624",
    BF: "0.370",
  },
  {
    AZ: "4.810",
    BA: "0.808",
    BB: "0.623",
    BC: "0.369",
    BD: "0.808",
    BE: "0.623",
    BF: "0.369",
  },
  {
    AZ: "4.820",
    BA: "0.807",
    BB: "0.622",
    BC: "0.368",
    BD: "0.807",
    BE: "0.622",
    BF: "0.368",
  },
  {
    AZ: "4.830",
    BA: "0.807",
    BB: "0.620",
    BC: "0.367",
    BD: "0.807",
    BE: "0.620",
    BF: "0.367",
  },
  {
    AZ: "4.840",
    BA: "0.806",
    BB: "0.619",
    BC: "0.366",
    BD: "0.806",
    BE: "0.619",
    BF: "0.366",
  },
  {
    AZ: "4.850",
    BA: "0.806",
    BB: "0.618",
    BC: "0.365",
    BD: "0.806",
    BE: "0.618",
    BF: "0.365",
  },
  {
    AZ: "4.860",
    BA: "0.806",
    BB: "0.617",
    BC: "0.364",
    BD: "0.806",
    BE: "0.617",
    BF: "0.364",
  },
  {
    AZ: "4.870",
    BA: "0.805",
    BB: "0.616",
    BC: "0.363",
    BD: "0.805",
    BE: "0.616",
    BF: "0.363",
  },
  {
    AZ: "4.880",
    BA: "0.805",
    BB: "0.614",
    BC: "0.362",
    BD: "0.805",
    BE: "0.614",
    BF: "0.362",
  },
  {
    AZ: "4.890",
    BA: "0.804",
    BB: "0.613",
    BC: "0.361",
    BD: "0.804",
    BE: "0.613",
    BF: "0.361",
  },
  {
    AZ: "4.900",
    BA: "0.804",
    BB: "0.612",
    BC: "0.360",
    BD: "0.804",
    BE: "0.612",
    BF: "0.360",
  },
  {
    AZ: "4.910",
    BA: "0.804",
    BB: "0.611",
    BC: "0.359",
    BD: "0.804",
    BE: "0.611",
    BF: "0.359",
  },
  {
    AZ: "4.920",
    BA: "0.803",
    BB: "0.610",
    BC: "0.358",
    BD: "0.803",
    BE: "0.610",
    BF: "0.358",
  },
  {
    AZ: "4.930",
    BA: "0.803",
    BB: "0.608",
    BC: "0.357",
    BD: "0.803",
    BE: "0.608",
    BF: "0.357",
  },
  {
    AZ: "4.940",
    BA: "0.802",
    BB: "0.607",
    BC: "0.356",
    BD: "0.802",
    BE: "0.607",
    BF: "0.356",
  },
  {
    AZ: "4.950",
    BA: "0.802",
    BB: "0.606",
    BC: "0.355",
    BD: "0.802",
    BE: "0.606",
    BF: "0.355",
  },
  {
    AZ: "4.960",
    BA: "0.802",
    BB: "0.605",
    BC: "0.354",
    BD: "0.802",
    BE: "0.605",
    BF: "0.354",
  },
  {
    AZ: "4.970",
    BA: "0.801",
    BB: "0.604",
    BC: "0.353",
    BD: "0.801",
    BE: "0.604",
    BF: "0.353",
  },
  {
    AZ: "4.980",
    BA: "0.801",
    BB: "0.602",
    BC: "0.352",
    BD: "0.801",
    BE: "0.602",
    BF: "0.352",
  },
  {
    AZ: "4.990",
    BA: "0.800",
    BB: "0.601",
    BC: "0.351",
    BD: "0.800",
    BE: "0.601",
    BF: "0.351",
  },
  {
    AZ: "5.000",
    BA: "0.800",
    BB: "0.600",
    BC: "0.350",
    BD: "0.800",
    BE: "0.600",
    BF: "0.350",
  },
  {
    AZ: "5.010",
    BA: "0.800",
    BB: "0.599",
    BC: "0.349",
    BD: "0.800",
    BE: "0.599",
    BF: "0.349",
  },
  {
    AZ: "5.020",
    BA: "0.799",
    BB: "0.598",
    BC: "0.348",
    BD: "0.799",
    BE: "0.598",
    BF: "0.348",
  },
  {
    AZ: "5.030",
    BA: "0.799",
    BB: "0.597",
    BC: "0.348",
    BD: "0.799",
    BE: "0.597",
    BF: "0.348",
  },
  {
    AZ: "5.040",
    BA: "0.798",
    BB: "0.596",
    BC: "0.347",
    BD: "0.798",
    BE: "0.596",
    BF: "0.347",
  },
  {
    AZ: "5.050",
    BA: "0.798",
    BB: "0.595",
    BC: "0.346",
    BD: "0.798",
    BE: "0.595",
    BF: "0.346",
  },
  {
    AZ: "5.060",
    BA: "0.797",
    BB: "0.594",
    BC: "0.345",
    BD: "0.797",
    BE: "0.594",
    BF: "0.345",
  },
  {
    AZ: "5.070",
    BA: "0.797",
    BB: "0.593",
    BC: "0.344",
    BD: "0.797",
    BE: "0.593",
    BF: "0.344",
  },
  {
    AZ: "5.080",
    BA: "0.796",
    BB: "0.592",
    BC: "0.344",
    BD: "0.796",
    BE: "0.592",
    BF: "0.344",
  },
  {
    AZ: "5.090",
    BA: "0.796",
    BB: "0.591",
    BC: "0.343",
    BD: "0.796",
    BE: "0.591",
    BF: "0.343",
  },
  {
    AZ: "5.100",
    BA: "0.795",
    BB: "0.590",
    BC: "0.342",
    BD: "0.795",
    BE: "0.590",
    BF: "0.342",
  },
  {
    AZ: "5.110",
    BA: "0.795",
    BB: "0.589",
    BC: "0.341",
    BD: "0.795",
    BE: "0.589",
    BF: "0.341",
  },
  {
    AZ: "5.120",
    BA: "0.794",
    BB: "0.588",
    BC: "0.340",
    BD: "0.794",
    BE: "0.588",
    BF: "0.340",
  },
  {
    AZ: "5.130",
    BA: "0.794",
    BB: "0.587",
    BC: "0.340",
    BD: "0.794",
    BE: "0.587",
    BF: "0.340",
  },
  {
    AZ: "5.140",
    BA: "0.793",
    BB: "0.586",
    BC: "0.339",
    BD: "0.793",
    BE: "0.586",
    BF: "0.339",
  },
  {
    AZ: "5.150",
    BA: "0.793",
    BB: "0.585",
    BC: "0.338",
    BD: "0.793",
    BE: "0.585",
    BF: "0.338",
  },
  {
    AZ: "5.160",
    BA: "0.792",
    BB: "0.584",
    BC: "0.337",
    BD: "0.792",
    BE: "0.584",
    BF: "0.337",
  },
  {
    AZ: "5.170",
    BA: "0.792",
    BB: "0.583",
    BC: "0.336",
    BD: "0.792",
    BE: "0.583",
    BF: "0.336",
  },
  {
    AZ: "5.180",
    BA: "0.791",
    BB: "0.582",
    BC: "0.336",
    BD: "0.791",
    BE: "0.582",
    BF: "0.336",
  },
  {
    AZ: "5.190",
    BA: "0.791",
    BB: "0.581",
    BC: "0.335",
    BD: "0.791",
    BE: "0.581",
    BF: "0.335",
  },
  {
    AZ: "5.200",
    BA: "0.790",
    BB: "0.580",
    BC: "0.334",
    BD: "0.790",
    BE: "0.580",
    BF: "0.334",
  },
  {
    AZ: "5.210",
    BA: "0.790",
    BB: "0.579",
    BC: "0.333",
    BD: "0.790",
    BE: "0.579",
    BF: "0.333",
  },
  {
    AZ: "5.220",
    BA: "0.789",
    BB: "0.578",
    BC: "0.332",
    BD: "0.789",
    BE: "0.578",
    BF: "0.332",
  },
  {
    AZ: "5.230",
    BA: "0.789",
    BB: "0.577",
    BC: "0.332",
    BD: "0.789",
    BE: "0.577",
    BF: "0.332",
  },
  {
    AZ: "5.240",
    BA: "0.788",
    BB: "0.576",
    BC: "0.331",
    BD: "0.788",
    BE: "0.576",
    BF: "0.331",
  },
  {
    AZ: "5.250",
    BA: "0.788",
    BB: "0.575",
    BC: "0.330",
    BD: "0.788",
    BE: "0.575",
    BF: "0.330",
  },
  {
    AZ: "5.260",
    BA: "0.787",
    BB: "0.574",
    BC: "0.329",
    BD: "0.787",
    BE: "0.574",
    BF: "0.329",
  },
  {
    AZ: "5.270",
    BA: "0.787",
    BB: "0.573",
    BC: "0.328",
    BD: "0.787",
    BE: "0.573",
    BF: "0.328",
  },
  {
    AZ: "5.280",
    BA: "0.786",
    BB: "0.572",
    BC: "0.328",
    BD: "0.786",
    BE: "0.572",
    BF: "0.328",
  },
  {
    AZ: "5.290",
    BA: "0.786",
    BB: "0.571",
    BC: "0.327",
    BD: "0.786",
    BE: "0.571",
    BF: "0.327",
  },
  {
    AZ: "5.300",
    BA: "0.785",
    BB: "0.570",
    BC: "0.326",
    BD: "0.785",
    BE: "0.570",
    BF: "0.326",
  },
  {
    AZ: "5.310",
    BA: "0.785",
    BB: "0.569",
    BC: "0.325",
    BD: "0.785",
    BE: "0.569",
    BF: "0.325",
  },
  {
    AZ: "5.320",
    BA: "0.784",
    BB: "0.568",
    BC: "0.324",
    BD: "0.784",
    BE: "0.568",
    BF: "0.324",
  },
  {
    AZ: "5.330",
    BA: "0.784",
    BB: "0.567",
    BC: "0.324",
    BD: "0.784",
    BE: "0.567",
    BF: "0.324",
  },
  {
    AZ: "5.340",
    BA: "0.783",
    BB: "0.566",
    BC: "0.323",
    BD: "0.783",
    BE: "0.566",
    BF: "0.323",
  },
  {
    AZ: "5.350",
    BA: "0.783",
    BB: "0.565",
    BC: "0.322",
    BD: "0.783",
    BE: "0.565",
    BF: "0.322",
  },
  {
    AZ: "5.360",
    BA: "0.782",
    BB: "0.564",
    BC: "0.321",
    BD: "0.782",
    BE: "0.564",
    BF: "0.321",
  },
  {
    AZ: "5.370",
    BA: "0.782",
    BB: "0.563",
    BC: "0.320",
    BD: "0.782",
    BE: "0.563",
    BF: "0.320",
  },
  {
    AZ: "5.380",
    BA: "0.781",
    BB: "0.562",
    BC: "0.320",
    BD: "0.781",
    BE: "0.562",
    BF: "0.320",
  },
  {
    AZ: "5.390",
    BA: "0.781",
    BB: "0.561",
    BC: "0.319",
    BD: "0.781",
    BE: "0.561",
    BF: "0.319",
  },
  {
    AZ: "5.400",
    BA: "0.780",
    BB: "0.560",
    BC: "0.318",
    BD: "0.780",
    BE: "0.560",
    BF: "0.318",
  },
  {
    AZ: "5.410",
    BA: "0.780",
    BB: "0.559",
    BC: "0.317",
    BD: "0.780",
    BE: "0.559",
    BF: "0.317",
  },
  {
    AZ: "5.420",
    BA: "0.779",
    BB: "0.558",
    BC: "0.316",
    BD: "0.779",
    BE: "0.558",
    BF: "0.316",
  },
  {
    AZ: "5.430",
    BA: "0.779",
    BB: "0.557",
    BC: "0.316",
    BD: "0.779",
    BE: "0.557",
    BF: "0.316",
  },
  {
    AZ: "5.440",
    BA: "0.778",
    BB: "0.556",
    BC: "0.315",
    BD: "0.778",
    BE: "0.556",
    BF: "0.315",
  },
  {
    AZ: "5.450",
    BA: "0.778",
    BB: "0.555",
    BC: "0.314",
    BD: "0.778",
    BE: "0.555",
    BF: "0.314",
  },
  {
    AZ: "5.460",
    BA: "0.777",
    BB: "0.554",
    BC: "0.313",
    BD: "0.777",
    BE: "0.554",
    BF: "0.313",
  },
  {
    AZ: "5.470",
    BA: "0.777",
    BB: "0.553",
    BC: "0.312",
    BD: "0.777",
    BE: "0.553",
    BF: "0.312",
  },
  {
    AZ: "5.480",
    BA: "0.776",
    BB: "0.552",
    BC: "0.312",
    BD: "0.776",
    BE: "0.552",
    BF: "0.312",
  },
  {
    AZ: "5.490",
    BA: "0.776",
    BB: "0.551",
    BC: "0.311",
    BD: "0.776",
    BE: "0.551",
    BF: "0.311",
  },
  {
    AZ: "5.500",
    BA: "0.775",
    BB: "0.550",
    BC: "0.310",
    BD: "0.775",
    BE: "0.550",
    BF: "0.310",
  },
  {
    AZ: "5.510",
    BA: "0.775",
    BB: "0.549",
    BC: "0.309",
    BD: "0.775",
    BE: "0.549",
    BF: "0.309",
  },
  {
    AZ: "5.520",
    BA: "0.774",
    BB: "0.548",
    BC: "0.308",
    BD: "0.774",
    BE: "0.548",
    BF: "0.308",
  },
  {
    AZ: "5.530",
    BA: "0.774",
    BB: "0.547",
    BC: "0.308",
    BD: "0.774",
    BE: "0.547",
    BF: "0.308",
  },
  {
    AZ: "5.540",
    BA: "0.773",
    BB: "0.546",
    BC: "0.307",
    BD: "0.773",
    BE: "0.546",
    BF: "0.307",
  },
  {
    AZ: "5.550",
    BA: "0.773",
    BB: "0.545",
    BC: "0.306",
    BD: "0.773",
    BE: "0.545",
    BF: "0.306",
  },
  {
    AZ: "5.560",
    BA: "0.772",
    BB: "0.544",
    BC: "0.305",
    BD: "0.772",
    BE: "0.544",
    BF: "0.305",
  },
  {
    AZ: "5.570",
    BA: "0.772",
    BB: "0.543",
    BC: "0.304",
    BD: "0.772",
    BE: "0.543",
    BF: "0.304",
  },
  {
    AZ: "5.580",
    BA: "0.771",
    BB: "0.542",
    BC: "0.304",
    BD: "0.771",
    BE: "0.542",
    BF: "0.304",
  },
  {
    AZ: "5.590",
    BA: "0.771",
    BB: "0.541",
    BC: "0.303",
    BD: "0.771",
    BE: "0.541",
    BF: "0.303",
  },
  {
    AZ: "5.600",
    BA: "0.770",
    BB: "0.540",
    BC: "0.302",
    BD: "0.770",
    BE: "0.540",
    BF: "0.302",
  },
  {
    AZ: "5.610",
    BA: "0.770",
    BB: "0.539",
    BC: "0.301",
    BD: "0.770",
    BE: "0.539",
    BF: "0.301",
  },
  {
    AZ: "5.620",
    BA: "0.769",
    BB: "0.538",
    BC: "0.300",
    BD: "0.769",
    BE: "0.538",
    BF: "0.300",
  },
  {
    AZ: "5.630",
    BA: "0.769",
    BB: "0.537",
    BC: "0.300",
    BD: "0.769",
    BE: "0.537",
    BF: "0.300",
  },
  {
    AZ: "5.640",
    BA: "0.768",
    BB: "0.536",
    BC: "0.299",
    BD: "0.768",
    BE: "0.536",
    BF: "0.299",
  },
  {
    AZ: "5.650",
    BA: "0.768",
    BB: "0.535",
    BC: "0.298",
    BD: "0.768",
    BE: "0.535",
    BF: "0.298",
  },
  {
    AZ: "5.660",
    BA: "0.767",
    BB: "0.534",
    BC: "0.297",
    BD: "0.767",
    BE: "0.534",
    BF: "0.297",
  },
  {
    AZ: "5.670",
    BA: "0.767",
    BB: "0.533",
    BC: "0.296",
    BD: "0.767",
    BE: "0.533",
    BF: "0.296",
  },
  {
    AZ: "5.680",
    BA: "0.766",
    BB: "0.532",
    BC: "0.296",
    BD: "0.766",
    BE: "0.532",
    BF: "0.296",
  },
  {
    AZ: "5.690",
    BA: "0.766",
    BB: "0.531",
    BC: "0.295",
    BD: "0.766",
    BE: "0.531",
    BF: "0.295",
  },
  {
    AZ: "5.700",
    BA: "0.765",
    BB: "0.530",
    BC: "0.294",
    BD: "0.765",
    BE: "0.530",
    BF: "0.294",
  },
  {
    AZ: "5.710",
    BA: "0.765",
    BB: "0.529",
    BC: "0.293",
    BD: "0.765",
    BE: "0.529",
    BF: "0.293",
  },
  {
    AZ: "5.720",
    BA: "0.764",
    BB: "0.528",
    BC: "0.292",
    BD: "0.764",
    BE: "0.528",
    BF: "0.292",
  },
  {
    AZ: "5.730",
    BA: "0.764",
    BB: "0.527",
    BC: "0.292",
    BD: "0.764",
    BE: "0.527",
    BF: "0.292",
  },
  {
    AZ: "5.740",
    BA: "0.763",
    BB: "0.526",
    BC: "0.291",
    BD: "0.763",
    BE: "0.526",
    BF: "0.291",
  },
  {
    AZ: "5.750",
    BA: "0.763",
    BB: "0.525",
    BC: "0.290",
    BD: "0.763",
    BE: "0.525",
    BF: "0.290",
  },
  {
    AZ: "5.760",
    BA: "0.762",
    BB: "0.524",
    BC: "0.289",
    BD: "0.762",
    BE: "0.524",
    BF: "0.289",
  },
  {
    AZ: "5.770",
    BA: "0.762",
    BB: "0.523",
    BC: "0.288",
    BD: "0.762",
    BE: "0.523",
    BF: "0.288",
  },
  {
    AZ: "5.780",
    BA: "0.761",
    BB: "0.522",
    BC: "0.288",
    BD: "0.761",
    BE: "0.522",
    BF: "0.288",
  },
  {
    AZ: "5.790",
    BA: "0.761",
    BB: "0.521",
    BC: "0.287",
    BD: "0.761",
    BE: "0.521",
    BF: "0.287",
  },
  {
    AZ: "5.800",
    BA: "0.760",
    BB: "0.520",
    BC: "0.286",
    BD: "0.760",
    BE: "0.520",
    BF: "0.286",
  },
  {
    AZ: "5.810",
    BA: "0.760",
    BB: "0.519",
    BC: "0.285",
    BD: "0.760",
    BE: "0.519",
    BF: "0.285",
  },
  {
    AZ: "5.820",
    BA: "0.759",
    BB: "0.518",
    BC: "0.284",
    BD: "0.759",
    BE: "0.518",
    BF: "0.284",
  },
  {
    AZ: "5.830",
    BA: "0.759",
    BB: "0.517",
    BC: "0.284",
    BD: "0.759",
    BE: "0.517",
    BF: "0.284",
  },
  {
    AZ: "5.840",
    BA: "0.758",
    BB: "0.516",
    BC: "0.283",
    BD: "0.758",
    BE: "0.516",
    BF: "0.283",
  },
  {
    AZ: "5.850",
    BA: "0.758",
    BB: "0.515",
    BC: "0.282",
    BD: "0.758",
    BE: "0.515",
    BF: "0.282",
  },
  {
    AZ: "5.860",
    BA: "0.757",
    BB: "0.514",
    BC: "0.281",
    BD: "0.757",
    BE: "0.514",
    BF: "0.281",
  },
  {
    AZ: "5.870",
    BA: "0.757",
    BB: "0.513",
    BC: "0.280",
    BD: "0.757",
    BE: "0.513",
    BF: "0.280",
  },
  {
    AZ: "5.880",
    BA: "0.756",
    BB: "0.512",
    BC: "0.280",
    BD: "0.756",
    BE: "0.512",
    BF: "0.280",
  },
  {
    AZ: "5.890",
    BA: "0.756",
    BB: "0.511",
    BC: "0.279",
    BD: "0.756",
    BE: "0.511",
    BF: "0.279",
  },
  {
    AZ: "5.900",
    BA: "0.755",
    BB: "0.510",
    BC: "0.278",
    BD: "0.755",
    BE: "0.510",
    BF: "0.278",
  },
  {
    AZ: "5.910",
    BA: "0.755",
    BB: "0.509",
    BC: "0.277",
    BD: "0.755",
    BE: "0.509",
    BF: "0.277",
  },
  {
    AZ: "5.920",
    BA: "0.754",
    BB: "0.508",
    BC: "0.276",
    BD: "0.754",
    BE: "0.508",
    BF: "0.276",
  },
  {
    AZ: "5.930",
    BA: "0.754",
    BB: "0.507",
    BC: "0.276",
    BD: "0.754",
    BE: "0.507",
    BF: "0.276",
  },
  {
    AZ: "5.940",
    BA: "0.753",
    BB: "0.506",
    BC: "0.275",
    BD: "0.753",
    BE: "0.506",
    BF: "0.275",
  },
  {
    AZ: "5.950",
    BA: "0.753",
    BB: "0.505",
    BC: "0.274",
    BD: "0.753",
    BE: "0.505",
    BF: "0.274",
  },
  {
    AZ: "5.960",
    BA: "0.752",
    BB: "0.504",
    BC: "0.273",
    BD: "0.752",
    BE: "0.504",
    BF: "0.273",
  },
  {
    AZ: "5.970",
    BA: "0.752",
    BB: "0.503",
    BC: "0.272",
    BD: "0.752",
    BE: "0.503",
    BF: "0.272",
  },
  {
    AZ: "5.980",
    BA: "0.751",
    BB: "0.502",
    BC: "0.272",
    BD: "0.751",
    BE: "0.502",
    BF: "0.272",
  },
  {
    AZ: "5.990",
    BA: "0.751",
    BB: "0.501",
    BC: "0.271",
    BD: "0.751",
    BE: "0.501",
    BF: "0.271",
  },
  {
    AZ: "6.000",
    BA: "0.750",
    BB: "0.500",
    BC: "0.270",
    BD: "0.750",
    BE: "0.500",
    BF: "0.270",
  },
  {
    AZ: "6.010",
    BA: "0.750",
    BB: "0.499",
    BC: "0.270",
    BD: "0.750",
    BE: "0.499",
    BF: "0.270",
  },
  {
    AZ: "6.020",
    BA: "0.749",
    BB: "0.498",
    BC: "0.269",
    BD: "0.749",
    BE: "0.498",
    BF: "0.269",
  },
  {
    AZ: "6.030",
    BA: "0.749",
    BB: "0.498",
    BC: "0.269",
    BD: "0.749",
    BE: "0.498",
    BF: "0.269",
  },
  {
    AZ: "6.040",
    BA: "0.748",
    BB: "0.497",
    BC: "0.268",
    BD: "0.748",
    BE: "0.497",
    BF: "0.268",
  },
  {
    AZ: "6.050",
    BA: "0.748",
    BB: "0.496",
    BC: "0.268",
    BD: "0.748",
    BE: "0.496",
    BF: "0.268",
  },
  {
    AZ: "6.060",
    BA: "0.747",
    BB: "0.495",
    BC: "0.267",
    BD: "0.747",
    BE: "0.495",
    BF: "0.267",
  },
  {
    AZ: "6.070",
    BA: "0.747",
    BB: "0.494",
    BC: "0.267",
    BD: "0.747",
    BE: "0.494",
    BF: "0.267",
  },
  {
    AZ: "6.080",
    BA: "0.746",
    BB: "0.494",
    BC: "0.266",
    BD: "0.746",
    BE: "0.494",
    BF: "0.266",
  },
  {
    AZ: "6.090",
    BA: "0.746",
    BB: "0.493",
    BC: "0.266",
    BD: "0.746",
    BE: "0.493",
    BF: "0.266",
  },
  {
    AZ: "6.100",
    BA: "0.745",
    BB: "0.492",
    BC: "0.265",
    BD: "0.745",
    BE: "0.492",
    BF: "0.265",
  },
  {
    AZ: "6.110",
    BA: "0.745",
    BB: "0.491",
    BC: "0.265",
    BD: "0.745",
    BE: "0.491",
    BF: "0.265",
  },
  {
    AZ: "6.120",
    BA: "0.744",
    BB: "0.490",
    BC: "0.264",
    BD: "0.744",
    BE: "0.490",
    BF: "0.264",
  },
  {
    AZ: "6.130",
    BA: "0.744",
    BB: "0.490",
    BC: "0.264",
    BD: "0.744",
    BE: "0.490",
    BF: "0.264",
  },
  {
    AZ: "6.140",
    BA: "0.743",
    BB: "0.489",
    BC: "0.263",
    BD: "0.743",
    BE: "0.489",
    BF: "0.263",
  },
  {
    AZ: "6.150",
    BA: "0.743",
    BB: "0.488",
    BC: "0.263",
    BD: "0.743",
    BE: "0.488",
    BF: "0.263",
  },
  {
    AZ: "6.160",
    BA: "0.742",
    BB: "0.487",
    BC: "0.262",
    BD: "0.742",
    BE: "0.487",
    BF: "0.262",
  },
  {
    AZ: "6.170",
    BA: "0.742",
    BB: "0.486",
    BC: "0.262",
    BD: "0.742",
    BE: "0.486",
    BF: "0.262",
  },
  {
    AZ: "6.180",
    BA: "0.741",
    BB: "0.486",
    BC: "0.261",
    BD: "0.741",
    BE: "0.486",
    BF: "0.261",
  },
  {
    AZ: "6.190",
    BA: "0.741",
    BB: "0.485",
    BC: "0.261",
    BD: "0.741",
    BE: "0.485",
    BF: "0.261",
  },
  {
    AZ: "6.200",
    BA: "0.740",
    BB: "0.484",
    BC: "0.260",
    BD: "0.740",
    BE: "0.484",
    BF: "0.260",
  },
  {
    AZ: "6.210",
    BA: "0.740",
    BB: "0.483",
    BC: "0.260",
    BD: "0.740",
    BE: "0.483",
    BF: "0.260",
  },
  {
    AZ: "6.220",
    BA: "0.739",
    BB: "0.482",
    BC: "0.259",
    BD: "0.739",
    BE: "0.482",
    BF: "0.259",
  },
  {
    AZ: "6.230",
    BA: "0.739",
    BB: "0.482",
    BC: "0.259",
    BD: "0.739",
    BE: "0.482",
    BF: "0.259",
  },
  {
    AZ: "6.240",
    BA: "0.738",
    BB: "0.481",
    BC: "0.258",
    BD: "0.738",
    BE: "0.481",
    BF: "0.258",
  },
  {
    AZ: "6.250",
    BA: "0.738",
    BB: "0.480",
    BC: "0.258",
    BD: "0.738",
    BE: "0.480",
    BF: "0.258",
  },
  {
    AZ: "6.260",
    BA: "0.737",
    BB: "0.479",
    BC: "0.257",
    BD: "0.737",
    BE: "0.479",
    BF: "0.257",
  },
  {
    AZ: "6.270",
    BA: "0.737",
    BB: "0.478",
    BC: "0.257",
    BD: "0.737",
    BE: "0.478",
    BF: "0.257",
  },
  {
    AZ: "6.280",
    BA: "0.736",
    BB: "0.478",
    BC: "0.256",
    BD: "0.736",
    BE: "0.478",
    BF: "0.256",
  },
  {
    AZ: "6.290",
    BA: "0.736",
    BB: "0.477",
    BC: "0.256",
    BD: "0.736",
    BE: "0.477",
    BF: "0.256",
  },
  {
    AZ: "6.300",
    BA: "0.735",
    BB: "0.476",
    BC: "0.255",
    BD: "0.735",
    BE: "0.476",
    BF: "0.255",
  },
  {
    AZ: "6.310",
    BA: "0.735",
    BB: "0.475",
    BC: "0.255",
    BD: "0.735",
    BE: "0.475",
    BF: "0.255",
  },
  {
    AZ: "6.320",
    BA: "0.734",
    BB: "0.474",
    BC: "0.254",
    BD: "0.734",
    BE: "0.474",
    BF: "0.254",
  },
  {
    AZ: "6.330",
    BA: "0.734",
    BB: "0.474",
    BC: "0.254",
    BD: "0.734",
    BE: "0.474",
    BF: "0.254",
  },
  {
    AZ: "6.340",
    BA: "0.733",
    BB: "0.473",
    BC: "0.253",
    BD: "0.733",
    BE: "0.473",
    BF: "0.253",
  },
  {
    AZ: "6.350",
    BA: "0.733",
    BB: "0.472",
    BC: "0.253",
    BD: "0.733",
    BE: "0.472",
    BF: "0.253",
  },
  {
    AZ: "6.360",
    BA: "0.732",
    BB: "0.471",
    BC: "0.252",
    BD: "0.732",
    BE: "0.471",
    BF: "0.252",
  },
  {
    AZ: "6.370",
    BA: "0.732",
    BB: "0.470",
    BC: "0.252",
    BD: "0.732",
    BE: "0.470",
    BF: "0.252",
  },
  {
    AZ: "6.380",
    BA: "0.731",
    BB: "0.470",
    BC: "0.251",
    BD: "0.731",
    BE: "0.470",
    BF: "0.251",
  },
  {
    AZ: "6.390",
    BA: "0.731",
    BB: "0.469",
    BC: "0.251",
    BD: "0.731",
    BE: "0.469",
    BF: "0.251",
  },
  {
    AZ: "6.400",
    BA: "0.730",
    BB: "0.468",
    BC: "0.250",
    BD: "0.730",
    BE: "0.468",
    BF: "0.250",
  },
  {
    AZ: "6.410",
    BA: "0.730",
    BB: "0.467",
    BC: "0.250",
    BD: "0.730",
    BE: "0.467",
    BF: "0.250",
  },
  {
    AZ: "6.420",
    BA: "0.729",
    BB: "0.466",
    BC: "0.249",
    BD: "0.729",
    BE: "0.466",
    BF: "0.249",
  },
  {
    AZ: "6.430",
    BA: "0.729",
    BB: "0.466",
    BC: "0.249",
    BD: "0.729",
    BE: "0.466",
    BF: "0.249",
  },
  {
    AZ: "6.440",
    BA: "0.728",
    BB: "0.465",
    BC: "0.248",
    BD: "0.728",
    BE: "0.465",
    BF: "0.248",
  },
  {
    AZ: "6.450",
    BA: "0.728",
    BB: "0.464",
    BC: "0.248",
    BD: "0.728",
    BE: "0.464",
    BF: "0.248",
  },
  {
    AZ: "6.460",
    BA: "0.727",
    BB: "0.463",
    BC: "0.247",
    BD: "0.727",
    BE: "0.463",
    BF: "0.247",
  },
  {
    AZ: "6.470",
    BA: "0.727",
    BB: "0.462",
    BC: "0.247",
    BD: "0.727",
    BE: "0.462",
    BF: "0.247",
  },
  {
    AZ: "6.480",
    BA: "0.726",
    BB: "0.462",
    BC: "0.246",
    BD: "0.726",
    BE: "0.462",
    BF: "0.246",
  },
  {
    AZ: "6.490",
    BA: "0.726",
    BB: "0.461",
    BC: "0.246",
    BD: "0.726",
    BE: "0.461",
    BF: "0.246",
  },
  {
    AZ: "6.500",
    BA: "0.725",
    BB: "0.460",
    BC: "0.245",
    BD: "0.725",
    BE: "0.460",
    BF: "0.245",
  },
  {
    AZ: "6.510",
    BA: "0.725",
    BB: "0.459",
    BC: "0.245",
    BD: "0.725",
    BE: "0.459",
    BF: "0.245",
  },
  {
    AZ: "6.520",
    BA: "0.724",
    BB: "0.458",
    BC: "0.244",
    BD: "0.724",
    BE: "0.458",
    BF: "0.244",
  },
  {
    AZ: "6.530",
    BA: "0.724",
    BB: "0.458",
    BC: "0.244",
    BD: "0.724",
    BE: "0.458",
    BF: "0.244",
  },
  {
    AZ: "6.540",
    BA: "0.723",
    BB: "0.457",
    BC: "0.243",
    BD: "0.723",
    BE: "0.457",
    BF: "0.243",
  },
  {
    AZ: "6.550",
    BA: "0.723",
    BB: "0.456",
    BC: "0.243",
    BD: "0.723",
    BE: "0.456",
    BF: "0.243",
  },
  {
    AZ: "6.560",
    BA: "0.722",
    BB: "0.455",
    BC: "0.242",
    BD: "0.722",
    BE: "0.455",
    BF: "0.242",
  },
  {
    AZ: "6.570",
    BA: "0.722",
    BB: "0.454",
    BC: "0.242",
    BD: "0.722",
    BE: "0.454",
    BF: "0.242",
  },
  {
    AZ: "6.580",
    BA: "0.721",
    BB: "0.454",
    BC: "0.241",
    BD: "0.721",
    BE: "0.454",
    BF: "0.241",
  },
  {
    AZ: "6.590",
    BA: "0.721",
    BB: "0.453",
    BC: "0.241",
    BD: "0.721",
    BE: "0.453",
    BF: "0.241",
  },
  {
    AZ: "6.600",
    BA: "0.720",
    BB: "0.452",
    BC: "0.240",
    BD: "0.720",
    BE: "0.452",
    BF: "0.240",
  },
  {
    AZ: "6.610",
    BA: "0.720",
    BB: "0.451",
    BC: "0.240",
    BD: "0.720",
    BE: "0.451",
    BF: "0.240",
  },
  {
    AZ: "6.620",
    BA: "0.719",
    BB: "0.450",
    BC: "0.239",
    BD: "0.719",
    BE: "0.450",
    BF: "0.239",
  },
  {
    AZ: "6.630",
    BA: "0.719",
    BB: "0.450",
    BC: "0.239",
    BD: "0.719",
    BE: "0.450",
    BF: "0.239",
  },
  {
    AZ: "6.640",
    BA: "0.718",
    BB: "0.449",
    BC: "0.238",
    BD: "0.718",
    BE: "0.449",
    BF: "0.238",
  },
  {
    AZ: "6.650",
    BA: "0.718",
    BB: "0.448",
    BC: "0.238",
    BD: "0.718",
    BE: "0.448",
    BF: "0.238",
  },
  {
    AZ: "6.660",
    BA: "0.717",
    BB: "0.447",
    BC: "0.237",
    BD: "0.717",
    BE: "0.447",
    BF: "0.237",
  },
  {
    AZ: "6.670",
    BA: "0.717",
    BB: "0.446",
    BC: "0.237",
    BD: "0.717",
    BE: "0.446",
    BF: "0.237",
  },
  {
    AZ: "6.680",
    BA: "0.716",
    BB: "0.446",
    BC: "0.236",
    BD: "0.716",
    BE: "0.446",
    BF: "0.236",
  },
  {
    AZ: "6.690",
    BA: "0.716",
    BB: "0.445",
    BC: "0.236",
    BD: "0.716",
    BE: "0.445",
    BF: "0.236",
  },
  {
    AZ: "6.700",
    BA: "0.715",
    BB: "0.444",
    BC: "0.235",
    BD: "0.715",
    BE: "0.444",
    BF: "0.235",
  },
  {
    AZ: "6.710",
    BA: "0.715",
    BB: "0.443",
    BC: "0.235",
    BD: "0.715",
    BE: "0.443",
    BF: "0.235",
  },
  {
    AZ: "6.720",
    BA: "0.714",
    BB: "0.442",
    BC: "0.234",
    BD: "0.714",
    BE: "0.442",
    BF: "0.234",
  },
  {
    AZ: "6.730",
    BA: "0.714",
    BB: "0.442",
    BC: "0.234",
    BD: "0.714",
    BE: "0.442",
    BF: "0.234",
  },
  {
    AZ: "6.740",
    BA: "0.713",
    BB: "0.441",
    BC: "0.233",
    BD: "0.713",
    BE: "0.441",
    BF: "0.233",
  },
  {
    AZ: "6.750",
    BA: "0.713",
    BB: "0.440",
    BC: "0.233",
    BD: "0.713",
    BE: "0.440",
    BF: "0.233",
  },
  {
    AZ: "6.760",
    BA: "0.712",
    BB: "0.439",
    BC: "0.232",
    BD: "0.712",
    BE: "0.439",
    BF: "0.232",
  },
  {
    AZ: "6.770",
    BA: "0.712",
    BB: "0.438",
    BC: "0.232",
    BD: "0.712",
    BE: "0.438",
    BF: "0.232",
  },
  {
    AZ: "6.780",
    BA: "0.711",
    BB: "0.438",
    BC: "0.231",
    BD: "0.711",
    BE: "0.438",
    BF: "0.231",
  },
  {
    AZ: "6.790",
    BA: "0.711",
    BB: "0.437",
    BC: "0.231",
    BD: "0.711",
    BE: "0.437",
    BF: "0.231",
  },
  {
    AZ: "6.800",
    BA: "0.710",
    BB: "0.436",
    BC: "0.230",
    BD: "0.710",
    BE: "0.436",
    BF: "0.230",
  },
  {
    AZ: "6.810",
    BA: "0.710",
    BB: "0.435",
    BC: "0.230",
    BD: "0.710",
    BE: "0.435",
    BF: "0.230",
  },
  {
    AZ: "6.820",
    BA: "0.709",
    BB: "0.434",
    BC: "0.229",
    BD: "0.709",
    BE: "0.434",
    BF: "0.229",
  },
  {
    AZ: "6.830",
    BA: "0.709",
    BB: "0.434",
    BC: "0.229",
    BD: "0.709",
    BE: "0.434",
    BF: "0.229",
  },
  {
    AZ: "6.840",
    BA: "0.708",
    BB: "0.433",
    BC: "0.228",
    BD: "0.708",
    BE: "0.433",
    BF: "0.228",
  },
  {
    AZ: "6.850",
    BA: "0.708",
    BB: "0.432",
    BC: "0.228",
    BD: "0.708",
    BE: "0.432",
    BF: "0.228",
  },
  {
    AZ: "6.860",
    BA: "0.707",
    BB: "0.431",
    BC: "0.227",
    BD: "0.707",
    BE: "0.431",
    BF: "0.227",
  },
  {
    AZ: "6.870",
    BA: "0.707",
    BB: "0.430",
    BC: "0.227",
    BD: "0.707",
    BE: "0.430",
    BF: "0.227",
  },
  {
    AZ: "6.880",
    BA: "0.706",
    BB: "0.430",
    BC: "0.226",
    BD: "0.706",
    BE: "0.430",
    BF: "0.226",
  },
  {
    AZ: "6.890",
    BA: "0.706",
    BB: "0.429",
    BC: "0.226",
    BD: "0.706",
    BE: "0.429",
    BF: "0.226",
  },
  {
    AZ: "6.900",
    BA: "0.705",
    BB: "0.428",
    BC: "0.225",
    BD: "0.705",
    BE: "0.428",
    BF: "0.225",
  },
  {
    AZ: "6.910",
    BA: "0.705",
    BB: "0.427",
    BC: "0.225",
    BD: "0.705",
    BE: "0.427",
    BF: "0.225",
  },
  {
    AZ: "6.920",
    BA: "0.704",
    BB: "0.426",
    BC: "0.224",
    BD: "0.704",
    BE: "0.426",
    BF: "0.224",
  },
  {
    AZ: "6.930",
    BA: "0.704",
    BB: "0.426",
    BC: "0.224",
    BD: "0.704",
    BE: "0.426",
    BF: "0.224",
  },
  {
    AZ: "6.940",
    BA: "0.703",
    BB: "0.425",
    BC: "0.223",
    BD: "0.703",
    BE: "0.425",
    BF: "0.223",
  },
  {
    AZ: "6.950",
    BA: "0.703",
    BB: "0.424",
    BC: "0.223",
    BD: "0.703",
    BE: "0.424",
    BF: "0.223",
  },
  {
    AZ: "6.960",
    BA: "0.702",
    BB: "0.423",
    BC: "0.222",
    BD: "0.702",
    BE: "0.423",
    BF: "0.222",
  },
  {
    AZ: "6.970",
    BA: "0.702",
    BB: "0.422",
    BC: "0.222",
    BD: "0.702",
    BE: "0.422",
    BF: "0.222",
  },
  {
    AZ: "6.980",
    BA: "0.701",
    BB: "0.422",
    BC: "0.221",
    BD: "0.701",
    BE: "0.422",
    BF: "0.221",
  },
  {
    AZ: "6.990",
    BA: "0.701",
    BB: "0.421",
    BC: "0.221",
    BD: "0.701",
    BE: "0.421",
    BF: "0.221",
  },
  {
    AZ: "7.000",
    BA: "0.700",
    BB: "0.420",
    BC: "0.220",
    BD: "0.700",
    BE: "0.420",
    BF: "0.220",
  },
  {
    AZ: "7.010",
    BA: "0.699",
    BB: "0.419",
    BC: "0.220",
    BD: "0.699",
    BE: "0.419",
    BF: "0.220",
  },
  {
    AZ: "7.020",
    BA: "0.698",
    BB: "0.419",
    BC: "0.219",
    BD: "0.698",
    BE: "0.419",
    BF: "0.219",
  },
  {
    AZ: "7.030",
    BA: "0.697",
    BB: "0.418",
    BC: "0.219",
    BD: "0.697",
    BE: "0.418",
    BF: "0.219",
  },
  {
    AZ: "7.040",
    BA: "0.696",
    BB: "0.417",
    BC: "0.218",
    BD: "0.696",
    BE: "0.417",
    BF: "0.218",
  },
  {
    AZ: "7.050",
    BA: "0.695",
    BB: "0.417",
    BC: "0.218",
    BD: "0.695",
    BE: "0.417",
    BF: "0.218",
  },
  {
    AZ: "7.060",
    BA: "0.694",
    BB: "0.416",
    BC: "0.218",
    BD: "0.694",
    BE: "0.416",
    BF: "0.218",
  },
  {
    AZ: "7.070",
    BA: "0.693",
    BB: "0.415",
    BC: "0.217",
    BD: "0.693",
    BE: "0.415",
    BF: "0.217",
  },
  {
    AZ: "7.080",
    BA: "0.692",
    BB: "0.414",
    BC: "0.217",
    BD: "0.692",
    BE: "0.414",
    BF: "0.217",
  },
  {
    AZ: "7.090",
    BA: "0.691",
    BB: "0.414",
    BC: "0.216",
    BD: "0.691",
    BE: "0.414",
    BF: "0.216",
  },
  {
    AZ: "7.100",
    BA: "0.690",
    BB: "0.413",
    BC: "0.216",
    BD: "0.690",
    BE: "0.413",
    BF: "0.216",
  },
  {
    AZ: "7.110",
    BA: "0.689",
    BB: "0.412",
    BC: "0.216",
    BD: "0.689",
    BE: "0.412",
    BF: "0.216",
  },
  {
    AZ: "7.120",
    BA: "0.688",
    BB: "0.412",
    BC: "0.215",
    BD: "0.688",
    BE: "0.412",
    BF: "0.215",
  },
  {
    AZ: "7.130",
    BA: "0.687",
    BB: "0.411",
    BC: "0.215",
    BD: "0.687",
    BE: "0.411",
    BF: "0.215",
  },
  {
    AZ: "7.140",
    BA: "0.686",
    BB: "0.410",
    BC: "0.214",
    BD: "0.686",
    BE: "0.410",
    BF: "0.214",
  },
  {
    AZ: "7.150",
    BA: "0.685",
    BB: "0.410",
    BC: "0.214",
    BD: "0.685",
    BE: "0.410",
    BF: "0.214",
  },
  {
    AZ: "7.160",
    BA: "0.684",
    BB: "0.409",
    BC: "0.214",
    BD: "0.684",
    BE: "0.409",
    BF: "0.214",
  },
  {
    AZ: "7.170",
    BA: "0.683",
    BB: "0.408",
    BC: "0.213",
    BD: "0.683",
    BE: "0.408",
    BF: "0.213",
  },
  {
    AZ: "7.180",
    BA: "0.682",
    BB: "0.407",
    BC: "0.213",
    BD: "0.682",
    BE: "0.407",
    BF: "0.213",
  },
  {
    AZ: "7.190",
    BA: "0.681",
    BB: "0.407",
    BC: "0.212",
    BD: "0.681",
    BE: "0.407",
    BF: "0.212",
  },
  {
    AZ: "7.200",
    BA: "0.680",
    BB: "0.406",
    BC: "0.212",
    BD: "0.680",
    BE: "0.406",
    BF: "0.212",
  },
  {
    AZ: "7.210",
    BA: "0.679",
    BB: "0.405",
    BC: "0.212",
    BD: "0.679",
    BE: "0.405",
    BF: "0.212",
  },
  {
    AZ: "7.220",
    BA: "0.678",
    BB: "0.405",
    BC: "0.211",
    BD: "0.678",
    BE: "0.405",
    BF: "0.211",
  },
  {
    AZ: "7.230",
    BA: "0.677",
    BB: "0.404",
    BC: "0.211",
    BD: "0.677",
    BE: "0.404",
    BF: "0.211",
  },
  {
    AZ: "7.240",
    BA: "0.676",
    BB: "0.403",
    BC: "0.210",
    BD: "0.676",
    BE: "0.403",
    BF: "0.210",
  },
  {
    AZ: "7.250",
    BA: "0.675",
    BB: "0.403",
    BC: "0.210",
    BD: "0.675",
    BE: "0.403",
    BF: "0.210",
  },
  {
    AZ: "7.260",
    BA: "0.674",
    BB: "0.402",
    BC: "0.210",
    BD: "0.674",
    BE: "0.402",
    BF: "0.210",
  },
  {
    AZ: "7.270",
    BA: "0.673",
    BB: "0.401",
    BC: "0.209",
    BD: "0.673",
    BE: "0.401",
    BF: "0.209",
  },
  {
    AZ: "7.280",
    BA: "0.672",
    BB: "0.400",
    BC: "0.209",
    BD: "0.672",
    BE: "0.400",
    BF: "0.209",
  },
  {
    AZ: "7.290",
    BA: "0.671",
    BB: "0.400",
    BC: "0.208",
    BD: "0.671",
    BE: "0.400",
    BF: "0.208",
  },
  {
    AZ: "7.300",
    BA: "0.670",
    BB: "0.399",
    BC: "0.208",
    BD: "0.670",
    BE: "0.399",
    BF: "0.208",
  },
  {
    AZ: "7.310",
    BA: "0.669",
    BB: "0.398",
    BC: "0.208",
    BD: "0.669",
    BE: "0.398",
    BF: "0.208",
  },
  {
    AZ: "7.320",
    BA: "0.668",
    BB: "0.398",
    BC: "0.207",
    BD: "0.668",
    BE: "0.398",
    BF: "0.207",
  },
  {
    AZ: "7.330",
    BA: "0.667",
    BB: "0.397",
    BC: "0.207",
    BD: "0.667",
    BE: "0.397",
    BF: "0.207",
  },
  {
    AZ: "7.340",
    BA: "0.666",
    BB: "0.396",
    BC: "0.206",
    BD: "0.666",
    BE: "0.396",
    BF: "0.206",
  },
  {
    AZ: "7.350",
    BA: "0.665",
    BB: "0.396",
    BC: "0.206",
    BD: "0.665",
    BE: "0.396",
    BF: "0.206",
  },
  {
    AZ: "7.360",
    BA: "0.664",
    BB: "0.395",
    BC: "0.206",
    BD: "0.664",
    BE: "0.395",
    BF: "0.206",
  },
  {
    AZ: "7.370",
    BA: "0.663",
    BB: "0.394",
    BC: "0.205",
    BD: "0.663",
    BE: "0.394",
    BF: "0.205",
  },
  {
    AZ: "7.380",
    BA: "0.662",
    BB: "0.393",
    BC: "0.205",
    BD: "0.662",
    BE: "0.393",
    BF: "0.205",
  },
  {
    AZ: "7.390",
    BA: "0.661",
    BB: "0.393",
    BC: "0.204",
    BD: "0.661",
    BE: "0.393",
    BF: "0.204",
  },
  {
    AZ: "7.400",
    BA: "0.660",
    BB: "0.392",
    BC: "0.204",
    BD: "0.660",
    BE: "0.392",
    BF: "0.204",
  },
  {
    AZ: "7.410",
    BA: "0.659",
    BB: "0.391",
    BC: "0.204",
    BD: "0.659",
    BE: "0.391",
    BF: "0.204",
  },
  {
    AZ: "7.420",
    BA: "0.658",
    BB: "0.391",
    BC: "0.203",
    BD: "0.658",
    BE: "0.391",
    BF: "0.203",
  },
  {
    AZ: "7.430",
    BA: "0.657",
    BB: "0.390",
    BC: "0.203",
    BD: "0.657",
    BE: "0.390",
    BF: "0.203",
  },
  {
    AZ: "7.440",
    BA: "0.656",
    BB: "0.389",
    BC: "0.202",
    BD: "0.656",
    BE: "0.389",
    BF: "0.202",
  },
  {
    AZ: "7.450",
    BA: "0.655",
    BB: "0.389",
    BC: "0.202",
    BD: "0.655",
    BE: "0.389",
    BF: "0.202",
  },
  {
    AZ: "7.460",
    BA: "0.654",
    BB: "0.388",
    BC: "0.202",
    BD: "0.654",
    BE: "0.388",
    BF: "0.202",
  },
  {
    AZ: "7.470",
    BA: "0.653",
    BB: "0.387",
    BC: "0.201",
    BD: "0.653",
    BE: "0.387",
    BF: "0.201",
  },
  {
    AZ: "7.480",
    BA: "0.652",
    BB: "0.386",
    BC: "0.201",
    BD: "0.652",
    BE: "0.386",
    BF: "0.201",
  },
  {
    AZ: "7.490",
    BA: "0.651",
    BB: "0.386",
    BC: "0.200",
    BD: "0.651",
    BE: "0.386",
    BF: "0.200",
  },
  {
    AZ: "7.500",
    BA: "0.650",
    BB: "0.385",
    BC: "0.200",
    BD: "0.650",
    BE: "0.385",
    BF: "0.200",
  },
  {
    AZ: "7.510",
    BA: "0.649",
    BB: "0.384",
    BC: "0.200",
    BD: "0.649",
    BE: "0.384",
    BF: "0.200",
  },
  {
    AZ: "7.520",
    BA: "0.648",
    BB: "0.384",
    BC: "0.199",
    BD: "0.648",
    BE: "0.384",
    BF: "0.199",
  },
  {
    AZ: "7.530",
    BA: "0.647",
    BB: "0.383",
    BC: "0.199",
    BD: "0.647",
    BE: "0.383",
    BF: "0.199",
  },
  {
    AZ: "7.540",
    BA: "0.646",
    BB: "0.382",
    BC: "0.198",
    BD: "0.646",
    BE: "0.382",
    BF: "0.198",
  },
  {
    AZ: "7.550",
    BA: "0.645",
    BB: "0.382",
    BC: "0.198",
    BD: "0.645",
    BE: "0.382",
    BF: "0.198",
  },
  {
    AZ: "7.560",
    BA: "0.644",
    BB: "0.381",
    BC: "0.198",
    BD: "0.644",
    BE: "0.381",
    BF: "0.198",
  },
  {
    AZ: "7.570",
    BA: "0.643",
    BB: "0.380",
    BC: "0.197",
    BD: "0.643",
    BE: "0.380",
    BF: "0.197",
  },
  {
    AZ: "7.580",
    BA: "0.642",
    BB: "0.379",
    BC: "0.197",
    BD: "0.642",
    BE: "0.379",
    BF: "0.197",
  },
  {
    AZ: "7.590",
    BA: "0.641",
    BB: "0.379",
    BC: "0.196",
    BD: "0.641",
    BE: "0.379",
    BF: "0.196",
  },
  {
    AZ: "7.600",
    BA: "0.640",
    BB: "0.378",
    BC: "0.196",
    BD: "0.640",
    BE: "0.378",
    BF: "0.196",
  },
  {
    AZ: "7.610",
    BA: "0.639",
    BB: "0.377",
    BC: "0.196",
    BD: "0.639",
    BE: "0.377",
    BF: "0.196",
  },
  {
    AZ: "7.620",
    BA: "0.638",
    BB: "0.377",
    BC: "0.195",
    BD: "0.638",
    BE: "0.377",
    BF: "0.195",
  },
  {
    AZ: "7.630",
    BA: "0.637",
    BB: "0.376",
    BC: "0.195",
    BD: "0.637",
    BE: "0.376",
    BF: "0.195",
  },
  {
    AZ: "7.640",
    BA: "0.636",
    BB: "0.375",
    BC: "0.194",
    BD: "0.636",
    BE: "0.375",
    BF: "0.194",
  },
  {
    AZ: "7.650",
    BA: "0.635",
    BB: "0.375",
    BC: "0.194",
    BD: "0.635",
    BE: "0.375",
    BF: "0.194",
  },
  {
    AZ: "7.660",
    BA: "0.634",
    BB: "0.374",
    BC: "0.194",
    BD: "0.634",
    BE: "0.374",
    BF: "0.194",
  },
  {
    AZ: "7.670",
    BA: "0.633",
    BB: "0.373",
    BC: "0.193",
    BD: "0.633",
    BE: "0.373",
    BF: "0.193",
  },
  {
    AZ: "7.680",
    BA: "0.632",
    BB: "0.372",
    BC: "0.193",
    BD: "0.632",
    BE: "0.372",
    BF: "0.193",
  },
  {
    AZ: "7.690",
    BA: "0.631",
    BB: "0.372",
    BC: "0.192",
    BD: "0.631",
    BE: "0.372",
    BF: "0.192",
  },
  {
    AZ: "7.700",
    BA: "0.630",
    BB: "0.371",
    BC: "0.192",
    BD: "0.630",
    BE: "0.371",
    BF: "0.192",
  },
  {
    AZ: "7.710",
    BA: "0.629",
    BB: "0.370",
    BC: "0.192",
    BD: "0.629",
    BE: "0.370",
    BF: "0.192",
  },
  {
    AZ: "7.720",
    BA: "0.628",
    BB: "0.370",
    BC: "0.191",
    BD: "0.628",
    BE: "0.370",
    BF: "0.191",
  },
  {
    AZ: "7.730",
    BA: "0.627",
    BB: "0.369",
    BC: "0.191",
    BD: "0.627",
    BE: "0.369",
    BF: "0.191",
  },
  {
    AZ: "7.740",
    BA: "0.626",
    BB: "0.368",
    BC: "0.190",
    BD: "0.626",
    BE: "0.368",
    BF: "0.190",
  },
  {
    AZ: "7.750",
    BA: "0.625",
    BB: "0.368",
    BC: "0.190",
    BD: "0.625",
    BE: "0.368",
    BF: "0.190",
  },
  {
    AZ: "7.760",
    BA: "0.624",
    BB: "0.367",
    BC: "0.190",
    BD: "0.624",
    BE: "0.367",
    BF: "0.190",
  },
  {
    AZ: "7.770",
    BA: "0.623",
    BB: "0.366",
    BC: "0.189",
    BD: "0.623",
    BE: "0.366",
    BF: "0.189",
  },
  {
    AZ: "7.780",
    BA: "0.622",
    BB: "0.365",
    BC: "0.189",
    BD: "0.622",
    BE: "0.365",
    BF: "0.189",
  },
  {
    AZ: "7.790",
    BA: "0.621",
    BB: "0.365",
    BC: "0.188",
    BD: "0.621",
    BE: "0.365",
    BF: "0.188",
  },
  {
    AZ: "7.800",
    BA: "0.620",
    BB: "0.364",
    BC: "0.188",
    BD: "0.620",
    BE: "0.364",
    BF: "0.188",
  },
  {
    AZ: "7.810",
    BA: "0.619",
    BB: "0.363",
    BC: "0.188",
    BD: "0.619",
    BE: "0.363",
    BF: "0.188",
  },
  {
    AZ: "7.820",
    BA: "0.618",
    BB: "0.363",
    BC: "0.187",
    BD: "0.618",
    BE: "0.363",
    BF: "0.187",
  },
  {
    AZ: "7.830",
    BA: "0.617",
    BB: "0.362",
    BC: "0.187",
    BD: "0.617",
    BE: "0.362",
    BF: "0.187",
  },
  {
    AZ: "7.840",
    BA: "0.616",
    BB: "0.361",
    BC: "0.186",
    BD: "0.616",
    BE: "0.361",
    BF: "0.186",
  },
  {
    AZ: "7.850",
    BA: "0.615",
    BB: "0.361",
    BC: "0.186",
    BD: "0.615",
    BE: "0.361",
    BF: "0.186",
  },
  {
    AZ: "7.860",
    BA: "0.614",
    BB: "0.360",
    BC: "0.186",
    BD: "0.614",
    BE: "0.360",
    BF: "0.186",
  },
  {
    AZ: "7.870",
    BA: "0.613",
    BB: "0.359",
    BC: "0.185",
    BD: "0.613",
    BE: "0.359",
    BF: "0.185",
  },
  {
    AZ: "7.880",
    BA: "0.612",
    BB: "0.358",
    BC: "0.185",
    BD: "0.612",
    BE: "0.358",
    BF: "0.185",
  },
  {
    AZ: "7.890",
    BA: "0.611",
    BB: "0.358",
    BC: "0.184",
    BD: "0.611",
    BE: "0.358",
    BF: "0.184",
  },
  {
    AZ: "7.900",
    BA: "0.610",
    BB: "0.357",
    BC: "0.184",
    BD: "0.610",
    BE: "0.357",
    BF: "0.184",
  },
  {
    AZ: "7.910",
    BA: "0.609",
    BB: "0.356",
    BC: "0.184",
    BD: "0.609",
    BE: "0.356",
    BF: "0.184",
  },
  {
    AZ: "7.920",
    BA: "0.608",
    BB: "0.356",
    BC: "0.183",
    BD: "0.608",
    BE: "0.356",
    BF: "0.183",
  },
  {
    AZ: "7.930",
    BA: "0.607",
    BB: "0.355",
    BC: "0.183",
    BD: "0.607",
    BE: "0.355",
    BF: "0.183",
  },
  {
    AZ: "7.940",
    BA: "0.606",
    BB: "0.354",
    BC: "0.182",
    BD: "0.606",
    BE: "0.354",
    BF: "0.182",
  },
  {
    AZ: "7.950",
    BA: "0.605",
    BB: "0.354",
    BC: "0.182",
    BD: "0.605",
    BE: "0.354",
    BF: "0.182",
  },
  {
    AZ: "7.960",
    BA: "0.604",
    BB: "0.353",
    BC: "0.182",
    BD: "0.604",
    BE: "0.353",
    BF: "0.182",
  },
  {
    AZ: "7.970",
    BA: "0.603",
    BB: "0.352",
    BC: "0.181",
    BD: "0.603",
    BE: "0.352",
    BF: "0.181",
  },
  {
    AZ: "7.980",
    BA: "0.602",
    BB: "0.351",
    BC: "0.181",
    BD: "0.602",
    BE: "0.351",
    BF: "0.181",
  },
  {
    AZ: "7.990",
    BA: "0.601",
    BB: "0.351",
    BC: "0.180",
    BD: "0.601",
    BE: "0.351",
    BF: "0.180",
  },
  {
    AZ: "8.000",
    BA: "0.600",
    BB: "0.350",
    BC: "0.180",
    BD: "0.600",
    BE: "0.350",
    BF: "0.180",
  },
  {
    AZ: "8.010",
    BA: "0.599",
    BB: "0.350",
    BC: "0.180",
    BD: "0.599",
    BE: "0.350",
    BF: "0.178",
  },
  {
    AZ: "8.020",
    BA: "0.598",
    BB: "0.349",
    BC: "0.179",
    BD: "0.598",
    BE: "0.349",
    BF: "0.176",
  },
  {
    AZ: "8.030",
    BA: "0.598",
    BB: "0.349",
    BC: "0.179",
    BD: "0.598",
    BE: "0.349",
    BF: "0.175",
  },
  {
    AZ: "8.040",
    BA: "0.597",
    BB: "0.348",
    BC: "0.179",
    BD: "0.597",
    BE: "0.348",
    BF: "0.173",
  },
  {
    AZ: "8.050",
    BA: "0.596",
    BB: "0.348",
    BC: "0.179",
    BD: "0.596",
    BE: "0.348",
    BF: "0.171",
  },
  {
    AZ: "8.060",
    BA: "0.595",
    BB: "0.347",
    BC: "0.178",
    BD: "0.595",
    BE: "0.347",
    BF: "0.169",
  },
  {
    AZ: "8.070",
    BA: "0.594",
    BB: "0.347",
    BC: "0.178",
    BD: "0.594",
    BE: "0.347",
    BF: "0.167",
  },
  {
    AZ: "8.080",
    BA: "0.594",
    BB: "0.346",
    BC: "0.178",
    BD: "0.594",
    BE: "0.346",
    BF: "0.166",
  },
  {
    AZ: "8.090",
    BA: "0.593",
    BB: "0.346",
    BC: "0.177",
    BD: "0.593",
    BE: "0.346",
    BF: "0.164",
  },
  {
    AZ: "8.100",
    BA: "0.592",
    BB: "0.345",
    BC: "0.177",
    BD: "0.592",
    BE: "0.345",
    BF: "0.162",
  },
  {
    AZ: "8.110",
    BA: "0.591",
    BB: "0.345",
    BC: "0.177",
    BD: "0.591",
    BE: "0.345",
    BF: "0.160",
  },
  {
    AZ: "8.120",
    BA: "0.590",
    BB: "0.344",
    BC: "0.176",
    BD: "0.590",
    BE: "0.344",
    BF: "0.158",
  },
  {
    AZ: "8.130",
    BA: "0.590",
    BB: "0.344",
    BC: "0.176",
    BD: "0.590",
    BE: "0.344",
    BF: "0.157",
  },
  {
    AZ: "8.140",
    BA: "0.589",
    BB: "0.343",
    BC: "0.176",
    BD: "0.589",
    BE: "0.343",
    BF: "0.155",
  },
  {
    AZ: "8.150",
    BA: "0.588",
    BB: "0.343",
    BC: "0.176",
    BD: "0.588",
    BE: "0.343",
    BF: "0.153",
  },
  {
    AZ: "8.160",
    BA: "0.587",
    BB: "0.342",
    BC: "0.175",
    BD: "0.587",
    BE: "0.342",
    BF: "0.151",
  },
  {
    AZ: "8.170",
    BA: "0.586",
    BB: "0.342",
    BC: "0.175",
    BD: "0.586",
    BE: "0.342",
    BF: "0.149",
  },
  {
    AZ: "8.180",
    BA: "0.586",
    BB: "0.341",
    BC: "0.175",
    BD: "0.586",
    BE: "0.341",
    BF: "0.148",
  },
  {
    AZ: "8.190",
    BA: "0.585",
    BB: "0.341",
    BC: "0.174",
    BD: "0.585",
    BE: "0.341",
    BF: "0.146",
  },
  {
    AZ: "8.200",
    BA: "0.584",
    BB: "0.340",
    BC: "0.174",
    BD: "0.584",
    BE: "0.340",
    BF: "0.144",
  },
  {
    AZ: "8.210",
    BA: "0.583",
    BB: "0.340",
    BC: "0.174",
    BD: "0.583",
    BE: "0.340",
    BF: "0.142",
  },
  {
    AZ: "8.220",
    BA: "0.582",
    BB: "0.339",
    BC: "0.173",
    BD: "0.582",
    BE: "0.339",
    BF: "0.140",
  },
  {
    AZ: "8.230",
    BA: "0.582",
    BB: "0.339",
    BC: "0.173",
    BD: "0.582",
    BE: "0.339",
    BF: "0.139",
  },
  {
    AZ: "8.240",
    BA: "0.581",
    BB: "0.338",
    BC: "0.173",
    BD: "0.581",
    BE: "0.338",
    BF: "0.137",
  },
  {
    AZ: "8.250",
    BA: "0.580",
    BB: "0.338",
    BC: "0.173",
    BD: "0.580",
    BE: "0.338",
    BF: "0.135",
  },
  {
    AZ: "8.260",
    BA: "0.579",
    BB: "0.337",
    BC: "0.172",
    BD: "0.579",
    BE: "0.337",
    BF: "0.133",
  },
  {
    AZ: "8.270",
    BA: "0.578",
    BB: "0.337",
    BC: "0.172",
    BD: "0.578",
    BE: "0.337",
    BF: "0.131",
  },
  {
    AZ: "8.280",
    BA: "0.578",
    BB: "0.336",
    BC: "0.172",
    BD: "0.578",
    BE: "0.336",
    BF: "0.130",
  },
  {
    AZ: "8.290",
    BA: "0.577",
    BB: "0.336",
    BC: "0.171",
    BD: "0.577",
    BE: "0.336",
    BF: "0.128",
  },
  {
    AZ: "8.300",
    BA: "0.576",
    BB: "0.335",
    BC: "0.171",
    BD: "0.576",
    BE: "0.335",
    BF: "0.126",
  },
  {
    AZ: "8.310",
    BA: "0.575",
    BB: "0.335",
    BC: "0.171",
    BD: "0.575",
    BE: "0.335",
    BF: "0.124",
  },
  {
    AZ: "8.320",
    BA: "0.574",
    BB: "0.334",
    BC: "0.170",
    BD: "0.574",
    BE: "0.334",
    BF: "0.122",
  },
  {
    AZ: "8.330",
    BA: "0.574",
    BB: "0.334",
    BC: "0.170",
    BD: "0.574",
    BE: "0.334",
    BF: "0.121",
  },
  {
    AZ: "8.340",
    BA: "0.573",
    BB: "0.333",
    BC: "0.170",
    BD: "0.573",
    BE: "0.333",
    BF: "0.119",
  },
  {
    AZ: "8.350",
    BA: "0.572",
    BB: "0.333",
    BC: "0.170",
    BD: "0.572",
    BE: "0.333",
    BF: "0.117",
  },
  {
    AZ: "8.360",
    BA: "0.571",
    BB: "0.332",
    BC: "0.169",
    BD: "0.571",
    BE: "0.332",
    BF: "0.115",
  },
  {
    AZ: "8.370",
    BA: "0.570",
    BB: "0.332",
    BC: "0.169",
    BD: "0.570",
    BE: "0.332",
    BF: "0.113",
  },
  {
    AZ: "8.380",
    BA: "0.570",
    BB: "0.331",
    BC: "0.169",
    BD: "0.570",
    BE: "0.331",
    BF: "0.112",
  },
  {
    AZ: "8.390",
    BA: "0.569",
    BB: "0.331",
    BC: "0.168",
    BD: "0.569",
    BE: "0.331",
    BF: "0.110",
  },
  {
    AZ: "8.400",
    BA: "0.568",
    BB: "0.330",
    BC: "0.168",
    BD: "0.568",
    BE: "0.330",
    BF: "0.108",
  },
  {
    AZ: "8.410",
    BA: "0.567",
    BB: "0.330",
    BC: "0.168",
    BD: "0.567",
    BE: "0.330",
    BF: "0.106",
  },
  {
    AZ: "8.420",
    BA: "0.566",
    BB: "0.329",
    BC: "0.167",
    BD: "0.566",
    BE: "0.329",
    BF: "0.104",
  },
  {
    AZ: "8.430",
    BA: "0.566",
    BB: "0.329",
    BC: "0.167",
    BD: "0.566",
    BE: "0.329",
    BF: "0.103",
  },
  {
    AZ: "8.440",
    BA: "0.565",
    BB: "0.328",
    BC: "0.167",
    BD: "0.565",
    BE: "0.328",
    BF: "0.101",
  },
  {
    AZ: "8.450",
    BA: "0.564",
    BB: "0.328",
    BC: "0.167",
    BD: "0.564",
    BE: "0.328",
    BF: "0.099",
  },
  {
    AZ: "8.460",
    BA: "0.563",
    BB: "0.327",
    BC: "0.166",
    BD: "0.563",
    BE: "0.327",
    BF: "0.097",
  },
  {
    AZ: "8.470",
    BA: "0.562",
    BB: "0.327",
    BC: "0.166",
    BD: "0.562",
    BE: "0.327",
    BF: "0.095",
  },
  {
    AZ: "8.480",
    BA: "0.562",
    BB: "0.326",
    BC: "0.166",
    BD: "0.562",
    BE: "0.326",
    BF: "0.094",
  },
  {
    AZ: "8.490",
    BA: "0.561",
    BB: "0.326",
    BC: "0.165",
    BD: "0.561",
    BE: "0.326",
    BF: "0.092",
  },
  {
    AZ: "8.500",
    BA: "0.560",
    BB: "0.325",
    BC: "0.165",
    BD: "0.560",
    BE: "0.325",
    BF: "0.090",
  },
  {
    AZ: "8.510",
    BA: "0.559",
    BB: "0.325",
    BC: "0.165",
    BD: "0.559",
    BE: "0.325",
    BF: "0.088",
  },
  {
    AZ: "8.520",
    BA: "0.558",
    BB: "0.324",
    BC: "0.164",
    BD: "0.558",
    BE: "0.324",
    BF: "0.086",
  },
  {
    AZ: "8.530",
    BA: "0.558",
    BB: "0.324",
    BC: "0.164",
    BD: "0.558",
    BE: "0.324",
    BF: "0.085",
  },
  {
    AZ: "8.540",
    BA: "0.557",
    BB: "0.323",
    BC: "0.164",
    BD: "0.557",
    BE: "0.323",
    BF: "0.083",
  },
  {
    AZ: "8.550",
    BA: "0.556",
    BB: "0.323",
    BC: "0.164",
    BD: "0.556",
    BE: "0.323",
    BF: "0.081",
  },
  {
    AZ: "8.560",
    BA: "0.555",
    BB: "0.322",
    BC: "0.163",
    BD: "0.555",
    BE: "0.322",
    BF: "0.079",
  },
  {
    AZ: "8.570",
    BA: "0.554",
    BB: "0.322",
    BC: "0.163",
    BD: "0.554",
    BE: "0.322",
    BF: "0.077",
  },
  {
    AZ: "8.580",
    BA: "0.554",
    BB: "0.321",
    BC: "0.163",
    BD: "0.554",
    BE: "0.321",
    BF: "0.076",
  },
  {
    AZ: "8.590",
    BA: "0.553",
    BB: "0.321",
    BC: "0.162",
    BD: "0.553",
    BE: "0.321",
    BF: "0.074",
  },
  {
    AZ: "8.600",
    BA: "0.552",
    BB: "0.320",
    BC: "0.162",
    BD: "0.552",
    BE: "0.320",
    BF: "0.072",
  },
  {
    AZ: "8.610",
    BA: "0.551",
    BB: "0.320",
    BC: "0.162",
    BD: "0.551",
    BE: "0.320",
    BF: "0.070",
  },
  {
    AZ: "8.620",
    BA: "0.550",
    BB: "0.319",
    BC: "0.161",
    BD: "0.550",
    BE: "0.319",
    BF: "0.068",
  },
  {
    AZ: "8.630",
    BA: "0.550",
    BB: "0.319",
    BC: "0.161",
    BD: "0.550",
    BE: "0.319",
    BF: "0.067",
  },
  {
    AZ: "8.640",
    BA: "0.549",
    BB: "0.318",
    BC: "0.161",
    BD: "0.549",
    BE: "0.318",
    BF: "0.065",
  },
  {
    AZ: "8.650",
    BA: "0.548",
    BB: "0.318",
    BC: "0.161",
    BD: "0.548",
    BE: "0.318",
    BF: "0.063",
  },
  {
    AZ: "8.660",
    BA: "0.547",
    BB: "0.317",
    BC: "0.160",
    BD: "0.547",
    BE: "0.317",
    BF: "0.061",
  },
  {
    AZ: "8.670",
    BA: "0.546",
    BB: "0.317",
    BC: "0.160",
    BD: "0.546",
    BE: "0.317",
    BF: "0.059",
  },
  {
    AZ: "8.680",
    BA: "0.546",
    BB: "0.316",
    BC: "0.160",
    BD: "0.546",
    BE: "0.316",
    BF: "0.058",
  },
  {
    AZ: "8.690",
    BA: "0.545",
    BB: "0.316",
    BC: "0.159",
    BD: "0.545",
    BE: "0.316",
    BF: "0.056",
  },
  {
    AZ: "8.700",
    BA: "0.544",
    BB: "0.315",
    BC: "0.159",
    BD: "0.544",
    BE: "0.315",
    BF: "0.054",
  },
  {
    AZ: "8.710",
    BA: "0.543",
    BB: "0.315",
    BC: "0.159",
    BD: "0.543",
    BE: "0.315",
    BF: "0.052",
  },
  {
    AZ: "8.720",
    BA: "0.542",
    BB: "0.314",
    BC: "0.158",
    BD: "0.542",
    BE: "0.314",
    BF: "0.050",
  },
  {
    AZ: "8.730",
    BA: "0.542",
    BB: "0.314",
    BC: "0.158",
    BD: "0.542",
    BE: "0.314",
    BF: "0.049",
  },
  {
    AZ: "8.740",
    BA: "0.541",
    BB: "0.313",
    BC: "0.158",
    BD: "0.541",
    BE: "0.313",
    BF: "0.047",
  },
  {
    AZ: "8.750",
    BA: "0.540",
    BB: "0.313",
    BC: "0.158",
    BD: "0.540",
    BE: "0.313",
    BF: "0.045",
  },
  {
    AZ: "8.760",
    BA: "0.539",
    BB: "0.312",
    BC: "0.157",
    BD: "0.539",
    BE: "0.312",
    BF: "0.043",
  },
  {
    AZ: "8.770",
    BA: "0.538",
    BB: "0.312",
    BC: "0.157",
    BD: "0.538",
    BE: "0.312",
    BF: "0.041",
  },
  {
    AZ: "8.780",
    BA: "0.538",
    BB: "0.311",
    BC: "0.157",
    BD: "0.538",
    BE: "0.311",
    BF: "0.040",
  },
  {
    AZ: "8.790",
    BA: "0.537",
    BB: "0.311",
    BC: "0.156",
    BD: "0.537",
    BE: "0.311",
    BF: "0.038",
  },
  {
    AZ: "8.800",
    BA: "0.536",
    BB: "0.310",
    BC: "0.156",
    BD: "0.536",
    BE: "0.310",
    BF: "0.036",
  },
  {
    AZ: "8.810",
    BA: "0.535",
    BB: "0.310",
    BC: "0.156",
    BD: "0.535",
    BE: "0.310",
    BF: "0.034",
  },
  {
    AZ: "8.820",
    BA: "0.534",
    BB: "0.309",
    BC: "0.155",
    BD: "0.534",
    BE: "0.309",
    BF: "0.032",
  },
  {
    AZ: "8.830",
    BA: "0.534",
    BB: "0.309",
    BC: "0.155",
    BD: "0.534",
    BE: "0.309",
    BF: "0.031",
  },
  {
    AZ: "8.840",
    BA: "0.533",
    BB: "0.308",
    BC: "0.155",
    BD: "0.533",
    BE: "0.308",
    BF: "0.029",
  },
  {
    AZ: "8.850",
    BA: "0.532",
    BB: "0.308",
    BC: "0.155",
    BD: "0.532",
    BE: "0.308",
    BF: "0.027",
  },
  {
    AZ: "8.860",
    BA: "0.531",
    BB: "0.307",
    BC: "0.154",
    BD: "0.531",
    BE: "0.307",
    BF: "0.025",
  },
  {
    AZ: "8.870",
    BA: "0.530",
    BB: "0.307",
    BC: "0.154",
    BD: "0.530",
    BE: "0.307",
    BF: "0.023",
  },
  {
    AZ: "8.880",
    BA: "0.530",
    BB: "0.306",
    BC: "0.154",
    BD: "0.530",
    BE: "0.306",
    BF: "0.022",
  },
  {
    AZ: "8.890",
    BA: "0.529",
    BB: "0.306",
    BC: "0.153",
    BD: "0.529",
    BE: "0.306",
    BF: "0.020",
  },
  {
    AZ: "8.900",
    BA: "0.528",
    BB: "0.305",
    BC: "0.153",
    BD: "0.528",
    BE: "0.305",
    BF: "0.018",
  },
  {
    AZ: "8.910",
    BA: "0.527",
    BB: "0.305",
    BC: "0.153",
    BD: "0.527",
    BE: "0.305",
    BF: "0.016",
  },
  {
    AZ: "8.920",
    BA: "0.526",
    BB: "0.304",
    BC: "0.152",
    BD: "0.526",
    BE: "0.304",
    BF: "0.014",
  },
  {
    AZ: "8.930",
    BA: "0.526",
    BB: "0.304",
    BC: "0.152",
    BD: "0.526",
    BE: "0.304",
    BF: "0.013",
  },
  {
    AZ: "8.940",
    BA: "0.525",
    BB: "0.303",
    BC: "0.152",
    BD: "0.525",
    BE: "0.303",
    BF: "0.011",
  },
  {
    AZ: "8.950",
    BA: "0.524",
    BB: "0.303",
    BC: "0.152",
    BD: "0.524",
    BE: "0.303",
    BF: "0.009",
  },
  {
    AZ: "8.960",
    BA: "0.523",
    BB: "0.302",
    BC: "0.151",
    BD: "0.523",
    BE: "0.302",
    BF: "0.007",
  },
  {
    AZ: "8.970",
    BA: "0.522",
    BB: "0.302",
    BC: "0.151",
    BD: "0.522",
    BE: "0.302",
    BF: "0.005",
  },
  {
    AZ: "8.980",
    BA: "0.522",
    BB: "0.301",
    BC: "0.151",
    BD: "0.522",
    BE: "0.301",
    BF: "0.004",
  },
  {
    AZ: "8.990",
    BA: "0.521",
    BB: "0.301",
    BC: "0.150",
    BD: "0.521",
    BE: "0.301",
    BF: "0.002",
  },
  {
    AZ: "9.000",
    BA: "0.520",
    BB: "0.300",
    BC: "0.150",
    BD: "0.520",
    BE: "0.300",
    BF: "0.000",
  },
  {
    AZ: "9.010",
    BA: "0.519",
    BB: "0.300",
    BC: "0.150",
    BD: "0.519",
    BE: "0.300",
    BF: "0.000",
  },
  {
    AZ: "9.020",
    BA: "0.519",
    BB: "0.299",
    BC: "0.150",
    BD: "0.519",
    BE: "0.299",
    BF: "0.000",
  },
  {
    AZ: "9.030",
    BA: "0.518",
    BB: "0.299",
    BC: "0.149",
    BD: "0.518",
    BE: "0.299",
    BF: "0.000",
  },
  {
    AZ: "9.040",
    BA: "0.517",
    BB: "0.298",
    BC: "0.149",
    BD: "0.517",
    BE: "0.298",
    BF: "0.000",
  },
  {
    AZ: "9.050",
    BA: "0.517",
    BB: "0.298",
    BC: "0.149",
    BD: "0.517",
    BE: "0.298",
    BF: "0.000",
  },
  {
    AZ: "9.060",
    BA: "0.516",
    BB: "0.298",
    BC: "0.149",
    BD: "0.516",
    BE: "0.298",
    BF: "0.000",
  },
  {
    AZ: "9.070",
    BA: "0.515",
    BB: "0.297",
    BC: "0.149",
    BD: "0.515",
    BE: "0.297",
    BF: "0.000",
  },
  {
    AZ: "9.080",
    BA: "0.514",
    BB: "0.297",
    BC: "0.148",
    BD: "0.514",
    BE: "0.297",
    BF: "0.000",
  },
  {
    AZ: "9.090",
    BA: "0.514",
    BB: "0.296",
    BC: "0.148",
    BD: "0.514",
    BE: "0.296",
    BF: "0.000",
  },
  {
    AZ: "9.100",
    BA: "0.513",
    BB: "0.296",
    BC: "0.148",
    BD: "0.513",
    BE: "0.296",
    BF: "0.000",
  },
  {
    AZ: "9.110",
    BA: "0.512",
    BB: "0.296",
    BC: "0.148",
    BD: "0.512",
    BE: "0.296",
    BF: "0.000",
  },
  {
    AZ: "9.120",
    BA: "0.512",
    BB: "0.295",
    BC: "0.148",
    BD: "0.512",
    BE: "0.295",
    BF: "0.000",
  },
  {
    AZ: "9.130",
    BA: "0.511",
    BB: "0.295",
    BC: "0.147",
    BD: "0.511",
    BE: "0.295",
    BF: "0.000",
  },
  {
    AZ: "9.140",
    BA: "0.510",
    BB: "0.294",
    BC: "0.147",
    BD: "0.510",
    BE: "0.294",
    BF: "0.000",
  },
  {
    AZ: "9.150",
    BA: "0.510",
    BB: "0.294",
    BC: "0.147",
    BD: "0.510",
    BE: "0.294",
    BF: "0.000",
  },
  {
    AZ: "9.160",
    BA: "0.509",
    BB: "0.294",
    BC: "0.147",
    BD: "0.509",
    BE: "0.294",
    BF: "0.000",
  },
  {
    AZ: "9.170",
    BA: "0.508",
    BB: "0.293",
    BC: "0.147",
    BD: "0.508",
    BE: "0.293",
    BF: "0.000",
  },
  {
    AZ: "9.180",
    BA: "0.507",
    BB: "0.293",
    BC: "0.146",
    BD: "0.507",
    BE: "0.293",
    BF: "0.000",
  },
  {
    AZ: "9.190",
    BA: "0.507",
    BB: "0.292",
    BC: "0.146",
    BD: "0.507",
    BE: "0.292",
    BF: "0.000",
  },
  {
    AZ: "9.200",
    BA: "0.506",
    BB: "0.292",
    BC: "0.146",
    BD: "0.506",
    BE: "0.292",
    BF: "0.000",
  },
  {
    AZ: "9.210",
    BA: "0.505",
    BB: "0.292",
    BC: "0.146",
    BD: "0.505",
    BE: "0.292",
    BF: "0.000",
  },
  {
    AZ: "9.220",
    BA: "0.505",
    BB: "0.291",
    BC: "0.146",
    BD: "0.505",
    BE: "0.291",
    BF: "0.000",
  },
  {
    AZ: "9.230",
    BA: "0.504",
    BB: "0.291",
    BC: "0.145",
    BD: "0.504",
    BE: "0.291",
    BF: "0.000",
  },
  {
    AZ: "9.240",
    BA: "0.503",
    BB: "0.290",
    BC: "0.145",
    BD: "0.503",
    BE: "0.290",
    BF: "0.000",
  },
  {
    AZ: "9.250",
    BA: "0.502",
    BB: "0.290",
    BC: "0.145",
    BD: "0.502",
    BE: "0.290",
    BF: "0.000",
  },
  {
    AZ: "9.260",
    BA: "0.502",
    BB: "0.290",
    BC: "0.145",
    BD: "0.502",
    BE: "0.290",
    BF: "0.000",
  },
  {
    AZ: "9.270",
    BA: "0.501",
    BB: "0.289",
    BC: "0.145",
    BD: "0.501",
    BE: "0.289",
    BF: "0.000",
  },
  {
    AZ: "9.280",
    BA: "0.500",
    BB: "0.289",
    BC: "0.144",
    BD: "0.500",
    BE: "0.289",
    BF: "0.000",
  },
  {
    AZ: "9.290",
    BA: "0.500",
    BB: "0.288",
    BC: "0.144",
    BD: "0.500",
    BE: "0.288",
    BF: "0.000",
  },
  {
    AZ: "9.300",
    BA: "0.499",
    BB: "0.288",
    BC: "0.144",
    BD: "0.499",
    BE: "0.288",
    BF: "0.000",
  },
  {
    AZ: "9.310",
    BA: "0.498",
    BB: "0.288",
    BC: "0.144",
    BD: "0.498",
    BE: "0.288",
    BF: "0.000",
  },
  {
    AZ: "9.320",
    BA: "0.498",
    BB: "0.287",
    BC: "0.144",
    BD: "0.498",
    BE: "0.287",
    BF: "0.000",
  },
  {
    AZ: "9.330",
    BA: "0.497",
    BB: "0.287",
    BC: "0.143",
    BD: "0.497",
    BE: "0.287",
    BF: "0.000",
  },
  {
    AZ: "9.340",
    BA: "0.496",
    BB: "0.286",
    BC: "0.143",
    BD: "0.496",
    BE: "0.286",
    BF: "0.000",
  },
  {
    AZ: "9.350",
    BA: "0.495",
    BB: "0.286",
    BC: "0.143",
    BD: "0.495",
    BE: "0.286",
    BF: "0.000",
  },
  {
    AZ: "9.360",
    BA: "0.495",
    BB: "0.286",
    BC: "0.143",
    BD: "0.495",
    BE: "0.286",
    BF: "0.000",
  },
  {
    AZ: "9.370",
    BA: "0.494",
    BB: "0.285",
    BC: "0.143",
    BD: "0.494",
    BE: "0.285",
    BF: "0.000",
  },
  {
    AZ: "9.380",
    BA: "0.493",
    BB: "0.285",
    BC: "0.142",
    BD: "0.493",
    BE: "0.285",
    BF: "0.000",
  },
  {
    AZ: "9.390",
    BA: "0.493",
    BB: "0.284",
    BC: "0.142",
    BD: "0.493",
    BE: "0.284",
    BF: "0.000",
  },
  {
    AZ: "9.400",
    BA: "0.492",
    BB: "0.284",
    BC: "0.142",
    BD: "0.492",
    BE: "0.284",
    BF: "0.000",
  },
  {
    AZ: "9.410",
    BA: "0.491",
    BB: "0.284",
    BC: "0.142",
    BD: "0.491",
    BE: "0.284",
    BF: "0.000",
  },
  {
    AZ: "9.420",
    BA: "0.491",
    BB: "0.283",
    BC: "0.142",
    BD: "0.491",
    BE: "0.283",
    BF: "0.000",
  },
  {
    AZ: "9.430",
    BA: "0.490",
    BB: "0.283",
    BC: "0.141",
    BD: "0.490",
    BE: "0.283",
    BF: "0.000",
  },
  {
    AZ: "9.440",
    BA: "0.489",
    BB: "0.282",
    BC: "0.141",
    BD: "0.489",
    BE: "0.282",
    BF: "0.000",
  },
  {
    AZ: "9.450",
    BA: "0.488",
    BB: "0.282",
    BC: "0.141",
    BD: "0.488",
    BE: "0.282",
    BF: "0.000",
  },
  {
    AZ: "9.460",
    BA: "0.488",
    BB: "0.282",
    BC: "0.141",
    BD: "0.488",
    BE: "0.282",
    BF: "0.000",
  },
  {
    AZ: "9.470",
    BA: "0.487",
    BB: "0.281",
    BC: "0.141",
    BD: "0.487",
    BE: "0.281",
    BF: "0.000",
  },
  {
    AZ: "9.480",
    BA: "0.486",
    BB: "0.281",
    BC: "0.140",
    BD: "0.486",
    BE: "0.281",
    BF: "0.000",
  },
  {
    AZ: "9.490",
    BA: "0.486",
    BB: "0.280",
    BC: "0.140",
    BD: "0.486",
    BE: "0.280",
    BF: "0.000",
  },
  {
    AZ: "9.500",
    BA: "0.485",
    BB: "0.280",
    BC: "0.140",
    BD: "0.485",
    BE: "0.280",
    BF: "0.000",
  },
  {
    AZ: "9.510",
    BA: "0.484",
    BB: "0.280",
    BC: "0.140",
    BD: "0.484",
    BE: "0.280",
    BF: "0.000",
  },
  {
    AZ: "9.520",
    BA: "0.484",
    BB: "0.279",
    BC: "0.140",
    BD: "0.484",
    BE: "0.279",
    BF: "0.000",
  },
  {
    AZ: "9.530",
    BA: "0.483",
    BB: "0.279",
    BC: "0.139",
    BD: "0.483",
    BE: "0.279",
    BF: "0.000",
  },
  {
    AZ: "9.540",
    BA: "0.482",
    BB: "0.278",
    BC: "0.139",
    BD: "0.482",
    BE: "0.278",
    BF: "0.000",
  },
  {
    AZ: "9.550",
    BA: "0.482",
    BB: "0.278",
    BC: "0.139",
    BD: "0.482",
    BE: "0.278",
    BF: "0.000",
  },
  {
    AZ: "9.560",
    BA: "0.481",
    BB: "0.278",
    BC: "0.139",
    BD: "0.481",
    BE: "0.278",
    BF: "0.000",
  },
  {
    AZ: "9.570",
    BA: "0.480",
    BB: "0.277",
    BC: "0.139",
    BD: "0.480",
    BE: "0.277",
    BF: "0.000",
  },
  {
    AZ: "9.580",
    BA: "0.479",
    BB: "0.277",
    BC: "0.138",
    BD: "0.479",
    BE: "0.277",
    BF: "0.000",
  },
  {
    AZ: "9.590",
    BA: "0.479",
    BB: "0.276",
    BC: "0.138",
    BD: "0.479",
    BE: "0.276",
    BF: "0.000",
  },
  {
    AZ: "9.600",
    BA: "0.478",
    BB: "0.276",
    BC: "0.138",
    BD: "0.478",
    BE: "0.276",
    BF: "0.000",
  },
  {
    AZ: "9.610",
    BA: "0.477",
    BB: "0.276",
    BC: "0.138",
    BD: "0.477",
    BE: "0.276",
    BF: "0.000",
  },
  {
    AZ: "9.620",
    BA: "0.477",
    BB: "0.275",
    BC: "0.138",
    BD: "0.477",
    BE: "0.275",
    BF: "0.000",
  },
  {
    AZ: "9.630",
    BA: "0.476",
    BB: "0.275",
    BC: "0.137",
    BD: "0.476",
    BE: "0.275",
    BF: "0.000",
  },
  {
    AZ: "9.640",
    BA: "0.475",
    BB: "0.274",
    BC: "0.137",
    BD: "0.475",
    BE: "0.274",
    BF: "0.000",
  },
  {
    AZ: "9.650",
    BA: "0.475",
    BB: "0.274",
    BC: "0.137",
    BD: "0.475",
    BE: "0.274",
    BF: "0.000",
  },
  {
    AZ: "9.660",
    BA: "0.474",
    BB: "0.274",
    BC: "0.137",
    BD: "0.474",
    BE: "0.274",
    BF: "0.000",
  },
  {
    AZ: "9.670",
    BA: "0.473",
    BB: "0.273",
    BC: "0.137",
    BD: "0.473",
    BE: "0.273",
    BF: "0.000",
  },
  {
    AZ: "9.680",
    BA: "0.472",
    BB: "0.273",
    BC: "0.136",
    BD: "0.472",
    BE: "0.273",
    BF: "0.000",
  },
  {
    AZ: "9.690",
    BA: "0.472",
    BB: "0.272",
    BC: "0.136",
    BD: "0.472",
    BE: "0.272",
    BF: "0.000",
  },
  {
    AZ: "9.700",
    BA: "0.471",
    BB: "0.272",
    BC: "0.136",
    BD: "0.471",
    BE: "0.272",
    BF: "0.000",
  },
  {
    AZ: "9.710",
    BA: "0.470",
    BB: "0.272",
    BC: "0.136",
    BD: "0.470",
    BE: "0.272",
    BF: "0.000",
  },
  {
    AZ: "9.720",
    BA: "0.470",
    BB: "0.271",
    BC: "0.136",
    BD: "0.470",
    BE: "0.271",
    BF: "0.000",
  },
  {
    AZ: "9.730",
    BA: "0.469",
    BB: "0.271",
    BC: "0.135",
    BD: "0.469",
    BE: "0.271",
    BF: "0.000",
  },
  {
    AZ: "9.740",
    BA: "0.468",
    BB: "0.270",
    BC: "0.135",
    BD: "0.468",
    BE: "0.270",
    BF: "0.000",
  },
  {
    AZ: "9.750",
    BA: "0.468",
    BB: "0.270",
    BC: "0.135",
    BD: "0.468",
    BE: "0.270",
    BF: "0.000",
  },
  {
    AZ: "9.760",
    BA: "0.467",
    BB: "0.270",
    BC: "0.135",
    BD: "0.467",
    BE: "0.270",
    BF: "0.000",
  },
  {
    AZ: "9.770",
    BA: "0.466",
    BB: "0.269",
    BC: "0.135",
    BD: "0.466",
    BE: "0.269",
    BF: "0.000",
  },
  {
    AZ: "9.780",
    BA: "0.465",
    BB: "0.269",
    BC: "0.134",
    BD: "0.465",
    BE: "0.269",
    BF: "0.000",
  },
  {
    AZ: "9.790",
    BA: "0.465",
    BB: "0.268",
    BC: "0.134",
    BD: "0.465",
    BE: "0.268",
    BF: "0.000",
  },
  {
    AZ: "9.800",
    BA: "0.464",
    BB: "0.268",
    BC: "0.134",
    BD: "0.464",
    BE: "0.268",
    BF: "0.000",
  },
  {
    AZ: "9.810",
    BA: "0.463",
    BB: "0.268",
    BC: "0.134",
    BD: "0.463",
    BE: "0.268",
    BF: "0.000",
  },
  {
    AZ: "9.820",
    BA: "0.463",
    BB: "0.267",
    BC: "0.134",
    BD: "0.463",
    BE: "0.267",
    BF: "0.000",
  },
  {
    AZ: "9.830",
    BA: "0.462",
    BB: "0.267",
    BC: "0.133",
    BD: "0.462",
    BE: "0.267",
    BF: "0.000",
  },
  {
    AZ: "9.840",
    BA: "0.461",
    BB: "0.266",
    BC: "0.133",
    BD: "0.461",
    BE: "0.266",
    BF: "0.000",
  },
  {
    AZ: "9.850",
    BA: "0.461",
    BB: "0.266",
    BC: "0.133",
    BD: "0.461",
    BE: "0.266",
    BF: "0.000",
  },
  {
    AZ: "9.860",
    BA: "0.460",
    BB: "0.266",
    BC: "0.133",
    BD: "0.460",
    BE: "0.266",
    BF: "0.000",
  },
  {
    AZ: "9.870",
    BA: "0.459",
    BB: "0.265",
    BC: "0.133",
    BD: "0.459",
    BE: "0.265",
    BF: "0.000",
  },
  {
    AZ: "9.880",
    BA: "0.458",
    BB: "0.265",
    BC: "0.132",
    BD: "0.458",
    BE: "0.265",
    BF: "0.000",
  },
  {
    AZ: "9.890",
    BA: "0.458",
    BB: "0.264",
    BC: "0.132",
    BD: "0.458",
    BE: "0.264",
    BF: "0.000",
  },
  {
    AZ: "9.900",
    BA: "0.457",
    BB: "0.264",
    BC: "0.132",
    BD: "0.457",
    BE: "0.264",
    BF: "0.000",
  },
  {
    AZ: "9.910",
    BA: "0.456",
    BB: "0.264",
    BC: "0.132",
    BD: "0.456",
    BE: "0.264",
    BF: "0.000",
  },
  {
    AZ: "9.920",
    BA: "0.456",
    BB: "0.263",
    BC: "0.132",
    BD: "0.456",
    BE: "0.263",
    BF: "0.000",
  },
  {
    AZ: "9.930",
    BA: "0.455",
    BB: "0.263",
    BC: "0.131",
    BD: "0.455",
    BE: "0.263",
    BF: "0.000",
  },
  {
    AZ: "9.940",
    BA: "0.454",
    BB: "0.262",
    BC: "0.131",
    BD: "0.454",
    BE: "0.262",
    BF: "0.000",
  },
  {
    AZ: "9.950",
    BA: "0.454",
    BB: "0.262",
    BC: "0.131",
    BD: "0.454",
    BE: "0.262",
    BF: "0.000",
  },
  {
    AZ: "9.960",
    BA: "0.453",
    BB: "0.262",
    BC: "0.131",
    BD: "0.453",
    BE: "0.262",
    BF: "0.000",
  },
  {
    AZ: "9.970",
    BA: "0.452",
    BB: "0.261",
    BC: "0.131",
    BD: "0.452",
    BE: "0.261",
    BF: "0.000",
  },
  {
    AZ: "9.980",
    BA: "0.451",
    BB: "0.261",
    BC: "0.130",
    BD: "0.451",
    BE: "0.261",
    BF: "0.000",
  },
  {
    AZ: "9.990",
    BA: "0.451",
    BB: "0.260",
    BC: "0.130",
    BD: "0.451",
    BE: "0.260",
    BF: "0.000",
  },
  {
    AZ: "10.000",
    BA: "0.450",
    BB: "0.260",
    BC: "0.130",
    BD: "0.450",
    BE: "0.260",
    BF: "0.000",
  },
  {
    AZ: "10.010",
    BA: "0.450",
    BB: "0.260",
    BC: "0.129",
    BD: "0.450",
    BE: "0.257",
    BF: "0.000",
  },
  {
    AZ: "10.020",
    BA: "0.449",
    BB: "0.259",
    BC: "0.127",
    BD: "0.449",
    BE: "0.255",
    BF: "0.000",
  },
  {
    AZ: "10.030",
    BA: "0.449",
    BB: "0.259",
    BC: "0.126",
    BD: "0.449",
    BE: "0.252",
    BF: "0.000",
  },
  {
    AZ: "10.040",
    BA: "0.448",
    BB: "0.259",
    BC: "0.125",
    BD: "0.448",
    BE: "0.250",
    BF: "0.000",
  },
  {
    AZ: "10.050",
    BA: "0.448",
    BB: "0.259",
    BC: "0.124",
    BD: "0.448",
    BE: "0.247",
    BF: "0.000",
  },
  {
    AZ: "10.060",
    BA: "0.448",
    BB: "0.258",
    BC: "0.122",
    BD: "0.448",
    BE: "0.244",
    BF: "0.000",
  },
  {
    AZ: "10.070",
    BA: "0.447",
    BB: "0.258",
    BC: "0.121",
    BD: "0.447",
    BE: "0.242",
    BF: "0.000",
  },
  {
    AZ: "10.080",
    BA: "0.447",
    BB: "0.258",
    BC: "0.120",
    BD: "0.447",
    BE: "0.239",
    BF: "0.000",
  },
  {
    AZ: "10.090",
    BA: "0.446",
    BB: "0.257",
    BC: "0.118",
    BD: "0.446",
    BE: "0.237",
    BF: "0.000",
  },
  {
    AZ: "10.100",
    BA: "0.446",
    BB: "0.257",
    BC: "0.117",
    BD: "0.446",
    BE: "0.234",
    BF: "0.000",
  },
  {
    AZ: "10.110",
    BA: "0.446",
    BB: "0.257",
    BC: "0.116",
    BD: "0.446",
    BE: "0.231",
    BF: "0.000",
  },
  {
    AZ: "10.120",
    BA: "0.445",
    BB: "0.256",
    BC: "0.114",
    BD: "0.445",
    BE: "0.229",
    BF: "0.000",
  },
  {
    AZ: "10.130",
    BA: "0.445",
    BB: "0.256",
    BC: "0.113",
    BD: "0.445",
    BE: "0.226",
    BF: "0.000",
  },
  {
    AZ: "10.140",
    BA: "0.444",
    BB: "0.256",
    BC: "0.112",
    BD: "0.444",
    BE: "0.224",
    BF: "0.000",
  },
  {
    AZ: "10.150",
    BA: "0.444",
    BB: "0.256",
    BC: "0.111",
    BD: "0.444",
    BE: "0.221",
    BF: "0.000",
  },
  {
    AZ: "10.160",
    BA: "0.444",
    BB: "0.255",
    BC: "0.109",
    BD: "0.444",
    BE: "0.218",
    BF: "0.000",
  },
  {
    AZ: "10.170",
    BA: "0.443",
    BB: "0.255",
    BC: "0.108",
    BD: "0.443",
    BE: "0.216",
    BF: "0.000",
  },
  {
    AZ: "10.180",
    BA: "0.443",
    BB: "0.255",
    BC: "0.107",
    BD: "0.443",
    BE: "0.213",
    BF: "0.000",
  },
  {
    AZ: "10.190",
    BA: "0.442",
    BB: "0.254",
    BC: "0.105",
    BD: "0.442",
    BE: "0.211",
    BF: "0.000",
  },
  {
    AZ: "10.200",
    BA: "0.442",
    BB: "0.254",
    BC: "0.104",
    BD: "0.442",
    BE: "0.208",
    BF: "0.000",
  },
  {
    AZ: "10.210",
    BA: "0.442",
    BB: "0.254",
    BC: "0.103",
    BD: "0.442",
    BE: "0.205",
    BF: "0.000",
  },
  {
    AZ: "10.220",
    BA: "0.441",
    BB: "0.253",
    BC: "0.101",
    BD: "0.441",
    BE: "0.203",
    BF: "0.000",
  },
  {
    AZ: "10.230",
    BA: "0.441",
    BB: "0.253",
    BC: "0.100",
    BD: "0.441",
    BE: "0.200",
    BF: "0.000",
  },
  {
    AZ: "10.240",
    BA: "0.440",
    BB: "0.253",
    BC: "0.099",
    BD: "0.440",
    BE: "0.198",
    BF: "0.000",
  },
  {
    AZ: "10.250",
    BA: "0.440",
    BB: "0.252",
    BC: "0.098",
    BD: "0.440",
    BE: "0.195",
    BF: "0.000",
  },
  {
    AZ: "10.260",
    BA: "0.440",
    BB: "0.252",
    BC: "0.096",
    BD: "0.440",
    BE: "0.192",
    BF: "0.000",
  },
  {
    AZ: "10.270",
    BA: "0.439",
    BB: "0.252",
    BC: "0.095",
    BD: "0.439",
    BE: "0.190",
    BF: "0.000",
  },
  {
    AZ: "10.280",
    BA: "0.439",
    BB: "0.252",
    BC: "0.094",
    BD: "0.439",
    BE: "0.187",
    BF: "0.000",
  },
  {
    AZ: "10.290",
    BA: "0.438",
    BB: "0.251",
    BC: "0.092",
    BD: "0.438",
    BE: "0.185",
    BF: "0.000",
  },
  {
    AZ: "10.300",
    BA: "0.438",
    BB: "0.251",
    BC: "0.091",
    BD: "0.438",
    BE: "0.182",
    BF: "0.000",
  },
  {
    AZ: "10.310",
    BA: "0.438",
    BB: "0.251",
    BC: "0.090",
    BD: "0.438",
    BE: "0.179",
    BF: "0.000",
  },
  {
    AZ: "10.320",
    BA: "0.437",
    BB: "0.250",
    BC: "0.088",
    BD: "0.437",
    BE: "0.177",
    BF: "0.000",
  },
  {
    AZ: "10.330",
    BA: "0.437",
    BB: "0.250",
    BC: "0.087",
    BD: "0.437",
    BE: "0.174",
    BF: "0.000",
  },
  {
    AZ: "10.340",
    BA: "0.436",
    BB: "0.250",
    BC: "0.086",
    BD: "0.436",
    BE: "0.172",
    BF: "0.000",
  },
  {
    AZ: "10.350",
    BA: "0.436",
    BB: "0.249",
    BC: "0.085",
    BD: "0.436",
    BE: "0.169",
    BF: "0.000",
  },
  {
    AZ: "10.360",
    BA: "0.436",
    BB: "0.249",
    BC: "0.083",
    BD: "0.436",
    BE: "0.166",
    BF: "0.000",
  },
  {
    AZ: "10.370",
    BA: "0.435",
    BB: "0.249",
    BC: "0.082",
    BD: "0.435",
    BE: "0.164",
    BF: "0.000",
  },
  {
    AZ: "10.380",
    BA: "0.435",
    BB: "0.249",
    BC: "0.081",
    BD: "0.435",
    BE: "0.161",
    BF: "0.000",
  },
  {
    AZ: "10.390",
    BA: "0.434",
    BB: "0.248",
    BC: "0.079",
    BD: "0.434",
    BE: "0.159",
    BF: "0.000",
  },
  {
    AZ: "10.400",
    BA: "0.434",
    BB: "0.248",
    BC: "0.078",
    BD: "0.434",
    BE: "0.156",
    BF: "0.000",
  },
  {
    AZ: "10.410",
    BA: "0.434",
    BB: "0.248",
    BC: "0.077",
    BD: "0.434",
    BE: "0.153",
    BF: "0.000",
  },
  {
    AZ: "10.420",
    BA: "0.433",
    BB: "0.247",
    BC: "0.075",
    BD: "0.433",
    BE: "0.151",
    BF: "0.000",
  },
  {
    AZ: "10.430",
    BA: "0.433",
    BB: "0.247",
    BC: "0.074",
    BD: "0.433",
    BE: "0.148",
    BF: "0.000",
  },
  {
    AZ: "10.440",
    BA: "0.432",
    BB: "0.247",
    BC: "0.073",
    BD: "0.432",
    BE: "0.146",
    BF: "0.000",
  },
  {
    AZ: "10.450",
    BA: "0.432",
    BB: "0.246",
    BC: "0.072",
    BD: "0.432",
    BE: "0.143",
    BF: "0.000",
  },
  {
    AZ: "10.460",
    BA: "0.432",
    BB: "0.246",
    BC: "0.070",
    BD: "0.432",
    BE: "0.140",
    BF: "0.000",
  },
  {
    AZ: "10.470",
    BA: "0.431",
    BB: "0.246",
    BC: "0.069",
    BD: "0.431",
    BE: "0.138",
    BF: "0.000",
  },
  {
    AZ: "10.480",
    BA: "0.431",
    BB: "0.246",
    BC: "0.068",
    BD: "0.431",
    BE: "0.135",
    BF: "0.000",
  },
  {
    AZ: "10.490",
    BA: "0.430",
    BB: "0.245",
    BC: "0.066",
    BD: "0.430",
    BE: "0.133",
    BF: "0.000",
  },
  {
    AZ: "10.500",
    BA: "0.430",
    BB: "0.245",
    BC: "0.065",
    BD: "0.430",
    BE: "0.130",
    BF: "0.000",
  },
  {
    AZ: "10.510",
    BA: "0.430",
    BB: "0.245",
    BC: "0.064",
    BD: "0.430",
    BE: "0.127",
    BF: "0.000",
  },
  {
    AZ: "10.520",
    BA: "0.429",
    BB: "0.244",
    BC: "0.062",
    BD: "0.429",
    BE: "0.125",
    BF: "0.000",
  },
  {
    AZ: "10.530",
    BA: "0.429",
    BB: "0.244",
    BC: "0.061",
    BD: "0.429",
    BE: "0.122",
    BF: "0.000",
  },
  {
    AZ: "10.540",
    BA: "0.428",
    BB: "0.244",
    BC: "0.060",
    BD: "0.428",
    BE: "0.120",
    BF: "0.000",
  },
  {
    AZ: "10.550",
    BA: "0.428",
    BB: "0.243",
    BC: "0.059",
    BD: "0.428",
    BE: "0.117",
    BF: "0.000",
  },
  {
    AZ: "10.560",
    BA: "0.428",
    BB: "0.243",
    BC: "0.057",
    BD: "0.428",
    BE: "0.114",
    BF: "0.000",
  },
  {
    AZ: "10.570",
    BA: "0.427",
    BB: "0.243",
    BC: "0.056",
    BD: "0.427",
    BE: "0.112",
    BF: "0.000",
  },
  {
    AZ: "10.580",
    BA: "0.427",
    BB: "0.243",
    BC: "0.055",
    BD: "0.427",
    BE: "0.109",
    BF: "0.000",
  },
  {
    AZ: "10.590",
    BA: "0.426",
    BB: "0.242",
    BC: "0.053",
    BD: "0.426",
    BE: "0.107",
    BF: "0.000",
  },
  {
    AZ: "10.600",
    BA: "0.426",
    BB: "0.242",
    BC: "0.052",
    BD: "0.426",
    BE: "0.104",
    BF: "0.000",
  },
  {
    AZ: "10.610",
    BA: "0.426",
    BB: "0.242",
    BC: "0.051",
    BD: "0.426",
    BE: "0.101",
    BF: "0.000",
  },
  {
    AZ: "10.620",
    BA: "0.425",
    BB: "0.241",
    BC: "0.049",
    BD: "0.425",
    BE: "0.099",
    BF: "0.000",
  },
  {
    AZ: "10.630",
    BA: "0.425",
    BB: "0.241",
    BC: "0.048",
    BD: "0.425",
    BE: "0.096",
    BF: "0.000",
  },
  {
    AZ: "10.640",
    BA: "0.424",
    BB: "0.241",
    BC: "0.047",
    BD: "0.424",
    BE: "0.094",
    BF: "0.000",
  },
  {
    AZ: "10.650",
    BA: "0.424",
    BB: "0.240",
    BC: "0.046",
    BD: "0.424",
    BE: "0.091",
    BF: "0.000",
  },
  {
    AZ: "10.660",
    BA: "0.424",
    BB: "0.240",
    BC: "0.044",
    BD: "0.424",
    BE: "0.088",
    BF: "0.000",
  },
  {
    AZ: "10.670",
    BA: "0.423",
    BB: "0.240",
    BC: "0.043",
    BD: "0.423",
    BE: "0.086",
    BF: "0.000",
  },
  {
    AZ: "10.680",
    BA: "0.423",
    BB: "0.240",
    BC: "0.042",
    BD: "0.423",
    BE: "0.083",
    BF: "0.000",
  },
  {
    AZ: "10.690",
    BA: "0.422",
    BB: "0.239",
    BC: "0.040",
    BD: "0.422",
    BE: "0.081",
    BF: "0.000",
  },
  {
    AZ: "10.700",
    BA: "0.422",
    BB: "0.239",
    BC: "0.039",
    BD: "0.422",
    BE: "0.078",
    BF: "0.000",
  },
  {
    AZ: "10.710",
    BA: "0.422",
    BB: "0.239",
    BC: "0.038",
    BD: "0.422",
    BE: "0.075",
    BF: "0.000",
  },
  {
    AZ: "10.720",
    BA: "0.421",
    BB: "0.238",
    BC: "0.036",
    BD: "0.421",
    BE: "0.073",
    BF: "0.000",
  },
  {
    AZ: "10.730",
    BA: "0.421",
    BB: "0.238",
    BC: "0.035",
    BD: "0.421",
    BE: "0.070",
    BF: "0.000",
  },
  {
    AZ: "10.740",
    BA: "0.420",
    BB: "0.238",
    BC: "0.034",
    BD: "0.420",
    BE: "0.068",
    BF: "0.000",
  },
  {
    AZ: "10.750",
    BA: "0.420",
    BB: "0.238",
    BC: "0.033",
    BD: "0.420",
    BE: "0.065",
    BF: "0.000",
  },
  {
    AZ: "10.760",
    BA: "0.420",
    BB: "0.237",
    BC: "0.031",
    BD: "0.420",
    BE: "0.062",
    BF: "0.000",
  },
  {
    AZ: "10.770",
    BA: "0.419",
    BB: "0.237",
    BC: "0.030",
    BD: "0.419",
    BE: "0.060",
    BF: "0.000",
  },
  {
    AZ: "10.780",
    BA: "0.419",
    BB: "0.237",
    BC: "0.029",
    BD: "0.419",
    BE: "0.057",
    BF: "0.000",
  },
  {
    AZ: "10.790",
    BA: "0.418",
    BB: "0.236",
    BC: "0.027",
    BD: "0.418",
    BE: "0.055",
    BF: "0.000",
  },
  {
    AZ: "10.800",
    BA: "0.418",
    BB: "0.236",
    BC: "0.026",
    BD: "0.418",
    BE: "0.052",
    BF: "0.000",
  },
  {
    AZ: "10.810",
    BA: "0.418",
    BB: "0.236",
    BC: "0.025",
    BD: "0.418",
    BE: "0.049",
    BF: "0.000",
  },
  {
    AZ: "10.820",
    BA: "0.417",
    BB: "0.235",
    BC: "0.023",
    BD: "0.417",
    BE: "0.047",
    BF: "0.000",
  },
  {
    AZ: "10.830",
    BA: "0.417",
    BB: "0.235",
    BC: "0.022",
    BD: "0.417",
    BE: "0.044",
    BF: "0.000",
  },
  {
    AZ: "10.840",
    BA: "0.416",
    BB: "0.235",
    BC: "0.021",
    BD: "0.416",
    BE: "0.042",
    BF: "0.000",
  },
  {
    AZ: "10.850",
    BA: "0.416",
    BB: "0.235",
    BC: "0.020",
    BD: "0.416",
    BE: "0.039",
    BF: "0.000",
  },
  {
    AZ: "10.860",
    BA: "0.416",
    BB: "0.234",
    BC: "0.018",
    BD: "0.416",
    BE: "0.036",
    BF: "0.000",
  },
  {
    AZ: "10.870",
    BA: "0.415",
    BB: "0.234",
    BC: "0.017",
    BD: "0.415",
    BE: "0.034",
    BF: "0.000",
  },
  {
    AZ: "10.880",
    BA: "0.415",
    BB: "0.234",
    BC: "0.016",
    BD: "0.415",
    BE: "0.031",
    BF: "0.000",
  },
  {
    AZ: "10.890",
    BA: "0.414",
    BB: "0.233",
    BC: "0.014",
    BD: "0.414",
    BE: "0.029",
    BF: "0.000",
  },
  {
    AZ: "10.900",
    BA: "0.414",
    BB: "0.233",
    BC: "0.013",
    BD: "0.414",
    BE: "0.026",
    BF: "0.000",
  },
  {
    AZ: "10.910",
    BA: "0.414",
    BB: "0.233",
    BC: "0.012",
    BD: "0.414",
    BE: "0.023",
    BF: "0.000",
  },
  {
    AZ: "10.920",
    BA: "0.413",
    BB: "0.232",
    BC: "0.010",
    BD: "0.413",
    BE: "0.021",
    BF: "0.000",
  },
  {
    AZ: "10.930",
    BA: "0.413",
    BB: "0.232",
    BC: "0.009",
    BD: "0.413",
    BE: "0.018",
    BF: "0.000",
  },
  {
    AZ: "10.940",
    BA: "0.412",
    BB: "0.232",
    BC: "0.008",
    BD: "0.412",
    BE: "0.016",
    BF: "0.000",
  },
  {
    AZ: "10.950",
    BA: "0.412",
    BB: "0.232",
    BC: "0.007",
    BD: "0.412",
    BE: "0.013",
    BF: "0.000",
  },
  {
    AZ: "10.960",
    BA: "0.412",
    BB: "0.231",
    BC: "0.005",
    BD: "0.412",
    BE: "0.010",
    BF: "0.000",
  },
  {
    AZ: "10.970",
    BA: "0.411",
    BB: "0.231",
    BC: "0.004",
    BD: "0.411",
    BE: "0.008",
    BF: "0.000",
  },
  {
    AZ: "10.980",
    BA: "0.411",
    BB: "0.231",
    BC: "0.003",
    BD: "0.411",
    BE: "0.005",
    BF: "0.000",
  },
  {
    AZ: "10.990",
    BA: "0.410",
    BB: "0.230",
    BC: "0.001",
    BD: "0.410",
    BE: "0.003",
    BF: "0.000",
  },
  {
    AZ: "11.000",
    BA: "0.410",
    BB: "0.230",
    BC: "0.000",
    BD: "0.410",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.010",
    BA: "0.410",
    BB: "0.230",
    BC: "0.000",
    BD: "0.410",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.020",
    BA: "0.409",
    BB: "0.230",
    BC: "0.000",
    BD: "0.409",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.030",
    BA: "0.409",
    BB: "0.229",
    BC: "0.000",
    BD: "0.409",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.040",
    BA: "0.408",
    BB: "0.229",
    BC: "0.000",
    BD: "0.408",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.050",
    BA: "0.408",
    BB: "0.229",
    BC: "0.000",
    BD: "0.408",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.060",
    BA: "0.408",
    BB: "0.229",
    BC: "0.000",
    BD: "0.408",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.070",
    BA: "0.407",
    BB: "0.229",
    BC: "0.000",
    BD: "0.407",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.080",
    BA: "0.407",
    BB: "0.228",
    BC: "0.000",
    BD: "0.407",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.090",
    BA: "0.406",
    BB: "0.228",
    BC: "0.000",
    BD: "0.406",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.100",
    BA: "0.406",
    BB: "0.228",
    BC: "0.000",
    BD: "0.406",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.110",
    BA: "0.406",
    BB: "0.228",
    BC: "0.000",
    BD: "0.406",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.120",
    BA: "0.405",
    BB: "0.228",
    BC: "0.000",
    BD: "0.405",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.130",
    BA: "0.405",
    BB: "0.227",
    BC: "0.000",
    BD: "0.405",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.140",
    BA: "0.404",
    BB: "0.227",
    BC: "0.000",
    BD: "0.404",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.150",
    BA: "0.404",
    BB: "0.227",
    BC: "0.000",
    BD: "0.404",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.160",
    BA: "0.404",
    BB: "0.227",
    BC: "0.000",
    BD: "0.404",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.170",
    BA: "0.403",
    BB: "0.227",
    BC: "0.000",
    BD: "0.403",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.180",
    BA: "0.403",
    BB: "0.226",
    BC: "0.000",
    BD: "0.403",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.190",
    BA: "0.402",
    BB: "0.226",
    BC: "0.000",
    BD: "0.402",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.200",
    BA: "0.402",
    BB: "0.226",
    BC: "0.000",
    BD: "0.402",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.210",
    BA: "0.402",
    BB: "0.226",
    BC: "0.000",
    BD: "0.402",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.220",
    BA: "0.401",
    BB: "0.226",
    BC: "0.000",
    BD: "0.401",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.230",
    BA: "0.401",
    BB: "0.225",
    BC: "0.000",
    BD: "0.401",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.240",
    BA: "0.400",
    BB: "0.225",
    BC: "0.000",
    BD: "0.400",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.250",
    BA: "0.400",
    BB: "0.225",
    BC: "0.000",
    BD: "0.400",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.260",
    BA: "0.400",
    BB: "0.225",
    BC: "0.000",
    BD: "0.400",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.270",
    BA: "0.399",
    BB: "0.225",
    BC: "0.000",
    BD: "0.399",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.280",
    BA: "0.399",
    BB: "0.224",
    BC: "0.000",
    BD: "0.399",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.290",
    BA: "0.398",
    BB: "0.224",
    BC: "0.000",
    BD: "0.398",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.300",
    BA: "0.398",
    BB: "0.224",
    BC: "0.000",
    BD: "0.398",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.310",
    BA: "0.398",
    BB: "0.224",
    BC: "0.000",
    BD: "0.398",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.320",
    BA: "0.397",
    BB: "0.224",
    BC: "0.000",
    BD: "0.397",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.330",
    BA: "0.397",
    BB: "0.223",
    BC: "0.000",
    BD: "0.397",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.340",
    BA: "0.396",
    BB: "0.223",
    BC: "0.000",
    BD: "0.396",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.350",
    BA: "0.396",
    BB: "0.223",
    BC: "0.000",
    BD: "0.396",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.360",
    BA: "0.396",
    BB: "0.223",
    BC: "0.000",
    BD: "0.396",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.370",
    BA: "0.395",
    BB: "0.223",
    BC: "0.000",
    BD: "0.395",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.380",
    BA: "0.395",
    BB: "0.222",
    BC: "0.000",
    BD: "0.395",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.390",
    BA: "0.394",
    BB: "0.222",
    BC: "0.000",
    BD: "0.394",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.400",
    BA: "0.394",
    BB: "0.222",
    BC: "0.000",
    BD: "0.394",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.410",
    BA: "0.394",
    BB: "0.222",
    BC: "0.000",
    BD: "0.394",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.420",
    BA: "0.393",
    BB: "0.222",
    BC: "0.000",
    BD: "0.393",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.430",
    BA: "0.393",
    BB: "0.221",
    BC: "0.000",
    BD: "0.393",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.440",
    BA: "0.392",
    BB: "0.221",
    BC: "0.000",
    BD: "0.392",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.450",
    BA: "0.392",
    BB: "0.221",
    BC: "0.000",
    BD: "0.392",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.460",
    BA: "0.392",
    BB: "0.221",
    BC: "0.000",
    BD: "0.392",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.470",
    BA: "0.391",
    BB: "0.221",
    BC: "0.000",
    BD: "0.391",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.480",
    BA: "0.391",
    BB: "0.220",
    BC: "0.000",
    BD: "0.391",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.490",
    BA: "0.390",
    BB: "0.220",
    BC: "0.000",
    BD: "0.390",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.500",
    BA: "0.390",
    BB: "0.220",
    BC: "0.000",
    BD: "0.390",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.510",
    BA: "0.390",
    BB: "0.220",
    BC: "0.000",
    BD: "0.390",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.520",
    BA: "0.389",
    BB: "0.220",
    BC: "0.000",
    BD: "0.389",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.530",
    BA: "0.389",
    BB: "0.219",
    BC: "0.000",
    BD: "0.389",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.540",
    BA: "0.388",
    BB: "0.219",
    BC: "0.000",
    BD: "0.388",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.550",
    BA: "0.388",
    BB: "0.219",
    BC: "0.000",
    BD: "0.388",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.560",
    BA: "0.388",
    BB: "0.219",
    BC: "0.000",
    BD: "0.388",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.570",
    BA: "0.387",
    BB: "0.219",
    BC: "0.000",
    BD: "0.387",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.580",
    BA: "0.387",
    BB: "0.218",
    BC: "0.000",
    BD: "0.387",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.590",
    BA: "0.386",
    BB: "0.218",
    BC: "0.000",
    BD: "0.386",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.600",
    BA: "0.386",
    BB: "0.218",
    BC: "0.000",
    BD: "0.386",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.610",
    BA: "0.386",
    BB: "0.218",
    BC: "0.000",
    BD: "0.386",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.620",
    BA: "0.385",
    BB: "0.218",
    BC: "0.000",
    BD: "0.385",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.630",
    BA: "0.385",
    BB: "0.217",
    BC: "0.000",
    BD: "0.385",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.640",
    BA: "0.384",
    BB: "0.217",
    BC: "0.000",
    BD: "0.384",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.650",
    BA: "0.384",
    BB: "0.217",
    BC: "0.000",
    BD: "0.384",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.660",
    BA: "0.384",
    BB: "0.217",
    BC: "0.000",
    BD: "0.384",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.670",
    BA: "0.383",
    BB: "0.217",
    BC: "0.000",
    BD: "0.383",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.680",
    BA: "0.383",
    BB: "0.216",
    BC: "0.000",
    BD: "0.383",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.690",
    BA: "0.382",
    BB: "0.216",
    BC: "0.000",
    BD: "0.382",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.700",
    BA: "0.382",
    BB: "0.216",
    BC: "0.000",
    BD: "0.382",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.710",
    BA: "0.382",
    BB: "0.216",
    BC: "0.000",
    BD: "0.382",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.720",
    BA: "0.381",
    BB: "0.216",
    BC: "0.000",
    BD: "0.381",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.730",
    BA: "0.381",
    BB: "0.215",
    BC: "0.000",
    BD: "0.381",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.740",
    BA: "0.380",
    BB: "0.215",
    BC: "0.000",
    BD: "0.380",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.750",
    BA: "0.380",
    BB: "0.215",
    BC: "0.000",
    BD: "0.380",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.760",
    BA: "0.380",
    BB: "0.215",
    BC: "0.000",
    BD: "0.380",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.770",
    BA: "0.379",
    BB: "0.215",
    BC: "0.000",
    BD: "0.379",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.780",
    BA: "0.379",
    BB: "0.214",
    BC: "0.000",
    BD: "0.379",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.790",
    BA: "0.378",
    BB: "0.214",
    BC: "0.000",
    BD: "0.378",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.800",
    BA: "0.378",
    BB: "0.214",
    BC: "0.000",
    BD: "0.378",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.810",
    BA: "0.378",
    BB: "0.214",
    BC: "0.000",
    BD: "0.378",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.820",
    BA: "0.377",
    BB: "0.214",
    BC: "0.000",
    BD: "0.377",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.830",
    BA: "0.377",
    BB: "0.213",
    BC: "0.000",
    BD: "0.377",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.840",
    BA: "0.376",
    BB: "0.213",
    BC: "0.000",
    BD: "0.376",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.850",
    BA: "0.376",
    BB: "0.213",
    BC: "0.000",
    BD: "0.376",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.860",
    BA: "0.376",
    BB: "0.213",
    BC: "0.000",
    BD: "0.376",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.870",
    BA: "0.375",
    BB: "0.213",
    BC: "0.000",
    BD: "0.375",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.880",
    BA: "0.375",
    BB: "0.212",
    BC: "0.000",
    BD: "0.375",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.890",
    BA: "0.374",
    BB: "0.212",
    BC: "0.000",
    BD: "0.374",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.900",
    BA: "0.374",
    BB: "0.212",
    BC: "0.000",
    BD: "0.374",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.910",
    BA: "0.374",
    BB: "0.212",
    BC: "0.000",
    BD: "0.374",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.920",
    BA: "0.373",
    BB: "0.212",
    BC: "0.000",
    BD: "0.373",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.930",
    BA: "0.373",
    BB: "0.211",
    BC: "0.000",
    BD: "0.373",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.940",
    BA: "0.372",
    BB: "0.211",
    BC: "0.000",
    BD: "0.372",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.950",
    BA: "0.372",
    BB: "0.211",
    BC: "0.000",
    BD: "0.372",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.960",
    BA: "0.372",
    BB: "0.211",
    BC: "0.000",
    BD: "0.372",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.970",
    BA: "0.371",
    BB: "0.211",
    BC: "0.000",
    BD: "0.371",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.980",
    BA: "0.371",
    BB: "0.210",
    BC: "0.000",
    BD: "0.371",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "11.990",
    BA: "0.370",
    BB: "0.210",
    BC: "0.000",
    BD: "0.370",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.000",
    BA: "0.370",
    BB: "0.210",
    BC: "0.000",
    BD: "0.370",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.010",
    BA: "0.370",
    BB: "0.208",
    BC: "0.000",
    BD: "0.366",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.020",
    BA: "0.369",
    BB: "0.206",
    BC: "0.000",
    BD: "0.363",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.030",
    BA: "0.369",
    BB: "0.204",
    BC: "0.000",
    BD: "0.359",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.040",
    BA: "0.369",
    BB: "0.202",
    BC: "0.000",
    BD: "0.355",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.050",
    BA: "0.369",
    BB: "0.200",
    BC: "0.000",
    BD: "0.352",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.060",
    BA: "0.368",
    BB: "0.197",
    BC: "0.000",
    BD: "0.348",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.070",
    BA: "0.368",
    BB: "0.195",
    BC: "0.000",
    BD: "0.344",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.080",
    BA: "0.368",
    BB: "0.193",
    BC: "0.000",
    BD: "0.340",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.090",
    BA: "0.367",
    BB: "0.191",
    BC: "0.000",
    BD: "0.337",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.100",
    BA: "0.367",
    BB: "0.189",
    BC: "0.000",
    BD: "0.333",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.110",
    BA: "0.367",
    BB: "0.187",
    BC: "0.000",
    BD: "0.329",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.120",
    BA: "0.366",
    BB: "0.185",
    BC: "0.000",
    BD: "0.326",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.130",
    BA: "0.366",
    BB: "0.183",
    BC: "0.000",
    BD: "0.322",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.140",
    BA: "0.366",
    BB: "0.181",
    BC: "0.000",
    BD: "0.318",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.150",
    BA: "0.366",
    BB: "0.179",
    BC: "0.000",
    BD: "0.315",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.160",
    BA: "0.365",
    BB: "0.176",
    BC: "0.000",
    BD: "0.311",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.170",
    BA: "0.365",
    BB: "0.174",
    BC: "0.000",
    BD: "0.307",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.180",
    BA: "0.365",
    BB: "0.172",
    BC: "0.000",
    BD: "0.303",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.190",
    BA: "0.364",
    BB: "0.170",
    BC: "0.000",
    BD: "0.300",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.200",
    BA: "0.364",
    BB: "0.168",
    BC: "0.000",
    BD: "0.296",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.210",
    BA: "0.364",
    BB: "0.166",
    BC: "0.000",
    BD: "0.292",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.220",
    BA: "0.363",
    BB: "0.164",
    BC: "0.000",
    BD: "0.289",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.230",
    BA: "0.363",
    BB: "0.162",
    BC: "0.000",
    BD: "0.285",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.240",
    BA: "0.363",
    BB: "0.160",
    BC: "0.000",
    BD: "0.281",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.250",
    BA: "0.362",
    BB: "0.158",
    BC: "0.000",
    BD: "0.278",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.260",
    BA: "0.362",
    BB: "0.155",
    BC: "0.000",
    BD: "0.274",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.270",
    BA: "0.362",
    BB: "0.153",
    BC: "0.000",
    BD: "0.270",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.280",
    BA: "0.362",
    BB: "0.151",
    BC: "0.000",
    BD: "0.266",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.290",
    BA: "0.361",
    BB: "0.149",
    BC: "0.000",
    BD: "0.263",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.300",
    BA: "0.361",
    BB: "0.147",
    BC: "0.000",
    BD: "0.259",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.310",
    BA: "0.361",
    BB: "0.145",
    BC: "0.000",
    BD: "0.255",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.320",
    BA: "0.360",
    BB: "0.143",
    BC: "0.000",
    BD: "0.252",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.330",
    BA: "0.360",
    BB: "0.141",
    BC: "0.000",
    BD: "0.248",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.340",
    BA: "0.360",
    BB: "0.139",
    BC: "0.000",
    BD: "0.244",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.350",
    BA: "0.359",
    BB: "0.137",
    BC: "0.000",
    BD: "0.241",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.360",
    BA: "0.359",
    BB: "0.134",
    BC: "0.000",
    BD: "0.237",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.370",
    BA: "0.359",
    BB: "0.132",
    BC: "0.000",
    BD: "0.233",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.380",
    BA: "0.359",
    BB: "0.130",
    BC: "0.000",
    BD: "0.229",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.390",
    BA: "0.358",
    BB: "0.128",
    BC: "0.000",
    BD: "0.226",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.400",
    BA: "0.358",
    BB: "0.126",
    BC: "0.000",
    BD: "0.222",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.410",
    BA: "0.358",
    BB: "0.124",
    BC: "0.000",
    BD: "0.218",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.420",
    BA: "0.357",
    BB: "0.122",
    BC: "0.000",
    BD: "0.215",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.430",
    BA: "0.357",
    BB: "0.120",
    BC: "0.000",
    BD: "0.211",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.440",
    BA: "0.357",
    BB: "0.118",
    BC: "0.000",
    BD: "0.207",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.450",
    BA: "0.356",
    BB: "0.116",
    BC: "0.000",
    BD: "0.204",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.460",
    BA: "0.356",
    BB: "0.113",
    BC: "0.000",
    BD: "0.200",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.470",
    BA: "0.356",
    BB: "0.111",
    BC: "0.000",
    BD: "0.196",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.480",
    BA: "0.356",
    BB: "0.109",
    BC: "0.000",
    BD: "0.192",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.490",
    BA: "0.355",
    BB: "0.107",
    BC: "0.000",
    BD: "0.189",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.500",
    BA: "0.355",
    BB: "0.105",
    BC: "0.000",
    BD: "0.185",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.510",
    BA: "0.355",
    BB: "0.103",
    BC: "0.000",
    BD: "0.181",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.520",
    BA: "0.354",
    BB: "0.101",
    BC: "0.000",
    BD: "0.178",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.530",
    BA: "0.354",
    BB: "0.099",
    BC: "0.000",
    BD: "0.174",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.540",
    BA: "0.354",
    BB: "0.097",
    BC: "0.000",
    BD: "0.170",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.550",
    BA: "0.353",
    BB: "0.095",
    BC: "0.000",
    BD: "0.167",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.560",
    BA: "0.353",
    BB: "0.092",
    BC: "0.000",
    BD: "0.163",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.570",
    BA: "0.353",
    BB: "0.090",
    BC: "0.000",
    BD: "0.159",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.580",
    BA: "0.353",
    BB: "0.088",
    BC: "0.000",
    BD: "0.155",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.590",
    BA: "0.352",
    BB: "0.086",
    BC: "0.000",
    BD: "0.152",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.600",
    BA: "0.352",
    BB: "0.084",
    BC: "0.000",
    BD: "0.148",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.610",
    BA: "0.352",
    BB: "0.082",
    BC: "0.000",
    BD: "0.144",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.620",
    BA: "0.351",
    BB: "0.080",
    BC: "0.000",
    BD: "0.141",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.630",
    BA: "0.351",
    BB: "0.078",
    BC: "0.000",
    BD: "0.137",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.640",
    BA: "0.351",
    BB: "0.076",
    BC: "0.000",
    BD: "0.133",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.650",
    BA: "0.350",
    BB: "0.074",
    BC: "0.000",
    BD: "0.130",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.660",
    BA: "0.350",
    BB: "0.071",
    BC: "0.000",
    BD: "0.126",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.670",
    BA: "0.350",
    BB: "0.069",
    BC: "0.000",
    BD: "0.122",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.680",
    BA: "0.350",
    BB: "0.067",
    BC: "0.000",
    BD: "0.118",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.690",
    BA: "0.349",
    BB: "0.065",
    BC: "0.000",
    BD: "0.115",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.700",
    BA: "0.349",
    BB: "0.063",
    BC: "0.000",
    BD: "0.111",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.710",
    BA: "0.349",
    BB: "0.061",
    BC: "0.000",
    BD: "0.107",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.720",
    BA: "0.348",
    BB: "0.059",
    BC: "0.000",
    BD: "0.104",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.730",
    BA: "0.348",
    BB: "0.057",
    BC: "0.000",
    BD: "0.100",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.740",
    BA: "0.348",
    BB: "0.055",
    BC: "0.000",
    BD: "0.096",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.750",
    BA: "0.347",
    BB: "0.053",
    BC: "0.000",
    BD: "0.093",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.760",
    BA: "0.347",
    BB: "0.050",
    BC: "0.000",
    BD: "0.089",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.770",
    BA: "0.347",
    BB: "0.048",
    BC: "0.000",
    BD: "0.085",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.780",
    BA: "0.347",
    BB: "0.046",
    BC: "0.000",
    BD: "0.081",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.790",
    BA: "0.346",
    BB: "0.044",
    BC: "0.000",
    BD: "0.078",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.800",
    BA: "0.346",
    BB: "0.042",
    BC: "0.000",
    BD: "0.074",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.810",
    BA: "0.346",
    BB: "0.040",
    BC: "0.000",
    BD: "0.070",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.820",
    BA: "0.345",
    BB: "0.038",
    BC: "0.000",
    BD: "0.067",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.830",
    BA: "0.345",
    BB: "0.036",
    BC: "0.000",
    BD: "0.063",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.840",
    BA: "0.345",
    BB: "0.034",
    BC: "0.000",
    BD: "0.059",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.850",
    BA: "0.344",
    BB: "0.032",
    BC: "0.000",
    BD: "0.056",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.860",
    BA: "0.344",
    BB: "0.029",
    BC: "0.000",
    BD: "0.052",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.870",
    BA: "0.344",
    BB: "0.027",
    BC: "0.000",
    BD: "0.048",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.880",
    BA: "0.344",
    BB: "0.025",
    BC: "0.000",
    BD: "0.044",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.890",
    BA: "0.343",
    BB: "0.023",
    BC: "0.000",
    BD: "0.041",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.900",
    BA: "0.343",
    BB: "0.021",
    BC: "0.000",
    BD: "0.037",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.910",
    BA: "0.343",
    BB: "0.019",
    BC: "0.000",
    BD: "0.033",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.920",
    BA: "0.342",
    BB: "0.017",
    BC: "0.000",
    BD: "0.030",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.930",
    BA: "0.342",
    BB: "0.015",
    BC: "0.000",
    BD: "0.026",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.940",
    BA: "0.342",
    BB: "0.013",
    BC: "0.000",
    BD: "0.022",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.950",
    BA: "0.341",
    BB: "0.011",
    BC: "0.000",
    BD: "0.019",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.960",
    BA: "0.341",
    BB: "0.008",
    BC: "0.000",
    BD: "0.015",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.970",
    BA: "0.341",
    BB: "0.006",
    BC: "0.000",
    BD: "0.011",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.980",
    BA: "0.341",
    BB: "0.004",
    BC: "0.000",
    BD: "0.007",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "12.990",
    BA: "0.340",
    BB: "0.002",
    BC: "0.000",
    BD: "0.004",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.000",
    BA: "0.340",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.010",
    BA: "0.340",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.020",
    BA: "0.339",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.030",
    BA: "0.339",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.040",
    BA: "0.339",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.050",
    BA: "0.339",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.060",
    BA: "0.338",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.070",
    BA: "0.338",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.080",
    BA: "0.338",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.090",
    BA: "0.337",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.100",
    BA: "0.337",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.110",
    BA: "0.337",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.120",
    BA: "0.336",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.130",
    BA: "0.336",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.140",
    BA: "0.336",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.150",
    BA: "0.336",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.160",
    BA: "0.335",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.170",
    BA: "0.335",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.180",
    BA: "0.335",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.190",
    BA: "0.334",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.200",
    BA: "0.334",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.210",
    BA: "0.334",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.220",
    BA: "0.333",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.230",
    BA: "0.333",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.240",
    BA: "0.333",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.250",
    BA: "0.332",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.260",
    BA: "0.332",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.270",
    BA: "0.332",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.280",
    BA: "0.332",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.290",
    BA: "0.331",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.300",
    BA: "0.331",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.310",
    BA: "0.331",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.320",
    BA: "0.330",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.330",
    BA: "0.330",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.340",
    BA: "0.330",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.350",
    BA: "0.329",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.360",
    BA: "0.329",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.370",
    BA: "0.329",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.380",
    BA: "0.329",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.390",
    BA: "0.328",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.400",
    BA: "0.328",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.410",
    BA: "0.328",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.420",
    BA: "0.327",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.430",
    BA: "0.327",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.440",
    BA: "0.327",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.450",
    BA: "0.326",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.460",
    BA: "0.326",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.470",
    BA: "0.326",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.480",
    BA: "0.326",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.490",
    BA: "0.325",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.500",
    BA: "0.325",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.510",
    BA: "0.325",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.520",
    BA: "0.324",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.530",
    BA: "0.324",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.540",
    BA: "0.324",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.550",
    BA: "0.323",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.560",
    BA: "0.323",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.570",
    BA: "0.323",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.580",
    BA: "0.323",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.590",
    BA: "0.322",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.600",
    BA: "0.322",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.610",
    BA: "0.322",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.620",
    BA: "0.321",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.630",
    BA: "0.321",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.640",
    BA: "0.321",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.650",
    BA: "0.320",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.660",
    BA: "0.320",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.670",
    BA: "0.320",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.680",
    BA: "0.320",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.690",
    BA: "0.319",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.700",
    BA: "0.319",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.710",
    BA: "0.319",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.720",
    BA: "0.318",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.730",
    BA: "0.318",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.740",
    BA: "0.318",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.750",
    BA: "0.317",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.760",
    BA: "0.317",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.770",
    BA: "0.317",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.780",
    BA: "0.317",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.790",
    BA: "0.316",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.800",
    BA: "0.316",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.810",
    BA: "0.316",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.820",
    BA: "0.315",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.830",
    BA: "0.315",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.840",
    BA: "0.315",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.850",
    BA: "0.314",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.860",
    BA: "0.314",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.870",
    BA: "0.314",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.880",
    BA: "0.314",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.890",
    BA: "0.313",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.900",
    BA: "0.313",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.910",
    BA: "0.313",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.920",
    BA: "0.312",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.930",
    BA: "0.312",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.940",
    BA: "0.312",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.950",
    BA: "0.311",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.960",
    BA: "0.311",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.970",
    BA: "0.311",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.980",
    BA: "0.311",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "13.990",
    BA: "0.310",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.000",
    BA: "0.310",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.010",
    BA: "0.310",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.020",
    BA: "0.309",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.030",
    BA: "0.309",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.040",
    BA: "0.309",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.050",
    BA: "0.309",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.060",
    BA: "0.308",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.070",
    BA: "0.308",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.080",
    BA: "0.308",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.090",
    BA: "0.307",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.100",
    BA: "0.307",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.110",
    BA: "0.307",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.120",
    BA: "0.306",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.130",
    BA: "0.306",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.140",
    BA: "0.306",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.150",
    BA: "0.306",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.160",
    BA: "0.305",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.170",
    BA: "0.305",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.180",
    BA: "0.305",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.190",
    BA: "0.304",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.200",
    BA: "0.304",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.210",
    BA: "0.304",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.220",
    BA: "0.303",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.230",
    BA: "0.303",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.240",
    BA: "0.303",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.250",
    BA: "0.302",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.260",
    BA: "0.302",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.270",
    BA: "0.302",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.280",
    BA: "0.302",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.290",
    BA: "0.301",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.300",
    BA: "0.301",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.310",
    BA: "0.301",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.320",
    BA: "0.300",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.330",
    BA: "0.300",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.340",
    BA: "0.300",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.350",
    BA: "0.299",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.360",
    BA: "0.299",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.370",
    BA: "0.299",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.380",
    BA: "0.299",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.390",
    BA: "0.298",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.400",
    BA: "0.298",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.410",
    BA: "0.298",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.420",
    BA: "0.297",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.430",
    BA: "0.297",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.440",
    BA: "0.297",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.450",
    BA: "0.296",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.460",
    BA: "0.296",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.470",
    BA: "0.296",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.480",
    BA: "0.296",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.490",
    BA: "0.295",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.500",
    BA: "0.295",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.510",
    BA: "0.295",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.520",
    BA: "0.294",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.530",
    BA: "0.294",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.540",
    BA: "0.294",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.550",
    BA: "0.293",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.560",
    BA: "0.293",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.570",
    BA: "0.293",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.580",
    BA: "0.293",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.590",
    BA: "0.292",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.600",
    BA: "0.292",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.610",
    BA: "0.292",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.620",
    BA: "0.291",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.630",
    BA: "0.291",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.640",
    BA: "0.291",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.650",
    BA: "0.290",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.660",
    BA: "0.290",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.670",
    BA: "0.290",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.680",
    BA: "0.290",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.690",
    BA: "0.289",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.700",
    BA: "0.289",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.710",
    BA: "0.289",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.720",
    BA: "0.288",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.730",
    BA: "0.288",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.740",
    BA: "0.288",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.750",
    BA: "0.287",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.760",
    BA: "0.287",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.770",
    BA: "0.287",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.780",
    BA: "0.287",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.790",
    BA: "0.286",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.800",
    BA: "0.286",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.810",
    BA: "0.286",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.820",
    BA: "0.285",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.830",
    BA: "0.285",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.840",
    BA: "0.285",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.850",
    BA: "0.284",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.860",
    BA: "0.284",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.870",
    BA: "0.284",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.880",
    BA: "0.284",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.890",
    BA: "0.283",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.900",
    BA: "0.283",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.910",
    BA: "0.283",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.920",
    BA: "0.282",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.930",
    BA: "0.282",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.940",
    BA: "0.282",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.950",
    BA: "0.281",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.960",
    BA: "0.281",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.970",
    BA: "0.281",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.980",
    BA: "0.281",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "14.990",
    BA: "0.280",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.000",
    BA: "0.280",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.010",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.020",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.030",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.040",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.050",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.060",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.070",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.080",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.090",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.100",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.110",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.120",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.130",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.140",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.150",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.160",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.170",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.180",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.190",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.200",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.210",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.220",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.230",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.240",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.250",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.260",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.270",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.280",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.290",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.300",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.310",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.320",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.330",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.340",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.350",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.360",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.370",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.380",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.390",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.400",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.410",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.420",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.430",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.440",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.450",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.460",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.470",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.480",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.490",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.500",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.510",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.520",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.530",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.540",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.550",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.560",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.570",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.580",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.590",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.600",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.610",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.620",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.630",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.640",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.650",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.660",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.670",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.680",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.690",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.700",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.710",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.720",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.730",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.740",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.750",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.760",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.770",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.780",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.790",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.800",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.810",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.820",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.830",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.840",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.850",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.860",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.870",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.880",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.890",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.900",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.910",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.920",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.930",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.940",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.950",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.960",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.970",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.980",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "15.990",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
  {
    AZ: "16.000",
    BA: "0.000",
    BB: "0.000",
    BC: "0.000",
    BD: "0.000",
    BE: "0.000",
    BF: "0.000",
  },
];
