import PropTypes from "prop-types";
import React from "react";
import { NONE } from "../../config/generalConst";
import { LOAD_DATA } from "../../stores/actions/DataAction";
import { DATA_A } from "../../stores/actions/TypeActions";
import { useStateValue } from "../../stores/services/StateProvider";

export const loadData = async (file) => {
  const response = await fetch(file);
  let text = await response.text(); // Ottieni la risposta come testo
  text = text.replace(/NaN/g, "-1");
  const json = JSON.parse(text, (key, value) => {
    // Controlla se il valore è una stringa che rappresenta "NaN"
    if (value === NaN) {
      return 0;
    }
    return value;
  });


  let current_data = {
    left_video: {
      ...json.outputs_struct.left,
      visibility: json.outputs_struct?.visibility
        ? json.outputs_struct?.visibility
        : NONE,
    },
    right_video: json.outputs_struct.right,
    hands: json.outputs_struct.hands,
    config: json.parameters,
  };

  // let current_data = {
  //   left_video: jsonSample.outputs_struct.left,
  //   right_video: jsonSample.outputs_struct.right,
  //   hands: jsonSample.outputs_struct.hands,
  //   config: jsonSample.parameters,
  // };

  return current_data;
};

export const loadDataOld = async (file) => {
  const response = await fetch(file);

  const json = await response.json();

  let current_data = {
    left_video: json.outputs_struct.left,
    right_video: json.outputs_struct.right,
    config: json.parameters,
  };

  return current_data;
};

const DataLoader = ({ inputForm }) => {
  const [state, dispatch] = useStateValue();

  const handleFileInput = async (e) => {
    const file = e.target.files[0];

    if (!file) {
      return;
    }

    try {
      const fileURL = URL.createObjectURL(file);
      dispatch({ type: DATA_A, subtype: LOAD_DATA, action: loadData(fileURL) });
    } catch (error) {
      console.error("Error loading or parsing JSON: ", error);
    }
  };

  return inputForm && <input type="file" onChange={handleFileInput} />;
};

DataLoader.propTypes = {
  inputForm: PropTypes.bool,
};

DataLoader.defaultProps = {
  inputForm: false,
};

export default DataLoader;
