// Implementazione della funzione cerca_vert per replicare la funzionalità di CERCA.VERT di Excel.
function cerca_vert(durata_media_netta, data_dict) {
  let keys = Object.keys(data_dict).map(Number);
  keys.sort((a, b) => a - b);
  let closest_key = keys[0];
  for (let key of keys) {
    if (key <= durata_media_netta) {
      closest_key = key;
    } else {
      break;
    }
  }
  return data_dict[closest_key];
}

// Funzione per stampare le informazioni sui task in un formato tabellare
function print_tasks_info_formatted(tasks_info) {
  let header =
    "Task   | % su totale ore anno lavorate     | MIN. LAVORATI TURNO/PER COMPITO     | % su costante 480 MIN.";
  console.log(header);
  console.log("-".repeat(header.length));

  for (let [task, info] of Object.entries(tasks_info)) {
    console.log(
      `${task.padEnd(6)} | ${info["percentuale_originale"]}%`.padEnd(30) +
        ` | ${info["min_lavorati_per_compito"]}`.padEnd(35) +
        ` | ${info["percentuale_su_480_min"]}%`
    );
  }
}

// Implementazione della funzione calculate_intrinsic_checklist_value come fornito dall'utente.
function calculate_intrinsic_checklist_value(task) {
  let sum_dx =
    task["dx"]["frequenza"] +
    task["dx"]["forza"] +
    task["dx"]["totale_postura"] +
    task["dx"]["complementari"];
  let sum_sx =
    task["sx"]["frequenza"] +
    task["sx"]["forza"] +
    task["sx"]["totale_postura"] +
    task["sx"]["complementari"];
  let valore_checklist_intrinseco_dx = sum_dx * 1.33;
  let valore_checklist_intrinseco_sx = sum_sx * 1.33;
  return [valore_checklist_intrinseco_dx, valore_checklist_intrinseco_sx];
}

// Implementazione della funzione matr_somma_prodotto che emula la funzione MATR.SOMMA.PRODOTTO di Excel.
function matr_somma_prodotto(scores, percentuali) {
  return scores.reduce(
    (acc, score, index) => acc + score * percentuali[index],
    0
  );
}

// Definizione della funzione che calcola i punteggi e le percentuali per tutti i task dati in input
// e poi applica la funzione matr_somma_prodotto per ottenere il risultato finale.
function calculate_task_info(task, durata_media_netta) {
  // Assuming a constant work shift duration of 480 minutes
  const total_minutes = 480;

  // Calculate the minutes worked per task and the percentage of each task
  let min_lavorati_per_compito =
    task["percentuale_originale"] * durata_media_netta;

  // Calculate the percentage of each task based on a 480 minute work shift
  let percent_of_shift = (min_lavorati_per_compito / total_minutes) * 100;

  // Update the task dictionary with the new information
  task["min_lavorati_per_compito"] =
    Math.round(min_lavorati_per_compito * 10) / 10;
  task["minuti_turno"] = min_lavorati_per_compito;
  task["percentuale_su_480_min"] = Math.round(percent_of_shift * 10) / 10;

  return task;
}

// Implementazione della funzione multitask_analysis per l'analisi multitask
export function multitask_analysis(tasks, dizionario_durata_netta_multitask) {
  let scores_dx = [];
  let scores_sx = [];
  let percentuali = [];
  let intrinsic_checklist_values = [];

  for (let task of tasks) {
    let updated_task = calculate_task_info(task, task["durata_media_netta"]);
    let intrinsic_value = calculate_intrinsic_checklist_value(updated_task);

    intrinsic_checklist_values.push(intrinsic_value);

    let moltiplicatore_fittizia_giornata = cerca_vert(
      updated_task["durata_media_netta"],
      dizionario_durata_netta_multitask
    );

    let task_score_dx =
      (intrinsic_value[0] / 1.33) *
      moltiplicatore_fittizia_giornata *
      updated_task["moltiplicatore_recupero"];
    scores_dx.push(task_score_dx);

    let task_score_sx =
      (intrinsic_value[1] / 1.33) *
      moltiplicatore_fittizia_giornata *
      updated_task["moltiplicatore_recupero"];
    scores_sx.push(task_score_sx);

    percentuali.push(updated_task["percentuale_originale"]);
  }

  return [
    matr_somma_prodotto(scores_dx, percentuali),
    matr_somma_prodotto(scores_sx, percentuali),
    intrinsic_checklist_values,
  ];
}

// Funzione per stampare i risultati
function print_results(
  tasks,
  weighted_avg_dx,
  weighted_avg_sx,
  intrinsic_checklist_values
) {
  console.log("MULTITASK - RISULTATI ");
  console.log(`Media ponderata per il tempo DX: ${weighted_avg_dx}`);
  console.log(`Media ponderata per il tempo SX: ${weighted_avg_sx}\n`);

  intrinsic_checklist_values.forEach((values, index) => {
    console.log(`TASK${index + 1}:`);
    console.log(
      `valore Check-List INTRINSECO (durata 8 ore con mensa e 2 pause da 10 minuti) DX: ${values[0]}`
    );
    console.log(
      `valore Check-List INTRINSECO (durata 8 ore con mensa e 2 pause da 10 minuti) SX: ${values[1]}\n`
    );
  });
}

// Implementazione della funzione multitask_analysis_complex per analisi multitask complessa
export function multitask_analysis_complex(
  tasks,
  dizionario_durata_netta_multitask
) {
  let scores_complex_dx = [];
  let scores_complex_sx = [];
  let scores_dx = [];
  let scores_sx = [];
  let lista_minuti_turno_dx = [];
  let lista_minuti_turno_sx = [];
  let intrinsic_checklist_values = [];

  for (let task of tasks) {
    let updated_task = calculate_task_info(task, task["durata_media_netta"]);

    lista_minuti_turno_dx.push(updated_task["minuti_turno"]);
    lista_minuti_turno_sx.push(updated_task["minuti_turno"]);

    let intrinsic_value = calculate_intrinsic_checklist_value(updated_task);

    intrinsic_checklist_values.push(intrinsic_value);

    let moltiplicatore_fittizia_giornata = cerca_vert(
      updated_task["durata_media_netta"],
      dizionario_durata_netta_multitask
    );
    let moltiplicatore_durata_parziale = cerca_vert(
      updated_task["minuti_turno"],
      dizionario_durata_netta_multitask
    );

    let task_score_dx =
      (intrinsic_value[0] / 1.33) *
      moltiplicatore_fittizia_giornata *
      updated_task["moltiplicatore_recupero"];
    scores_dx.push(task_score_dx);

    let task_score_sx =
      (intrinsic_value[1] / 1.33) *
      moltiplicatore_fittizia_giornata *
      updated_task["moltiplicatore_recupero"];
    scores_sx.push(task_score_sx);

    let task_score_complex_dx =
      (task_score_dx / moltiplicatore_fittizia_giornata) *
      moltiplicatore_durata_parziale;
    scores_complex_dx.push(task_score_complex_dx);

    let task_score_complex_sx =
      (task_score_sx / moltiplicatore_fittizia_giornata) *
      moltiplicatore_durata_parziale;
    scores_complex_sx.push(task_score_complex_sx);
  }

  let max_score_complex_dx = Math.max(...scores_complex_dx);
  let max_score_complex_sx = Math.max(...scores_complex_sx);

  let indice_max_dx = scores_complex_dx.indexOf(max_score_complex_dx);
  let indice_max_sx = scores_complex_sx.indexOf(max_score_complex_sx);
  lista_minuti_turno_dx[indice_max_dx] = 0;
  lista_minuti_turno_sx[indice_max_sx] = 0;

  let total_minuti_dx = lista_minuti_turno_dx.reduce((a, b) => a + b, 0);
  let total_minuti_sx = lista_minuti_turno_sx.reduce((a, b) => a + b, 0);
  let lista_percentuali_turno_dx = lista_minuti_turno_dx.map((x) =>
    x === 0 ? 0 : x / total_minuti_dx
  );
  let lista_percentuali_turno_sx = lista_minuti_turno_sx.map((x) =>
    x === 0 ? 0 : x / total_minuti_sx
  );

  let indici_O_dx = scores_complex_dx.map((x, i) =>
    x === max_score_complex_dx ? 0 : scores_dx[i]
  );
  let indici_O_sx = scores_complex_sx.map((x, i) =>
    x === max_score_complex_sx ? 0 : scores_sx[i]
  );

  let L9_dx = matr_somma_prodotto(lista_percentuali_turno_dx, indici_O_dx);
  let L9_sx = matr_somma_prodotto(lista_percentuali_turno_sx, indici_O_sx);

  let I9_dx = Math.max(scores_dx) !== 0 ? L9_dx / Math.max(...scores_dx) : 0;
  let I9_sx = Math.max(scores_sx) !== 0 ? L9_sx / Math.max(...scores_sx) : 0;

  let output_dx, output_sx;
  if (tasks.length <= 1) {
    output_dx = Math.max(...scores_dx);
    output_sx = Math.max(...scores_sx);
  } else {
    output_dx =
      max_score_complex_dx +
      (Math.max(...scores_dx) - max_score_complex_dx) * I9_dx;
    output_sx =
      max_score_complex_sx +
      (Math.max(...scores_sx) - max_score_complex_sx) * I9_sx;
  }

  return [output_dx, output_sx];
}

let dizionario_durata_netta_multitask = {
  0: 0.007,
  1.87: 0.018,
  3.75: 0.05,
  7.5: 0.1,
  15: 0.2,
  30: 0.35,
  60: 0.5,
  121: 0.65,
  181: 0.75,
  241: 0.85,
  301: 0.925,
  361: 0.95,
  420: 1,
  481: 1.2,
  541: 1.5,
  601: 2,
  661: 2.8,
  721: 4,
};

export function testMultitask() {
  // Esempi di task in JavaScript
  let task1 = {
    percentuale_originale: 0.33,
    min_lavorati_per_compito: 0,
    percentuale_su_480_min: 0,
    minuti_turno: 0,
    moltiplicatore_recupero: 1.12,
    durata_media_netta: 420,
    n_ore_senza_recupero: 4,
    sx: {
      frequenza: 4.0,
      forza: 5.0,
      postura: { spalla: 5.0, gomito: 5.0, polso: 5.0, mano: 5.0 },
      stereotipia: 5.0,
      totale_postura: 10,
      complementari: 8,
    },
    dx: {
      frequenza: 4.0,
      forza: 5.0,
      postura: { spalla: 4.0, gomito: 5.0, polso: 5.0, mano: 6.0 },
      stereotipia: 7.0,
      totale_postura: 11,
      complementari: 8,
    },
  };

  let task2 = {
    percentuale_originale: 0.33,
    min_lavorati_per_compito: 0,
    percentuale_su_480_min: 0,
    minuti_turno: 0,
    moltiplicatore_recupero: 1.12,
    durata_media_netta: 420,
    n_ore_senza_recupero: 4,
    sx: {
      frequenza: 4.0,
      forza: 5.0,
      postura: { spalla: 5.0, gomito: 5.0, polso: 5.0, mano: 5.0 },
      stereotipia: 7.0,
      totale_postura: 12,
      complementari: 8,
    },
    dx: {
      frequenza: 4.0,
      forza: 5.0,
      postura: { spalla: 4.0, gomito: 5.0, polso: 5.0, mano: 6.0 },
      stereotipia: 7.0,
      totale_postura: 11,
      complementari: 8,
    },
  };

  let task3 = {
    percentuale_originale: 0.34,
    min_lavorati_per_compito: 0,
    percentuale_su_480_min: 0,
    minuti_turno: 0,
    moltiplicatore_recupero: 1.12,
    durata_media_netta: 420,
    n_ore_senza_recupero: 4,
    sx: {
      frequenza: 4.0,
      forza: 5.0,
      postura: { spalla: 5.0, gomito: 5.0, polso: 5.0, mano: 5.0 },
      stereotipia: 7.0,
      totale_postura: 12,
      complementari: 8,
    },
    dx: {
      frequenza: 4.0,
      forza: 5.0,
      postura: { spalla: 4.0, gomito: 5.0, polso: 5.0, mano: 6.0 },
      stereotipia: 7.0,
      totale_postura: 11,
      complementari: 8,
    },
  };

  // Esempio di tasks forniti dall'utente
  let tasks_input = [task1, task2, task3]; // Qui puoi aggiungere altri task alla lista

  // Utilizzo delle funzioni
  let [
    media_ponderata_tempo_dx,
    media_ponderata_tempo_sx,
    intrinsic_checklist_values,
  ] = multitask_analysis(tasks_input, dizionario_durata_netta_multitask);

  print_results(
    tasks_input,
    Math.round(media_ponderata_tempo_dx * 10) / 10,
    Math.round(media_ponderata_tempo_sx * 10) / 10,
    intrinsic_checklist_values
  );

  let multitask_complex = multitask_analysis_complex(
    tasks_input,
    dizionario_durata_netta_multitask
  );
}

export const multitask_global = (tasks_input) => {
  let [
    media_ponderata_tempo_dx,
    media_ponderata_tempo_sx,
    intrinsic_checklist_values,
  ] = multitask_analysis(tasks_input, dizionario_durata_netta_multitask);

  let multitask_complex = multitask_analysis_complex(
    tasks_input,
    dizionario_durata_netta_multitask
  );
  if (tasks_input.length > 0)
    return {
      mtUnder90: {
        dx: Math.round(media_ponderata_tempo_dx * 10) / 10,
        sx: Math.round(media_ponderata_tempo_sx * 10) / 10,
      },
      mtOver90: {
        dx: Math.round(multitask_complex[0] * 10) / 10,
        sx: Math.round(multitask_complex[1] * 10) / 10,
      },
    };
  else
    return {
      mtUnder90: { dx: 0, sx: 0 },
      mtOver90: { dx: 0, sx: 0 },
    };
};
