import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { InfoTabWrapper, Title } from "./InfoTab.styled";
import { DividerSmall } from "../AnalysisSelector/AnalysisSelector";
import { colors } from "../../config/style";
import Select from "react-select";
import { getOcraColor, mappingOcra } from "../../utils/Protocols/OCRA/OCRA";
import { isNumber } from "mathjs";
import { useTranslation } from "react-i18next";
import zIndex from "@mui/material/styles/zIndex";
import { getColorFromMapping } from "../../utils/Protocols/commonFunction";

export const Selector = ({
  options,
  width,
  height,
  value,
  visibility,
  noBorder,
  onChange,
  single,
  err,
  menuPosition,
}) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const customStyles = {
    control: (base, state) => ({
      ...base,
      fontFamily: "Atkinson Hyperlegible, sans-serif",
      fontSize: "0.82vw",
      width: width,
      height: height,
      visibility: visibility,
      minHeight: height,
      borderWidth: noBorder ? "0px" : "0.15vw",
      paddingLeft: "0.1vw",
      borderColor: err
        ? colors.negative
        : state.isFocused
        ? colors.main
        : colors.background,
      boxShadow: state.isFocused ? `0 0 0 0.03vw ${colors.main}` : "none",
      // display: "flex",
      // alignItems: "center", // This will vertically center the text
      // justifyContent: "space-between",
      // boxShadow: "none",
      "&:hover": {
        borderColor: state.isFocused ? colors.main : colors.main,
      },
    }),

    indicatorSeparator: () => ({
      display: "none", // This removes the vertical separator
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      color: colors.text, // This changes the color of the dropdown indicator (arrow)
      padding: "0.1vw",
      display: single ? "flex" : options?.length == 1 && "none",
      paddingRight: "0.5vw",
      "&:hover": {
        color: colors.text, // Color of dropdown indicator on hover
      },
    }),
    menu: (base) => ({
      ...base,
      fontFamily: "Atkinson Hyperlegible, sans-serif",
      fontSize: "0.82vw",
      zIndex: 99999,
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected
        ? colors.disabled
        : state.isFocused
        ? colors.highlight
        : null,
      color: colors.text,
      "&:hover": {
        backgroundColor: colors.disabled,
        color: colors.text,
      },
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 99999, // Assicurati che il portale del menu sia sopra altri elementi
    }),
  };

  const handleMenuOpen = () => {
    // Se ci sono più di un'opzione, permetti l'apertura del menu
    if (single ? options.length > 0 : options.length > 1) {
      setMenuIsOpen(true);
    }
  };

  const handleMenuClose = () => {
    setMenuIsOpen(false);
  };

  const optionsEx = options || [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];

  return (
    <Select
      menuPortalTarget={document.body}
      menuPosition="fixed"
      value={value}
      onMenuOpen={handleMenuOpen}
      onMenuClose={handleMenuClose}
      menuIsOpen={menuIsOpen}
      options={optionsEx}
      styles={customStyles}
      placeholder={"Seleziona un valore"}
      isSearchable={false}
      onChange={(x) => onChange(x)}
      menuPlacement={menuPosition ? menuPosition : "auto"}
    />
  );
};

export const InfoTabScroller = ({
  infoTab,
  style,
  key,
  bottomComponentHeight,
  setBottomComponentHeight,
}) => {
  const ref = useRef(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      if (ref.current) {
        const rect = ref.current.getBoundingClientRect();
        const windowHeight = window.innerHeight;
        const heightToBottom =
          windowHeight - rect.top - window.innerHeight * 0.02; // 5px dal fondo dello schermo
        setHeight(heightToBottom);
        if (setBottomComponentHeight) {
          setBottomComponentHeight(heightToBottom);
        }
      }
    };

    // Imposta l'altezza iniziale
    handleResize();

    // Aggiungi l'event listener per il ridimensionamento della finestra
    window.addEventListener("resize", handleResize);

    // Pulisci l'event listener quando il componente viene smontato
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      key={key}
      ref={ref}
      style={{
        height: height,
        overflow: "auto",
        background: "white",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        borderRadius: "0.3vw",
        justifyContent: "start",
        boxSizing: "border-box",
        boxShadow: "0px 0.2vw 0.8vw 0.2vw rgba(113, 109, 242, 0.1)",
        ...style,
      }}
    >
      {infoTab}
    </div>
  );
};

export const InfoTab = ({
  width,
  height,
  title,
  content,
  justifyContent,
  gap,
  bottom,
  justifyContentMain,
  embedded,
  style,
  fullWidth,
  titleButton,
  info,
  divider,
}) => {
  // Assicurati che title e content siano array
  const titles = Array.isArray(title) ? title : [title];
  const contents = Array.isArray(content) ? content : [content];

  return (
    <InfoTabWrapper
      style={{
        width: width,
        height: height,
        justifyContent: justifyContentMain,
        boxShadow: embedded && "0px 0vw 0vw 0vw rgba(113, 109, 242, 0.1)",
        borderBottom: "1vw solid white",
        fullWidth,
        ...style,
      }}
    >
      {titles.map((titleIn, index) => {
        // Determina se l'elemento corrente è l'ultimo
        const isLast = index === titles.length - 1;
        return (
          <React.Fragment key={index}>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "2.5vw",
                minHeight: "2.5vw",
                paddingRight: "0.5vw",
                paddingLeft: "1.25vw",
                boxSizing: "border-box",
              }}
            >
              <Title>{titleIn} </Title>
              <InfoButton
                ai={false}
                err={false}
                std={true}
                addStyle={{ paddingTop: "1vw", marginLeft: "-2.5vw" }}
                info={info && info[index]}
              />
              {titleButton ? titleButton : <></>}
            </div>
            {(!fullWidth || divider) && (
              <DividerSmall margin={"0vw 1.25vw 0vw 1.25vw"} />
            )}
            <div
              style={{
                width: "100%",
                marginBottom: isLast ? "0" : "0vw", // Imposta a 0 per l'ultimo elemento
                height: Array.isArray(title) ? "75%" : "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: justifyContent || "space-between",
                alignItems: "center",
                gap: gap,
                padding: !fullWidth && "1.25vw",
                boxSizing: "border-box",
              }}
            >
              {contents[index]}
            </div>
            {bottom && (
              <p
                style={{
                  color: colors.main,
                  fontFamily: "Atkinson Hyperlegible",
                  fontSize: "0.7vw",
                  fontWeight: "400",
                  boxSizing: "border-box",
                  margin: 0,
                  width: "100%",
                }}
              >
                {bottom}
              </p>
            )}
          </React.Fragment>
        );
      })}
    </InfoTabWrapper>
  );
};

export const Row = (props) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: props.justifyContent
          ? props.justifyContent
          : "space-between",
        alignItems: "center",
        width: props.width ? props.width : "100%",
        gap: "0.5vw",
        height: props.height && props.height,
      }}
    >
      {props.children}
    </div>
  );
};

export const BasicText = (props) => {
  return (
    <p
      style={{
        color: colors.text,
        fontFamily: "Atkinson Hyperlegible",
        fontSize: "0.9vw",
        fontWeight: "400",
        boxSizing: "border-box",
        margin: 0,
        // paddingRight: "2vw",
        ...props.style,
      }}
    >
      {props.children}
    </p>
  );
};

export const ScoreTab = ({
  scores,
  title,
  style,
  styleInternal,
  labels,
  mapping,
}) => {
  if (!mapping) mapping = mappingOcra["prec"];
  console.log("mappingmapping", mapping);
  console.log("scoresscoresscores", scores);
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        ...style,
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",

          boxSizing: "border-box",
          height: "2.0vw",
          // marginRight: "0.7vw",
          // paddingLeft: "0.6vw",
          // paddingRight: "0.2vw",
        }}
      >
        <p
          style={{
            color: colors.text,
            fontFamily: "Atkinson Hyperlegible",
            fontSize: "0.9vw",
            fontWeight: "700",

            boxSizing: "border-box",
            margin: 0,
            width: "50%",
            paddingLeft: "0.8vw",
          }}
        >
          {title}
        </p>
        <p
          style={{
            color: colors.disabledText,
            fontFamily: "Atkinson Hyperlegible",
            fontSize: "0.9vw",
            fontWeight: "400",
            boxSizing: "border-box",
            margin: 0,
            width: "25%",
            textAlign: "center",
          }}
        >
          {labels ? labels[0] : "DX"}
        </p>
        <p
          style={{
            color: colors.disabledText,
            fontFamily: "Atkinson Hyperlegible",
            fontSize: "0.9vw",
            fontWeight: "400",
            boxSizing: "border-box",
            margin: 0,
            width: "25%",
            textAlign: "center",
          }}
        >
          {labels ? labels[1] : "SX"}
        </p>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          border: "0.15vw solid " + colors.background,
          borderRadius: "0.7vw",
          // paddingLeft: "0.5vw",
          // paddingRight: "0.5vw",
          // boxSizing: "border-box",
          ...styleInternal,
        }}
      >
        {scores.map((score, id) => {
          return (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                // padding: "0.2vw",
                boxSizing: "border-box",
                height: "2.9vw",
                borderBottom:
                  id == scores.length - 1
                    ? "0vw solid red"
                    : "0.15vw solid " + colors.background,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "start",
                  width: "50%",
                  boxSizing: "border-box",
                  paddingLeft: "0.5vw",
                }}
              >
                {score.info && (
                  <InfoButton
                    addStyle={{ margin: "0px" }}
                    ai={false}
                    err={false}
                    std={true}
                    info={score.info}
                  />
                )}
                <p
                  style={{
                    color: colors.text,
                    fontFamily: "Atkinson Hyperlegible",
                    fontSize: "0.9vw",
                    fontWeight: "400",
                    boxSizing: "border-box",
                    margin: 0,
                    marginLeft: "0.3vw",
                  }}
                >
                  {score.label}
                </p>
              </div>
              {score.score ? (
                score.score.map((sc, id) => {
                  return (
                    <p
                      style={{
                        color: getColorFromMapping(sc, mapping)[0],
                        fontFamily: "Atkinson Hyperlegible",
                        fontSize: "0.9vw",
                        fontWeight: "700",
                        boxSizing: "border-box",
                        margin: 0,
                        width: "25%",
                        textAlign: "center",
                      }}
                    >
                      {sc >= 999
                        ? "CRITICO"
                        : sc >= 0
                        ? Math.round(sc * 100) / 100
                        : "-"}
                    </p>
                  );
                })
              ) : (
                <>
                  <p
                    style={{
                      color: getColorFromMapping(
                        score.dx,
                        mapping
                      )[0],
                      fontFamily: "Atkinson Hyperlegible",
                      fontSize: "0.9vw",
                      fontWeight: "700",
                      boxSizing: "border-box",
                      margin: 0,
                      width: "25%",
                      textAlign: "center",
                    }}
                  >
                    {score.dx >= 0 ? Math.round(score.dx * 100) / 100 : "-"}
                  </p>
                  <p
                    style={{
                      color: getColorFromMapping(
                        score.sx,
                        mapping
                      )[0],
                      fontFamily: "Atkinson Hyperlegible",
                      fontSize: "0.9vw",
                      fontWeight: "700",
                      boxSizing: "border-box",
                      margin: 0,
                      width: "25%",
                      textAlign: "center",
                    }}
                  >
                    {score.sx >= 0 ? Math.round(score.sx * 100) / 100 : "-"}
                  </p>
                </>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const InfoBox = ({
  value,
  unit,
  ai,
  info,
  err,
  width,
  marginRight,
  decimals,
  marginLeft,
}) => {
  if (!decimals) {
    decimals = 1;
  }
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          gap: "0.3vw",
          marginRight: marginRight,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: width ? width : "6.25vw",
            minWidth: width ? width : "6.25vw",
            height: "2.5vw",
            background: colors.disabled,
            border:
              "0.14vw solid " + (err ? colors.negative : colors.background),
            borderRadius: "0.31vw",
            padding: "0.55vw 0.55vw 0.55vw 0.9vw",
            boxSizing: "border-box",
          }}
        >
          <p
            style={{
              color: colors.text,
              fontFamily: "Atkinson Hyperlegible",
              fontSize: "0.9vw",
              fontWeight: "400",
              boxSizing: "border-box",
              marginLeft: marginLeft
                ? marginLeft
                : (!isNumber(value) || value == -1) && "1vw",
            }}
          >
            {isNumber(value)
              ? value == -1
                ? "-"
                : Math.round(value * 10 ** decimals) / 10 ** decimals +
                  " " +
                  unit
              : value == undefined
              ? "-"
              : value}
          </p>
          <img
            src={
              ai
                ? process.env.REACT_APP_RESOURCES_BUCKET + "Magic.png"
                : err
                ? process.env.REACT_APP_RESOURCES_BUCKET + "CalculatorNeg.png"
                : process.env.REACT_APP_RESOURCES_BUCKET + "Calculator.png"
            }
            style={{
              width: "1.4vw",
              position: "relative",
            }}
          />
        </div>
        {info && <InfoButton ai={ai} err={err} info={info} />}
      </div>
    </>
  );
};

export const InfoButton = ({ info, std, ai, err, war, addStyle, trigger }) => {
  const [hover, setHover] = useState(false);
  const menuRef = useRef(null); // Ref per il menu contestuale
  const btnRef = useRef(null);

  // Funzione per calcolare e impostare la posizione del menu
  const updateMenuPosition = () => {
    if (menuRef.current && btnRef.current) {
      const menu = menuRef.current;
      const btnRect = btnRef.current.getBoundingClientRect();
      const menuRect = menu.getBoundingClientRect();
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;

      // Posiziona il menu inizialmente al di sotto e centrato rispetto al pulsante
      let newTop = window.scrollY + btnRect.bottom;
      let newLeft = window.scrollX + btnRect.left + btnRect.width / 2;

      // Ajusta la posizione orizzontale se il menu esce dal lato destro dello schermo
      const rightOverflow = newLeft + menuRect.width - windowWidth;
      if (rightOverflow > -20) {
        // 50 pixel di margine dal bordo destro
        newLeft = btnRect.left + btnRect.width - menuRect.width / 2;
      }
      const leftOverflow = newLeft - menuRect.width;
      // Ajusta la posizione orizzontale se il menu esce dal lato sinistro dello schermo
      if (leftOverflow < 0) {
        newLeft = btnRect.left + 3 + menuRect.width / 2; // 50 pixel di margine dal bordo sinistro
      }

      // Ajusta la posizione verticale se il menu esce dal lato inferiore dello schermo
      if (newTop + menu.offsetHeight > windowHeight) {
        newTop = btnRect.top + window.scrollY - menu.offsetHeight;
      }

      // Ajusta la posizione verticale se il menu esce dal lato superiore dello schermo
      if (newTop < 0) {
        newTop = window.scrollY + btnRect.bottom;
      }

      // Applica le nuove posizioni calcolate
      menu.style.top = `${newTop}px`;
      menu.style.left = `${newLeft}px`;
    }
  };

  // Aggiorna la posizione del menu quando 'hover' cambia
  useEffect(() => {
    const menuNode = menuRef.current;
    if (hover && menuNode) {
      document.body.appendChild(menuNode); // Aggiungi il menu al body
      updateMenuPosition();
    }
    return () => {
      if (menuNode && menuNode.parentNode) {
        menuNode?.parentNode?.removeChild(menuNode);
      }
    };
  }, [hover]);

  const getInfo = () => {
    if (err)
      return [
        colors.negative,
        process.env.REACT_APP_RESOURCES_BUCKET + "Questionmarkerr.png",
        "ERRORE",
      ];
    if (std)
      if (hover)
        return [
          colors.main,
          process.env.REACT_APP_RESOURCES_BUCKET + "QuestionmarkMain.png",
          "TIP",
        ];
      else
        return [
          colors.main,
          process.env.REACT_APP_RESOURCES_BUCKET + "Questionmark.png",
          "TIP",
        ];
    if (war)
      if (trigger)
        return [
          colors.riskUnsureBase,
          process.env.REACT_APP_RESOURCES_BUCKET + "warningTriggered.png",
          war,
        ];
      else if (hover)
        return [
          colors.main,
          process.env.REACT_APP_RESOURCES_BUCKET + "warningHW.png",
          war,
        ];
      else
        return [
          colors.main,
          process.env.REACT_APP_RESOURCES_BUCKET + "warningDef.png",
          war,
        ];
    if (ai)
      return [
        colors.tertiary,
        process.env.REACT_APP_RESOURCES_BUCKET + "Questionmarkai.png",
        "AI ANALYSIS",
      ];
    else
      return [
        colors.calculator,
        process.env.REACT_APP_RESOURCES_BUCKET + "Questionmarkhw.png",
        "FORMULA",
      ];
  };

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      onMouseEnter={() => info && setHover(true)}
    >
      <img
        src={getInfo()[1]}
        ref={btnRef}
        style={{
          width: "1.4vw",
          opacity: hover ? 0.7 : 1,
          cursor: "pointer",
          visibility: !info && "hidden",
          margin: "-0.2vw",
          ...addStyle,
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      />
      {
        <div
          id={"infoBoxChild"}
          ref={menuRef}
          style={{
            position: "absolute",
            top: "120%", // you might need to adjust this
            left: "50%",
            transform: "translateX(-50%)",
            maxWidth: "20vw",
            minWidth: "12vw",
            background: getInfo()[0],
            display: !hover ? "none" : "",
            borderRadius: "0.3vw",
            padding: "0.5vw",
            textAlign: "start",
            zIndex: "9999",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            color: "white",
            fontFamily: "Atkinson Hyperlegible",
            fontSize: "0.9vw",
            fontWeight: "400",
            boxSizing: "border-box",
            margin: 0,
          }}
        >
          <p
            style={{
              color: "white",
              fontFamily: "Atkinson Hyperlegible",
              fontSize: "0.9vw",
              fontWeight: "700",
              boxSizing: "border-box",
              margin: 0,
              marginBottom: "0.2vw",
            }}
          >
            {getInfo()[2]}
          </p>
          {info}
        </div>
      }
    </div>
  );
};

export const TextBox = ({
  value,
  onChange,
  onBlur,
  width,
  locked,
  marginRight,
  style,
  unit,
  unitMargin,
  info,
  infoBox,
  err,
  ai,
  edited,
  visibility,
  placeholder,
  textAlign,
  backgroundFixed,
  borderColorFixed,
  checkFunction,
}) => {
  // const [value, setValue] = useState(value);
  const [isFocused, setIsFocused] = useState(false);
  const [tempValue, setTempValue] = useState(onBlur && value);

  const inputRef = useRef();

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = (event) => {
    if (onBlur) {
      if (checkFunction(tempValue)) {
        setTempValue(checkFunction(tempValue));
        onBlur(checkFunction(tempValue));
      } else {
        setTempValue(checkFunction(value));
      }
    }

    setIsFocused(false);
  };

  const handleChange = (event) => {
    if (onBlur) {
      setTempValue(event.target.value);
    } else {
      onChange(event.target.value);
    }
  };

  // Determina il colore del bordo
  const borderColor = () => {
    if (checkFunction && !checkFunction(tempValue)) return colors.negative;
    if (borderColorFixed) return borderColorFixed;
    if (isFocused) return colors.main; // Blu quando è in focus
    if (err) return colors.negative; // Rosso se il valore è vuoto
    return colors.background; // Colore di default se non in focus e non vuoto
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        gap: "0.3vw",
        marginRight: marginRight,
        visibility: visibility,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: width ? "start" : "center",
          width: width ? width : "6.25vw",
          height: "2.5vw",
          background: backgroundFixed
            ? backgroundFixed
            : locked
            ? colors.disabled
            : "white",
          border: `0.14vw solid ${borderColor()}`,
          borderRadius: "0.31vw",
          padding: "0",
          boxSizing: "border-box",
          minWidth: width ? width : "6.25vw",
          marginRight: marginRight,
          ...style,
        }}
        onClick={() => {
          inputRef.current.focus();
        }}
      >
        <input
          ref={inputRef}
          type="text"
          value={onBlur ? tempValue : value}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          disabled={locked}
          placeholder={placeholder ? placeholder : ""}
          style={{
            color: colors.text,
            fontFamily: "Atkinson Hyperlegible",
            fontSize: "0.82vw",
            fontWeight: "400",
            width: unit ? "60%" : "95%",
            height: "100%",
            border: "none",
            outline: "none",
            textAlign: textAlign
              ? textAlign
              : width && unit
              ? "end"
              : unit
              ? "end"
              : width
              ? "start"
              : "center",
            paddingLeft: textAlign
              ? "0.7vw"
              : unit && width
              ? "0.2vw"
              : width
              ? "0.7vw"
              : "0vw",
            boxSizing: "border-box",
            backgroundColor: "transparent",
          }}
        />
        {unit && (
          <span
            style={{
              marginLeft: unitMargin,
              color: colors.text,
              fontFamily: "Atkinson Hyperlegible",
              fontSize: "0.82vw",
              fontWeight: "400",
              width: "50%",
              textAlign: "start",
            }}
          >
            {unit}
          </span>
        )}
        {(ai || edited) && (
          <img
            src={
              ai
                ? process.env.REACT_APP_RESOURCES_BUCKET + "Magic.png"
                : process.env.REACT_APP_RESOURCES_BUCKET + "Edit.png"
            }
            style={{
              width: "1.4vw",
              position: "relative",
              marginRight: "0.2vw",
            }}
          />
        )}
      </div>
      {infoBox && <InfoButton ai={false} err={false} info={info} />}
    </div>
  );
};

export const CheckBox = ({
  value,
  onClick,
  label,
  info,
  infoBox,
  err,
  width,
  red,
}) => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        gap: "0.3vw",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyItems: width ? "center" : "start",
          minWidth: width ? width : "6.25vw",
          gap: "0.5vw",
          cursor: "pointer",
        }}
      >
        <img
          onClick={() => onClick()}
          src={
            value
              ? red
                ? process.env.REACT_APP_RESOURCES_BUCKET + "TickRed.png"
                : process.env.REACT_APP_RESOURCES_BUCKET + "checkboxhw.png"
              : err
              ? process.env.REACT_APP_RESOURCES_BUCKET + "CheckboxErr.png"
              : process.env.REACT_APP_RESOURCES_BUCKET + "checkbox.png"
          }
          style={{
            width: "1.4vw",
            height: "1.4vw",
            cursor: "pointer",
            filter:
              value && "drop-shadow(0px 0.4vw 0.8vw rgba(113, 109, 242, 0.30))",
          }}
        />
        {label == "" ? (
          <></>
        ) : (
          <p
            style={{
              color: colors.text,
              fontFamily: "Atkinson Hyperlegible",
              fontSize: "0.9vw",
              fontWeight: "400",
              boxSizing: "border-box",
            }}
          >
            {label ? label : t("present")}
          </p>
        )}
      </div>
      {infoBox && <InfoButton ai={false} err={false} info={info} />}
    </div>
  );
};

export const LegendRow = ({ value, img }) => {
  return (
    <Row justifyContent={"start"}>
      <img
        src={process.env.REACT_APP_RESOURCES_BUCKET + "" + img}
        style={{
          width: "2.8vw",
        }}
      />
      <BasicText>{value}</BasicText>
    </Row>
  );
};

export const InfoTabScore = ({ analysis, ocraRes }) => {
  const { t } = useTranslation();
  let wrongTime = 0;
  let percCicle = 0;
  let ocraScore = 0;
  let frequency = 0;
  let label = t("postIncong");

  if (analysis.id_local == "rightShoulder") {
    wrongTime = ocraRes.input.secondi_spalla_incongrua_dx;
    percCicle = ocraRes.output.percentuali_incongrue.spalla_dx;
    ocraScore = ocraRes.output.rischi_totali.spalla_dx;
  } else if (analysis.id_local == "leftShoulder") {
    wrongTime = ocraRes.input.secondi_spalla_incongrua_sx;
    percCicle = ocraRes.output.percentuali_incongrue.spalla_sx;
    ocraScore = ocraRes.output.rischi_totali.spalla_sx;
  } else if (analysis.id_local == "rightElbow") {
    wrongTime = ocraRes.input.secondi_gomito_incongrua_dx;
    percCicle = ocraRes.output.percentuali_incongrue.gomito_dx;
    ocraScore = ocraRes.output.rischi_totali.gomito_dx;
  } else if (analysis.id_local == "leftElbow") {
    wrongTime = ocraRes.input.secondi_gomito_incongrua_sx;
    percCicle = ocraRes.output.percentuali_incongrue.gomito_sx;
    ocraScore = ocraRes.output.rischi_totali.gomito_sx;
  } else if (analysis.id_local == "rightWrist") {
    wrongTime = ocraRes.input.secondi_polso_incongrua_dx;
    percCicle = ocraRes.output.percentuali_incongrue.polso_dx;
    ocraScore = ocraRes.output.rischi_totali.polso_dx;
  } else if (analysis.id_local == "leftWrist") {
    wrongTime = ocraRes.input.secondi_polso_incongrua_sx;
    percCicle = ocraRes.output.percentuali_incongrue.polso_sx;
    ocraScore = ocraRes.output.rischi_totali.polso_sx;
  } else if (analysis.id_local == "rightHand") {
    wrongTime = ocraRes.input.secondi_mano_incongrua_dx;
    percCicle = ocraRes.output.percentuali_incongrue.mano_dx;
    ocraScore = ocraRes.output.rischi_totali.mano_dx;
  } else if (analysis.id_local == "leftHand") {
    wrongTime = ocraRes.input.secondi_mano_incongrua_sx;
    percCicle = ocraRes.output.percentuali_incongrue.mano_sx;
    ocraScore = ocraRes.output.rischi_totali.mano_sx;
  } else if (analysis.id_local == "dynamicRight") {
    wrongTime = ocraRes.input.numero_azioni_dinamiche_dx;
    frequency = ocraRes.output.freq_azioni_dinamiche_dx;
    ocraScore = ocraRes.output.rischio_AT_dx;
    label = "Numero di azioni";
  } else if (analysis.id_local == "dynamicLeft") {
    wrongTime = ocraRes.input.numero_azioni_dinamiche_sx;
    frequency = ocraRes.output.freq_azioni_dinamiche_sx;
    ocraScore = ocraRes.output.rischio_AT_sx;
    label = "Numero di azioni";
  }

  return (
    <InfoTab
      width={"17.8vw"}
      height={"19.5vw"}
      title={t("SCORE")}
      justifyContentMain={"start"}
      justifyContent={"start"}
      gap={"1vw"}
      content={
        <>
          <Row>
            <BasicText>{t("cycleTime")}</BasicText>
            <InfoBox
              value={
                ocraRes.output.tempo_ciclo_posture_forza >= 0
                  ? ocraRes.output.tempo_ciclo_posture_forza
                  : "-"
              }
              unit={"s"}
              ai={false}
            />
          </Row>
          <Row>
            <BasicText>{label}</BasicText>
            <InfoBox value={wrongTime} unit={analysis.unit} ai={true} />
          </Row>
          {analysis.unit ? (
            <Row>
              <BasicText>{t("cycleTimePerc")}</BasicText>
              <InfoBox
                value={percCicle >= 0 ? percCicle : "-"}
                unit={"%"}
                ai={false}
              />
            </Row>
          ) : (
            <Row>
              <BasicText>{t("frequency")}</BasicText>
              <InfoBox
                value={frequency >= 0 ? frequency : "-"}
                unit={"/m"}
                ai={false}
              />
            </Row>
          )}
          <Row>
            <BasicText>{t("ocraScore")}</BasicText>
            <InfoBox
              value={
                ocraRes.output.tempo_ciclo_posture_forza >= 0 ? ocraScore : "-"
              }
              unit={""}
              ai={false}
            />
          </Row>
        </>
      }
    />
  );
};

export const InfoTabLegend = ({ legend }) => {
  const { t } = useTranslation();
  return (
    <InfoTab
      width={"17.8vw"}
      height={"19.5vw"}
      title={t("GRAPHLEGEND")}
      justifyContentMain={"start"}
      justifyContent={"start"}
      gap={"1vw"}
      content={[
        legend.map((x) => {
          return <LegendRow value={x.label} img={x.img} />;
        }),
      ]}
    />
  );
};

export const StaticTree = ({ img, text, style, textStyle }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        marginTop: "0.2vw",
        ...style,
      }}
    >
      <img
        src={img}
        style={{
          width: "1.8vw",
          position: "relative",
        }}
      />
      <p
        style={{
          color: colors.text,
          fontFamily: "Atkinson Hyperlegible",
          fontSize: "0.9vw",
          fontWeight: "700",
          width: "100%",
          marginTop: "0vw",
          marginBottom: "0vw",
          marginLeft: "0.5vw",
          boxSizing: "border-box",
          ...textStyle,
        }}
      >
        {text}
      </p>
    </div>
  );
};

export const ButtonTree = ({
  img,
  text,
  style,
  textStyle,
  toggle,
  onClick,
  complete,
  imgH,
  sx,
  dx,
  map,
  fullWidth,
  icon,
  disabled,
}) => {
  const [hover, setHover] = useState(0);

  function getStatus() {
    if (disabled)
      return process.env.REACT_APP_RESOURCES_BUCKET + "status_neutral.png";
    if (toggle)
      return process.env.REACT_APP_RESOURCES_BUCKET + "status_toggle.png";
    if (complete)
      return process.env.REACT_APP_RESOURCES_BUCKET + "status_complete.png";
    else return process.env.REACT_APP_RESOURCES_BUCKET + "status_neutral.png";
  }

  function getColor() {
    if (disabled) return colors.disabledText;
    if (hover || toggle) return colors.main;
    else return colors.text;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        marginTop: "0.5vw",
        width: fullWidth ? "22vw" : "98%",
        height: "1.8vw",
        cursor: !toggle && hover && disabled != true && "pointer",
        ...style,
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={disabled ? () => {} : () => onClick()}
    >
      <img
        src={getStatus()}
        style={{
          width: "1.4vw",
          position: "relative",
          marginRight: "0.5vw",
        }}
      />

      {icon && icon(getColor(), "1.975vw")}
      <p
        style={{
          color: getColor(),
          fontFamily: "Atkinson Hyperlegible",
          fontSize: "0.95vw",
          fontWeight: toggle ? "700" : "400",
          marginTop: "0vw",
          marginBottom: "0vw",
          marginLeft: "0.3vw",
          boxSizing: "border-box",
          width: fullWidth ? "100%" : "55%",
          whiteSpace: "nowrap",
          overflow: "hidden", // Nasconde il testo che supera la larghezza del contenitore
          textOverflow: "ellipsis",
          ...textStyle,
        }}
      >
        {text}
      </p>
      <p
        style={{
          color: getOcraColor(dx, map)[0],
          fontFamily: "Atkinson Hyperlegible",
          fontSize: "0.95vw",
          fontWeight: "700",
          width: "100%",
          marginTop: "0vw",
          marginBottom: "0vw",
          marginLeft: "0.5vw",
          width: "15%",
          textAlign: "center",
          textAlign: "center",
          boxSizing: "border-box",
          ...textStyle,
        }}
      >
        {dx}
      </p>
      <p
        style={{
          color: getOcraColor(sx, map)[0],
          fontFamily: "Atkinson Hyperlegible",
          fontSize: "0.9vw",
          fontWeight: "700",
          marginTop: "0vw",
          marginBottom: "0vw",
          width: "15%",
          textAlign: "center",
          boxSizing: "border-box",
          ...textStyle,
        }}
      >
        {sx}
      </p>
    </div>
  );
};

InfoTab.propTypes = {
  dataTestId: PropTypes.string, // Definisci il tipo di prop "dataTestId"
};

InfoTab.defaultProps = {
  dataTestId: "default-data-testid", // Imposta un valore predefinito per "dataTestId"
};

export default InfoTab;
