const URL = process.env.REACT_APP_API_URL;

/*****
 ***** GET
 *****/

async function getResults(result, jwt = null) {
  // console.log("[API] getResults");
  try {
    const requestObject = {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        //"User-Agent": "PostmanRuntime/7.29.2", // TODO: change
        Authorization: "Bearer " + jwt,
      }),
    };

    const response = await fetch(URL + "results/" + result, requestObject);

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

async function getInspections(jwt = null) {
  // console.log("[API] getInspections");
  try {
    const requestObject = {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        // "User-Agent": "PostmanRuntime/7.29.2", // TODO: change
        Authorization: "Bearer " + jwt,
      }),
    };

    const response = await fetch(URL + "inspections/all", requestObject);

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

async function getInspection(id, jwt = null) {
  // console.log("[API] getInspection");
  try {
    const requestObject = {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        // "User-Agent": "PostmanRuntime/7.29.2", // TODO: change
        Authorization: "Bearer " + jwt,
      }),
    };

    const response = await fetch(URL + "inspections/" + id, requestObject);

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

async function getCompanies(jwt = null) {
  // console.log("[API] getCompanies");
  try {
    const requestObject = {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        // "User-Agent": "PostmanRuntime/7.29.2", // TODO: change
        Authorization: "Bearer " + jwt,
      }),
    };

    const response = await fetch(URL + "companies/all", requestObject);

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

async function getLocation(id, jwt = null) {
  // console.log("[API] getLocation");
  try {
    const requestObject = {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        // "User-Agent": "PostmanRuntime/7.29.2", // TODO: change
        Authorization: "Bearer " + jwt,
      }),
    };

    const response = await fetch(URL + "locations/" + id, requestObject);

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

async function getLocations(jwt = null) {
  // console.log("[API] getLocations");
  try {
    const requestObject = {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        // "User-Agent": "PostmanRuntime/7.29.2", // TODO: change
        Authorization: "Bearer " + jwt,
      }),
    };

    const response = await fetch(URL + "locations/all", requestObject);

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

async function getVideoByStatus(status, jwt = null) {
  // console.log("[API] getLocations");
  try {
    const requestObject = {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        // "User-Agent": "PostmanRuntime/7.29.2", // TODO: change
        Authorization: "Bearer " + jwt,
      }),
      body: JSON.stringify({ status: status }),
    };

    const response = await fetch(
      URL + "inspections/data/status",
      requestObject
    );

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

async function getLocationsByCompany(company, jwt = null) {
  try {
    const requestObject = {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        // "User-Agent": "PostmanRuntime/7.29.2", // TODO: change
        Authorization: "Bearer " + jwt,
      }),
    };

    const response = await fetch(
      URL + "companies/" + company + "/locations/all",
      requestObject
    );

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

async function getDepartment(location, department, jwt = null) {
  try {
    const requestObject = {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        // "User-Agent": "PostmanRuntime/7.29.2", // TODO: change
        Authorization: "Bearer " + jwt,
      }),
    };

    const response = await fetch(
      URL + "locations/" + location + "/departments/" + department,
      requestObject
    );

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

async function getTask(task, jwt = null) {
  try {
    const requestObject = {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        // "User-Agent": "PostmanRuntime/7.29.2", // TODO: change
        Authorization: "Bearer " + jwt,
      }),
    };

    const response = await fetch(URL + "tasks/" + task, requestObject);

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

async function getTaskPerStation(
  location,
  department,
  line,
  station,
  jwt = null
) {
  try {
    const requestObject = {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        // "User-Agent": "PostmanRuntime/7.29.2", // TODO: change
        Authorization: "Bearer " + jwt,
      }),
    };

    const response = await fetch(
      URL +
        "locations/" +
        location +
        "/departments/" +
        department +
        "/lines/" +
        line +
        "/stations/" +
        station +
        "/tasks",
      requestObject
    );

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

/*****
 ***** POST
 *****/

async function newCompany(company, jwt = null) {
  try {
    const requestObject = {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        // "User-Agent": "PostmanRuntime/7.29.2", // TODO: change
        Authorization: "Bearer " + jwt,
      }),
      body: JSON.stringify(company),
    };

    const response = await fetch(URL + "companies/add", requestObject);

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

async function newLocation(company, location, jwt = null) {
  try {
    const requestObject = {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        // "User-Agent": "PostmanRuntime/7.29.2", // TODO: change
        Authorization: "Bearer " + jwt,
      }),
      body: JSON.stringify(location),
    };

    const response = await fetch(
      URL + "companies/" + company + "/locations/add",
      requestObject
    );

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

async function newDepartment(location, department, jwt = null) {
  try {
    const requestObject = {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        // "User-Agent": "PostmanRuntime/7.29.2", // TODO: change
        Authorization: "Bearer " + jwt,
      }),
      body: JSON.stringify(department),
    };

    const response = await fetch(
      URL + "locations/" + location + "/departments/add",
      requestObject
    );

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

async function newWorkshift(location, department, workshift, jwt = null) {
  try {
    const requestObject = {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        // "User-Agent": "PostmanRuntime/7.29.2", // TODO: change
        Authorization: "Bearer " + jwt,
      }),
      body: JSON.stringify(workshift),
    };

    const response = await fetch(
      URL +
        "locations/" +
        location +
        "/departments/" +
        department +
        "/turns/add",
      requestObject
    );

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

async function editWorkshift(wsId, workshift, jwt = null) {
  try {
    const requestObject = {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        // "User-Agent": "PostmanRuntime/7.29.2", // TODO: change
        Authorization: "Bearer " + jwt,
      }),
      body: JSON.stringify(workshift),
    };

    const response = await fetch(
      URL + "workshifts/" + wsId + "/update",
      requestObject
    );

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

async function deleteWorkshift(wsId, jwt = null) {
  try {
    const requestObject = {
      method: "DELETE",
      headers: new Headers({
        "Content-Type": "application/json",
        // "User-Agent": "PostmanRuntime/7.29.2", // TODO: change
        Authorization: "Bearer " + jwt,
      }),
      // body: JSON.stringify(workshift),
    };

    const response = await fetch(URL + "workshifts/" + wsId, requestObject);

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

async function newJob(location, department, job, jwt = null) {
  try {
    const requestObject = {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        // "User-Agent": "PostmanRuntime/7.29.2", // TODO: change
        Authorization: "Bearer " + jwt,
      }),
      body: JSON.stringify(job),
    };

    const response = await fetch(
      URL +
        "locations/" +
        location +
        "/departments/" +
        department +
        "/jobs/add",
      requestObject
    );

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

async function updateJob(location, department, job, id, jwt = null) {
  try {
    const requestObject = {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        // "User-Agent": "PostmanRuntime/7.29.2", // TODO: change
        Authorization: "Bearer " + jwt,
      }),
      body: JSON.stringify(job),
    };

    const response = await fetch(
      URL +
        "locations/" +
        location +
        "/departments/" +
        department +
        "/jobs/" +
        id +
        "/update",
      requestObject
    );

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

async function deleteJob(location, department, id, jwt = null) {
  try {
    const requestObject = {
      method: "DELETE",
      headers: new Headers({
        "Content-Type": "application/json",
        // "User-Agent": "PostmanRuntime/7.29.2", // TODO: change
        Authorization: "Bearer " + jwt,
      }),
    };

    const response = await fetch(
      URL +
        "locations/" +
        location +
        "/departments/" +
        department +
        "/jobs/" +
        id,
      requestObject
    );

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

async function newJobTask(location, department, job, jobTask, jwt = null) {
  try {
    const requestObject = {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        // "User-Agent": "PostmanRuntime/7.29.2", // TODO: change
        Authorization: "Bearer " + jwt,
      }),
      body: JSON.stringify(jobTask),
    };

    const response = await fetch(
      URL +
        "locations/" +
        location +
        "/departments/" +
        department +
        "/jobs/" +
        job +
        "/tasks/add",
      requestObject
    );

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

async function deleteJobTask(location, department, job, jobTask, jwt = null) {
  try {
    const requestObject = {
      method: "DELETE",
      headers: new Headers({
        "Content-Type": "application/json",
        // "User-Agent": "PostmanRuntime/7.29.2", // TODO: change
        Authorization: "Bearer " + jwt,
      }),
    };

    const response = await fetch(
      URL +
        "locations/" +
        location +
        "/departments/" +
        department +
        "/jobs/" +
        job +
        "/jobtasks/" +
        jobTask,
      requestObject
    );

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

async function updateJobTask(
  location,
  department,
  job,
  jobTask,
  durata,
  jwt = null
) {
  try {
    const requestObject = {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        //"User-Agent": "PostmanRuntime/7.29.2", // TODO: change
        Authorization: "Bearer " + jwt,
      }),
      body: JSON.stringify(durata),
    };

    const response = await fetch(
      URL +
        "locations/" +
        location +
        "/departments/" +
        department +
        "/jobs/" +
        job +
        "/jobtasks/" +
        jobTask +
        "/update",
      requestObject
    );

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

async function newLine(location, department, line, jwt = null) {
  try {
    const requestObject = {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        //"User-Agent": "PostmanRuntime/7.29.2", // TODO: change
        Authorization: "Bearer " + jwt,
      }),
      body: JSON.stringify(line),
    };

    const response = await fetch(
      URL +
        "locations/" +
        location +
        "/departments/" +
        department +
        "/lines/add",
      requestObject
    );

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

async function newStation(location, department, line, station, jwt = null) {
  try {
    const requestObject = {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        //"User-Agent": "PostmanRuntime/7.29.2", // TODO: change
        Authorization: "Bearer " + jwt,
      }),
      body: JSON.stringify(station),
    };

    const response = await fetch(
      URL +
        "locations/" +
        location +
        "/departments/" +
        department +
        "/lines/" +
        line +
        "/stations/add",
      requestObject
    );

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

async function updateStation(
  location,
  department,
  line,
  station,
  name,
  jwt = null
) {
  try {
    const requestObject = {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        //"User-Agent": "PostmanRuntime/7.29.2", // TODO: change
        Authorization: "Bearer " + jwt,
      }),
      body: JSON.stringify(name),
    };

    const response = await fetch(
      URL +
        "locations/" +
        location +
        "/departments/" +
        department +
        "/lines/" +
        line +
        "/stations/" +
        station +
        "/update",
      requestObject
    );

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

async function deleteStation(location, department, line, station, jwt = null) {
  try {
    const requestObject = {
      method: "DELETE",
      headers: new Headers({
        "Content-Type": "application/json",
        //"User-Agent": "PostmanRuntime/7.29.2", // TODO: change
        Authorization: "Bearer " + jwt,
      }),
    };

    const response = await fetch(
      URL +
        "locations/" +
        location +
        "/departments/" +
        department +
        "/lines/" +
        line +
        "/stations/" +
        station,
      requestObject
    );

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

async function newTask(location, department, line, station, task, jwt = null) {
  try {
    const requestObject = {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        //"User-Agent": "PostmanRuntime/7.29.2", // TODO: change
        Authorization: "Bearer " + jwt,
      }),
      body: JSON.stringify(task),
    };

    const response = await fetch(
      URL +
        "locations/" +
        location +
        "/departments/" +
        department +
        "/lines/" +
        line +
        "/stations/" +
        station +
        "/tasks/add",
      requestObject
    );

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

async function updateTaskNiosh(task, niosh, jwt = null) {
  console.log("niosh", niosh);
  try {
    const requestObject = {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        //"User-Agent": "PostmanRuntime/7.29.2", // TODO: change
        Authorization: "Bearer " + jwt,
      }),
      body: JSON.stringify(niosh),
    };

    const response = await fetch(
      URL + "tasks/" + task + "/niosh/update",
      requestObject
    );

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

async function deleteTask(id, jwt = null) {
  try {
    const requestObject = {
      method: "DELETE",
      headers: new Headers({
        "Content-Type": "application/json",
        //"User-Agent": "PostmanRuntime/7.29.2", // TODO: change
        Authorization: "Bearer " + jwt,
      }),
    };

    const response = await fetch(URL + "tasks/" + id, requestObject);

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

export {
  getCompanies,
  getResults,
  getInspections,
  getInspection,
  getLocation,
  getLocations,
  getLocationsByCompany,
  getDepartment,
  getTask,
  deleteTask,
  getTaskPerStation,
  newCompany,
  newLocation,
  newLine,
  newStation,
  updateStation,
  deleteStation,
  updateJobTask,
  newDepartment,
  newWorkshift,
  editWorkshift,
  deleteWorkshift,
  newJob,
  updateJob,
  deleteJob,
  newJobTask,
  deleteJobTask,
  newTask,
  getVideoByStatus,
  updateTaskNiosh,
};
