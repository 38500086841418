import styled from "styled-components";
import { colors } from "../../config/style";

export const InfoTabWrapper = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0.3vw;
  margin: 0vw;

  justify-content: start;
  box-sizing: border-box;
  font-family: Atkinson Hyperlegible;
  font-size: 0.95vw;
  font-style: normal;
  font-weight: 400;
  box-shadow: 0px 0.2vw 0.8vw 0.2vw rgba(113, 109, 242, 0.1);
`;

export const Title = styled.p`
  margin: 0;
  color: ${colors.text};
  font-family: Atkinson Hyperlegible;
  font-size: 1vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-edge: cap;
  text-transform: uppercase;
  margin-top: 0.25vw;
  box-sizing: border-box;
  // width: 100%;
`;
