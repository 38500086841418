import React from "react";
import * as ExcelJS from "exceljs";
import { shift_global } from "../../utils/Protocols/OCRA/singleTask";
import { getResults } from "../../remote/structure";
import { getToken } from "../../userManagement/utilities";

export async function ExcelDownloader(data, name, session) {
  let ocra_left = data.left_video.ocra;
  let ocra_right = data.right_video.ocra;
  let ocra_hands = data.hands.ocra;
  let shift_res = shift_global(data.shift);
  let res = await getResults(
    data.tasks[0].task.last_result,
    await getToken(session)
  );

  console.log("data?.info?.company", data.shift, shift_res);

  console.log("res", res);

  console.log("data.tasks[0]", data.tasks[0]);

  let mapping = [
    { data: data?.info?.company ?? "", type: "string", cell: "F4" },
    { data: data?.info?.workstation ?? "", type: "string", cell: "F6" },
    { data: data?.info?.department ?? "", type: "string", cell: "L4" },
    { data: "X", type: "string", cell: "P10" },

    { data: data?.shift?.durata ?? 0, cell: "F15" },
    { data: data?.shift?.durata ?? 0, cell: "L15" },
    { data: shift_res?.output?.durata_non_ripetitivi ?? 0, cell: "L17" },
    { data: data?.shift?.n_pause_effettive ?? 0, cell: "L19" },
    { data: data?.shift?.d_pause ?? 0, cell: "L21" },
    { data: data?.shift?.d_pause_mensa ?? 0, cell: "L24" },

    { data: shift_res?.output?.durata_media_netta ?? 0, cell: "L29" },
    { data: res?.checklist?.tempo_ciclo_osservato ?? 0, cell: "G30" },
    { data: parseInt(res?.checklist?.targa_ciclo) ?? 0, cell: "L30" },
    { data: shift_res?.output?.durata_media_netta ?? 0, cell: "L31" },
    {
      data:
        Math.round(
          shift_res?.output?.durata_media_netta /
            parseInt(res?.checklist?.targa_ciclo)
        ) ?? 0,
      cell: "G29",
    },

    {
      data:
        Math.round(
          data?.tasks[0]?.task?.ocra?.sx?.otherTemp?.ocraRes?.input
            ?.secondi_spalla_incongrua_sx * 10
        ) / 10 ?? 0,
      cell: "F70",
    },
    {
      data:
        Math.round(
          data?.tasks[0]?.task?.ocra?.sx?.otherTemp?.ocraRes?.input
            ?.secondi_gomito_incongrua_sx * 10
        ) / 10 ?? 0,
      cell: "F72",
    },
    {
      data:
        Math.round(
          data?.tasks[0]?.task?.ocra?.sx?.otherTemp?.ocraRes?.input
            ?.secondi_polso_incongrua_sx * 10
        ) / 10 ?? 0,
      cell: "F71",
    },
    {
      data:
        Math.round(
          data?.tasks[0]?.task?.ocra?.sx?.otherTemp?.ocraRes?.input
            ?.secondi_mano_incongrua_sx * 10
        ) / 10 ?? 0,
      cell: "F69",
    },
    {
      data:
        Math.round(
          data?.tasks[0]?.task?.ocra?.sx?.otherTemp?.ocraRes?.input
            ?.numero_azioni_dinamiche_sx * 10
        ) / 10 ?? 0,
      cell: "M48",
    },
    {
      data:
        Math.round(
          data?.tasks[0]?.task?.ocra?.sx?.otherTemp?.ocraRes?.input
            ?.secondi_spalla_incongrua_dx * 10
        ) / 10 ?? 0,
      cell: "F62",
    },
    {
      data:
        Math.round(
          data?.tasks[0]?.task?.ocra?.sx?.otherTemp?.ocraRes?.input
            ?.secondi_gomito_incongrua_dx * 10
        ) / 10 ?? 0,
      cell: "F64",
    },
    {
      data:
        Math.round(
          data?.tasks[0]?.task?.ocra?.sx?.otherTemp?.ocraRes?.input
            ?.secondi_polso_incongrua_dx * 10
        ) / 10 ?? 0,
      cell: "F63",
    },
    {
      data:
        Math.round(
          data?.tasks[0]?.task?.ocra?.sx?.otherTemp?.ocraRes?.input
            ?.secondi_mano_incongrua_dx * 10
        ) / 10 ?? 0,
      cell: "F61",
    },
    {
      data:
        Math.round(
          data?.tasks[0]?.task?.ocra?.sx?.otherTemp?.ocraRes?.input
            ?.numero_azioni_dinamiche_dx * 10
        ) / 10 ?? 0,
      cell: "I48",
    },

    {
      data:
        Math.round(
          data?.tasks[0]?.task?.ocra?.sx?.otherTemp?.ocraRes?.input
            ?.forza_moderata_dx_sec * 10
        ) / 10 ?? 0,
      cell: "F78",
    },
    {
      data:
        Math.round(
          data?.tasks[0]?.task?.ocra?.sx?.otherTemp?.ocraRes?.input
            ?.forza_elevata_dx_sec * 10
        ) / 10 ?? 0,
      cell: "F79",
    },
    {
      data:
        Math.round(
          data?.tasks[0]?.task?.ocra?.sx?.otherTemp?.ocraRes?.input
            ?.picchi_di_forza_dx_sec * 10
        ) / 10 ?? 0,
      cell: "F80",
    },
    {
      data:
        Math.round(
          data?.tasks[0]?.task?.ocra?.sx?.otherTemp?.ocraRes?.input
            ?.forza_moderata_sx_sec * 10
        ) / 10 ?? 0,
      cell: "F82",
    },
    {
      data:
        Math.round(
          data?.tasks[0]?.task?.ocra?.sx?.otherTemp?.ocraRes?.input
            ?.forza_elevata_sx_sec * 10
        ) / 10 ?? 0,
      cell: "F83",
    },
    {
      data:
        Math.round(
          data?.tasks[0]?.task?.ocra?.sx?.otherTemp?.ocraRes?.input
            ?.picchi_di_forza_sx_sec * 10
        ) / 10 ?? 0,
      cell: "F84",
    },
  ];

  if (
    data?.tasks[0]?.task?.ocra?.sx?.otherTemp?.ocraRes?.input
      ?.ritmo_macchina_dx > 1
  ) {
    mapping.push({
      data: "X",
      type: "string",
      cell: "I51",
    });
  } else {
    mapping.push({
      data: "X",
      type: "string",
      cell: "J51",
    });
  }

  if (
    data?.tasks[0]?.task?.ocra?.sx?.otherTemp?.ocraRes?.input
      ?.flag_azioni_statiche_dx == 1
  ) {
    mapping.push({
      data: "X",
      type: "string",
      cell: "J54",
    });
  } else {
    mapping.push({
      data: "X",
      type: "string",
      cell: "I54",
    });
  }

  if (
    data?.tasks[0]?.task?.ocra?.sx?.otherTemp?.ocraRes?.input
      ?.flag_azioni_statiche_dx == 2
  ) {
    mapping.push({
      data: "X",
      type: "string",
      cell: "J55",
    });
  } else {
    mapping.push({
      data: "X",
      type: "string",
      cell: "I55",
    });
  }

  if (
    data?.tasks[0]?.task?.ocra?.sx?.otherTemp?.ocraRes?.input
      ?.flag_azioni_statiche_sx == 1
  ) {
    mapping.push({
      data: "X",
      type: "string",
      cell: "M54",
    });
  } else {
    mapping.push({
      data: "X",
      type: "string",
      cell: "L54",
    });
  }

  if (
    data?.tasks[0]?.task?.ocra?.sx?.otherTemp?.ocraRes?.input
      ?.flag_azioni_statiche_sx == 2
  ) {
    mapping.push({
      data: "X",
      type: "string",
      cell: "M55",
    });
  } else {
    mapping.push({
      data: "X",
      type: "string",
      cell: "L55",
    });
  }
  console.log(
    "parseFloat(res?.checklist?.targa_ciclo)parseFloat(res?.checklist?.targa_ciclo)parseFloat(res?.checklist?.targa_ciclo)",
    parseFloat(res?.checklist?.targa_ciclo)
  );
  if (parseFloat(res?.checklist?.targa_ciclo) > 15) {
    mapping.push({
      data: "X",
      type: "string",
      cell: "H65",
    });
    mapping.push({
      data: "X",
      type: "string",
      cell: "H73",
    });
  } else if (parseFloat(res?.checklist?.targa_ciclo) > 8) {
    mapping.push({
      data: "X",
      type: "string",
      cell: "K65",
    });
    mapping.push({
      data: "X",
      type: "string",
      cell: "K73",
    });
  } else {
    mapping.push({
      data: "X",
      type: "string",
      cell: "N65",
    });
    mapping.push({
      data: "X",
      type: "string",
      cell: "N73",
    });
  }

  if (
    data?.tasks[0]?.task?.ocra?.sx?.otherTemp?.ocraRes?.input
      ?.stereotipia_ripetizione_dx == 2
  ) {
    mapping.push({
      data: "X",
      type: "string",
      cell: "N66",
    });
  }
  if (
    data?.tasks[0]?.task?.ocra?.sx?.otherTemp?.ocraRes?.input
      ?.stereotipia_ripetizione_dx == 1
  ) {
    mapping.push({
      data: "X",
      type: "string",
      cell: "K66",
    });
  }

  if (
    data?.tasks[0]?.task?.ocra?.sx?.otherTemp?.ocraRes?.input
      ?.stereotipia_ripetizione_sx == 2
  ) {
    mapping.push({
      data: "X",
      type: "string",
      cell: "N74",
    });
  }
  if (
    data?.tasks[0]?.task?.ocra?.sx?.otherTemp?.ocraRes?.input
      ?.stereotipia_ripetizione_sx == 1
  ) {
    mapping.push({
      data: "X",
      type: "string",
      cell: "K74",
    });
  }

  if (
    data?.tasks[0]?.task?.ocra?.sx?.otherTemp?.ocraRes?.input
      ?.ritmo_macchina_dx == 1
  ) {
    mapping.push({
      data: "X",
      type: "string",
      cell: "H90",
    });
  } else if (
    data?.tasks[0]?.task?.ocra?.sx?.otherTemp?.ocraRes?.input
      ?.ritmo_macchina_dx == 2
  ) {
    mapping.push({
      data: "X",
      type: "string",
      cell: "J90",
    });
  } else if (
    data?.tasks[0]?.task?.ocra?.sx?.otherTemp?.ocraRes?.input
      ?.ritmo_macchina_dx == 3
  ) {
    mapping.push({
      data: "X",
      type: "string",
      cell: "M90",
    });
  }

  if (
    data?.tasks[0]?.task?.ocra?.sx?.otherTemp?.ocraRes?.input?.uso_martelli_dx
  ) {
    mapping.push({
      data: "X",
      type: "string",
      cell: "M86",
    });
  }
  if (
    data?.tasks[0]?.task?.ocra?.sx?.otherTemp?.ocraRes?.input?.uso_martelli_sx
  ) {
    mapping.push({
      data: "X",
      type: "string",
      cell: "N86",
    });
  }
  if (
    data?.tasks[0]?.task?.ocra?.sx?.otherTemp?.ocraRes?.input?.uso_mani_colpi_dx
  ) {
    mapping.push({
      data: "X",
      type: "string",
      cell: "M87",
    });
  }
  if (
    data?.tasks[0]?.task?.ocra?.sx?.otherTemp?.ocraRes?.input?.uso_mani_colpi_sx
  ) {
    mapping.push({
      data: "X",
      type: "string",
      cell: "N87",
    });
  }
  if (
    data?.tasks[0]?.task?.ocra?.sx?.otherTemp?.ocraRes?.input
      ?.strumenti_vibranti_dx
  ) {
    mapping.push({
      data: "X",
      type: "string",
      cell: "M88",
    });
  }
  if (
    data?.tasks[0]?.task?.ocra?.sx?.otherTemp?.ocraRes?.input
      ?.strumenti_vibranti_sx
  ) {
    mapping.push({
      data: "X",
      type: "string",
      cell: "N88",
    });
  }
  if (data?.tasks[0]?.task?.ocra?.sx?.otherTemp?.ocraRes?.input?.altro_dx) {
    mapping.push({
      data: "X",
      type: "string",
      cell: "M89",
    });
  }
  if (data?.tasks[0]?.task?.ocra?.sx?.otherTemp?.ocraRes?.input?.altro_sx) {
    mapping.push({
      data: "X",
      type: "string",
      cell: "N89",
    });
  }

  if (
    data?.tasks[0]?.task?.ocra?.sx?.otherTemp?.ocraRes?.output
      ?.freq_azioni_dinamiche_dx > 70
  ) {
    mapping.push({
      data: "X",
      type: "string",
      cell: "I49",
    });
  }

  if (
    data?.tasks[0]?.task?.ocra?.sx?.otherTemp?.ocraRes?.output
      ?.freq_azioni_dinamiche_sx > 70
  ) {
    mapping.push({
      data: "X",
      type: "string",
      cell: "M49",
    });
  }

  // Funzione per scaricare e modificare il file Excel
  const handleDownload = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_RESOURCES_BUCKET + "files/OCRA_GYMNASIO.xlsx"
      );
      const arrayBuffer = await response.arrayBuffer();

      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(arrayBuffer);

      const sheet = workbook.getWorksheet(1);

      mapping.map((x) => {
        const cell = sheet.getCell(x.cell);
        if (x?.type == "string") {
          cell.value = x.data;
        } else {
          cell.value = Math.round(x.data * 10) / 10;
        }
      });

      const buffer = await workbook.xlsx.writeBuffer();

      const blob = new Blob([buffer], { type: "application/octet-stream" });

      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = downloadUrl;
      a.download = name + ".xlsx";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(downloadUrl);
      document.body.removeChild(a);
    } catch (error) {
      console.error("Errore nel download o nella modifica del file:", error);
    }
  };

  handleDownload();
}

export default ExcelDownloader;
