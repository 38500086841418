export const defaultWorkshift = {
  t_altro: 0,
  d_no_rec: -1,
  n_pause_ufficiali: 2,
  name: "nomeShift",
  n_pause_effettive: 2,
  d_pause_mensa: 60,
  n_pause_extra: 0,
  t_ragg_post: 0,
  t_pulizie: 0,
  t_vestirsi: 0,
  task_non_ripetitivi: false,
  durata: 480,
  d_pause: 20,
};

export const defaultDepartment = {
  n_men: 1,
  n_women: 1,
  name: "NomeDipartimento",
};

export const defaultLineWs = {
  name: "Postazione vuota",
};

export const defaultJob = {
  name: "Mansione vuota",
  workshift: "00",
  n_employees: 0,
};

export const defaultTask = {
  description: "Descrizione compito",
};

export const defaultCompany = {
  description: "Descrizione azienda",
};
