import styled from "styled-components";
import { colors } from "../../../config/style";

export const ButtonNavigationWrapper = styled.div``;

export const ButtonNavigatorContainer = styled.div`
  height: 3vw;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-betweem;
  align-items: center;
  cursor: pointer;
`;
export const ButtonNavigatorIndicator = styled.div`
  min-height: 100%;
  min-width: 0.6vw;
  background: ${colors.main};
  border-top-right-radius: 0.6vw;
  border-bottom-right-radius: 0.6vw;
  box-shadow: 0px 0.4vw 0.8vw 0px rgba(113, 109, 242, 0.3);
  margin-right: 1vw;
`;
export const IconButtonNavigator = styled.img`
  width: 2vw;
  margin-right: 1vw;
`;

export const LabelButtonNavigator = styled.p`
  margin: 0;
  color: ${colors.text};
  font-family: Atkinson Hyperlegible;
  font-size: 1vw;
  font-style: normal;

  line-height: normal;
  text-edge: cap;
`;
