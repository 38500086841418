import { useSignIn } from "@clerk/clerk-react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import EmailForm from "../../components/EmailForm/EmailForm";
import ResetPwdForm from "../../components/ResetPwdForm/ResetPwdForm";

const ResetPwd = () => {
  const [successfulCreation, setSuccessfulCreation] = useState(false);
  const [complete, setComplete] = useState(false);
  const [secondFactor, setSecondFactor] = useState(false);
  const [email, setEmail] = useState();
  const [error, setError] = useState("");

  const navigate = useNavigate();

  const { isLoaded, signIn, setActive } = useSignIn();

  const { t } = useTranslation();

  useEffect(() => {
    setError("");
  }, [successfulCreation, email]);

  if (!isLoaded) {
    return null;
  }

  async function create(email) {
    await signIn
      ?.create({
        strategy: "reset_password_email_code",
        identifier: email,
      })
      .then((_) => {
        setSuccessfulCreation(true);
      })
      .catch((err) => {
        setError(err.errors[0].longMessage);
        console.error("error", err.errors[0].longMessage);
      });
  }

  async function reset(code, password, passwordCopy) {
    if (password != passwordCopy) {
      setError(t("pass_match"));
    } else {
      await signIn
        ?.attemptFirstFactor({
          strategy: "reset_password_email_code",
          code,
          password,
        })
        .then((result) => {
          if (result.status === "needs_second_factor") {
            setSecondFactor(true);
          } else if (result.status === "complete") {
            setActive({ session: result.createdSessionId });
            setComplete(true);
          } else {
            console.log(result);
          }
        })
        .catch((err) => {
          setError(err.errors[0].longMessage);
          console.error("error", err.errors[0].longMessage);
        });
    }
  }

  return (
    <div>
      {!successfulCreation && !complete && (
        <EmailForm
          handleSubmit={create}
          errorMsg={error}
          setEmail={(x) => setEmail(x)}
          email={email}
        />
      )}

      {successfulCreation && !complete && (
        <ResetPwdForm
          handleReset={reset}
          errorMsg={error}
          setError={(x) => setError(x)}
          mail={email}
        />
      )}
    </div>
  );
};

export default ResetPwd;
