export function deepEqual(a, b) {
  if (a === b) return true;

  if (
    typeof a !== "object" ||
    a === null ||
    typeof b !== "object" ||
    b === null
  )
    return false;

  let keysA = Object.keys(a);
  let keysB = Object.keys(b);

  if (keysA.length !== keysB.length) return false;

  for (let key of keysA) {
    if (!keysB.includes(key) || !deepEqual(a[key], b[key])) return false;
  }

  return true;
}

export function compareArrayOfObjects(arr1, arr2) {
  if (arr1.length !== arr2.length) return false;

  for (let i = 0; i < arr1.length; i++) {
    if (!deepEqual(arr1[i], arr2[i])) {
      return false;
    }
  }

  return true;
}

export function isValidTime(time) {
  // Espressione regolare per verificare il formato hh:mm o h:mm
  const timeFormat = /^([0-9]):([0-5]\d)$/; // Formato h:mm (senza 0 iniziale)
  const fullTimeFormat = /^([01]\d|2[0-3]):([0-5]\d)$/; // Formato hh:mm

  if (fullTimeFormat.test(time)) {
    return time; // Se è già nel formato corretto hh:mm
  } else if (timeFormat.test(time)) {
    // Aggiungi 0 iniziale se il formato è h:mm
    return `0${time}`;
  } else {
    return false; // Non è un orario valido
  }
}

export function setAlpha(color, alpha) {
  // Verifica se il colore è in formato RGBA
  if (/^rgba\(/.test(color)) {
    // Estrai i valori R, G, B e A dal colore
    const matches = color.match(/(\d+(\.\d+)*)/g);
    if (matches.length === 4) {
      const r = matches[0];
      const g = matches[1];
      const b = matches[2];
      // Imposta la nuova trasparenza
      return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }
  }
  // Se il colore non è in formato RGBA o non ha il formato corretto, restituisci il colore originale
  return color;
}

export function increaseSaturation(rgba, amount) {
  // Funzione per convertire da RGBA a HSL
  function rgbaToHsl(r, g, b, a) {
    r /= 255;
    g /= 255;
    b /= 255;

    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h,
      s,
      l = (max + min) / 2;

    if (max === min) {
      h = s = 0; // Grayscale
    } else {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
      }

      h /= 6;
    }

    return [h * 360, s * 100, l * 100, a];
  }

  // Funzione per convertire da HSL a RGBA
  function hslToRgba(h, s, l, a) {
    let r, g, b;

    h /= 360;
    s /= 100;
    l /= 100;

    if (s === 0) {
      r = g = b = l; // Grayscale
    } else {
      const hue2rgb = (p, q, t) => {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1 / 6) return p + (q - p) * 6 * t;
        if (t < 1 / 2) return q;
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
        return p;
      };

      const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      const p = 2 * l - q;

      r = hue2rgb(p, q, h + 1 / 3);
      g = hue2rgb(p, q, h);
      b = hue2rgb(p, q, h - 1 / 3);
    }

    return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255), a];
  }

  // Estrai i valori RGBA dal formato input
  const [r, g, b, a] = rgba.match(/\d+(\.\d+)?/g).map(Number);

  // Converti RGBA in HSL
  let [h, s, l, alpha] = rgbaToHsl(r, g, b, a);

  // Aumenta la saturazione
  s = Math.min(100, s + amount);

  // Converti HSL di nuovo in RGBA
  const [newR, newG, newB, newA] = hslToRgba(h, s, l, alpha);

  // Restituisci il colore nel formato RGBA
  return `rgba(${newR}, ${newG}, ${newB}, ${newA})`;
}
