import styled from "styled-components";
import { colors, layout } from "../../config/style";

export const LateralNavigationWrapper = styled.div`
  height: 100vh;
  width: 12vw;
  max-width: 12vw;
  background: white;
  box-shadow: 0.4vw 0px 1.1vw 0px rgba(113, 109, 242, 0.15);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 0px 0.6vw 0.6vw 0px;
`;

export const LogoWrapper = styled.img`
  width: 7.2vw;
  margin-top: ${layout.paddingTop};
  margin-bottom: 2vw;
  margin-right: 0.5vw;
`;
