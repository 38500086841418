import styled from "styled-components";

export const ButtonToggleWrapper = styled.div`
  width: 10.6vw;
  height: 2.8vw;
  border-radius: 1.4vw;
  align-items: center;
  font-family: Atkinson Hyperlegible;
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  line-height: 2.6vw;
  text-edge: cap;
  text-align: center;
  box-sizing: border-box;
`;
