import React from "react";

const MagicIcon = ({ fill, size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 36 36"
      fill={fill}
      style={{ minWidth: size, minHeight: size }}
    >
      <path d="M15.627 26.2453C15.5639 26.3648 15.4663 26.4653 15.3455 26.5356C15.2246 26.6059 15.0852 26.6431 14.943 26.6431C14.8008 26.6431 14.6614 26.6059 14.5405 26.5356C14.4196 26.4653 14.3221 26.3648 14.259 26.2453L11.3142 20.7235L5.42437 17.9629C5.29694 17.9036 5.18969 17.8122 5.11471 17.6989C5.03973 17.5856 5 17.4549 5 17.3216C5 17.1882 5.03973 17.0575 5.11471 16.9442C5.18969 16.8309 5.29694 16.7395 5.42437 16.6803L11.3142 13.9196L14.2613 8.39784C14.3245 8.27838 14.422 8.17783 14.5429 8.10754C14.6637 8.03725 14.8032 8 14.9454 8C15.0876 8 15.227 8.03725 15.3479 8.10754C15.4687 8.17783 15.5662 8.27838 15.6294 8.39784L18.5741 13.9196L24.464 16.6803C24.5914 16.7395 24.6987 16.8309 24.7737 16.9442C24.8486 17.0575 24.8884 17.1882 24.8884 17.3216C24.8884 17.4549 24.8486 17.5856 24.7737 17.6989C24.6987 17.8122 24.5914 17.9036 24.464 17.9629L18.5717 20.7235L15.627 26.2453ZM26.796 12.6616C26.3422 12.6616 25.8987 12.5355 25.5214 12.2991C25.1441 12.0628 24.85 11.7269 24.6763 11.3338C24.5027 10.9408 24.4573 10.5083 24.5458 10.0911C24.6343 9.67387 24.8528 9.29062 25.1737 8.9898C25.4946 8.68899 25.9034 8.48414 26.3484 8.40115C26.7935 8.31815 27.2548 8.36075 27.674 8.52355C28.0932 8.68634 28.4515 8.96203 28.7036 9.31575C28.9558 9.66946 29.0903 10.0853 29.0903 10.5107C29.0903 11.0812 28.8486 11.6283 28.4183 12.0317C27.9881 12.435 27.4045 12.6616 26.796 12.6616ZM27.9412 27C27.3363 27 26.7449 26.8318 26.2419 26.5167C25.7389 26.2016 25.3468 25.7538 25.1153 25.2298C24.8838 24.7058 24.8232 24.1292 24.9413 23.573C25.0593 23.0167 25.3506 22.5058 25.7784 22.1047C26.2062 21.7037 26.7512 21.4306 27.3445 21.3199C27.9379 21.2093 28.5529 21.2661 29.1118 21.4831C29.6707 21.7002 30.1484 22.0677 30.4845 22.5393C30.8206 23.0108 31 23.5653 31 24.1324C31 24.8926 30.678 25.6217 30.1048 26.1595C29.5316 26.6972 28.7541 26.9995 27.9432 27H27.9412Z" />
    </svg>
  );
};

export default MagicIcon;
