import React from "react";

const ClockIcon = ({ fill, size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      minWidth={size}
      minHeight={size}
      viewBox="0 0 48 48"
      fill={fill}
      style={{ minWidth: size, minHeight: size }}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M24.0007 38.6668C32.1008 38.6668 38.6673 32.1003 38.6673 24.0002C38.6673 15.9 32.1008 9.3335 24.0007 9.3335C15.9005 9.3335 9.33398 15.9 9.33398 24.0002C9.33398 32.1003 15.9005 38.6668 24.0007 38.6668ZM25.0998 18.1342C25.0998 17.5267 24.6073 17.0342 23.9998 17.0342C23.3923 17.0342 22.8998 17.5267 22.8998 18.1342V24.0009C22.8998 24.2926 23.0157 24.5724 23.222 24.7787L26.8887 28.4454C27.3182 28.875 28.0147 28.875 28.4443 28.4454C28.8739 28.0158 28.8739 27.3193 28.4443 26.8898L25.0998 23.5453V18.1342Z"
      />
    </svg>
  );
};

export default ClockIcon;
