import { colors } from "../../../config/style";

export const mappingNiosh = {
  vli: [
    { value: 0, colors: [colors.neutral, colors.disabled] },
    { value: 0.85, colors: [colors.positive, colors.positivePale] },
    { value: 1, colors: [colors.riskUnsure, colors.riskUnsurePale] },
    { value: 1.5, colors: [colors.riskNegative, colors.riskNegativePale] },
    {
      value: 2,
      colors: [colors.riskVeryNegative, colors.riskVeryNegativePale],
    },
    {
      value: 100,
      colors: [colors.riskVeryNegative, colors.riskVeryNegativePale],
    },
  ],
};
