let videoWidth = 44;
let videoHeight = (44 * 9) / 16;

export const colors = {
  mainBackground: "#E8E5FC",
  text: "#494759",
  main: "rgba(109, 105, 242, 1)",
  mainH: "rgba(141, 138, 245, 1)",
  mainOpaque: "rgba(113, 109, 242, 0.25)",
  mainPale: "rgba(218, 217, 255, 1)",
  details: "#ECEBF7",
  neutral: "#B6B4D1",
  neutralPale: "#E1E1E6",
  error: "#E20074",
  errorH: "#E65FA3",
  tertiary: "rgba(229, 110, 170, 1)",
  tertiaryH: "rgba(252, 139, 196, 1)",
  tertiaryBackground: "#FFEBF5",
  positive: "rgba(29, 191, 136, 1)",
  positivePale: "rgba(203, 247, 232, 1)",
  positive2: "rgba(111, 152, 250, 1)",
  positive2Pale: "rgba(232, 239, 255, 1)",
  negative: "rgba(240, 108, 108, 1)",
  negativePale: "rgba(250, 204, 202, 1)",
  negative2: "rgba(237, 139, 71, 1)",
  negative2Pale: "rgba(252, 226, 207, 1)",
  background: "rgba(237, 235, 255, 1)",
  disabled: "rgba(248, 247, 255, 1)",
  disabledText: "rgba(161, 159, 204, 1)",
  calculator: "rgba(83, 131, 245, 1)",
  riskUnsure: "rgba(232, 136, 70, 1)",
  riskUnsurePale: "rgba(252, 235, 222, 1)",
  riskNegative: "rgba(250, 125, 167, 1)",
  riskNegativePale: "rgba(255, 232, 240, 1)",
  riskVeryNegative: "rgba(226, 0, 116, 1)",
  riskVeryNegativePale: "rgba(251, 221, 233, 1)",
  backgroundOpaque: "rgba(35, 35, 51, 0.7)",
  riskUnsureBase: "#E88846",
  riskUnacceptable: "rgba(220, 219, 255, 1)",
};

export const layout = {
  leftMenuWidth: "14vw",
  rightContainerWidth: "86vw",
  fullHeight: "100vh",
  headerHeight: "10vh",
  contnentHeight: "90vh",
  paddingLeft: "1.9vw",
  paddingTop: "4.7vh",
  videoWidth: videoWidth + "vw",
  videoHeight: videoHeight + "vw",
};
