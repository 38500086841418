import JSZip from "jszip";

async function GTGenerator({ id_result, json, edit, ocraRes }) {
  // CREA COPIE JSON DA MODIFICARE
  console.log("id_result", id_result);
  console.log("json", json);
  console.log("edit", edit);
  console.log("ocraRes", ocraRes);

  let left_video = JSON.parse(JSON.stringify(json.left_video));
  let right_video = JSON.parse(JSON.stringify(json.right_video));
  let hands_video = JSON.parse(JSON.stringify(json.hands));

  // PER OGNI EDIT : verifica il JSON da modificare -> unisci i dati dell'edit a quelli del JSON -> salvali nel JSON
  for (const key in edit) {
    if (edit.hasOwnProperty(key)) {
      if (key == "rightShoulder") {
        // Merging errors
        let newErrors = mergeErrors(
          edit[key][0].errors,
          edit[key][0].addedErrors
        );

        // Copying new errors
        right_video.debug.shoulder.right_flexion_errors = newErrors[0];
        right_video.debug.shoulder.right_abduction_errors = newErrors[1];

        // Update error time
        right_video.ocra.shoulder.right_wrong_time = edit[key][0].newValue;
      }

      if (key == "leftShoulder") {
        // Merging errors
        let newErrors = mergeErrors(
          edit[key][0].errors,
          edit[key][0].addedErrors
        );

        // Copying new errors
        left_video.debug.shoulder.left_flexion_errors = newErrors[0];
        left_video.debug.shoulder.left_abduction_errors = newErrors[1];

        // Update error time
        left_video.ocra.shoulder.left_wrong_time = edit[key][0].newValue;
      }

      if (key == "rightElbow") {
        // Elbow angle

        let removedAscent = findBatches(edit[key][0].errors[0], 2).map(
          (x) => x[0]
        );
        let removedDescent = findBatches(edit[key][0].errors[1], 2).map(
          (x) => x[0]
        );
        let addedAscent = findBatches(edit[key][0].addedErrors[0], 1);
        let addedDescent = findBatches(edit[key][0].addedErrors[1], 1);

        right_video.debug.elbow.ascent_movements_right =
          right_video.debug.elbow.ascent_movements_right.filter(
            (x) => !removedAscent.includes(x)
          );
        right_video.debug.elbow.descent_movements_right =
          right_video.debug.elbow.descent_movements_right.filter(
            (x) => !removedDescent.includes(x)
          );

        addAndSortPairs(
          addedAscent,
          right_video.debug.elbow.retained_peaks_right,
          right_video.debug.elbow.ascent_movements_right
        );

        addAndSortPairs(
          addedDescent,
          right_video.debug.elbow.retained_peaks_right,
          right_video.debug.elbow.descent_movements_right
        );

        // Prono supination

        right_video.debug.elbow.ascent_movements_right_prono = createIndices(
          edit[key][1].batchLevel,
          right_video.sampling_frequency,
          right_video.debug.elbow.right_fingers_distance.length
        );
        right_video.debug.elbow.descent_movements_right_prono = [];

        // OCRA score
        right_video.ocra.elbow.right_wrong_time =
          edit[key][0].newValue + edit[key][1].newValue;
      }

      if (key == "leftElbow") {
        // Elbow angle

        let removedAscent = findBatches(edit[key][0].errors[0], 2).map(
          (x) => x[0]
        );
        let removedDescent = findBatches(edit[key][0].errors[1], 2).map(
          (x) => x[0]
        );
        let addedAscent = findBatches(edit[key][0].addedErrors[0], 1);
        let addedDescent = findBatches(edit[key][0].addedErrors[1], 1);

        left_video.debug.elbow.ascent_movements_left =
          left_video.debug.elbow.ascent_movements_left.filter(
            (x) => !removedAscent.includes(x)
          );
        left_video.debug.elbow.descent_movements_left =
          left_video.debug.elbow.descent_movements_left.filter(
            (x) => !removedDescent.includes(x)
          );

        addAndSortPairs(
          addedAscent,
          left_video.debug.elbow.retained_peaks_left,
          left_video.debug.elbow.ascent_movements_left
        );

        addAndSortPairs(
          addedDescent,
          left_video.debug.elbow.retained_peaks_left,
          left_video.debug.elbow.descent_movements_left
        );

        // Prono supination

        left_video.debug.elbow.ascent_movements_left_prono = createIndices(
          edit[key][1].batchLevel,
          left_video.sampling_frequency,
          left_video.debug.elbow.left_fingers_distance.length
        );
        left_video.debug.elbow.descent_movements_left_prono = [];

        // OCRA score
        left_video.ocra.elbow.left_wrong_time =
          edit[key][0].newValue + edit[key][1].newValue;
      }

      if (key == "rightWrist") {
        // Wrist angle

        let removedAscent = findBatches(edit[key][0].errors[0], 2).map(
          (x) => x[0]
        );
        let removedDescent = findBatches(edit[key][0].errors[1], 2).map(
          (x) => x[0]
        );
        let addedAscent = findBatches(edit[key][0].addedErrors[0], 1);
        let addedDescent = findBatches(edit[key][0].addedErrors[1], 1);

        right_video.debug.wrist.ascent_movements_right =
          right_video.debug.wrist.ascent_movements_right.filter(
            (x) => !removedAscent.includes(x)
          );
        right_video.debug.wrist.descent_movements_right =
          right_video.debug.wrist.descent_movements_right.filter(
            (x) => !removedDescent.includes(x)
          );

        addAndSortPairs(
          addedAscent,
          right_video.debug.wrist.retained_peaks_right,
          right_video.debug.wrist.ascent_movements_right
        );

        addAndSortPairs(
          addedDescent,
          right_video.debug.wrist.retained_peaks_right,
          right_video.debug.wrist.descent_movements_right
        );

        // OCRA score
        right_video.ocra.wrist.right_wrong_time = edit[key][0].newValue;
      }

      if (key == "leftWrist") {
        // Wrist angle

        let removedAscent = findBatches(edit[key][0].errors[0], 2).map(
          (x) => x[0]
        );
        let removedDescent = findBatches(edit[key][0].errors[1], 2).map(
          (x) => x[0]
        );
        let addedAscent = findBatches(edit[key][0].addedErrors[0], 1);
        let addedDescent = findBatches(edit[key][0].addedErrors[1], 1);

        left_video.debug.wrist.ascent_movements_left =
          left_video.debug.wrist.ascent_movements_left.filter(
            (x) => !removedAscent.includes(x)
          );
        left_video.debug.wrist.descent_movements_left =
          left_video.debug.wrist.descent_movements_left.filter(
            (x) => !removedDescent.includes(x)
          );

        addAndSortPairs(
          addedAscent,
          left_video.debug.wrist.retained_peaks_left,
          left_video.debug.wrist.ascent_movements_left
        );

        addAndSortPairs(
          addedDescent,
          left_video.debug.wrist.retained_peaks_left,
          left_video.debug.wrist.descent_movements_left
        );

        // OCRA score
        left_video.ocra.wrist.left_wrong_time = edit[key][0].newValue;
      }

      if (key == "rightHand") {
        let rounded = edit[key][0].batchLevel.map((num) =>
          Math.round(Math.abs(num))
        );
        let global_percentage =
          Math.round(
            (edit[key][0].newValue * 1000) /
              hands_video.debug.grabs.video_length
          ) / 10;

        hands_video.debug.grabs.right_wrong_percentages = rounded;
        hands_video.ocra.grabs.right_wrong_percentage = global_percentage;
      }

      if (key == "leftHand") {
        let rounded = edit[key][0].batchLevel.map((num) =>
          Math.round(Math.abs(num))
        );
        let global_percentage =
          Math.round(
            (edit[key][0].newValue * 1000) /
              hands_video.debug.grabs.video_length
          ) / 10;

        hands_video.debug.grabs.left_wrong_percentages = rounded;
        hands_video.ocra.grabs.left_wrong_percentage = global_percentage;
      }

      if (key == "dynamicRight") {
        // Dynamic right
        right_video.debug.technical_actions.right_instants_frames =
          createIndices(
            edit[key][0].batchLevel,
            right_video.sampling_frequency,
            right_video.debug.technical_actions.signals.length
          );

        // OCRA score
        right_video.ocra.technical_actions.right_dynamic_actions =
          edit[key][0].newValue;
      }

      if (key == "dynamicLeft") {
        // Dynamic right
        left_video.debug.technical_actions.left_instants_frames = createIndices(
          edit[key][0].batchLevel,
          left_video.sampling_frequency,
          left_video.debug.technical_actions.signals.length
        );

        // OCRA score
        left_video.ocra.technical_actions.left_dynamic_actions =
          edit[key][0].newValue;
      }

      //  AGGIUNGI TUTTI GLI ALTRI DISTRETTI
    }
  }

  // CREA ZIP DEI 3 JSON CON NOME UGUALE AGLI INIZIALI E NOME ZIP UGUALE A ID_RESULT
  // Converti gli oggetti in stringhe JSON
  const leftJson = createJsonSides(left_video, json.config);
  const rightJson = createJsonSides(right_video, json.config);
  const handsJson = createJsonHands(hands_video, json.config);

  // Crea un nuovo archivio ZIP
  const regex = /^(?!.*obscured).*\/([a-f0-9\-]+)\./;

  const zip = new JSZip();

  zip.file(left_video.video_path.match(regex)[1] + ".json", leftJson);
  zip.file(right_video.video_path.match(regex)[1] + ".json", rightJson);
  zip.file(hands_video.video_path.match(regex)[1] + ".json", handsJson);

  // Genera l'archivio ZIP e avvia il download
  const content = await zip.generateAsync({ type: "blob" });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(content);
  link.download = id_result + ".zip";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  // SCARICA LO ZIP
}

function mergeErrors(errors, addedErrors) {
  const cleanConst = [2];

  // Funzione per pulire gli errori in base a cleanConst
  function cleanErrors(error) {
    return error.map((value) => (cleanConst.includes(value) ? 0 : value));
  }

  // Pulizia degli errori
  const cleanedErrors = errors.map(cleanErrors);

  // Unione degli errori puliti con gli errori aggiunti
  const mergedErrors = cleanedErrors.map((error, index) => {
    return error.map((value, idx) => value || addedErrors[index][idx]);
  });

  return mergedErrors;
}

// FUNZIONE TEMPORANEA PER FARE IN MODO CHE addedErrors sia un vettore di vettori
function createZeroVector(vet) {
  return Array(vet.length).fill(0);
}

function findGroupIndices(arr) {
  let indices = [];
  for (let i = 0; i < arr.length - 1; i++) {
    if (arr[i] === 2 && arr[i + 1] === 2) {
      indices.push(i);
      // Salta tutti i successivi 2 dello stesso gruppo
      while (i < arr.length - 1 && arr[i + 1] === 2) {
        i++;
      }
    }
  }
  return indices;
}

function findBatches(arr, value) {
  let batches = [];
  let i = 0;

  while (i < arr.length) {
    if (arr[i] === value) {
      let start = i;
      while (i + 1 < arr.length && arr[i + 1] === value) {
        i++;
      }
      let end = i;
      batches.push([start, end]);
    }
    i++;
  }

  return batches;
}

function addAndSortPairs(pairs, peaks, movement) {
  // Rimuovi i valori di "peaks" che si trovano tra "start" e "end"
  // Rimuovi i valori di "peaks" che si trovano tra "start" e "end"
  pairs.forEach(([start, end]) => {
    for (let i = peaks.length - 1; i >= 0; i--) {
      if (peaks[i] >= start && peaks[i] <= end) {
        peaks.splice(i, 1);
      }
    }
  });

  // Aggiungi i valori di inizio e fine a "peaks"
  pairs.forEach(([start, end]) => {
    peaks.push(start);
    peaks.push(end);
  });

  // Aggiungi solo i valori di inizio a "movement"
  pairs.forEach(([start, _]) => {
    movement.push(start);
  });

  // Ordina entrambi gli array in ordine crescente
  peaks.sort((a, b) => a - b);
  movement.sort((a, b) => a - b);
}

function arrayDifference(arr1, arr2) {
  // Determina la lunghezza dell'array più lungo
  const maxLength = Math.max(arr1.length, arr2.length);

  // Crea un nuovo array con la differenza elemento per elemento
  let difference = [];
  for (let i = 0; i < maxLength; i++) {
    const val1 = arr1[i] !== undefined ? arr1[i] : 0;
    const val2 = arr2[i] !== undefined ? arr2[i] : 0;
    difference.push(val1 - val2);
  }

  return difference;
}

function createIndices(batchPoints, frameRate, maxIndex) {
  const batchDuration = 10; // Durata di ogni batch in secondi
  const batchSize = batchDuration * frameRate; // Numero di indici in ogni batch
  let indices = [];

  batchPoints.forEach((points, batchIndex) => {
    const batchStart = batchIndex * batchSize;
    const batchEnd = Math.min(batchStart + batchSize - 1, maxIndex); // Assicurati di non superare maxIndex
    const batchMiddle = Math.floor((batchStart + batchEnd) / 2);
    const startPoint = Math.max(
      batchStart,
      batchMiddle - Math.floor(points / 2)
    );

    for (let i = 0; i < points; i++) {
      const currentIndex = startPoint + i;
      if (currentIndex > batchEnd) break; // Interrompi se si supera il batchEnd
      indices.push(currentIndex);
    }
  });

  indices.sort((a, b) => a - b); // Ordina gli indici per sicurezza
  return indices;
}

function createJsonSides(data, parameters) {
  let dataUpdated = {
    outputs_struct: {
      visibility: { ...data.visibility },
      left: { ...copyObjectExceptKey(data, "visibility") },
    },
    parameters: { ...parameters },
  };

  return JSON.stringify(dataUpdated, null, 2);
}

function createJsonHands(data, parameters) {
  let dataUpdated = {
    outputs_struct: {
      visibility: {},
      hands: { ...copyObjectExceptKey(data, "visibility") },
    },
    parameters: { ...parameters },
  };

  return JSON.stringify(dataUpdated, null, 2);
}

function copyObjectExceptKey(obj, keyToExclude) {
  const { [keyToExclude]: _, ...rest } = obj;
  return rest;
}

export default GTGenerator;
