import React, { useState } from "react";
import PropTypes from "prop-types";
import { colors } from "../../config/style";

const TextField = ({
  dataTestId,
  icon,
  title,
  value,
  error,
  onChange,
  type,
  placeholder,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  // Determina il colore del bordo
  const borderColor = () => {
    if (isFocused) return colors.main; // Blu quando è in focus
    if (error) return colors.negative; // Rosso se il valore è vuoto
    return colors.background; // Colore di default se non in focus e non vuoto
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        background: "white",
        width: "100%",
      }}
    >
      <div style={{ display: "flex", alignItems: "center", gap: "0.3vw" }}>
        <img style={{ width: "1.87vw" }} src={icon} />

        <p
          style={{
            color: colors.text,
            fontFamily: "Atkinson Hyperlegible",
            fontSize: "0.9vw",
            fontWeight: "400",
            margin: "0",
            width: "3vw",
            overflow: "hidden",
          }}
        >
          {title}
        </p>
      </div>
      <input
        className="placeholder-custom"
        onFocus={handleFocus}
        onBlur={handleBlur}
        style={{
          border: `0.14vw solid ${borderColor()}`,
          color: colors.text,
          fontFamily: "Atkinson Hyperlegible",
          fontSize: "0.8vw",
          fontWeight: "400",
          margin: "0px",
          padding: "0.6vw",
          boxSizing: "border-box",
          width: "16.4vw",
          height: "2.5vw",
          borderRadius: "0.31vw",
          outline: "none",
        }}
        // onFocus={(e) => (e.target.style.outline = "none")}
        placeholder={placeholder}
        type={type}
        value={value}
        onChange={(e) => onChange(e)}
      />
      {/* <img
        style={{
          width: "8%",
          padding: "0.7vw",
          visibility: error ? "" : "hidden",
        }}
        src={
          process.env.REACT_APP_RESOURCES_BUCKET+"attentionField.png"
        }
      /> */}
    </div>
  );
};

TextField.propTypes = {
  dataTestId: PropTypes.string, // Definisci il tipo di prop "dataTestId"
};

TextField.defaultProps = {
  dataTestId: "default-data-testid", // Imposta un valore predefinito per "dataTestId"
};

export default TextField;
