import React, { useState } from "react";
import PropTypes from "prop-types";

const ButtonIcon = ({
  onClick,
  children,
  icon,
  iconH,
  iconDisabled,
  style,
  disabled,
}) => {
  const [hover, setHover] = useState(false);

  return (
    <img
      src={disabled ? iconDisabled : hover ? iconH : icon}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        cursor: disabled ? "not-allowed" : "pointer",

        width: style.width,

        visibility: style.visibility,
        cursor: !disabled && "pointer",
        ...style,
      }}
      onClick={!disabled && onClick}
    >
      {children}
    </img>
  );
};

ButtonIcon.propTypes = {
  dataTestId: PropTypes.string, // Definisci il tipo di prop "dataTestId"
  onClick: PropTypes.func, // Definisci il tipo di prop "onClick"
  children: PropTypes.node, // Definisci il tipo di prop "children"
};

ButtonIcon.defaultProps = {
  dataTestId: "default-data-testid", // Imposta un valore predefinito per "dataTestId"
  onClick: () => {}, // Imposta una funzione vuota come valore predefinito per "onClick"
};

export default ButtonIcon;
