/**
 * pose's specific actions for rxjs
 */
export const LOAD_DATA = "LOAD_DATA";
export const CLEAR_DATA = "CLEAR_DATA";

export const LOAD_INSPECTIONS = "LOAD_INSPECTIONS";
export const CLEAR_INSPECTIONS = "CLEAR_INSPECTIONS";

export const LOAD_LOCATION = "LOAD_LOCATION";
export const CLEAR_LOCATION = "CLEAR_LOCATION";

export const CLEAR_ALL = "CLEAR_ALL";
