import React from "react";

const TimeTableIcon = ({ fill, size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill={fill}
      style={{ minWidth: size, minHeight: size }}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.71084 13.7168C6.48983 13.7168 5.5 14.7066 5.5 15.9276C5.5 17.1487 6.48983 18.1385 7.71084 18.1385H20.6265C21.8475 18.1385 22.8373 17.1487 22.8373 15.9276C22.8373 14.7066 21.8475 13.7168 20.6265 13.7168H7.71084ZM31.9277 14.9276C26.9172 14.9276 22.8554 18.9894 22.8554 23.9999C22.8554 29.0104 26.9172 33.0722 31.9277 33.0722C36.9382 33.0722 41 29.0104 41 23.9999C41 18.9894 36.9382 14.9276 31.9277 14.9276ZM7.71084 21.7891C6.48983 21.7891 5.5 22.7789 5.5 23.9999C5.5 25.2209 6.48983 26.2108 7.71084 26.2108H17.3976C18.6186 26.2108 19.6084 25.2209 19.6084 23.9999C19.6084 22.7789 18.6186 21.7891 17.3976 21.7891H7.71084ZM7.71084 29.8614C6.48983 29.8614 5.5 30.8512 5.5 32.0722C5.5 33.2932 6.48983 34.2831 7.71084 34.2831H20.6265C21.8475 34.2831 22.8373 33.2932 22.8373 32.0722C22.8373 30.8512 21.8475 29.8614 20.6265 29.8614H7.71084Z"
      />
      <path
        fill="white"
        d="M33.1385 20.7709C33.1385 20.1022 32.5964 19.5601 31.9276 19.5601C31.2589 19.5601 30.7168 20.1022 30.7168 20.7709V23.7514C30.7168 24.0428 30.8218 24.3243 31.0126 24.5445L32.6271 26.4073C33.065 26.9126 33.8298 26.9673 34.3351 26.5293C34.8405 26.0913 34.8951 25.3266 34.4571 24.8213L33.1385 23.2998V20.7709Z"
      />
    </svg>
  );
};

export default TimeTableIcon;
