import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  AnalysisSelectorWrapper,
  AnalysisSelectorWrapperLateral,
  ButtonInfoWrapper,
  ButtonInfoWrapperSmall,
  Column,
  ColumnSmall,
  Grid,
  GridSmall,
  SubtitleAnalysisWrapper,
  TitleAnalysisWrapper,
} from "./AnalysisSelector.styled";
import ButtonToggle from "../buttons/ButtonToggle/ButtonToggle";
import { colors } from "../../config/style";
import { useTranslation } from "react-i18next";

export const Divider = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={"100%"}
      height="0.3vw"
      fill="none"
    >
      <path
        d="M2 2H766"
        stroke="#B6B4D1"
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  );
};

export const DividerSmallOld = ({ margin }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={"100%"}
      height="0.3vw"
      fill="rgba(237, 235, 255, 1)"
      style={{ margin: margin }}
      viewBox="0 0 768 1"
    >
      <path
        d="M0 0.5H768"
        stroke="rgba(237, 235, 255, 1)"
        stroke-width="1.2"
        stroke-linecap="round"
      />
    </svg>
  );
};

export const DividerSmall = ({ margin, width }) => {
  return (
    <div
      style={{
        padding: margin,
        width: `100%`,
        boxSizing: "border-box",
      }}
    >
      <div
        style={{
          height: "0.14vw",
          minHeight: "0.14vw",
          maxHeight: "0.14vw",
          backgroundColor: "#EFEDFF",
          width: `100%`,
          boxSizing: "border-box",
        }}
      ></div>
    </div>
  );
};

export const TwoColumnList = ({ items }) => {
  // Divide l'elenco di elementi in due parti
  const columns = [[], []];

  // Distribuisci gli elementi alternativamente tra le due colonne
  items.forEach((item, index) => {
    columns[index % 2].push(item);
  });

  return (
    <Grid>
      <Column>
        {columns[0].map((item, index) => (
          <>{item}</>
        ))}
      </Column>
      <Column>
        {columns[1].map((item, index) => (
          <>{item}</>
        ))}
      </Column>
    </Grid>
  );
};

export const TwoColumnListSmall = ({ items }) => {
  // Divide l'elenco di elementi in due parti
  const columns = [[], []];

  // Distribuisci gli elementi alternativamente tra le due colonne
  items.forEach((item, index) => {
    columns[index % 2].push(item);
  });

  return (
    <GridSmall>
      <ColumnSmall>
        {columns[0].map((item, index) => (
          <>{item}</>
        ))}
      </ColumnSmall>
      <ColumnSmall>
        {columns[1].map((item, index) => (
          <>{item}</>
        ))}
      </ColumnSmall>
    </GridSmall>
  );
};

const ButtonInfo = ({ item, onClick, toggle }) => {
  return (
    <ButtonInfoWrapper>
      <ButtonToggle
        onClick={() => onClick()}
        label={item.label}
        toggle={toggle}
        disabled={item.graph.length < 1 || isNaN(item.value)}
      />
      <b style={{ width: "12%" }}>{Math.round(item.value * 10) / 10}</b>{" "}
      {item.unit}
    </ButtonInfoWrapper>
  );
};

const ButtonInfoSmall = ({ item, onClick, toggle }) => {
  const [hover, setHover] = useState(false);
  return (
    <ButtonInfoWrapperSmall
      onClick={() => onClick()}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <img
        src={
          toggle || hover
            ? process.env.REACT_APP_RESOURCES_BUCKET+"Informationshw.png"
            : process.env.REACT_APP_RESOURCES_BUCKET+"Informations.png"
        }
        style={{
          width: "1.9vw",
          height: "1.9vw",
        }}
      />
      <p
        style={{
          fontFamily: "Atkinson Hyperlegible",
          fontSize: "0.9vw",
          fontWeight: toggle ? "800" : "400",
          cursor: "pointer",
          color: toggle || hover ? colors.main : colors.text,
          margin: "0",
        }}
      >
        {item.label}
      </p>
    </ButtonInfoWrapperSmall>
  );
};

const AnalysisComponent = ({ item, setAnalysis, analysis, setVideoLoaded }) => {
  if (analysis)
    return (
      <>
        <TitleAnalysisWrapper>{item.category}</TitleAnalysisWrapper>
        <Divider />
        <SubtitleAnalysisWrapper>{item.indications}</SubtitleAnalysisWrapper>
        <TwoColumnList
          items={Object.keys(item.analysis).map((x) => (
            <ButtonInfo
              onClick={() => {
                setAnalysis(item.analysis[x]);
                if (analysis.side != item.analysis[x].side)
                  setVideoLoaded(false);
              }}
              item={item.analysis[x]}
              toggle={analysis.label == item.analysis[x].label}
            />
          ))}
        />
      </>
    );
};

const AnalysisComponentLateral = ({
  item,
  setAnalysis,
  analysis,
  setVideoLoaded,
}) => {
  if (analysis)
    return (
      <>
        <TitleAnalysisWrapper
          style={{
            marginBottom: "0.5vw",
            fontSize: "0.9vw",
            fontWeight: "700",
          }}
        >
          {item.category}
        </TitleAnalysisWrapper>
        <DividerSmall />
        <TwoColumnListSmall
          items={Object.keys(item.analysis).map((x) => (
            <ButtonInfoSmall
              onClick={() => {
                setAnalysis(item.analysis[x]);
                if (analysis.side != item.analysis[x].side) {
                  setVideoLoaded(false);
                }
              }}
              item={item.analysis[x]}
              toggle={analysis.label == item.analysis[x].label}
            />
          ))}
        />
      </>
    );
};

const AnalysisSelector = ({
  dataTestId,
  items,
  setAnalysis,
  analysis,
  setVideoLoaded,
}) => {
  return (
    <AnalysisSelectorWrapper>
      {items.map((x) => (
        <AnalysisComponent
          analysis={analysis}
          item={x}
          setAnalysis={setAnalysis}
          setVideoLoaded={setVideoLoaded}
        />
      ))}
    </AnalysisSelectorWrapper>
  );
};

export const AnalysisSelectorLateral = ({
  dataTestId,
  items,
  setAnalysis,
  analysis,
  setVideoLoaded,
}) => {
  const { t } = useTranslation();
  return (
    <AnalysisSelectorWrapperLateral>
      {items.map((x) => (
        <AnalysisComponentLateral
          analysis={analysis}
          item={x}
          setAnalysis={setAnalysis}
          setVideoLoaded={setVideoLoaded}
        />
      ))}
    </AnalysisSelectorWrapperLateral>
  );
};

AnalysisSelector.propTypes = {
  dataTestId: PropTypes.string, // Definisci il tipo di prop "dataTestId"
};

AnalysisSelector.defaultProps = {
  dataTestId: "default-data-testid", // Imposta un valore predefinito per "dataTestId"
};

export default AnalysisSelector;
