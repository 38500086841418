import styled from "styled-components";

export const BodySchemaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 20.7vw;
  margin-top: -0.6vw;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const Cell = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 50%;
  height: 2vw;
  border-radius: 0.3vw;
  //   margin: 0.1vw;
  padding: 0.4vw;
  box-sizing: border-box;
  font-family: Atkinson Hyperlegible;
  font-size: 0.95vw;
  font-style: normal;
  font-weight: 400;
  width: 50%;
`;

export const ValueSpan = styled.span`
  font-weight: 800;
`;
