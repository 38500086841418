import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { colors } from "../../../config/style";
import {
  ButtonTextIconWrapper,
  IconButtonWrapper,
} from "./ButtonTextIcon.styled";

const ButtonTextIcon = ({
  onClick,
  icon,
  iconH,
  toggle,
  label,
  disabled,
  background,
  backgroundH,
  id,
  width,
  color,
  colorH,
  margin,
  style,
  iconHeight,
  loading,
  loadingLabel,
  loadingStyle,
}) => {
  const [hover, setHover] = useState(false);
  const buttonRef = useRef(null);
  const [buttonWidth, setButtonWidth] = useState(null);

  useEffect(() => {
    if (buttonRef.current && !loading) {
      setButtonWidth(buttonRef.current.offsetWidth);
    }
  }, [loading, label, icon]);

  const getBackground = () => {
    if (toggle) return colors.main;
    if (hover && !disabled) return backgroundH;
    return background;
  };

  const getBorder = () => {
    if (toggle) return colors.main;
    return colors.details;
  };

  const getColor = () => {
    if (color && colorH) return hover ? colorH : color;
    if (color) return color;
    return "white";
  };

  return (
    <ButtonTextIconWrapper
      ref={buttonRef}
      onMouseEnter={() => !loading && setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={disabled || loading === true ? () => {} : onClick}
      id={id}
      style={{
        cursor: disabled || loading === true ? "not-allowed" : "pointer",
        background: getBackground(),
        width: width ? width : "",
        minWidth: buttonWidth,
        color: getColor(),
        paddingRight: loading ? "0vw" : label ? "0.8vw" : "0.4vw",
        paddingLeft: loading && "0vw",
        opacity: disabled && 0.6,
        margin: margin,
        gap: "0.4vw",
        ...style,
      }}
    >
      {loading ? (
        <>
          <i
            className="fa fa-circle-o-notch fa-spin"
            style={
              loadingStyle
                ? loadingStyle
                : { marginLeft: "-12px", marginRight: "8px" }
            }
          ></i>
          {loadingLabel || loadingLabel == "" ? loadingLabel : "Caricamento"}
        </>
      ) : (
        <>
          <IconButtonWrapper
            style={{ height: iconHeight }}
            src={hover && iconH ? iconH : icon}
          />{" "}
          {label}
        </>
      )}
    </ButtonTextIconWrapper>
  );
};

ButtonTextIcon.propTypes = {
  dataTestId: PropTypes.string, // Definisci il tipo di prop "dataTestId"
  onClick: PropTypes.func, // Definisci il tipo di prop "onClick"
  children: PropTypes.node, // Definisci il tipo di prop "children"
};

ButtonTextIcon.defaultProps = {
  dataTestId: "default-data-testid", // Imposta un valore predefinito per "dataTestId"
  onClick: () => {}, // Imposta una funzione vuota come valore predefinito per "onClick"
};

export default ButtonTextIcon;
