import styled from "styled-components";
import { colors } from "../../config/style";

export const AnalysisSelectorWrapper = styled.div`
  width: 37vw;
  background: white;
  border-radius: 0.625vw;
  box-shadow: 0vw 0.2vw 0.7vw 0.2vw rgba(113, 109, 242, 0.1);
  padding: 1.2vw;
  padding-bottom: 0;
  margin-bottom: 0.65vw;
  box-sizing: border-box;
`;

export const AnalysisSelectorWrapperLateral = styled.div`
  padding-bottom: 0;
  margin-bottom: -0.65vw;
  box-sizing: border-box;
  width: 100%;
`;

export const Grid = styled.div`
  width: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  gap: 0.5vw;
  margin-bottom: 1vw;
`;

export const GridSmall = styled.div`
  width: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  gap: 0.1vw;
  margin-bottom: 0.5vw;
  margin-top: 0.5vw;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5vw;
`;

export const ColumnSmall = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.3vw;
`;

export const ButtonInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 0.4vw;
  font-family: Atkinson Hyperlegible;
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  width: 100%;
`;

export const ButtonInfoWrapperSmall = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 0.4vw;
  font-family: Atkinson Hyperlegible;
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  width: 100%;
  cursor: pointer;
`;

export const TitleAnalysisWrapper = styled.p`
  margin: 0;
  color: ${colors.text};
  font-family: Atkinson Hyperlegible;
  font-size: 1vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-edge: cap;
  text-transform: uppercase;
  margin-left: 0.2vw;
`;

export const SubtitleAnalysisWrapper = styled.p`
  margin: 0;
  color: ${colors.neutral};
  font-family: Atkinson Hyperlegible;
  font-size: 0.7vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-edge: cap;
  margin-bottom: 0.5vw;
`;
