import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { colors } from "../../config/style";
import { tips } from "../../config/tips";
import BoxIcon from "../../icons/BoxIcon";
import ClockIcon from "../../icons/ClockIcon";
import InfoIcon from "../../icons/InfoIcon";
import LIIcon from "../../icons/LIIcon";
import NotepadIcon from "../../icons/NotepadIcon";
import PeopleIcon from "../../icons/PeopleIcon";
import PersonIcon from "../../icons/PersonIcon";
import RulerIcon from "../../icons/RulerIcon";
import Timer2Icon from "../../icons/Timer2Icon";
import TimerIcon from "../../icons/TimerIcon";
import TimeTableIcon from "../../icons/TimeTableIcon";
import WeightIcon from "../../icons/WeightIcon";
import { DividerSmall } from "../AnalysisSelector/AnalysisSelector";
import BodySchema from "../BodySchema/BodySchema";
import ButtonTextIcon from "../buttons/ButtonTextIcon/ButtonTextIcon";
import InfoTab, {
  BasicText,
  ButtonTree,
  CheckBox,
  InfoBox,
  InfoButton,
  InfoTabScroller,
  Row,
  Selector,
  TextBox,
} from "../InfoTab/InfoTab";
import { formatDecimal } from "./AnalysisContainerFunctions";
import {
  MovementsTable,
  TableWeightCategory,
  TableWeightList,
  WeightTable,
} from "./AnalysisContainerTables";
import { PopupCronogram } from "../Popup/Popup";
import {
  durataOpt,
  updateWeightCatVli,
  vliGlobal,
} from "../../utils/Protocols/NIOSH/vli";
import { compareArrayOfObjects } from "../../utils/generalFunctions";
import { protocols } from "../../config/availableProtocols";

export const DataSelector = ({
  checklists_menu,
  checklistRef,
  setChecklist,
}) => {
  const { t } = useTranslation();

  const hasLeftOrRight = checklists_menu.some(
    (obj) => "sx" in obj || "dx" in obj
  );

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          justifyContent: "space-between",
          width: "100%",
          marginTop: "0vw",
        }}
      >
        <div
          style={{
            width: "100%",
            marginBottom: "0.5vw",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <p
            style={{
              color: colors.text,
              fontFamily: "Atkinson Hyperlegible",
              fontSize: "0.9vw",
              fontWeight: "700",

              marginTop: "0vw",
              marginBottom: "0vw",
              boxSizing: "border-box",
            }}
          >
            {t("checklistData")}
          </p>
          {hasLeftOrRight && (
            <p
              style={{
                color: colors.text,
                fontFamily: "Atkinson Hyperlegible",
                fontSize: "0.9vw",
                fontWeight: "700",

                marginTop: "0vw",
                marginBottom: "0vw",
                boxSizing: "border-box",
              }}
            >
              {t("SCORE")}
            </p>
          )}
        </div>
        <DividerSmall />
        {hasLeftOrRight && (
          <div
            style={{
              width: "100%",
              marginTop: "0.8vw",
              display: "flex",
              justifyContent: "end",
            }}
          >
            <p
              style={{
                color: colors.disabledText,
                fontFamily: "Atkinson Hyperlegible",
                fontSize: "0.9vw",
                fontWeight: "400",
                marginTop: "0vw",
                marginBottom: "0vw",
                boxSizing: "border-box",
                width: "15%",
                textAlign: "center",
              }}
            >
              {t("RI")}
            </p>
            <p
              style={{
                color: colors.disabledText,
                fontFamily: "Atkinson Hyperlegible",
                fontSize: "0.9vw",
                fontWeight: "400",
                marginTop: "0vw",
                marginBottom: "0vw",
                boxSizing: "border-box",
                width: "15%",
                textAlign: "center",
              }}
            >
              {t("LE")}
            </p>
          </div>
        )}
        {checklists_menu.map((x, id) => {
          return (
            <ButtonTree
              fullWidth={!hasLeftOrRight}
              text={x.label}
              toggle={checklistRef.current.id == x.id}
              icon={x.icon}
              sx={x.sx ? (id == 0 ? "" : x.complete ? x.sx : "-") : ""}
              dx={x.dx ? (id == 0 ? "" : x.complete ? x.dx : "-") : ""}
              map={x.map}
              style={{ marginLeft: "0vw" }}
              complete={x.complete}
              onClick={() => setChecklist(x)}
              disabled={x.disabled}
            />
          );
        })}
      </div>
    </>
  );
};

export const LeftHeader = ({ onClose, name, disabled }) => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "start",
        gap: "0.5vw",
        width: "100%",
        borderBottom: "0.136vw solid " + colors.background,
        paddingBottom: "0.5vw",
      }}
    >
      <ButtonTextIcon
        disabled={disabled}
        icon={process.env.REACT_APP_RESOURCES_BUCKET + "CrossesBig.png"}
        backgroundH={colors.tertiaryH}
        background={colors.tertiary}
        color={"white"}
        onClick={() => {
          onClose();
        }}
        style={{
          borderRadius: "2vw",
          width: "2.5vw",
          minWidth: "2.5vw",
          height: "2.5vw",
          minHeight: "2.5vw",
        }}
      />
      <p
        style={{
          color: colors.text,
          fontFamily: "Atkinson Hyperlegible",
          fontSize: "0.9vw",
          fontWeight: "400",
          marginLeft: "0.5vw",
          // marginTop: "0vw",
          boxSizing: "border-box",
        }}
      >
        <b>{name}</b>
      </p>
    </div>
  );
};

export const RightTitle = ({ title, edit }) => {
  const { t } = useTranslation();

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <p
        style={{
          color: colors.text,
          fontFamily: "Atkinson Hyperlegible",
          fontSize: "1.6vw",
          fontWeight: "700",
          width: "100%",
          margin: 0,
          marginBottom: "0.5vw",
          boxSizing: "border-box",
        }}
      >
        {title}
      </p>

      <div
        style={{
          color: colors.text,
          fontFamily: "Atkinson Hyperlegible",
          fontSize: "0.8vw",
          fontWeight: "400",
          background: "white",
          height: "1.4vw",
          borderRadius: "1vw",
          marginBottom: "0vw",
          boxSizing: "border-box",
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "center",
          width: "fit-content",
          padding: "0.5vw",
          boxShadow: "0vw 0.2vw 0.7vw 0.2vw rgba(113, 109, 242, 0.1)",
          visibility: (!edit || edit == "<>" || edit == "--") && "hidden",
        }}
      >
        {t("editedby")}: {edit}
      </div>
    </div>
  );
};

export const checklists_menu = ({
  ocraRes,
  ocraMemory,
  vliRes,
  vliMemory,
  t,
  selectedProtocol,
}) => {
  console.log("vliMemory", vliMemory);
  console.log("vliRes", vliRes);

  // OCRA
  if (selectedProtocol?.ref == protocols[0].ref || !selectedProtocol)
    return [
      {
        id: 0,
        icon: (fill, size) => <InfoIcon fill={fill} size={size} />,
        label: t("infoCycle"),
        complete: ocraMemory?.targa_ciclo >= 0,
        sx: null,
        dx: null,
      },
      {
        id: 1,
        icon: (fill, size) => <NotepadIcon fill={fill} size={size} />,
        label: t("technAction"),
        complete:
          ocraRes?.output?.rischio_AT_dx >= 0 &&
          ocraRes?.output?.rischio_AT_sx >= 0,
        dx: ocraRes?.output?.rischio_AT_dx,
        sx: ocraRes?.output?.rischio_AT_sx,
        map: "dist",
      },
      {
        id: 2,
        icon: (fill, size) => <NotepadIcon fill={fill} size={size} />,
        label: t("postIncong"),
        complete:
          ocraRes?.output?.rischio_totale_dx >= 0 &&
          ocraRes?.output?.rischio_totale_sx >= 0,
        dx: ocraRes?.output?.rischio_totale_dx,
        sx: ocraRes?.output?.rischio_totale_sx,
        map: "dist",
      },
      {
        id: 3,
        icon: (fill, size) => <NotepadIcon fill={fill} size={size} />,
        label: t("borgForces"),
        complete:
          ocraRes?.output?.risk_score_borg_dx >= 0 &&
          ocraRes?.output?.risk_score_borg_sx >= 0,
        dx: ocraRes?.output?.risk_score_borg_dx,
        sx: ocraRes?.output?.risk_score_borg_sx,
        map: "dist",
      },
      {
        id: 4,
        icon: (fill, size) => <NotepadIcon fill={fill} size={size} />,
        label: t("complFactors"),
        complete:
          ocraRes?.output?.complementare_dx >= 0 &&
          ocraRes?.output?.complementare_sx >= 0,
        dx: ocraRes?.output?.complementare_dx,
        sx: ocraRes?.output?.complementare_sx,
        map: "dist",
      },
    ];
  if (selectedProtocol?.ref == protocols[1].ref)
    return [
      {
        id: 0,
        icon: (fill, size) => <InfoIcon fill={fill} size={size} />,
        label: "1. Info produttive",
        complete:
          vliRes?.elenco_pesi_sum?.tot_oggetti_sollevati > 0 &&
          vliMemory?.n_lavoratori > 0 &&
          vliMemory?.cronogramma?.durata_netta_sollevamento > 0,
      },
      {
        id: 1,
        icon: (fill, size) => <WeightIcon fill={fill} size={size} />,
        label: "2. Categorie di peso",
        complete: vliMemory?.locked,
        disabled: !(
          vliRes?.elenco_pesi_sum?.tot_oggetti_sollevati > 0 &&
          vliMemory?.n_lavoratori > 0 &&
          vliMemory?.cronogramma?.durata_netta_sollevamento > 0
        ),
        map: "dist",
      },
      {
        id: 2,
        icon: (fill, size) => <RulerIcon fill={fill} size={size} />,
        label: "3. Geometrie posto di lavoro",
        complete: vliMemory?.locked,
        disabled: !(
          vliRes?.elenco_pesi_sum?.tot_oggetti_sollevati > 0 &&
          vliMemory?.n_lavoratori > 0 &&
          vliMemory?.cronogramma?.durata_netta_sollevamento > 0
        ),
        map: "dist",
      },
      // {
      //   id: 3,
      //   icon: (fill, size) => <LIIcon fill={fill} size={size} />,
      //   label: "4. Risultati Lifting Index",
      //   complete:
      //     ocraRes?.output.risk_score_borg_dx >= 0 &&
      //     ocraRes?.output.risk_score_borg_sx >= 0,

      //   map: "dist",
      // },
    ];
};

export const ChecklistScreens = ({
  id,
  videoEl,
  onChange,
  ocraMemory,
  setOcraMemory,
  ocraRes,
  vliMemory,
  setVliMemory,
  vliRes,
  protocol,
}) => {
  const [bottomComponentHeight, setBottomComponentHeight] = useState(0);

  const popupRef = useRef();
  const { t } = useTranslation();

  const bottomComponentHeightRef = useRef();

  bottomComponentHeightRef.current = bottomComponentHeight;

  console.log(
    "  bottomComponentHeightRef.current",
    bottomComponentHeightRef.current
  );

  let rowHeight = "1.5vw";
  let staticTecnicalOpt = [
    { label: t("notApplicable"), value: 0 },
    { label: t("2/3"), value: 1 },
    { label: t("allTime"), value: 2 },
  ];

  let stereoOpt = [
    { label: t("notApplicable"), value: 0 },
    { label: t("greatPartTime"), value: 1 },
    { label: t("almostAllTime"), value: 2 },
  ];

  let ritmoOpt = [
    { label: t("notApplicable"), value: 0 },
    { label: t("modulationPoss"), value: 1 },
    {
      label: t("lineVerySlow"),
      value: 2,
    },
    { label: t("noModulation"), value: 3 },
  ];

  const peso_default = {
    peso: -1,
    n_oggetti: -1,
    n_sollevamenti: -1,
  };

  // OCRA
  if (protocol.ref == protocols[0].ref && ocraMemory && ocraRes) {
    if (id == 0)
      return (
        <div
          style={{
            marginTop: "0.5vw",
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "start",
            gap: "0.8vw",
            zIndex: 99,
          }}
        >
          <div
            style={{
              marginTop: "0.5vw",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "start",
              width: "100%",
              height: "19.5vw",
            }}
          >
            <InfoTab
              width={"36.3vw"}
              height={"100%"}
              title={t("infoCycleTitle")}
              justifyContent={"start"}
              gap={"1vw"}
              content={
                <>
                  <Row justifyContent={"start"}>
                    <BasicText style={{ width: "24vw" }}>
                      {t("infoCycleTrueCycle") + " "}
                    </BasicText>
                    <CheckBox
                      value={ocraMemory.veri_cicli}
                      onClick={() => {
                        setOcraMemory({
                          ...ocraMemory,
                          veri_cicli: !ocraMemory.veri_cicli,
                          targa_ciclo: !ocraMemory.veri_cicli
                            ? -1
                            : ocraMemory.tempo_ciclo_osservato,
                        });
                      }}
                    />
                  </Row>

                  {ocraMemory.veri_cicli && (
                    <Row justifyContent={"start"}>
                      <BasicText
                        style={{
                          width: "24vw",
                        }}
                      >
                        {t("infoCycleTarga")}
                      </BasicText>
                      <TextBox
                        value={
                          ocraMemory.targa_ciclo <= -1
                            ? "-"
                            : ocraMemory.targa_ciclo
                        }
                        err={ocraMemory.targa_ciclo == -1}
                        unit={ocraMemory.targa_ciclo <= -1 ? "" : "s"}
                        onChange={(valueIn) => {
                          let value = valueIn.replace(/-/g, "");

                          if (!value) {
                            setOcraMemory({
                              ...ocraMemory,
                              targa_ciclo: -1,
                            });
                          } else if (formatDecimal(value)) {
                            setOcraMemory({
                              ...ocraMemory,
                              targa_ciclo: formatDecimal(value)
                                ? formatDecimal(value)
                                : 0,
                            });
                          }
                        }}
                      />
                    </Row>
                  )}

                  <Row justifyContent={"start"}>
                    <BasicText
                      style={{
                        width: "24vw",
                        color:
                          !ocraMemory.tempo_ciclo_osservato && colors.negative,
                      }}
                    >
                      {t("infoCycleObs")}
                    </BasicText>
                    <InfoBox
                      value={ocraMemory.tempo_ciclo_osservato}
                      unit={"s"}
                      ai={false}
                      info={tips[document.language].tempo_ciclo_osservato}
                      decimals={1}
                    />
                  </Row>
                  <Row justifyContent={"start"}>
                    <BasicText
                      style={{
                        width: "24vw",
                      }}
                    >
                      {t("infoCycleDiff")}
                    </BasicText>
                    <InfoBox
                      value={ocraRes.output.percentuale_differenza_ciclo}
                      err={ocraRes.output.percentuale_differenza_ciclo > 5}
                      unit={"%"}
                      ai={false}
                      info={
                        tips[document.language].percentuale_differenza_ciclo
                      }
                    />
                  </Row>
                </>
              }
            />
            {videoEl}
          </div>
          <InfoTabScroller
            style={{ width: "100%" }}
            key={id}
            infoTab={
              <InfoTab
                width={"100%"}
                height={"100%"}
                title={[t("infoCycleRecoveryTime")]}
                info={[""]}
                justifyContent={"start"}
                gap={"1vw"}
                content={[
                  <>
                    <Row justifyContent={"start"}>
                      <BasicText style={{ width: "24vw" }}>
                        {t("infoCycleRecoveryPresent")}
                      </BasicText>
                      <CheckBox
                        value={ocraMemory.flag_tempi_recupero}
                        onClick={() => {
                          setOcraMemory({
                            ...ocraMemory,
                            flag_tempi_recupero:
                              !ocraMemory.flag_tempi_recupero,
                          });
                        }}
                      />
                    </Row>
                    {ocraMemory.flag_tempi_recupero && (
                      <>
                        <Row justifyContent={"start"}>
                          <BasicText
                            style={{
                              width: "24vw",
                              color:
                                !ocraMemory.totale_tempo_attivo &&
                                colors.negative,
                            }}
                          >
                            {t("infoCycleActiveTime")}
                          </BasicText>
                          <TextBox
                            value={
                              ocraMemory.totale_tempo_attivo == -1
                                ? "-"
                                : ocraMemory.totale_tempo_attivo
                            }
                            locked={!ocraMemory.flag_tempi_recupero}
                            unit={
                              ocraMemory.totale_tempo_attivo == -1 ? "" : "s"
                            }
                            onChange={(valueIn) => {
                              let value = valueIn.replace(/-/g, "");

                              if (!value) {
                                setOcraMemory({
                                  ...ocraMemory,
                                  totale_tempo_attivo: -1,
                                });
                              } else if (formatDecimal(value)) {
                                setOcraMemory({
                                  ...ocraMemory,
                                  totale_tempo_attivo: formatDecimal(value)
                                    ? formatDecimal(value)
                                    : 0,
                                });
                              }
                            }}
                            marginRight={"0.7vw"}
                          />
                          <BasicText
                            style={{
                              width: "24vw",
                              marginLeft: "5vw",
                              color:
                                !ocraRes.output.percentuale_tempo_attivo &&
                                colors.negative,
                            }}
                          >
                            {t("infoCycleActiveTime%")}
                          </BasicText>
                          <InfoBox
                            value={
                              ocraRes.output.percentuale_tempo_attivo < 0
                                ? "-"
                                : Math.round(
                                    ocraRes.output.percentuale_tempo_attivo
                                  )
                            }
                            unit={"%"}
                            ai={false}
                            info={tips[document.language].percentuale_t_ciclo}
                          />
                        </Row>
                        <Row justifyContent={"start"}>
                          <BasicText
                            style={{
                              width: "24vw",
                              color:
                                !ocraRes.output.totale_secondi_tempo_passivo &&
                                colors.negative,
                            }}
                          >
                            {t("infoCyclePassiveTime")}
                          </BasicText>

                          <InfoBox
                            value={
                              ocraRes.output.totale_secondi_tempo_passivo < 0
                                ? "-"
                                : Math.round(
                                    ocraRes.output
                                      .totale_secondi_tempo_passivo * 10
                                  ) / 10
                            }
                            unit={"s"}
                            ai={false}
                            info={
                              tips[document.language]
                                .totale_secondi_tempo_passivo
                            }
                          />
                          <BasicText
                            style={{
                              width: "24vw",
                              marginLeft: "5vw",
                              color:
                                !ocraRes.output.percentuale_tempo_passivo &&
                                colors.negative,
                            }}
                          >
                            {t("infoCyclePassiveTime%")}
                          </BasicText>
                          <InfoBox
                            value={
                              ocraRes.output.percentuale_tempo_passivo < 0
                                ? "-"
                                : Math.round(
                                    ocraRes.output.percentuale_tempo_passivo
                                  )
                            }
                            unit={"%"}
                            ai={false}
                            info={tips[document.language].percentuale_t_ciclo}
                          />
                        </Row>
                        {/* <Row justifyContent={"start"}>
                          <BasicText
                            style={{
                              width: "24vw",
                              color:
                                !ocraRes.output.minuti_recupero_ciclo &&
                                colors.negative,
                            }}
                          >
                            Tempo totale di recupero nel turno (minuti)
                          </BasicText>
  
                          <InfoBox
                            value={Math.round(
                              ocraRes.output.minuti_recupero_ciclo
                            )}
                            unit={"m"}
                            ai={false}
                            info={tips[document.language].minuti_recupero_ciclo}
                          />
                        </Row> */}
                        <Row justifyContent={"start"}>
                          <BasicText
                            style={{
                              width: "24vw",
                              color:
                                !ocraRes.output.moltiplicatore &&
                                colors.negative,
                            }}
                          >
                            {t("infoCycleCoeffP1")}
                            <br></br>
                            {t("infoCycleCoeffP2")}
                          </BasicText>

                          <InfoBox
                            value={
                              ocraRes.output.rapporto_tempo_attivo_passivo < 0
                                ? "-"
                                : ocraRes.output.rapporto_tempo_attivo_passivo
                            }
                            unit={""}
                            ai={false}
                            err={
                              ocraRes.output.rapporto_tempo_attivo_passivo > 6.5
                            }
                            info={
                              tips[document.language]
                                .rapporto_tempo_attivo_passivo
                            }
                          />
                        </Row>
                      </>
                    )}
                  </>,
                ]}
              />
            }
          />
        </div>
      );
    if (id == 1)
      return (
        <div
          style={{
            marginTop: "0.5vw",
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "start",
            gap: "0.8vw",
            zIndex: 99,
          }}
        >
          <div
            style={{
              marginTop: "0.5vw",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "start",
              width: "100%",
              height: "19.5vw",
            }}
          >
            <InfoTab
              width={"36.3vw"}
              height={"100%"}
              title={t("tecnicalTitle")}
              justifyContent={"start"}
              gap={"1vw"}
              style={{ overflow: "hidden" }}
              content={
                <>
                  <BodySchema
                    items={[
                      {
                        analysis: {
                          destra: {
                            value: ocraRes.output.rischio_AT_dx,
                            label: "DX",
                            map: "dist",
                          },
                          sinistra: {
                            value: ocraRes.output.rischio_AT_sx,
                            label: "SX",
                            map: "dist",
                          },
                        },
                      },
                    ]}
                  />{" "}
                </>
              }
            />
            {videoEl}
          </div>
          <InfoTabScroller
            style={{ width: "100%" }}
            key={id}
            infoTab={
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "end",
                  // height: "100%",
                  // width: "100%",
                  // backgroundColor: "white",
                  // overflowY: "scroll",
                  // borderRadius: "0.3vw",
                  // boxShadow: "0px 0.2vw 0.8vw 0.2vw rgba(113, 109, 242, 0.1)",
                }}
              >
                <InfoTab
                  width={"23.9vw"}
                  // height={"100%"}
                  title={[t("tecnicalDyn"), t("tecnicalSta")]}
                  justifyContent={"start"}
                  justifyContentMain={"start"}
                  gap={"2vw"}
                  embedded={true}
                  content={[
                    <>
                      <Row justifyContent={"start"} height={rowHeight}>
                        <BasicText
                          style={{
                            width: "25vw",
                          }}
                        >
                          {t("tecnicalNumber")}
                        </BasicText>
                      </Row>
                      <Row justifyContent={"start"} height={rowHeight}>
                        <BasicText
                          style={{
                            width: "25vw",
                          }}
                        >
                          {t("tecnicalFreq")}
                        </BasicText>
                      </Row>
                    </>,
                    <>
                      <Row justifyContent={"start"} height={rowHeight}>
                        <BasicText
                          style={{
                            width: "25vw",
                          }}
                        >
                          {t("tecnicalStati")}
                        </BasicText>
                      </Row>
                    </>,
                  ]}
                />
                <InfoTab
                  width={"23.9vw"}
                  title={[t("RIGHT"), "\u00A0"]}
                  justifyContent={"start"}
                  justifyContentMain={"start"}
                  gap={"2vw"}
                  embedded={true}
                  content={[
                    <>
                      <Row justifyContent={"start"} height={rowHeight}>
                        <InfoBox
                          value={ocraMemory.numero_azioni_dinamiche_dx}
                          unit={""}
                          ai={true}
                          info={tips[document.language].ai_analysis_azioni}
                        />
                      </Row>
                      <Row justifyContent={"start"} height={rowHeight}>
                        <InfoBox
                          value={ocraRes.output.freq_azioni_dinamiche_dx}
                          unit={""}
                          ai={false}
                          info={tips[document.language].frequenza_azioni}
                        />
                      </Row>
                    </>,
                    <>
                      <Row justifyContent={"start"} height={rowHeight}>
                        <Selector
                          err={ocraMemory.flag_azioni_statiche_dx == -1}
                          options={staticTecnicalOpt}
                          value={
                            staticTecnicalOpt[
                              ocraMemory.flag_azioni_statiche_dx
                            ]
                          }
                          onChange={(value) =>
                            setOcraMemory({
                              ...ocraMemory,
                              flag_azioni_statiche_dx: value.value,
                            })
                          }
                          width={"19.5vw"}
                          height={"2.5vw"}
                        />
                      </Row>
                    </>,
                  ]}
                />
                <InfoTab
                  width={"23.9vw"}
                  title={[t("LEFT"), "\u00A0"]}
                  justifyContent={"start"}
                  justifyContentMain={"start"}
                  gap={"2vw"}
                  embedded={true}
                  content={[
                    <>
                      <Row justifyContent={"start"} height={rowHeight}>
                        <InfoBox
                          value={ocraMemory.numero_azioni_dinamiche_sx}
                          unit={""}
                          ai={true}
                          info={tips[document.language].ai_analysis_azioni}
                        />
                      </Row>
                      <Row justifyContent={"start"} height={rowHeight}>
                        <InfoBox
                          value={ocraRes.output.freq_azioni_dinamiche_sx}
                          unit={""}
                          ai={false}
                          info={tips[document.language].frequenza_azioni}
                        />
                      </Row>
                    </>,
                    <>
                      <Row justifyContent={"start"} height={rowHeight}>
                        <Selector
                          err={ocraMemory.flag_azioni_statiche_sx == -1}
                          options={staticTecnicalOpt}
                          value={
                            staticTecnicalOpt[
                              ocraMemory.flag_azioni_statiche_sx
                            ]
                          }
                          onChange={(value) =>
                            setOcraMemory({
                              ...ocraMemory,
                              flag_azioni_statiche_sx: value.value,
                            })
                          }
                          width={"19.5vw"}
                          height={"2.5vw"}
                        />
                      </Row>
                    </>,
                  ]}
                />
              </div>
            }
          />
        </div>
      );
    if (id == 2)
      return (
        <div
          style={{
            marginTop: "0.5vw",
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "start",
            gap: "0.8vw",
          }}
        >
          <div
            style={{
              marginTop: "0.5vw",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "start",
              width: "100%",
              height: "19.5vw",
            }}
          >
            <InfoTab
              width={"36.3vw"}
              height={"100%"}
              title={t("posturalTitle")}
              justifyContent={"start"}
              gap={"1vw"}
              style={{ overflow: "hidden" }}
              content={
                <>
                  <BodySchema
                    items={[
                      {
                        analysis: {
                          shDx: {
                            value: ocraRes.output.rischi_totali.spalla_dx,
                            label: t("shoulder") + " DX",
                            map: "dist",
                          },
                          shLx: {
                            value: ocraRes.output.rischi_totali.spalla_sx,
                            label: t("shoulder") + " SX",
                            map: "dist",
                          },
                          elDx: {
                            value: ocraRes.output.rischi_totali.gomito_dx,
                            label: t("elbow") + " DX",
                            map: "dist",
                          },
                          elLx: {
                            value: ocraRes.output.rischi_totali.gomito_sx,
                            label: t("elbow") + " SX",
                            map: "dist",
                          },
                          wrDx: {
                            value: ocraRes.output.rischi_totali.polso_dx,
                            label: t("wrist") + " DX",
                            map: "dist",
                          },
                          wrLx: {
                            value: ocraRes.output.rischi_totali.polso_sx,
                            label: t("wrist") + " SX",
                            map: "dist",
                          },
                          haDx: {
                            value: ocraRes.output.rischi_totali.mano_dx,
                            label: t("hand") + " DX",
                            map: "dist",
                          },
                          haLx: {
                            value: ocraRes.output.rischi_totali.mano_sx,
                            label: t("hand") + " SX",
                            map: "dist",
                          },
                          stDx: {
                            value: ocraRes.output.stereotipia_dx,
                            label: t("stereotipia") + " DX",
                            map: "stereo",
                          },
                          stLx: {
                            value: ocraRes.output.stereotipia_sx,
                            label: t("stereotipia") + " SX",
                            map: "stereo",
                          },
                        },
                      },
                    ]}
                  />{" "}
                </>
              }
            />
            {videoEl}
          </div>
          <InfoTabScroller
            style={{ width: "100%" }}
            key={id}
            infoTab={
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "start",
                }}
              >
                <InfoTab
                  width={"23.9vw"}
                  // height={"100%"}
                  title={[t("posturalWrong"), t("posturalStereo")]}
                  justifyContent={"start"}
                  justifyContentMain={"start"}
                  gap={"2vw"}
                  embedded={true}
                  content={[
                    <>
                      <Row justifyContent={"start"} height={"0vw"}></Row>

                      <Row justifyContent={"start"} height={rowHeight}>
                        <BasicText
                          style={{
                            width: "25vw",
                          }}
                        >
                          {t("posturalShoulders")}
                        </BasicText>
                      </Row>

                      <Row justifyContent={"start"} height={rowHeight}>
                        <BasicText
                          style={{
                            width: "25vw",
                          }}
                        >
                          {t("posturalElbows")}
                        </BasicText>
                      </Row>
                      <Row justifyContent={"start"} height={rowHeight}>
                        <BasicText
                          style={{
                            width: "25vw",
                          }}
                        >
                          {t("posturalWrists")}
                        </BasicText>
                      </Row>
                      <Row justifyContent={"start"} height={rowHeight}>
                        <BasicText
                          style={{
                            width: "25vw",
                          }}
                        >
                          {t("posturalHands")}
                        </BasicText>
                      </Row>
                    </>,
                    <>
                      <Row justifyContent={"start"} height={rowHeight}>
                        <BasicText
                          style={{
                            width: "25vw",
                          }}
                        >
                          {t("posturalRepetition")}
                        </BasicText>
                      </Row>
                    </>,
                  ]}
                />
                <InfoTab
                  width={"23.9vw"}
                  title={[t("RIGHT"), "\u00A0"]}
                  justifyContent={"start"}
                  justifyContentMain={"start"}
                  gap={"2vw"}
                  embedded={true}
                  content={[
                    <>
                      <Row justifyContent={"start"} height={"0vw"}>
                        <BasicText
                          style={{
                            width: "17vw",
                            color: colors.disabledText,
                          }}
                        >
                          {t("SECONDS")}
                        </BasicText>
                        <BasicText
                          style={{
                            width: "25vw",
                            color: colors.disabledText,
                          }}
                        >
                          {t("tcycle")}
                        </BasicText>
                      </Row>

                      <Row justifyContent={"start"} height={rowHeight}>
                        <InfoBox
                          value={ocraMemory.secondi_spalla_incongrua_dx}
                          unit={"s"}
                          ai={true}
                          info={tips[document.language].ai_analysis_braccia}
                          marginRight={"1vw"}
                        />
                        <InfoBox
                          value={ocraRes.output.percentuali_incongrue.spalla_dx}
                          unit={"%"}
                          ai={false}
                          info={tips[document.language].percentuale_t_ciclo}
                        />
                      </Row>
                      <Row justifyContent={"start"} height={rowHeight}>
                        <InfoBox
                          value={ocraMemory.secondi_gomito_incongrua_dx}
                          unit={"s"}
                          ai={true}
                          info={tips[document.language].ai_analysis_gomito}
                          marginRight={"1vw"}
                        />
                        <InfoBox
                          value={ocraRes.output.percentuali_incongrue.gomito_dx}
                          unit={"%"}
                          ai={false}
                          info={tips[document.language].percentuale_t_ciclo}
                        />
                      </Row>
                      <Row justifyContent={"start"} height={rowHeight}>
                        <InfoBox
                          value={ocraMemory.secondi_polso_incongrua_dx}
                          unit={"s"}
                          ai={true}
                          info={tips[document.language].ai_analysis_polso}
                          marginRight={"1vw"}
                        />
                        <InfoBox
                          value={ocraRes.output.percentuali_incongrue.polso_dx}
                          unit={"%"}
                          ai={false}
                          info={tips[document.language].percentuale_t_ciclo}
                        />
                      </Row>

                      <Row justifyContent={"start"} height={rowHeight}>
                        <InfoBox
                          value={ocraMemory.secondi_mano_incongrua_dx}
                          unit={"s"}
                          ai={true}
                          info={tips[document.language].ai_analysis_mano}
                          marginRight={"1vw"}
                        />
                        <InfoBox
                          value={ocraRes.output.percentuali_incongrue.mano_dx}
                          unit={"%"}
                          ai={false}
                          info={tips[document.language].percentuale_t_ciclo}
                        />
                      </Row>
                    </>,
                    <>
                      <Row justifyContent={"start"} height={rowHeight}>
                        <Selector
                          err={ocraMemory.stereotipia_ripetizione_dx == -1}
                          options={stereoOpt}
                          value={
                            stereoOpt[ocraMemory.stereotipia_ripetizione_dx]
                          }
                          onChange={(value) => {
                            setOcraMemory({
                              ...ocraMemory,
                              stereotipia_ripetizione_dx: value.value,
                            });
                          }}
                          width={"19.5vw"}
                          height={"2.5vw"}
                        />
                      </Row>
                    </>,
                  ]}
                />
                <InfoTab
                  width={"23.9vw"}
                  title={[t("LEFT"), "\u00A0"]}
                  justifyContent={"start"}
                  justifyContentMain={"start"}
                  gap={"2vw"}
                  embedded={true}
                  content={[
                    <>
                      <Row justifyContent={"start"} height={"0vw"}>
                        <BasicText
                          style={{
                            width: "17vw",
                            color: colors.disabledText,
                          }}
                        >
                          {t("SECONDS")}
                        </BasicText>
                        <BasicText
                          style={{
                            width: "25vw",
                            color: colors.disabledText,
                          }}
                        >
                          {t("tcycle")}
                        </BasicText>
                      </Row>

                      <Row justifyContent={"start"} height={rowHeight}>
                        <InfoBox
                          value={ocraMemory.secondi_spalla_incongrua_sx}
                          unit={"s"}
                          ai={true}
                          info={tips[document.language].ai_analysis_braccia}
                          marginRight={"1vw"}
                        />
                        <InfoBox
                          value={ocraRes.output.percentuali_incongrue.spalla_sx}
                          unit={"%"}
                          ai={false}
                          info={tips[document.language].percentuale_t_ciclo}
                        />
                      </Row>
                      <Row justifyContent={"start"} height={rowHeight}>
                        <InfoBox
                          value={ocraMemory.secondi_gomito_incongrua_sx}
                          unit={"s"}
                          ai={true}
                          info={tips[document.language].ai_analysis_gomito}
                          marginRight={"1vw"}
                        />
                        <InfoBox
                          value={ocraRes.output.percentuali_incongrue.gomito_sx}
                          unit={"%"}
                          ai={false}
                          info={tips[document.language].percentuale_t_ciclo}
                        />
                      </Row>
                      <Row justifyContent={"start"} height={rowHeight}>
                        <InfoBox
                          value={ocraMemory.secondi_polso_incongrua_sx}
                          unit={"s"}
                          ai={true}
                          info={tips[document.language].ai_analysis_polso}
                          marginRight={"1vw"}
                        />
                        <InfoBox
                          value={ocraRes.output.percentuali_incongrue.polso_sx}
                          unit={"%"}
                          ai={false}
                          info={tips[document.language].percentuale_t_ciclo}
                        />
                      </Row>

                      <Row justifyContent={"start"} height={rowHeight}>
                        <InfoBox
                          value={ocraMemory.secondi_mano_incongrua_sx}
                          unit={"s"}
                          ai={true}
                          info={tips[document.language].ai_analysis_mano}
                          marginRight={"1vw"}
                        />
                        <InfoBox
                          value={ocraRes.output.percentuali_incongrue.mano_sx}
                          unit={"%"}
                          ai={false}
                          info={tips[document.language].percentuale_t_ciclo}
                        />
                      </Row>
                    </>,
                    <>
                      <Row justifyContent={"start"} height={rowHeight}>
                        <Selector
                          err={ocraMemory.stereotipia_ripetizione_sx == -1}
                          options={stereoOpt}
                          value={
                            stereoOpt[ocraMemory.stereotipia_ripetizione_sx]
                          }
                          onChange={(value) => {
                            setOcraMemory({
                              ...ocraMemory,
                              stereotipia_ripetizione_sx: value.value,
                            });
                          }}
                          width={"19.5vw"}
                          height={"2.5vw"}
                        />
                      </Row>
                    </>,
                  ]}
                />
              </div>
            }
          />
        </div>
      );
    if (id == 3)
      return (
        <div
          style={{
            marginTop: "0.5vw",
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "start",
            gap: "0.8vw",
          }}
        >
          <div
            style={{
              marginTop: "0.5vw",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "start",
              width: "100%",
              height: "19.5vw",
            }}
          >
            <InfoTab
              width={"36.3vw"}
              height={"100%"}
              title={t("borgTitle")}
              justifyContent={"start"}
              gap={"1vw"}
              style={{ overflow: "hidden" }}
              content={
                <>
                  <BodySchema
                    items={[
                      {
                        analysis: {
                          destra: {
                            value: ocraRes.output.risk_score_borg_dx,
                            label: "DX",
                            map: "dist",
                          },
                          sinistra: {
                            value: ocraRes.output.risk_score_borg_sx,
                            label: "SX",
                            map: "dist",
                          },
                        },
                      },
                    ]}
                  />{" "}
                </>
              }
            />
            {videoEl}
          </div>
          <InfoTabScroller
            style={{ width: "100%" }}
            key={id}
            infoTab={
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "start",
                }}
              >
                <InfoTab
                  width={"23.9vw"}
                  // height={"100%"}
                  title={[t("borgTitleSec")]}
                  justifyContent={"start"}
                  justifyContentMain={"start"}
                  gap={"2vw"}
                  embedded={true}
                  content={[
                    <>
                      <Row justifyContent={"start"} height={"0vw"}></Row>
                      <Row justifyContent={"start"} height={rowHeight}>
                        <BasicText
                          style={{
                            width: "25vw",
                          }}
                        >
                          {t("borgModerate")}
                        </BasicText>
                      </Row>
                      <Row justifyContent={"start"} height={rowHeight}>
                        <BasicText
                          style={{
                            width: "25vw",
                          }}
                        >
                          {t("borgElevate1") + " "}
                          <b>{t("borgElevate2")}</b>
                        </BasicText>
                      </Row>
                      <Row justifyContent={"start"} height={rowHeight}>
                        <BasicText
                          style={{
                            width: "25vw",
                          }}
                        >
                          {t("borgPeak1") + " "}
                          <b>{t("borgPeak2")}</b>
                        </BasicText>
                      </Row>
                    </>,
                  ]}
                />
                <InfoTab
                  width={"23.9vw"}
                  title={[t("RIGHT")]}
                  justifyContent={"start"}
                  justifyContentMain={"start"}
                  gap={"2vw"}
                  embedded={true}
                  content={[
                    <>
                      <Row justifyContent={"start"} height={"0vw"}>
                        <BasicText
                          style={{
                            width: "17vw",
                            color: colors.disabledText,
                          }}
                        >
                          {t("SECONDS")}
                        </BasicText>
                        <BasicText
                          style={{
                            width: "25vw",
                            color: colors.disabledText,
                          }}
                        >
                          {t("%tcycle")}
                        </BasicText>
                      </Row>

                      <Row justifyContent={"start"} height={rowHeight}>
                        <TextBox
                          value={
                            ocraMemory.forza_moderata_dx_sec == -1
                              ? "-"
                              : ocraMemory.forza_moderata_dx_sec
                          }
                          onChange={(valueIn) => {
                            let value = valueIn.replace(/-/g, "");
                            if (!value) {
                              value = 0;
                            }
                            if (formatDecimal(value)) {
                              if (
                                parseFloat(formatDecimal(value)) <=
                                ocraMemory.targa_ciclo
                              ) {
                                setOcraMemory({
                                  ...ocraMemory,
                                  forza_moderata_dx_sec: formatDecimal(value)
                                    ? formatDecimal(value)
                                    : 0,
                                });
                              }
                            }
                          }}
                          unit={ocraMemory.forza_moderata_dx_sec != -1 && "s"}
                          marginRight={"1.2vw"}
                          err={ocraMemory.forza_moderata_dx_sec == -1}
                        />
                        <InfoBox
                          value={ocraRes.output.borg_percentuali_dx.moderata}
                          unit={"%"}
                          ai={false}
                          info={tips[document.language].percentuale_t_ciclo}
                        />
                      </Row>
                      <Row justifyContent={"start"} height={rowHeight}>
                        <TextBox
                          value={
                            ocraMemory.forza_elevata_dx_sec == -1
                              ? "-"
                              : ocraMemory.forza_elevata_dx_sec
                          }
                          onChange={(valueIn) => {
                            let value = valueIn.replace(/-/g, "");
                            if (!value) {
                              value = 0;
                            }
                            if (formatDecimal(value)) {
                              if (
                                parseFloat(formatDecimal(value)) <=
                                ocraMemory.targa_ciclo
                              )
                                setOcraMemory({
                                  ...ocraMemory,
                                  forza_elevata_dx_sec: formatDecimal(value)
                                    ? formatDecimal(value)
                                    : 0,
                                });
                            }
                          }}
                          err={ocraMemory.forza_elevata_dx_sec == -1}
                          unit={ocraMemory.forza_elevata_dx_sec != -1 && "s"}
                          marginRight={"1.2vw"}
                        />
                        <InfoBox
                          value={ocraRes.output.borg_percentuali_dx.elevata}
                          unit={"%"}
                          ai={false}
                          info={tips[document.language].percentuale_t_ciclo}
                        />
                      </Row>
                      <Row justifyContent={"start"} height={rowHeight}>
                        <TextBox
                          value={
                            ocraMemory.picchi_di_forza_dx_sec == -1
                              ? "-"
                              : ocraMemory.picchi_di_forza_dx_sec
                          }
                          onChange={(valueIn) => {
                            let value = valueIn.replace(/-/g, "");
                            if (!value) {
                              value = 0;
                            }
                            if (formatDecimal(value)) {
                              if (
                                parseFloat(formatDecimal(value)) <=
                                ocraMemory.targa_ciclo
                              )
                                setOcraMemory({
                                  ...ocraMemory,
                                  picchi_di_forza_dx_sec: formatDecimal(value)
                                    ? formatDecimal(value)
                                    : 0,
                                });
                            }
                          }}
                          err={ocraMemory.picchi_di_forza_dx_sec == -1}
                          unit={ocraMemory.picchi_di_forza_dx_sec != -1 && "s"}
                          marginRight={"1.2vw"}
                        />
                        <InfoBox
                          value={ocraRes.output.borg_percentuali_dx.picchi}
                          unit={"%"}
                          ai={false}
                          info={tips[document.language].percentuale_t_ciclo}
                        />
                      </Row>
                    </>,
                  ]}
                />
                <InfoTab
                  width={"23.9vw"}
                  title={[t("LEFT")]}
                  justifyContent={"start"}
                  justifyContentMain={"start"}
                  gap={"2vw"}
                  embedded={true}
                  content={[
                    <>
                      <Row justifyContent={"start"} height={"0vw"}>
                        <BasicText
                          style={{
                            width: "17vw",
                            color: colors.disabledText,
                          }}
                        >
                          {t("SECONDS")}
                        </BasicText>
                        <BasicText
                          style={{
                            width: "25vw",
                            color: colors.disabledText,
                          }}
                        >
                          {t("%tcycle")}
                        </BasicText>
                      </Row>

                      <Row justifyContent={"start"} height={rowHeight}>
                        <TextBox
                          value={
                            ocraMemory.forza_moderata_sx_sec == -1
                              ? "-"
                              : ocraMemory.forza_moderata_sx_sec
                          }
                          onChange={(valueIn) => {
                            let value = valueIn.replace(/-/g, "");
                            if (!value) {
                              value = 0;
                            }
                            if (formatDecimal(value)) {
                              if (
                                parseFloat(formatDecimal(value)) <=
                                ocraMemory.targa_ciclo
                              )
                                setOcraMemory({
                                  ...ocraMemory,
                                  forza_moderata_sx_sec: formatDecimal(value)
                                    ? formatDecimal(value)
                                    : 0,
                                });
                            }
                          }}
                          err={ocraMemory.forza_moderata_sx_sec == -1}
                          unit={ocraMemory.forza_moderata_sx_sec != -1 && "s"}
                          marginRight={"1.2vw"}
                        />
                        <InfoBox
                          value={ocraRes.output.borg_percentuali_sx.moderata}
                          unit={"%"}
                          ai={false}
                          info={tips[document.language].percentuale_t_ciclo}
                        />
                      </Row>
                      <Row justifyContent={"start"} height={rowHeight}>
                        <TextBox
                          value={
                            ocraMemory.forza_elevata_sx_sec == -1
                              ? "-"
                              : ocraMemory.forza_elevata_sx_sec
                          }
                          onChange={(valueIn) => {
                            let value = valueIn.replace(/-/g, "");
                            if (!value) {
                              value = 0;
                            }
                            if (formatDecimal(value)) {
                              if (
                                parseFloat(formatDecimal(value)) <=
                                ocraMemory.targa_ciclo
                              )
                                setOcraMemory({
                                  ...ocraMemory,
                                  forza_elevata_sx_sec: formatDecimal(value)
                                    ? formatDecimal(value)
                                    : 0,
                                });
                            }
                          }}
                          err={ocraMemory.forza_elevata_sx_sec == -1}
                          unit={ocraMemory.forza_elevata_sx_sec != -1 && "s"}
                          marginRight={"1.2vw"}
                        />
                        <InfoBox
                          value={ocraRes.output.borg_percentuali_sx.elevata}
                          unit={"%"}
                          ai={false}
                          info={tips[document.language].percentuale_t_ciclo}
                        />
                      </Row>
                      <Row justifyContent={"start"} height={rowHeight}>
                        <TextBox
                          value={
                            ocraMemory.picchi_di_forza_sx_sec == -1
                              ? "-"
                              : ocraMemory.picchi_di_forza_sx_sec
                          }
                          onChange={(valueIn) => {
                            let value = valueIn.replace(/-/g, "");
                            if (!value) {
                              value = 0;
                            }
                            if (formatDecimal(value)) {
                              if (
                                parseFloat(formatDecimal(value)) <=
                                ocraMemory.targa_ciclo
                              )
                                setOcraMemory({
                                  ...ocraMemory,
                                  picchi_di_forza_sx_sec: formatDecimal(value)
                                    ? formatDecimal(value)
                                    : 0,
                                });
                            }
                          }}
                          err={ocraMemory.picchi_di_forza_sx_sec == -1}
                          unit={ocraMemory.picchi_di_forza_sx_sec != -1 && "s"}
                          marginRight={"1.2vw"}
                        />
                        <InfoBox
                          value={ocraRes.output.borg_percentuali_sx.picchi}
                          unit={"%"}
                          ai={false}
                          info={tips[document.language].percentuale_t_ciclo}
                        />
                      </Row>
                    </>,
                  ]}
                />
              </div>
            }
          />
        </div>
      );
    if (id == 4)
      return (
        <div
          style={{
            marginTop: "0.5vw",
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "start",
            gap: "0.8vw",
          }}
        >
          <div
            style={{
              marginTop: "0.5vw",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "start",
              width: "100%",
              height: "19.5vw",
            }}
          >
            <InfoTab
              width={"36.3vw"}
              height={"100%"}
              title={t("compleTitle")}
              justifyContent={"start"}
              gap={"1vw"}
              style={{ overflow: "hidden" }}
              content={
                <>
                  <BodySchema
                    items={[
                      {
                        analysis: {
                          destra: {
                            value: ocraRes.output.complementare_dx,
                            label: "DX",
                            map: "dist",
                          },
                          sinistra: {
                            value: ocraRes.output.complementare_sx,
                            label: "SX",
                            map: "dist",
                          },
                        },
                      },
                    ]}
                  />{" "}
                </>
              }
            />
            {videoEl}
          </div>
          <InfoTabScroller
            style={{ width: "100%" }}
            key={id}
            infoTab={
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "start",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "start",
                  }}
                >
                  <InfoTab
                    width={"23.9vw"}
                    // height={"100%"}
                    title={[t("complePhysic")]}
                    justifyContent={"start"}
                    justifyContentMain={"start"}
                    gap={"2vw"}
                    embedded={true}
                    content={[
                      <>
                        <Row justifyContent={"start"} height={rowHeight}>
                          <BasicText
                            style={{
                              width: "25vw",
                            }}
                          >
                            {t("compleHammer")}
                          </BasicText>
                        </Row>
                        <Row justifyContent={"start"} height={rowHeight}>
                          <BasicText
                            style={{
                              width: "25vw",
                            }}
                          >
                            {t("compleHands")}
                          </BasicText>
                        </Row>
                        <Row justifyContent={"start"} height={rowHeight}>
                          <BasicText
                            style={{
                              width: "25vw",
                            }}
                          >
                            {t("compleVibra")}
                          </BasicText>
                        </Row>
                        <Row justifyContent={"start"} height={rowHeight}>
                          <BasicText
                            style={{
                              width: "25vw",
                            }}
                          >
                            {t("compleOther")}
                          </BasicText>
                        </Row>
                      </>,
                      <>
                        <Row justifyContent={"start"} height={rowHeight}>
                          <BasicText
                            style={{
                              width: "25vw",
                            }}
                          >
                            {t("compleRithm")}
                          </BasicText>
                        </Row>
                      </>,
                    ]}
                  />
                  <InfoTab
                    width={"23.9vw"}
                    title={[t("RIGHT")]}
                    justifyContent={"start"}
                    justifyContentMain={"start"}
                    gap={"2vw"}
                    embedded={true}
                    content={[
                      <>
                        <Row justifyContent={"start"} height={rowHeight}>
                          <CheckBox
                            label={"\u00A0"}
                            value={ocraMemory.uso_martelli_dx}
                            onClick={() => {
                              setOcraMemory({
                                ...ocraMemory,
                                uso_martelli_dx: !ocraMemory.uso_martelli_dx,
                              });
                            }}
                          />
                        </Row>
                        <Row justifyContent={"start"} height={rowHeight}>
                          <CheckBox
                            label={"\u00A0"}
                            value={ocraMemory.uso_mani_colpi_dx}
                            onClick={() => {
                              setOcraMemory({
                                ...ocraMemory,
                                uso_mani_colpi_dx:
                                  !ocraMemory.uso_mani_colpi_dx,
                              });
                            }}
                          />
                        </Row>
                        <Row justifyContent={"start"} height={rowHeight}>
                          <CheckBox
                            label={"\u00A0"}
                            value={ocraMemory.strumenti_vibranti_dx}
                            onClick={() => {
                              setOcraMemory({
                                ...ocraMemory,
                                strumenti_vibranti_dx:
                                  !ocraMemory.strumenti_vibranti_dx,
                              });
                            }}
                          />
                        </Row>
                        <Row justifyContent={"start"} height={rowHeight}>
                          <CheckBox
                            label={"\u00A0"}
                            value={ocraMemory.altro_dx}
                            onClick={() => {
                              setOcraMemory({
                                ...ocraMemory,
                                altro_dx: !ocraMemory.altro_dx,
                              });
                            }}
                          />
                        </Row>
                      </>,
                    ]}
                  />
                  <InfoTab
                    width={"23.9vw"}
                    title={[t("LEFT")]}
                    justifyContent={"start"}
                    justifyContentMain={"start"}
                    gap={"2vw"}
                    embedded={true}
                    content={[
                      <>
                        <Row justifyContent={"start"} height={rowHeight}>
                          <CheckBox
                            label={"\u00A0"}
                            value={ocraMemory.uso_martelli_sx}
                            onClick={() => {
                              setOcraMemory({
                                ...ocraMemory,
                                uso_martelli_sx: !ocraMemory.uso_martelli_sx,
                              });
                            }}
                          />
                        </Row>
                        <Row justifyContent={"start"} height={rowHeight}>
                          <CheckBox
                            label={"\u00A0"}
                            value={ocraMemory.uso_mani_colpi_sx}
                            onClick={() => {
                              setOcraMemory({
                                ...ocraMemory,
                                uso_mani_colpi_sx:
                                  !ocraMemory.uso_mani_colpi_sx,
                              });
                            }}
                          />
                        </Row>
                        <Row justifyContent={"start"} height={rowHeight}>
                          <CheckBox
                            label={"\u00A0"}
                            value={ocraMemory.strumenti_vibranti_sx}
                            onClick={() => {
                              setOcraMemory({
                                ...ocraMemory,
                                strumenti_vibranti_sx:
                                  !ocraMemory.strumenti_vibranti_sx,
                              });
                            }}
                          />
                        </Row>
                        <Row justifyContent={"start"} height={rowHeight}>
                          <CheckBox
                            label={"\u00A0"}
                            value={ocraMemory.altro_sx}
                            onClick={() => {
                              setOcraMemory({
                                ...ocraMemory,
                                altro_sx: !ocraMemory.altro_sx,
                              });
                            }}
                          />
                        </Row>
                      </>,
                    ]}
                  />
                </div>
                <InfoTab
                  width={"100%"}
                  // height={"100%"}
                  title={[t("compleOrganiz")]}
                  justifyContent={"start"}
                  justifyContentMain={"start"}
                  gap={"2vw"}
                  embedded={true}
                  content={[
                    <>
                      <Row justifyContent={"start"} height={rowHeight}>
                        <BasicText
                          style={{
                            width: "30vw",
                          }}
                        >
                          {t("compleRithm")}
                        </BasicText>
                        <Selector
                          err={ocraMemory.ritmo_macchina_dx == -1}
                          options={ritmoOpt}
                          value={ritmoOpt[ocraMemory.ritmo_macchina_dx]}
                          onChange={(value) => {
                            setOcraMemory({
                              ...ocraMemory,
                              ritmo_macchina_dx: value.value,
                            });
                          }}
                          width={"24.0vw"}
                          height={"2.5vw"}
                        />
                      </Row>
                    </>,
                  ]}
                />
              </div>
            }
          />
        </div>
      );
  }
  // NIOSH
  if (protocol.ref == protocols[1].ref && vliMemory && vliRes) {
    if (id == 0)
      return (
        <div
          style={{
            marginTop: "0.5vw",
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "start",
            gap: "0.8vw",
            zIndex: 99,
          }}
        >
          <div
            style={{
              marginTop: "0.5vw",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "start",
              width: "100%",
              height: "19.5vw",
            }}
          >
            <InfoTab
              width={"36.3vw"}
              height={"100%"}
              title={"OPERATORI E TURNO DI LAVORO"}
              justifyContent={"start"}
              gap={"1vw"}
              content={
                <>
                  <Row justifyContent={"start"}>
                    <PeopleIcon size={"1.875vw"} />
                    <BasicText
                      style={{
                        width: "24vw",
                      }}
                    >
                      {"N. di lavoratori coinvolti"}
                    </BasicText>
                    <TextBox
                      value={
                        vliMemory.n_lavoratori <= -1
                          ? "-"
                          : vliMemory.n_lavoratori
                      }
                      err={vliMemory.n_lavoratori == -1}
                      unit={""}
                      infoBox={true}
                      onChange={(valueIn) => {
                        let value = valueIn.replace(/-/g, "");
                        if (!value)
                          setVliMemory({
                            ...vliMemory,
                            n_lavoratori: -1,
                          });
                        else if (formatDecimal(value)) {
                          setVliMemory({
                            ...vliMemory,
                            n_lavoratori: parseInt(
                              formatDecimal(value) != "."
                                ? formatDecimal(value)
                                : -1
                            ),
                          });
                        }
                      }}
                    />
                  </Row>
                  <Row justifyContent={"start"}>
                    <TimeTableIcon size={"1.875vw"} />
                    <BasicText style={{ width: "24vw" }}>
                      {"Cronogramma"}
                    </BasicText>
                    <ButtonTextIcon
                      label={"Apri"}
                      color={"white"}
                      backgroundH={colors.mainH}
                      background={colors.main}
                      iconHeight={"1.7vw"}
                      icon={
                        process.env.REACT_APP_RESOURCES_BUCKET + "Expand.png"
                      }
                      iconH={
                        process.env.REACT_APP_RESOURCES_BUCKET + "Expand.png"
                      }
                      onClick={() => {
                        popupRef.current.open();
                      }}
                      style={{
                        fontSize: "0.8vw",
                        boxShadow: "0px 0px 0px 0px white",
                        height: "1.875vw",
                        width: "6.25vw",
                        minWidth: "6.25vw",
                        borderRadius: "0.3vw",
                        paddingLeft: "0.15vw",
                        marginTop: "0.3vw",
                        marginBottom: "0.3vw",
                      }}
                    />
                    <InfoButton
                      ai={false}
                      err={false}
                      std={true}
                      info={tips[document.language].cronogramma}
                      addStyle={{ marginLeft: "-0.4vw" }}
                    />
                  </Row>

                  <Row justifyContent={"start"}>
                    <ClockIcon size={"1.875vw"} />
                    <BasicText
                      style={{
                        width: "24vw",
                      }}
                    >
                      {"Durata netta sollevamento manuale "}
                    </BasicText>
                    <InfoBox
                      value={
                        vliMemory.cronogramma.durata_netta_sollevamento != -1
                          ? vliMemory.cronogramma.durata_netta_sollevamento
                          : "-"
                      }
                      unit={"m"}
                      ai={false}
                      info={tips[document.language].durata_sollevamento}
                      decimals={1}
                    />
                  </Row>
                  <Row justifyContent={"start"}>
                    <TimerIcon size={"1.875vw"} />
                    <BasicText
                      style={{
                        width: "24vw",
                      }}
                    >
                      {"Tipo di durata"}
                    </BasicText>
                    <InfoBox
                      value={durataOpt[vliMemory.cronogramma.tipo_durata].label}
                      err={false}
                      ai={false}
                      info={tips[document.language].tipo_durata}
                      decimals={0}
                      marginLeft={"0vw"}
                    />
                  </Row>
                  {/* POPUP */}
                  <PopupCronogram
                    ref={popupRef}
                    info={""}
                    id={"Cronogram"}
                    title={"CRONOGRAMMA"}
                    jobName={"job.name"}
                    vliMemory={vliMemory}
                    setVliMemory={setVliMemory}
                    onSuccess={() => {}}
                  />
                </>
              }
            />
            {videoEl}
          </div>
          <InfoTabScroller
            style={{ width: "100%", overflow: "hidden" }}
            bottomComponentHeight={bottomComponentHeight}
            setBottomComponentHeight={setBottomComponentHeight}
            key={"vli" + id}
            infoTab={
              <InfoTab
                width={"100%"}
                height={"100%"}
                title={"ELENCO PESI MOVIMENTATI DAGLI OPERATORI"}
                info={[""]}
                fullWidth={true}
                justifyContent={"start"}
                gap={"1vw"}
                content={[
                  <TableWeightList
                    availableHeight={bottomComponentHeightRef.current}
                    vliMemory={vliMemory}
                    setVliMemory={setVliMemory}
                    vliRes={vliRes}
                  />,
                ]}
                titleButton={
                  vliMemory.locked ? (
                    <ButtonTextIcon
                      label={"Sblocca modifica"}
                      color={colors.negative}
                      backgroundH={colors.negativePale}
                      background={"transparent"}
                      onClick={() => {
                        alert(
                          "Ogni modifica all'elenco pesi cancellerà i dati delle categorie e delle geometrie."
                        );
                        setVliMemory({
                          ...vliMemory,
                          locked: false,
                        });
                      }}
                      style={{
                        fontSize: "0.82vw",
                        boxShadow: "0px 0px 0px 0px white",
                        height: "2vw",
                        fontWeight: "700",
                        boxSizing: "border-box",
                      }}
                    />
                  ) : (
                    <ButtonTextIcon
                      label={"Aggiungi peso"}
                      color={colors.main}
                      backgroundH={colors.details}
                      background={"transparent"}
                      icon={process.env.REACT_APP_RESOURCES_BUCKET + "Plus.png"}
                      iconH={
                        process.env.REACT_APP_RESOURCES_BUCKET + "Plus.png"
                      }
                      onClick={() => {
                        setVliMemory({
                          ...vliMemory,
                          elenco_pesi: [...vliMemory.elenco_pesi, peso_default],
                        });
                      }}
                      style={{
                        fontSize: "0.82vw",
                        boxShadow: "0px 0px 0px 0px white",
                        height: "2vw",
                        fontWeight: "700",
                        marginRight: "0.5vw",
                        padding: "0.3vw",
                        paddingRight: "0.4vw",

                        boxSizing: "border-box",
                      }}
                    />
                  )
                }
              />
            }
          />
        </div>
      );
    if (id == 1) {
      if (!vliMemory.locked) setVliMemory({ ...vliMemory, locked: true });
      return (
        <div
          style={{
            marginTop: "0.5vw",
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "start",
            gap: "0.8vw",
            zIndex: 99,
          }}
        >
          <div
            style={{
              marginTop: "0.5vw",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "start",
              width: "100%",
              height: "19.5vw",
            }}
          >
            <InfoTab
              width={"36.3vw"}
              height={"100%"}
              title={"PESI SOLLEVATI E FREQUENZA"}
              justifyContent={"start"}
              gap={"1vw"}
              content={
                <>
                  <Row justifyContent={"start"}>
                    <ClockIcon size={"1.875vw"} />
                    <BasicText
                      style={{
                        width: "24vw",
                      }}
                    >
                      {"Durata netta sollevamento manuale "}
                    </BasicText>
                    <InfoBox
                      value={
                        vliMemory.cronogramma.durata_netta_sollevamento != -1
                          ? vliMemory.cronogramma.durata_netta_sollevamento
                          : "-"
                      }
                      unit={"m"}
                      ai={false}
                      info={tips[document.language].durata_sollevamento}
                      decimals={1}
                    />
                  </Row>
                  <Row justifyContent={"start"}>
                    <BoxIcon size={"1.875vw"} />
                    <BasicText
                      style={{
                        width: "24vw",
                      }}
                    >
                      {"N. tot. oggetti sollevati durante il compito (> 3kg) "}
                    </BasicText>
                    <InfoBox
                      value={vliRes.elenco_pesi_sum.totale_reale_oggetti}
                      unit={""}
                      ai={false}
                      info={tips[document.language].tot_oggetti_sollevati}
                      decimals={1}
                    />
                  </Row>
                  <Row justifyContent={"start"}>
                    <PersonIcon size={"1.875vw"} />
                    <BasicText
                      style={{
                        width: "24vw",
                      }}
                    >
                      {"N. oggetti sollevati da ciascun operatore (> 3kg)"}
                    </BasicText>
                    <InfoBox
                      value={vliRes.n_oggetti_sollevati_operatore}
                      unit={""}
                      ai={false}
                      info={tips[document.language].oggetti_per_lavoratore}
                      decimals={1}
                    />
                  </Row>
                  {/* <Row justifyContent={"start"}>
                    <Timer2Icon size={"1.875vw"} />
                    <BasicText
                      style={{
                        width: "24vw",
                      }}
                    >
                      {"Frequenza di sollevamento al minuto"}
                    </BasicText>
                    <InfoBox
                      value={vliRes.frequenza}
                      unit={"/m"}
                      ai={false}
                      info={tips[document.language].frequenza_sollevamento_minuto}
                      decimals={1}
                    />
                  </Row> */}
                </>
              }
            />
            {videoEl}
          </div>
          <InfoTabScroller
            style={{ width: "100%", overflow: "hidden" }}
            bottomComponentHeight={bottomComponentHeight}
            setBottomComponentHeight={setBottomComponentHeight}
            key={"vli" + id}
            infoTab={
              <InfoTab
                width={"100%"}
                height={"100%"}
                title={"CATEGORIE DI PESO"}
                info={[""]}
                fullWidth={true}
                justifyContent={"start"}
                gap={"1vw"}
                content={[
                  <TableWeightCategory
                    availableHeight={bottomComponentHeightRef.current}
                    vliMemory={vliMemory}
                    setVliMemory={setVliMemory}
                    vliRes={vliRes}
                  />,
                ]}
              />
            }
          />
        </div>
      );
    }
    if (id == 2) {
      if (!vliMemory.locked) setVliMemory({ ...vliMemory, locked: true });
      return (
        <div
          style={{
            marginTop: "0.5vw",
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "start",
            gap: "0.8vw",
          }}
        >
          <div
            style={{
              marginTop: "0.5vw",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "start",
              width: "100%",
              height: "19.5vw",
            }}
          >
            <InfoTab
              width={"36.3vw"}
              height={"100%"}
              title={"ASIMMETRIE"}
              justifyContent={"start"}
              gap={"1vw"}
              style={{ overflow: "hidden" }}
              content={
                <>
                  <WeightTable
                    vliMemory={vliMemory}
                    setVliMemory={setVliMemory}
                  />
                </>
              }
            />
            {videoEl}
          </div>
          <InfoTabScroller
            style={{ width: "100%", overflow: "hidden" }}
            bottomComponentHeight={bottomComponentHeight}
            setBottomComponentHeight={setBottomComponentHeight}
            key={"vli" + id}
            infoTab={
              <InfoTab
                width={"100%"}
                height={"100%"}
                title={
                  "Descrizione dell'area di movimentazione manuale carichi"
                }
                info={[""]}
                fullWidth={false}
                justifyContent={"start"}
                gap={"0.7vw"}
                content={[
                  <MovementsTable
                    vliMemory={vliMemory}
                    setVliMemory={setVliMemory}
                  />,
                ]}
              />
            }
          />
        </div>
      );
    }
  }
};
