import React, { useState } from "react";
import PropTypes from "prop-types";
import { colors } from "../../../config/style";
import {
  ButtonTextIconSquaredWrapper,
  IconButtonWrapper,
} from "./ButtonTextIconSquare.styled";

const ButtonTextIconSquare = ({
  onClick,
  icon,
  iconH,
  toggle,
  label,
  disabled,
  background,
  backgroundH,
  id,
  width,
  color,
  margin,
  noBorder,
}) => {
  const [hover, setHover] = useState(false);

  const getBackground = () => {
    if (toggle) return colors.main;
    if (hover && !disabled) return backgroundH;
    return background;
  };

  const getBorder = () => {
    if (toggle) return colors.main;
    return colors.details;
  };

  const getColor = () => {
    if ((hover || toggle) && !disabled) return background;
    return backgroundH;
  };

  return (
    <ButtonTextIconSquaredWrapper
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={disabled ? () => {} : onClick}
      id={id}
      style={{
        cursor: disabled ? "not-allowed" : "pointer",
        opacity: disabled ? 0.4 : 1,
        background: getBackground(),
        width: width ? width : "",
        color: getColor(),
        paddingRight: label ? "0.8vw" : "0vw",
        margin: margin,
        border: noBorder ? "" : "0.14vw solid " + backgroundH,
        boxShadow: noBorder && "0px 0px 0px 0px  rgba(113, 109, 242, 0.1)",
        height: noBorder && "fit-content",
        lineHeight: noBorder && "normal",
        padding: noBorder && "0.15vw",
      }}
    >
      <IconButtonWrapper src={hover | toggle && !disabled ? iconH : icon} />
      {label}
    </ButtonTextIconSquaredWrapper>
  );
};

ButtonTextIconSquare.propTypes = {
  dataTestId: PropTypes.string, // Definisci il tipo di prop "dataTestId"
  onClick: PropTypes.func, // Definisci il tipo di prop "onClick"
  children: PropTypes.node, // Definisci il tipo di prop "children"
};

ButtonTextIconSquare.defaultProps = {
  dataTestId: "default-data-testid", // Imposta un valore predefinito per "dataTestId"
  onClick: () => {}, // Imposta una funzione vuota come valore predefinito per "onClick"
};

export default ButtonTextIconSquare;
