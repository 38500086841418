import { useClerk } from "@clerk/clerk-react";
import { colors } from "../../config/style";
import {
  newCompany,
  newDepartment,
  newJob,
  newLine,
  newLocation,
  newStation,
  newTask,
  newWorkshift,
  updateStation,
  updateTaskNiosh,
} from "../../remote/structure";
import {
  BasicText,
  CheckBox,
  InfoButton,
  Row,
  Selector,
  TextBox,
} from "../InfoTab/InfoTab";
import ButtonTextIcon from "../buttons/ButtonTextIcon/ButtonTextIcon";
import { useState } from "react";
import { getToken } from "../../userManagement/utilities";
import {
  defaultCompany,
  defaultDepartment,
  defaultJob,
  defaultLineWs,
  defaultTask,
  defaultWorkshift,
} from "../../remote/defaultVariable";
import { useTranslation } from "react-i18next";
import { IMPERSONATION } from "../../config/generalConst";
import NotepadIcon from "../../icons/NotepadIcon";
import { defaultVli } from "../../utils/Protocols/NIOSH/vli";

export const PopupAlert = ({
  negative,
  positive,
  negativeLabel,
  positiveLabel,
  message,
}) => {
  const [loading, setLoading] = useState(false);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "0.8vw",
        // marginTop: "0.8vw",
        paddingTop: "0.8vw",
        boxSizing: "border-box",
        height: "100%",
        justifyContent: "space-between",
      }}
    >
      <BasicText
        style={{
          width: "80%",
          color: colors.text,
        }}
      >
        {message}
      </BasicText>{" "}
      <div
        style={{
          width: "100%",
          justifyContent: "end",
          alignItems: "center",
          display: "flex",
          flexDirection: "row",
          gap: "0.5vw",
        }}
      >
        <ButtonTextIcon
          label={negativeLabel}
          color={colors.negative}
          colorH={colors.negative}
          backgroundH={colors.negativePale}
          background={"transparent"}
          onClick={() => negative()}
          style={{
            fontSize: "0.8vw",
            boxShadow: "0px 0px 0px 0px white",
          }}
        />
        <ButtonTextIcon
          label={positiveLabel}
          backgroundH={colors.mainH}
          loading={loading}
          background={colors.main}
          loadingLabel={" "}
          loadingStyle={{}}
          onClick={() => {
            setLoading(true);
            positive();
          }}
          style={{ width: "9vw", display: "flex", justifyContent: "center" }}
        />
      </div>
    </div>
  );
};

export const PopupJob = ({
  MainOwnerCompany,
  location,
  department,
  shift,
  onSuccess,
}) => {
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [ownerCompany, setOwnerCompany] = useState(false);

  const { session, user } = useClerk();
  const { t } = useTranslation();

  const handleClick = async () => {
    setLoading(true);

    let request = await newJob(
      location,
      department,
      ownerCompany
        ? {
            ...defaultJob,
            name: name,
            workshift: shift,
            ownerCompany: MainOwnerCompany,
          }
        : { ...defaultJob, name: name, workshift: shift },
      await getToken(session)
    );
    onSuccess(request);
  };
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "0.8vw",
        // marginTop: "0.8vw",
        paddingTop: "0.8vw",
        boxSizing: "border-box",
        height: "100%",
        justifyContent: "space-between",
      }}
    >
      {" "}
      <Row justifyContent={"center"}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            width: "10vw",
          }}
        >
          <img
            src={process.env.REACT_APP_RESOURCES_BUCKET + "Documentnotes.png"}
            style={{
              width: "1.875vw",
              height: "1.875vw",
              margin: "0px",
              marginRight: "0.2vw",
            }}
          />
          <BasicText
            style={{
              width: "5vw",
              color: colors.text,
            }}
          >
            {t("name")}
          </BasicText>
        </div>
        <TextBox
          width={"16.4vw"}
          value={name}
          onChange={(value) => {
            setName(value);
          }}
          locked={false}
          err={false}
          placeholder={t("jobName")}
        />
      </Row>
      {user?.publicMetadata?.role == "admin" && IMPERSONATION && (
        <Row justifyContent={"center"}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            <CheckBox
              label={t("ownerCompany")}
              value={ownerCompany}
              onClick={() => {
                setOwnerCompany(!ownerCompany);
              }}
            />
          </div>
        </Row>
      )}
      <div
        style={{
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <ButtonTextIcon
          label={t("createJob")}
          disabled={name == ""}
          backgroundH={colors.mainH}
          loading={loading}
          background={colors.main}
          onClick={handleClick}
          style={{ width: "14vw", display: "flex", justifyContent: "center" }}
        />
      </div>
    </div>
  );
};

export const PopupDepartment = ({ MainOwnerCompany, location, onSuccess }) => {
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [ownerCompany, setOwnerCompany] = useState(false);

  const { session, user } = useClerk();
  const { t } = useTranslation();

  const handleClick = async () => {
    setLoading(true);
    console.log("MainOwnerCompany", MainOwnerCompany);
    let request = await newDepartment(
      location,
      ownerCompany
        ? {
            ...defaultDepartment,
            name: name,
            ownerCompany: MainOwnerCompany,
          }
        : {
            ...defaultDepartment,
            name: name,
          },
      await getToken(session)
    );
    onSuccess(request.id);
  };
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "0.8vw",
        // marginTop: "0.8vw",
        paddingTop: "0.8vw",
        boxSizing: "border-box",
        height: "100%",
        justifyContent: "space-between",
      }}
    >
      {" "}
      <Row justifyContent={"center"}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            width: "10vw",
          }}
        >
          <img
            src={process.env.REACT_APP_RESOURCES_BUCKET + "Documentnotes.png"}
            style={{
              width: "1.875vw",
              height: "1.875vw",
              margin: "0px",
              marginRight: "0.2vw",
            }}
          />
          <BasicText
            style={{
              width: "5vw",
              color: colors.text,
            }}
          >
            {t("name")}
          </BasicText>
        </div>
        <TextBox
          width={"16.4vw"}
          value={name}
          onChange={(value) => {
            setName(value);
          }}
          locked={false}
          err={false}
          placeholder={t("depName")}
        />
      </Row>
      {user?.publicMetadata?.role == "admin" && IMPERSONATION && (
        <Row justifyContent={"center"}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            <CheckBox
              label={t("ownerCompany")}
              value={ownerCompany}
              onClick={() => {
                setOwnerCompany(!ownerCompany);
              }}
            />
          </div>
        </Row>
      )}
      <div
        style={{
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <ButtonTextIcon
          label={t("createDep")}
          disabled={name == ""}
          backgroundH={colors.mainH}
          loading={loading}
          background={colors.main}
          onClick={handleClick}
          style={{ width: "14vw", display: "flex", justifyContent: "center" }}
        />
      </div>
    </div>
  );
};

export const PopupShift = ({
  MainOwnerCompany,
  location,
  department,
  onSuccess,
}) => {
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [ownerCompany, setOwnerCompany] = useState(false);

  const { session, user } = useClerk();
  const { t } = useTranslation();

  const handleClick = async () => {
    setLoading(true);
    let request = await newWorkshift(
      location,
      department,
      ownerCompany
        ? {
            ...defaultWorkshift,
            name: name,
            ownerCompany: MainOwnerCompany,
          }
        : {
            ...defaultWorkshift,
            name: name,
          },
      await getToken(session)
    );
    onSuccess(request.id);
  };
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "0.8vw",
        // marginTop: "0.8vw",
        paddingTop: "0.8vw",
        boxSizing: "border-box",
        height: "100%",
        justifyContent: "space-between",
      }}
    >
      {" "}
      <Row justifyContent={"center"}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            width: "10vw",
          }}
        >
          <img
            src={process.env.REACT_APP_RESOURCES_BUCKET + "Documentnotes.png"}
            style={{
              width: "1.875vw",
              height: "1.875vw",
              margin: "0px",
              marginRight: "0.2vw",
            }}
          />
          <BasicText
            style={{
              width: "5vw",
              color: colors.text,
            }}
          >
            {t("name")}
          </BasicText>
        </div>
        <TextBox
          width={"16.4vw"}
          value={name}
          onChange={(value) => {
            setName(value);
          }}
          locked={false}
          err={false}
          placeholder={t("shiftName")}
        />
      </Row>
      {user?.publicMetadata?.role == "admin" && IMPERSONATION && (
        <Row justifyContent={"center"}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            <CheckBox
              label={t("ownerCompany")}
              value={ownerCompany}
              onClick={() => {
                setOwnerCompany(!ownerCompany);
              }}
            />
          </div>
        </Row>
      )}
      <div
        style={{
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <ButtonTextIcon
          label={t("createShift")}
          loading={loading}
          disabled={name == ""}
          backgroundH={colors.mainH}
          background={colors.main}
          onClick={handleClick}
          style={{ width: "14vw", display: "flex", justifyContent: "center" }}
        />
      </div>
    </div>
  );
};

export const PopupLine = ({
  MainOwnerCompany,
  location,
  department,
  onSuccess,
}) => {
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [ownerCompany, setOwnerCompany] = useState(false);

  const { session, user } = useClerk();
  const { t } = useTranslation();

  const handleClick = async () => {
    setLoading(true);
    console.log("MainOwnerCompany", MainOwnerCompany);

    let requestLine = await newLine(
      location,
      department,
      ownerCompany
        ? { name: name, ownerCompany: MainOwnerCompany }
        : { name: name },
      await getToken(session)
    );
    let request = await updateStation(
      location,
      department,
      requestLine.line,
      requestLine.id,
      defaultLineWs,
      await getToken(session)
    );
    onSuccess(request);
  };
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "0.8vw",
        // marginTop: "0.8vw",
        paddingTop: "0.8vw",
        boxSizing: "border-box",
        height: "100%",
        justifyContent: "space-between",
      }}
    >
      {" "}
      <Row justifyContent={"center"}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            width: "10vw",
          }}
        >
          <img
            src={process.env.REACT_APP_RESOURCES_BUCKET + "Documentnotes.png"}
            style={{
              width: "1.875vw",
              height: "1.875vw",
              margin: "0px",
              marginRight: "0.2vw",
            }}
          />
          <BasicText
            style={{
              width: "5vw",
              color: colors.text,
            }}
          >
            {t("name")}
          </BasicText>
        </div>
        <TextBox
          width={"16.4vw"}
          value={name}
          onChange={(value) => {
            setName(value);
          }}
          locked={false}
          err={false}
          placeholder={t("areaName")}
        />
      </Row>
      {user?.publicMetadata?.role == "admin" && IMPERSONATION && (
        <Row justifyContent={"center"}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            <CheckBox
              label={t("ownerCompany")}
              value={ownerCompany}
              onClick={() => {
                setOwnerCompany(!ownerCompany);
              }}
            />
          </div>
        </Row>
      )}
      <div
        style={{
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <ButtonTextIcon
          label={t("createArea")}
          loading={loading}
          disabled={name == ""}
          backgroundH={colors.mainH}
          background={colors.main}
          onClick={handleClick}
          style={{ width: "14vw", display: "flex", justifyContent: "center" }}
        />
      </div>
    </div>
  );
};

export const PopupWorkstation = ({
  MainOwnerCompany,
  location,
  department,
  line,
  onSuccess,
}) => {
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [ownerCompany, setOwnerCompany] = useState(false);

  const { session, user } = useClerk();
  const { t } = useTranslation();

  const handleClick = async () => {
    setLoading(true);

    let request = await newStation(
      location,
      department,
      line,
      ownerCompany
        ? { name: name, ownerCompany: MainOwnerCompany }
        : { name: name },
      await getToken(session)
    );
    onSuccess(request);
  };
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "0.8vw",
        // marginTop: "0.8vw",
        paddingTop: "0.8vw",
        boxSizing: "border-box",
        height: "100%",
        justifyContent: "space-between",
      }}
    >
      {" "}
      <Row justifyContent={"center"}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            width: "10vw",
          }}
        >
          <img
            src={process.env.REACT_APP_RESOURCES_BUCKET + "Documentnotes.png"}
            style={{
              width: "1.875vw",
              height: "1.875vw",
              margin: "0px",
              marginRight: "0.2vw",
            }}
          />
          <BasicText
            style={{
              width: "5vw",
              color: colors.text,
            }}
          >
            {t("name")}
          </BasicText>
        </div>
        <TextBox
          width={"16.4vw"}
          value={name}
          onChange={(value) => {
            setName(value);
          }}
          locked={false}
          err={false}
          placeholder={t("wsName")}
        />
      </Row>
      {user?.publicMetadata?.role == "admin" && IMPERSONATION && (
        <Row justifyContent={"center"}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            <CheckBox
              label={t("ownerCompany")}
              value={ownerCompany}
              onClick={() => {
                setOwnerCompany(!ownerCompany);
              }}
            />
          </div>
        </Row>
      )}
      <div
        style={{
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <ButtonTextIcon
          label={t("createWs")}
          loading={loading}
          disabled={name == ""}
          backgroundH={colors.mainH}
          background={colors.main}
          onClick={handleClick}
          style={{ width: "14vw", display: "flex", justifyContent: "center" }}
        />
      </div>
    </div>
  );
};

export const PopupTask = ({
  MainOwnerCompany,
  location,
  department,
  line,
  station,
  onSuccess,
}) => {
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [ownerCompany, setOwnerCompany] = useState(false);
  const [niosh, setNiosh] = useState(false);

  const { session, user } = useClerk();
  const { t } = useTranslation();

  const handleClick = async () => {
    setLoading(true);

    let request = await newTask(
      location,
      department,
      line,
      station,
      ownerCompany
        ? {
            name: name,
            description: defaultTask.description,
            ownerCompany: MainOwnerCompany,
          }
        : { name: name, description: defaultTask.description },
      await getToken(session)
    );

    if (niosh) {
      let updateNiosh = await updateTaskNiosh(
        request.id,
        defaultVli,
        await getToken(session)
      );
    }

    onSuccess(request.id);
  };
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "0.8vw",
        // marginTop: "0.8vw",
        paddingTop: "0.8vw",
        boxSizing: "border-box",
        height: "100%",
        justifyContent: "space-between",
      }}
    >
      {" "}
      <Row justifyContent={"space-between"} height={"2.5vw"}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
          }}
        >
          <img
            src={process.env.REACT_APP_RESOURCES_BUCKET + "Documentnotes.png"}
            style={{
              width: "1.875vw",
              height: "1.875vw",
              margin: "0px",
              marginRight: "0.2vw",
            }}
          />
          <BasicText
            style={{
              width: "5vw",
              color: colors.text,
            }}
          >
            {t("name")}
          </BasicText>
        </div>
        <TextBox
          width={"16.4vw"}
          value={name}
          onChange={(value) => {
            setName(value);
          }}
          locked={false}
          err={false}
          placeholder={t("taskName")}
        />
      </Row>
      <Row justifyContent={"space-between"} height={"2.5vw"}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            gap: "0.5vw",
          }}
        >
          <InfoButton
            info={"ciao"}
            std={true}
            addStyle={{ margin: "0vw", marginLeft: "0.2vw" }}
          />
          <BasicText
            style={{
              width: "10vw",
              color: colors.text,
            }}
          >
            {"Presenza di MMC?"}
          </BasicText>
        </div>
        <CheckBox
          label={""}
          width={"0vw"}
          value={niosh}
          onClick={() => {
            setNiosh(!niosh);
          }}
        />
      </Row>
      <Row justifyContent={"space-between"} height={"2.5vw"}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            width: "25.1vw",
            gap: "0.2vw",
          }}
        >
          <NotepadIcon size={"1.875vw"} />
          <BasicText
            style={{
              width: "10vw",
              color: colors.text,
            }}
          >
            {"Protocolli"}
          </BasicText>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            width: "25.1vw",
            gap: "0.2vw",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: colors.background,
              boxSizing: "border-box",
              width: "5.3vw",
              height: "1.4vw",
              borderRadius: "0.625vw",
            }}
          >
            <p
              style={{
                color: colors.text,
                fontFamily: "Atkinson Hyperlegible",
                fontSize: "0.9vw",
                fontWeight: "400",
                boxSizing: "border-box",
                margin: 0,
              }}
            >
              OCRA
            </p>
          </div>
          {niosh && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: colors.background,
                boxSizing: "border-box",
                width: "5.3vw",
                height: "1.4vw",
                borderRadius: "0.625vw",
              }}
            >
              <p
                style={{
                  color: colors.text,
                  fontFamily: "Atkinson Hyperlegible",
                  fontSize: "0.9vw",
                  fontWeight: "400",
                  boxSizing: "border-box",
                  margin: 0,
                }}
              >
                NIOSH
              </p>
            </div>
          )}
        </div>
      </Row>
      {user?.publicMetadata?.role == "admin" && IMPERSONATION && (
        <Row justifyContent={"center"}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            <CheckBox
              label={t("ownerCompany")}
              value={ownerCompany}
              onClick={() => {
                setOwnerCompany(!ownerCompany);
              }}
            />
          </div>
        </Row>
      )}
      <div
        style={{
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <ButtonTextIcon
          label={t("createTask")}
          backgroundH={colors.mainH}
          loading={loading}
          disabled={name == ""}
          background={colors.main}
          onClick={handleClick}
          style={{ width: "14vw", display: "flex", justifyContent: "center" }}
        />
      </div>
    </div>
  );
};

export const PopupCompany = ({ onSuccess }) => {
  const [name, setName] = useState("");
  const [owner, setOwner] = useState("");
  const [loading, setLoading] = useState(false);

  const { session, user } = useClerk();
  const { t } = useTranslation();

  const handleClick = async () => {
    setLoading(true);

    let request = await newCompany(
      owner != ""
        ? {
            name: name,
            description: defaultCompany.description,
            ownerCompany: owner,
          }
        : {
            name: name,
            description: defaultCompany.description,
          },
      await getToken(session)
    );
    onSuccess(request.id);
  };
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "0.8vw",
        // marginTop: "0.8vw",
        paddingTop: "0.8vw",
        boxSizing: "border-box",
        height: "100%",
        justifyContent: "space-between",
      }}
    >
      {" "}
      <Row justifyContent={"center"}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            width: "10vw",
          }}
        >
          <img
            src={process.env.REACT_APP_RESOURCES_BUCKET + "Documentnotes.png"}
            style={{
              width: "1.875vw",
              height: "1.875vw",
              margin: "0px",
              marginRight: "0.2vw",
            }}
          />
          <BasicText
            style={{
              width: "5vw",
              color: colors.text,
            }}
          >
            {t("name")}
          </BasicText>
        </div>
        <TextBox
          width={"16.4vw"}
          value={name}
          onChange={(value) => {
            setName(value);
          }}
          locked={false}
          err={false}
          placeholder={t("companyName")}
        />
      </Row>
      {user?.publicMetadata?.role == "admin" && IMPERSONATION && (
        <Row justifyContent={"center"}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              width: "10vw",
            }}
          >
            <img
              src={process.env.REACT_APP_RESOURCES_BUCKET + "Documentnotes.png"}
              style={{
                width: "1.875vw",
                height: "1.875vw",
                margin: "0px",
                marginRight: "0.2vw",
              }}
            />
            <BasicText
              style={{
                width: "5vw",
                color: colors.text,
              }}
            >
              {t("owner")}
            </BasicText>
          </div>
          <TextBox
            width={"16.4vw"}
            value={owner}
            onChange={(value) => {
              setOwner(value);
            }}
            locked={false}
            err={false}
            placeholder={""}
          />
        </Row>
      )}
      <div
        style={{
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <ButtonTextIcon
          label={t("createCompany")}
          backgroundH={colors.mainH}
          loading={loading}
          disabled={name == ""}
          background={colors.main}
          onClick={handleClick}
          style={{ width: "14vw", display: "flex", justifyContent: "center" }}
        />
      </div>
    </div>
  );
};

export const PopupLocation = ({ company, MainOwnerCompany, onSuccess }) => {
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [ownerCompany, setOwnerCompany] = useState(false);

  const { session, user } = useClerk();
  const { t } = useTranslation();

  const handleClick = async () => {
    setLoading(true);
    console.log("ownerCompany", ownerCompany);

    let request = await newLocation(
      company,
      ownerCompany
        ? { name: name, ownerCompany: MainOwnerCompany }
        : { name: name },
      await getToken(session)
    );
    onSuccess(request.id);
  };

  console.log("user", user?.publicMetadata?.role == "admin");
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "0.8vw",
        // marginTop: "0.8vw",
        paddingTop: "0.8vw",
        boxSizing: "border-box",
        height: "100%",
        justifyContent: "space-between",
      }}
    >
      {" "}
      <Row justifyContent={"center"}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            width: "10vw",
          }}
        >
          <img
            src={process.env.REACT_APP_RESOURCES_BUCKET + "Documentnotes.png"}
            style={{
              width: "1.875vw",
              height: "1.875vw",
              margin: "0px",
              marginRight: "0.2vw",
            }}
          />
          <BasicText
            style={{
              width: "5vw",
              color: colors.text,
            }}
          >
            {t("name")}
          </BasicText>
        </div>
        <TextBox
          width={"16.4vw"}
          value={name}
          onChange={(value) => {
            setName(value);
          }}
          locked={false}
          err={false}
          placeholder={t("locationName")}
        />
      </Row>
      {user?.publicMetadata?.role == "admin" && IMPERSONATION && (
        <Row justifyContent={"center"}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            <CheckBox
              label={t("ownerCompany")}
              value={ownerCompany}
              onClick={() => {
                setOwnerCompany(!ownerCompany);
              }}
            />
          </div>
        </Row>
      )}
      <div
        style={{
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <ButtonTextIcon
          label={t("createLocation")}
          loading={loading}
          disabled={name == ""}
          backgroundH={colors.mainH}
          background={colors.main}
          onClick={handleClick}
          style={{ width: "14vw", display: "flex", justifyContent: "center" }}
        />
      </div>
    </div>
  );
};
