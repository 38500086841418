import React from "react";

const TimerIcon = ({ fill, size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      minWidth={size}
      minHeight={size}
      viewBox="0 0 48 48"
      fill={fill}
      style={{ minWidth: size, minHeight: size }}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M34.6584 36.5708C31.8343 39.2521 28.1996 40.5816 23.7703 40.5816C19.7996 40.5816 16.34 39.4742 13.42 36.7788C10.5001 34.0835 8.77042 30.3385 8.61159 26.3679C8.45277 22.3973 9.87778 18.5261 12.5731 15.6062C15.2685 12.6862 19.0134 10.9565 22.9841 10.7977V9.6H20.3864C20.0687 9.6 19.7641 9.47382 19.5395 9.2492C19.3148 9.02459 19.1887 8.71995 19.1887 8.4023C19.1887 8.08465 19.3148 7.78 19.5395 7.55539C19.7641 7.33078 20.0687 7.20459 20.3864 7.20459H27.9772C28.2948 7.20459 28.5995 7.33078 28.8241 7.55539C29.0487 7.78 29.1749 8.08465 29.1749 8.4023C29.1749 8.71995 29.0487 9.02459 28.8241 9.2492C28.5995 9.47382 28.2948 9.6 27.9772 9.6H25.3795V10.7977C29.2647 11.0635 32.8931 12.8339 35.4936 15.7327C38.0941 18.6315 39.4617 22.4301 39.3058 26.3213C39.1498 30.2124 37.4826 33.8894 34.6584 36.5708ZM35.6199 10.8935L38.9974 14.2711H39.0453C39.2135 14.4379 39.3284 14.6508 39.3756 14.8829C39.4228 15.1151 39.4001 15.356 39.3103 15.5752C39.2206 15.7944 39.0679 15.9821 38.8715 16.1145C38.6751 16.2469 38.4438 16.3181 38.2069 16.3191C38.0493 16.32 37.893 16.2898 37.7471 16.2302C37.6012 16.1706 37.4685 16.0828 37.3566 15.9718L33.9191 12.5344C33.7581 12.3008 33.6853 12.0175 33.7136 11.7352C33.7419 11.4529 33.8695 11.1898 34.0737 10.9928C34.2779 10.7958 34.5454 10.6777 34.8286 10.6595C35.1118 10.6413 35.3922 10.7242 35.6199 10.8935ZM23.9979 35.2053C18.5873 35.2053 14.1981 30.816 14.1981 25.4053C14.1981 24.2597 15.1228 23.3349 16.2685 23.3349C17.4005 23.3349 18.339 24.2597 18.339 25.4053C18.339 28.5247 20.8788 31.0645 23.9979 31.0645C27.1177 31.0645 29.6572 28.5247 29.6572 25.4053C29.6572 22.2859 27.1177 19.7461 23.9979 19.7461C22.8525 19.7461 21.9275 18.8214 21.9275 17.6757C21.9275 16.5301 22.8525 15.6053 23.9979 15.6053C29.395 15.6053 33.798 20.0084 33.798 25.4053C33.798 30.816 29.395 35.2053 23.9979 35.2053Z"
      />
    </svg>
  );
};

export default TimerIcon;
