import { useUser } from "@clerk/clerk-react";
import Chart from "chart.js/auto";
import _, { transform } from "lodash";
import PropTypes from "prop-types";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
  butterLowpassFilter,
  extractVisibilityIntervals,
  filterArrayDiscrete,
} from "../../algorithm/SignalProcessing";
import {
  disabledColors,
  editColors,
  lineColor,
  rightColors,
  wrongColors,
} from "../../config/graphsConfig";
import { colors } from "../../config/style";
import { useStateValue } from "../../stores/services/StateProvider";
import { InfoButton, Selector, TextBox } from "../InfoTab/InfoTab";
import ButtonTextIconSquare from "../buttons/ButtonTextIconSquare/ButtonTextIconSquare";
import { LineChartWrapper } from "./LineChart.styled";
import { color } from "chart.js/helpers";
import { DividerSmall } from "../AnalysisSelector/AnalysisSelector";
import { tips } from "../../config/tips";
import ButtonIcon from "../buttons/ButtonIcon/ButtonIcon";
import {
  FILTER_VISIBILITY,
  WARNING_VISIBILITY,
} from "../../config/generalConst";
import { PopupError } from "../Popup/Popup";

const LineChart = forwardRef(
  (
    {
      data,
      videoId,
      videoLoaded,
      id,
      graphMemory,
      setGraphMemory,
      setSelectedGraph,
    },
    ref
  ) => {
    const [state, dispatch] = useStateValue();
    const [selectedChart, setSelectedChart] = useState(0);
    const [editing, setEditing] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const [duration, setDuration] = useState(0);
    const [time, setTime] = useState(0);
    const [batchLevel, setBatchLevel] = useState([]);
    const [currentBatchLevel, setCurrentBatchLevel] = useState([0, 5]);
    const [editingBatch, setEditingBatch] = useState(false);
    const [batchIndex, setBatchIndex] = useState(0);
    const [videoDuration, setVideoDuration] = useState(0);
    const [showVisisbility, setShowVisisbility] = useState(true);
    const [visibilityIndex, setVisibilityIndex] = useState(-1);
    const [visibilityScore, setVisibilityScore] = useState(-1);

    const chartRef = useRef(null);
    const chartInstance = useRef(null);
    const selectedSpan = useRef(0);
    const maxSpan = useRef(60);
    const editingRef = useRef(null);

    const dataForChart = useRef(null);
    const dataForChartSecondary = useRef(null);
    const dataForChartHistory = useRef([]);
    const historyIndex = useRef(0);
    // const batchIndex = useRef(0);
    const backupAddedError = useRef(null);
    const enableMouseLocation = useRef(null);
    const buttonsContainer = useRef(null);

    const visibilityIndexRef = useRef(null);
    const visibilityScoreRef = useRef(null);

    const popupErrorRef = useRef(null);

    const { t } = useTranslation();
    const { user } = useUser();

    editingRef.current = editing;
    visibilityIndexRef.current = visibilityIndex;
    visibilityScoreRef.current = visibilityScore;

    let debounceTimeout;
    let thresholdPercentage = 2;
    let memoryDepth = 10;
    let optionsGraph = Object.keys(data.graph).map((x) => {
      return { label: data.graph[x].label, value: x };
    });

    useImperativeHandle(ref, () => ({
      refresh: (memory) => {
        setBatchLevel([]);
        load(memory);
      },
    }));

    useEffect(() => {
      // Register the plugin when the component mounts
      Chart.register(backgroundPlugin);
      Chart.register(visibilityPlugin);
      Chart.register(lineaVerticalePlugin);

      return () => {
        Chart.unregister(backgroundPlugin);
        Chart.unregister(visibilityPlugin);
        Chart.unregister(lineaVerticalePlugin);
      };
    }, []);

    useEffect(() => {
      if (chartInstance.current) {
        if (editing) {
          chartInstance.current.lineColor = editColors[0];
          chartInstance.current.editing = editing;
          chartInstance.current.update("none");
        } else {
          chartInstance.current.lineColor = lineColor;
          chartInstance.current.editing = false;
          chartInstance.current.update("none");
        }
      }
    }, [editing]);

    useEffect(() => {
      if (chartInstance.current) {
        if (showVisisbility && !editing) {
          chartInstance.current.visibility = dataForChart.current.visibility;
          chartInstance.current.update("none");
        } else {
          chartInstance.current.visibility = [];
          chartInstance.current.update("none");
        }
      }
    }, [showVisisbility, editing]);

    useEffect(() => {
      if (batchLevel[batchIndex] != null) {
        dataForChart.current.batchLevel = batchLevel[batchIndex];

        if (batchLevel.length > 0 && dataForChart.current.originalBatchLevel) {
          let res = createValueArray(
            dataForChart.current.labels,
            batchLevel[batchIndex],
            dataForChart.current.originalBatchLevel,
            data.graph[selectedChart].batch
          );

          dataForChart.current.values = [res.values];
          dataForChart.current.errors = [res.errors];
          createChart(dataForChart.current, chartRef.current.getContext("2d"));
          setRefresh(!refresh);
        }
      }
    }, [batchLevel, batchIndex, selectedChart]);

    useEffect(() => {
      if (videoDuration > 0) {
        dataForChart.current = null;
        load(graphMemory);
        let video = document.getElementById(videoId);
        let lastTime = -1;
        function checkTime() {
          if (video.currentTime !== lastTime) {
            lastTime = video.currentTime;
            timeUpdateHandler(); // Chiama la tua funzione handler
          }
          requestAnimationFrame(checkTime);
        }

        requestAnimationFrame(checkTime);
        const timeUpdateHandler = () => {
          aggiornaGrafico(video.currentTime, chartInstance.current);
          setTime(video.currentTime);
        };
        positionButtons(chartInstance.current);
      }
    }, [videoDuration, selectedChart]);

    useEffect(() => {
      if (videoLoaded) {
        let video = document.getElementById(videoId);
        setVideoDuration(video.duration);
        if (video) {
          video.addEventListener("loadedmetadata", function () {
            // Stampa la durata del video
            setVideoDuration(video.duration);
          });
        }
      }
    }, [videoLoaded]);
    console.log("dataforChart curremt", dataForChart.current);

    function save(graphMemory, selectedChart) {
      dataForChart.current.newValue =
        Math.round(calculateTotalDuration(dataForChart.current) * 10) / 10;

      let graphMemoryNew = [];
      if (graphMemory?.[data.id_local])
        graphMemoryNew = { ...graphMemory[data.id_local] };

      let editData = {
        errors: dataForChart.current["errors"],
        addedErrors: dataForChart.current["addedErrors"],
        newValue: dataForChart.current["newValue"],
        batchLevel: dataForChart.current["batchLevel"],
        originalBatchLevel: dataForChart.current["originalBatchLevel"],
      };

      graphMemoryNew[selectedChart] = editData;

      if (dataForChartSecondary.current) {
        dataForChartSecondary.current.newValue =
          Math.round(
            calculateTotalDuration(dataForChartSecondary.current) * 10
          ) / 10;
        let editDataSec = {
          errors: dataForChartSecondary.current["errors"],
          addedErrors: dataForChartSecondary.current["addedErrors"],
          newValue: dataForChartSecondary.current["newValue"],
          batchLevel: dataForChartSecondary.current["batchLevel"],
          originalBatchLevel:
            dataForChartSecondary.current["originalBatchLevel"],
        };
        graphMemoryNew[1] = { ...editDataSec };
        dataForChartSecondary.current = null;
      }

      setGraphMemory({ ...graphMemory, [data.id_local]: graphMemoryNew });
    }

    function load(graphMemory) {
      //CARICO I DATI DEL GRAFICO PRIMARIO
      dataForChart.current = loadData(
        data,
        state.current_data[data.side].sampling_frequency,
        videoLoaded,
        selectedChart,
        graphMemory
      );

      //CARICO I DATI DEL GRAFICO SECONDARIO SE ESISTE
      if (data.graph.length > 1 && !graphMemory?.[data?.id_local]?.[1]) {
        dataForChartSecondary.current = loadData(
          data,
          state.current_data[data.side].sampling_frequency,
          videoLoaded,
          1,
          graphMemory
        );
      }

      // dataForChart.current = {
      //   ...graphMemory[data.id_local][selectedChart],
      // };

      if (graphMemory?.[data?.id_local]?.[selectedChart]) {
        Object.keys(graphMemory[data.id_local][selectedChart]).forEach(
          (key) => {
            if (
              dataForChart.current.hasOwnProperty(key) &&
              key != "originalBatchLevel"
            ) {
              if (key == "addedErrors") {
                if (
                  isArrayOfArrays(
                    graphMemory[data.id_local][selectedChart][key]
                  )
                )
                  dataForChart.current[key] = graphMemory[data.id_local][
                    selectedChart
                  ][key].slice(0, dataForChart.current.errors.length);
                else {
                  // COMPATIBILITA CON VECCHI SALVATAGGI

                  dataForChart.current[key] = dataForChart.current.errors.map(
                    (innerArray) => innerArray.map(() => 0)
                  );
                  dataForChart.current[key][0] =
                    graphMemory[data.id_local][selectedChart][key];
                }
              } else {
                dataForChart.current[key] =
                  graphMemory[data.id_local][selectedChart][key];
              }
            }
          }
        );
      }

      createChart(dataForChart.current, chartRef.current.getContext("2d"));
      setBatchLevel([dataForChart.current.batchLevel]);
    }

    // Graph plugins
    const isArrayOfArrays = (arr) => {
      if (arr.length === 0) {
        return false; // Considera un array vuoto come semplice
      }
      return arr.some(Array.isArray);
    };

    const lineaVerticalePlugin = {
      id: "lineaVerticale",
      afterDraw: (chart, args, options) => {
        var ctx = chart.ctx;
        var x = chart.scales.x.getPixelForValue(options.currentTime); // Linea per il tempo corrente
        // Disegna la linea per il tempo corrente
        drawLine(ctx, x, chart.chartArea, chart.lineColor); // Colore solido

        if (chart.cursorPosition) {
          // Disegna la linea per la posizione del cursore
          drawLine(
            ctx,
            chart.cursorPosition,
            chart.chartArea,
            chart.lineColor
              ? setAlpha(chart.lineColor, 0.3)
              : setAlpha(lineColor, 0.3)
          ); // Colore trasparente
        }
        if (chart?.editing?.startFix) {
          drawLine(
            ctx,
            chart.scales.x.getPixelForValue(
              dataForChart.current.labels[chart.editing.start]
            ),
            chart.chartArea,
            chart.lineColor
          );
        }
        if (chart?.editing?.endFix) {
          drawLine(
            ctx,
            chart.scales.x.getPixelForValue(
              dataForChart.current.labels[chart.editing.end]
            ),
            chart.chartArea,
            chart.lineColor
          );
        }
      },
    };

    function drawLine(ctx, x, chartArea, color) {
      if (x < chartArea.left || x > chartArea.right) {
        return; // Non disegnare la linea se è fuori dall'area del grafico
      }
      ctx.save();
      ctx.beginPath();
      ctx.moveTo(x, chartArea.top);
      ctx.lineTo(x, chartArea.bottom);
      ctx.lineWidth = 2;
      ctx.strokeStyle = color;
      ctx.stroke();
      ctx.restore();
    }

    const positionButtons = (chart) => {
      const chartArea = chart.chartArea;

      buttonsContainer.current.style.marginLeft = chartArea.left + "px";
      buttonsContainer.current.style.width =
        chartArea.right - chartArea.left + "px";
    };

    const backgroundPlugin = {
      id: "customCanvasBackground",
      beforeDraw: (chart, args, options) => {
        let area = chart.editing;
        if (area) {
          let ctx = chart.ctx;
          let chartArea = chart.chartArea;
          // Calcola larghezza e altezza corrette
          let leftEnd = area.start ? area.start : area.center;
          let rightStart = area.end ? area.end : area.center;

          leftEnd = chart.scales.x.getPixelForValue(
            dataForChart.current.labels[leftEnd]
          );
          rightStart = chart.scales.x.getPixelForValue(
            dataForChart.current.labels[rightStart]
          );
          let leftWidth = leftEnd - chartArea.left;
          let rightWidth = chartArea.right - rightStart;
          let height = chartArea.bottom - chartArea.top;

          ctx.save();
          ctx.fillStyle = "rgba(200, 200, 200, 0.5)";
          ctx.fillRect(chartArea.left, chartArea.top, leftWidth, height);
          ctx.fillRect(rightStart, chartArea.top, rightWidth, height);
          ctx.restore();
        }
      },
    };
    const visibilityPlugin = {
      id: "visibilityPlugin",
      beforeDraw: (chart, args, options) => {
        let areas = chart.visibility;
        if (areas) {
          let ctx = chart.ctx;
          let chartArea = chart.chartArea;
          let xScale = chart.scales["x"]; // Assume the x-axis is named 'x'
          let yScale = chart.scales["y"]; // Assume the y-axis is named 'y'

          ctx.save();
          // ctx.fillStyle = colors.mainBackground;

          ctx.fillStyle = "#F0F0F0";

          areas.forEach((area) => {
            let startPixel = xScale.getPixelForValue(area.start);
            let endPixel = xScale.getPixelForValue(area.end);
            let width = endPixel - startPixel;
            let height = chartArea.bottom - chartArea.top;

            ctx.fillRect(startPixel, chartArea.top, width, height);
          });

          ctx.restore();
        }
      },
    };

    // History
    function checkpoint() {
      if (historyIndex.current > 0) {
        dataForChartHistory.current.splice(0, historyIndex.current);
        historyIndex.current = 0;
      }
      // Add current data on top of history
      let newEl = _.cloneDeep(dataForChart.current);

      dataForChartHistory.current.unshift(newEl);
      // Limit history to max memoryDepth elements
      if (dataForChartHistory.current.length > memoryDepth) {
        dataForChartHistory.current.pop();
      }
      setDuration(calculateTotalDuration(dataForChart.current));
    }

    function moveHistory(shift) {
      let newIndex = historyIndex.current - shift;
      if (
        newIndex > memoryDepth - 1 ||
        newIndex < 0 ||
        newIndex > dataForChartHistory.current.length - 1
      ) {
        return;
      }
      historyIndex.current = newIndex;
      dataForChart.current = _.cloneDeep(dataForChartHistory.current[newIndex]);
      createChart(dataForChart.current, chartRef.current.getContext("2d"));
      setRefresh(!refresh);
    }

    const handleClick = (evt) => {
      console.log("Click su grafico");
      const rect = document.getElementById(id).getBoundingClientRect();
      const mouseX = evt.clientX - rect.left;
      const timeValue = chartInstance.current.scales.x.getValueForPixel(mouseX);
      videoLoaded.currentTime = timeValue; // Aggiorna il tempo del video
      console.log("Tempo aggiornato", timeValue);
      if (editingRef.current) {
        dataForChart.current = updateErrori(
          dataForChart.current,
          timeValue,
          true,
          evt
        );
      }
    };

    const handleDoubleClick = (evt) => {
      if (data.graph[selectedChart].type == 0) {
        let rect = document.getElementById(id).getBoundingClientRect();
        let mouseX = evt.clientX - rect.left;
        let timeValue = chartInstance.current.scales.x.getValueForPixel(mouseX);
        handleChartUpdate(timeValue);
      }
    };

    const handleMouseOut = (evt) => {
      delete chartInstance.current.cursorPosition;
      chartInstance.current.update("none");
    };

    const handleMouseMove = (evt) => {
      const rect = document.getElementById(id).getBoundingClientRect();
      const mouseX = evt.clientX - rect.left;
      const mouseY = evt.clientY - rect.top;
      if (enableMouseLocation.current !== null) {
        // const mouseX = evt.clientX;
        // const mouseY = evt.clientY;

        if (!isWithinThreshold(mouseX, mouseY, thresholdPercentage)) {
          return; // Esci se il mouse non è entro la soglia
        } else {
          enableMouseLocation.current = null;
        }
      }
      // Cancella il timeout precedente, se c'è
      clearTimeout(debounceTimeout);

      // Imposta un nuovo timeout
      debounceTimeout = setTimeout(() => {
        const rect = chartRef.current.getBoundingClientRect();
        const mouseX = evt.clientX - rect.left;
        chartInstance.current.cursorPosition = mouseX;
        chartInstance.current.update("none"); // Aggiorna senza animazione
        if (editingRef.current) {
          const timeValue =
            chartInstance.current.scales.x.getValueForPixel(mouseX);
          videoLoaded.currentTime = timeValue; // Aggiorna il tempo del video
          dataForChart.current = updateErrori(
            dataForChart.current,
            timeValue,
            false
          );
        }
      }, 1); // Imposta un ritardo di 50 millisecondi
    };

    function calculateDistance(x1, y1, x2, y2) {
      return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
    }

    function isWithinThreshold(mouseX, mouseY, threshold) {
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;
      const thresholdInPixels =
        Math.sqrt(Math.pow(screenWidth, 2) + Math.pow(screenHeight, 2)) *
        (threshold / 100);

      const distance = calculateDistance(
        mouseX,
        mouseY,
        enableMouseLocation.current[0],
        enableMouseLocation.current[1]
      );
      return distance < thresholdInPixels;
    }

    function handleChartUpdate(timeValue) {
      if (!editingRef.current) {
        dataForChart.current = updateErrori(
          dataForChart.current,
          timeValue,
          true
        );
      }
    }

    function calculateBatchErrorCounts(labels, errors, batchDuration) {
      let batchErrorCounts = [];
      let batchStart = 0;
      // Nessun controllo iniziale necessario qui, viene gestito nel ciclo e dopo il ciclo

      for (let i = 0; i < labels.length; i++) {
        if (labels[i] >= batchStart + batchDuration) {
          const errorsInBatch = errors.filter(
            (errorIndex) =>
              labels[errorIndex] >= batchStart &&
              labels[errorIndex] < batchStart + batchDuration
          ).length;

          batchErrorCounts.push(errorsInBatch);
          batchStart += batchDuration;
        }
      }

      // Controlla l'ultimo batch, indipendentemente dalla sua lunghezza
      const errorsInLastBatch = errors.filter(
        (errorIndex) =>
          labels[errorIndex] >= batchStart &&
          labels[errorIndex] < labels[labels.length - 1]
      ).length;

      // Aggiungi l'ultimo conteggio degli errori solo se il batch è iniziato (cioè ci sono dati rimanenti)
      if (batchStart < labels[labels.length - 1]) {
        batchErrorCounts.push(errorsInLastBatch);
      }

      return batchErrorCounts;
    }

    function createValueArray(
      labels,
      batchErrorCounts,
      batchErrorCountsOriginal,
      batchDuration
    ) {
      const values = new Array(labels.length).fill(0);
      const errors = new Array(labels.length).fill(1);
      let batchStart = 0;
      let batchIndex = 0;
      let firstElementOfBatch = true;

      for (let i = 0; i < labels.length; i++) {
        if (labels[i] >= batchStart + batchDuration) {
          // Imposta l'ultimo elemento del batch precedente a null
          if (i > 0) {
            values[i - 1] = null;
          }

          batchStart += batchDuration;
          batchIndex++;
          firstElementOfBatch = true;
        }

        const currentErrorsInBatch = batchErrorCounts[batchIndex] || 0;
        const previousErrorsInBatch = batchErrorCountsOriginal[batchIndex] || 0;

        if (firstElementOfBatch) {
          values[i] = null;
          firstElementOfBatch = false;
        } else {
          if (currentErrorsInBatch == previousErrorsInBatch) {
            values[i] = currentErrorsInBatch;
            errors[i] = 1;
          } else {
            values[i] = currentErrorsInBatch;
            errors[i] = 0;
          }
        }
      }

      // Imposta l'ultimo elemento dell'ultimo batch a null
      if (labels.length > 0) {
        values[labels.length - 1] = null;
      }

      return { values, errors };
    }

    // Assume data is your given data structure
    function calculateTotalDuration(currentData) {
      let duration = data.graph[selectedChart].stdDuration;
      if (currentData != null) {
        const totalLength = currentData.errors[0].length; // Assuming all arrays are the same length

        if (duration == 0) {
          // CASO DURATA
          // Step 1: Combine all error arrays into one, marking 1 for any position where any array has 1
          const combinedErrors = [];

          for (let i = 0; i < totalLength; i++) {
            // let hasError = currentData.addedErrors[i] === 1 ? 1 : 0; // Check addedErrors first
            let hasError = 0;
            // Check all errors arrays
            currentData.addedErrors.forEach((errorArray) => {
              if (errorArray[i] === 1) hasError = 1;
            });

            // Check all errors arrays
            currentData.errors.forEach((errorArray) => {
              if (errorArray[i] === 1) hasError = 1;
            });

            combinedErrors.push(hasError);
          }

          // Step 2: Calculate durations for each continuous segment of 1s in the combined array
          let totalDuration = 0;
          let segmentStart = -1; // Start of the current segment of 1s

          for (let i = 0; i < combinedErrors.length; i++) {
            if (combinedErrors[i] === 1 && segmentStart === -1) {
              // Start of a new segment of 1s
              segmentStart = i;
            } else if (combinedErrors[i] === 0 && segmentStart !== -1) {
              // End of the current segment of 1s
              totalDuration +=
                currentData.labels[i] - currentData.labels[segmentStart];
              segmentStart = -1; // Reset for the next possible segment
            }
          }

          // Check if the last segment goes till the end of the array
          if (segmentStart !== -1) {
            totalDuration +=
              currentData.labels[combinedErrors.length - 1] -
              currentData.labels[segmentStart];
          }

          // Step 3: Return the total duration of all 1 segments
          return totalDuration;
        } else {
          // CASO PICCHI
          if (currentData.type == 0) {
            // Function to count segments in an error array
            function countSegments(errorArray) {
              let segmentCount = 0;
              let segmentStart = false;

              for (let i = 0; i < totalLength; i++) {
                if (errorArray[i] === 1 && !segmentStart) {
                  segmentStart = true; // Start of a new segment
                } else if (errorArray[i] === 0 && segmentStart) {
                  segmentStart = false; // End of a segment
                  segmentCount++; // Increment segment count
                }
              }

              // If the last segment goes until the end
              if (segmentStart) {
                segmentCount++;
              }

              return segmentCount;
            }

            // Calculate number of segments for addedErrors
            let addedErrorsSegmentCount = currentData.addedErrors.reduce(
              (acc, errorArray) => acc + countSegments(errorArray),
              0
            );

            // Calculate total number of segments for each error array
            let totalErrorSegments = currentData.errors.reduce(
              (acc, errorArray) => acc + countSegments(errorArray),
              0
            );

            // Calculate total time as number of segments multiplied by duration for addedErrors and all errors
            let totalTime =
              (addedErrorsSegmentCount + totalErrorSegments) * duration;

            return totalTime;
          }
          // CASO ONDA QUADRA
          else if (currentData.type == 1) {
            return (
              currentData?.batchLevel?.reduce(
                (acc, valoreCorrente) => acc + valoreCorrente,
                0
              ) * duration
            );
          } else if (currentData.type == 2) {
            const durataFissaBatch = 10; // Durata fissa di ogni batch in secondi
            const durataTotale =
              currentData.labels[currentData.labels.length - 1];
            // Calcola la durata dell'ultimo batch
            const durataUltimoBatch =
              currentData.batchLevel.length > 1
                ? durataTotale -
                  durataFissaBatch * (currentData.batchLevel.length - 1)
                : durataTotale;

            return currentData?.batchLevel?.reduce(
              (acc, valoreCorrente, indice, array) => {
                // Se è l'ultimo elemento nell'array, usa 'durataUltimoBatch', altrimenti usa 'durataFissaBatch'
                const durataBatch =
                  indice === array.length - 1
                    ? durataUltimoBatch
                    : durataFissaBatch;
                return acc + (valoreCorrente * durataBatch) / 100;
              },
              0
            );
          }
        }
      }
    }

    function updateErrori(dataForChart, targetTime, click, evt) {
      let editingError = false;

      // Trova l'indice del tempo più vicino a targetTime
      let closestIndex = dataForChart.labels.reduce(
        (prev, curr, index) =>
          Math.abs(curr - targetTime) <
          Math.abs(dataForChart.labels[prev] - targetTime)
            ? index
            : prev,
        0
      );

      // Itera sugli array degli errori
      if (!editingRef.current) {
        // Case removing original error
        dataForChart.errors.forEach((errorArray) => {
          // Controlla e aggiorna l'elemento all'indice trovato e i suoi vicini
          if (errorArray[closestIndex] === 1) {
            editingError = true;
            // Aggiorna l'elemento trovato e quelli adiacenti con lo stesso valore originale
            let originalValue = errorArray[closestIndex];
            let newValue = originalValue === 1 ? 2 : 1;
            // Aggiorna l'elemento trovato e quelli adiacenti con lo stesso valore originale
            // Verso sinistra
            for (
              let i = closestIndex;
              i >= 0 && errorArray[i] === originalValue;
              i--
            ) {
              errorArray[i] = newValue;
            }
            // Verso destra
            for (
              let i = closestIndex + 1;
              i < errorArray.length && errorArray[i] === originalValue;
              i++
            ) {
              errorArray[i] = newValue;
            }
          }
        });
        dataForChart.addedErrors.forEach((errorArray) => {
          // Controlla e aggiorna l'elemento all'indice trovato e i suoi vicini
          if (errorArray[closestIndex] === 1) {
            editingError = true;
            // Aggiorna l'elemento trovato e quelli adiacenti con lo stesso valore originale
            let originalValue = errorArray[closestIndex];
            let newValue = 0;
            // Aggiorna l'elemento trovato e quelli adiacenti con lo stesso valore originale
            // Verso sinistra
            for (
              let i = closestIndex;
              i >= 0 && errorArray[i] === originalValue;
              i--
            ) {
              errorArray[i] = newValue;
            }
            // Verso destra
            for (
              let i = closestIndex + 1;
              i < errorArray.length && errorArray[i] === originalValue;
              i++
            ) {
              errorArray[i] = newValue;
            }
          }
        });
      }

      if (editingError) {
        // dataForChart.current = dataForChart;
        checkpoint();
        createChart(dataForChart, chartRef.current.getContext("2d"));
      } else {
        let currentEditing = editingRef.current;
        // Enter editing mode
        if (!currentEditing && click) {
          // backupAddedError.current = dataForChart.addedErrors.slice();
          currentEditing = {
            start: null,
            startFix: false,
            end: null,
            endFix: false,
            center: closestIndex,
          };
          setEditing(currentEditing);
        }
        // Edit start
        else if (
          closestIndex <= currentEditing.center &&
          (!currentEditing.startFix || closestIndex < currentEditing.start)
        ) {
          currentEditing = {
            start: closestIndex,
            startFix: click,
            end: currentEditing.endFix ? currentEditing.end : null,
            endFix: currentEditing.endFix,
            center: currentEditing.center,
          };
          setEditing(currentEditing);
        }
        // Edit end
        else if (
          closestIndex >= currentEditing.center &&
          (!currentEditing.endFix || closestIndex > currentEditing.end)
        ) {
          currentEditing = {
            start: currentEditing.startFix ? currentEditing.start : null,
            startFix: currentEditing.startFix,
            end: closestIndex,
            endFix: click,
            center: currentEditing.center,
          };
          setEditing(currentEditing);
        }

        if (currentEditing.startFix && currentEditing.endFix && click) {
          popupErrorRef.current.open(evt);
        } else if (currentEditing.start || currentEditing.end) {
          let start = currentEditing.start
            ? currentEditing.start
            : currentEditing.center;
          let end = currentEditing.end
            ? currentEditing.end
            : currentEditing.center;

          for (let i = start; i <= end; i++) {
            dataForChart.addingError[i] = 1;
          }

          createChart(dataForChart, chartRef.current.getContext("2d"));
        }

        // createChart(dataForChart, chartRef.current.getContext("2d"));
      }

      return dataForChart;
    }

    function findClosestIndexAndCheckError(dataForChart, targetTime) {
      if (!dataForChart) return false;
      // Trova l'indice del tempo più vicino a targetTime
      let closestIndex = dataForChart.labels.reduce(
        (prev, curr, index) =>
          Math.abs(curr - targetTime) <
          Math.abs(dataForChart.labels[prev] - targetTime)
            ? index
            : prev,
        0
      );

      // Controlla se c'è un errore all'indice trovato
      let isError = dataForChart.errors.some(
        (errorArray) => errorArray[closestIndex] === 1
      );

      return isError;
    }

    function changeSpan(span) {
      let lastData =
        chartInstance.current.data.labels[
          chartInstance.current.data.labels.length - 1
        ];
      let startPoint = maxSpan.current * span;
      let endPoint = maxSpan.current * (span + 1);
      if (lastData < endPoint) {
        // endPoint = lastData;
        // startPoint = endPoint - maxSpan.current;
      }
      let newLevels = [6 * span, 6 * (span + 1) - 1];
      setCurrentBatchLevel(newLevels);

      chartInstance.current.config.options.scales.x.min = startPoint;
      chartInstance.current.config.options.scales.x.max = endPoint;

      chartInstance.current.update("none");
      //chartInstance.update();
      selectedSpan.current = span;
    }

    function reconstructSignal(labels, batchLevels, batchDuration) {
      const signal = []; // Array per il segnale ricostruito

      labels.forEach((label) => {
        const batchIndex = Math.floor(label / batchDuration); // Calcola l'indice del batch corrispondente
        const level = batchLevels[batchIndex] || 0; // Ottieni il livello del batch, usa 0 se l'indice è fuori dall'array
        signal.push(level); // Aggiungi il livello del batch al segnale ricostruito
      });

      return signal;
    }

    function calculateZeroPercentage(array) {
      let count = 0; // Contatore per gli zeri

      // Conta tutti gli zeri nell'array
      array.forEach((element) => {
        if (element === 0) {
          count++;
        }
      });

      // Calcola la percentuale di zeri rispetto alla lunghezza totale dell'array
      const percentage = (count / array.length) * 100;

      return percentage.toFixed(2); // Ritorna la percentuale con due cifre decimali
    }

    function loadData(analysis, fps, video, selectedChartLocal) {
      let component = null;
      component = state.current_data[analysis.side].debug[analysis.component];

      // ---------------------VISIBILITY
      let visibility_cumulative = [];
      for (
        let i = 0;
        i < analysis.graph[selectedChartLocal]?.visibility?.length;
        i++
      ) {
        let visibility =
          state.current_data[analysis.side].visibility[
            analysis.graph[selectedChartLocal].visibility[i].joint
          ];

        let thr = analysis.graph[selectedChartLocal].visibility[i].thr;
        if (visibility?.length > 0) {
          let visibility_filtered = butterLowpassFilter(
            visibility,
            analysis.graph[selectedChartLocal].visibility[i].cutoff,
            state.current_data[analysis.side].sampling_frequency
          );
          if (visibility_cumulative.length > 0) {
            visibility_cumulative = visibility_filtered.map((x, id) => {
              if (x > thr) return visibility_cumulative[id];
              else return 0;
            });
          } else {
            visibility_cumulative = visibility_filtered.map((x, id) => {
              if (x > thr) return 1;
              else return 0;
            });
          }
        }
      }
      if (visibility_cumulative.length > 0) {
        visibility_cumulative = filterArrayDiscrete(
          visibility_cumulative,
          Math.round(
            state.current_data[analysis.side].sampling_frequency *
              FILTER_VISIBILITY
          )
        );
        setVisibilityScore(calculateZeroPercentage(visibility_cumulative));
      }

      // -----------------------WISIBILITY ENDDDDD

      // VALUES
      let values = component[analysis.graph[selectedChartLocal].values[0]];
      if (analysis.graph[selectedChartLocal].abs)
        values = values.map((value) => (value < 1 ? 1 : value));

      // LABELS
      let labels = values.map((_, index) => index / fps);
      if (video.duration > 0) {
        let correctionTime = labels[labels.length - 1] / video.duration;
        labels = labels.map((elemento) => elemento / correctionTime);
        if (video.duration < maxSpan.current) {
          // maxSpan.current = video.duration;
        }
      }

      // ERRORS
      let errors = [];
      for (
        let i = 0;
        i < analysis.graph[selectedChartLocal].errors.length;
        i++
      ) {
        errors.push(component[analysis.graph[selectedChartLocal].errors[i]]);
      }

      let batchErrorCounts = 0;

      // IF TYPE 1 COUNT ERRORS
      if (analysis.graph[selectedChartLocal].type == 1) {
        batchErrorCounts = calculateBatchErrorCounts(
          labels,
          errors.flat(),
          analysis.graph[selectedChartLocal].batch
        );

        // setBatchLevel(batchErrorCounts);
        if (selectedChart == selectedChartLocal) {
          setBatchLevel([batchErrorCounts]);
        }
        let res = createValueArray(
          labels,
          batchErrorCounts,
          batchErrorCounts,
          analysis.graph[selectedChartLocal].batch
        );
        values = res.values;
        errors = [res.errors];
      } else if (analysis.graph[selectedChartLocal].type == 2) {
        batchErrorCounts = errors[0];

        // setBatchLevel(batchErrorCounts);
        if (selectedChart == selectedChartLocal) {
          setBatchLevel([batchErrorCounts]);
        }
        let res = createValueArray(
          labels,
          batchErrorCounts,
          batchErrorCounts,
          analysis.graph[selectedChartLocal].batch
        );

        values = res.values;
        errors = [res.errors];
      }
      // If peaks elaborate errors to transform in true false
      else if (analysis.graph[selectedChartLocal].peaks.length > 0) {
        let peaks = component[analysis.graph[selectedChartLocal].peaks[0]];
        errors = markErrorsInSignal(values.length, errors, peaks);
      }
      let completeData = aggiungiPuntiSoglia(
        values,
        errors,
        visibility_cumulative,
        analysis.graph[selectedChartLocal].thresholds,
        labels,
        values?.length < errors[0]?.length ? false : true
      );

      let thr = [];
      for (
        let i = 0;
        i < analysis.graph[selectedChartLocal].thresholds.length;
        i++
      ) {
        thr.push(
          completeData.dati.map(
            (_, index) => analysis.graph[selectedChartLocal].thresholds[i]
          )
        );
      }

      let visibility_batch = extractVisibilityIntervals(
        completeData.visibility,
        completeData.tempi
      );

      let dataForChartNew = {
        labels: completeData.tempi,
        values: [completeData.dati],
        valuesLabels: analysis.graph[selectedChartLocal].valuesLabels,
        errors: completeData.errori,
        errorsLabels: analysis.graph[selectedChartLocal].errorsLabels,
        addedErrors: Array.from({ length: completeData.errori.length }, () =>
          Array.from({ length: completeData.dati.length }, () => 0)
        ),
        addingError: Array.from({ length: completeData.dati.length }, () => 0),
        visibility: visibility_batch,
        thr: thr,
        type: analysis.graph[selectedChartLocal].type,
      };

      console.log("dataForChartNewdataForChartNew", dataForChartNew);

      if (analysis.graph[selectedChartLocal].type >= 1) {
        dataForChartNew.batchLevel = [...batchErrorCounts];
        dataForChartNew.originalBatchLevel = [...batchErrorCounts];
      }

      const copiedObject = JSON.parse(JSON.stringify(dataForChartNew));

      return dataForChartNew;
    }

    const createChart = (dataForChart, ctx) => {
      save(graphMemory, selectedChart);

      // Inizializza un array vuoto per memorizzare il risultato
      let globalError = [];

      for (let i = 0; i < dataForChart.errors[0].length; i++) {
        let logicalOr = false; // Inizializza con 'false' o 'true' a seconda del tuo requisito
        for (let j = 0; j < dataForChart.errors.length; j++) {
          logicalOr =
            logicalOr ||
            dataForChart.errors[j][i] ||
            dataForChart.addedErrors[j][i];
        }

        globalError.push(logicalOr);
      }

      let rightDatasets = [];
      for (let i = 0; i < dataForChart.values.length; i++) {
        rightDatasets.push({
          label: dataForChart.valuesLabels[i],
          data: dataForChart.values[i].map((value, index) =>
            globalError[index] ? null : value == 0 ? 0.1 : value
          ),
          borderColor:
            data.graph[selectedChart].type >= 1
              ? user?.publicMetadata?.custom?.includes("wizard")
                ? wrongColors[0]
                : editColors[0]
              : rightColors[i],
          borderWidth: 3,
          pointRadius: 0,
          fill: data.graph[selectedChart].type >= 1 ? "start" : false,
          backgroundColor:
            data.graph[selectedChart].type >= 1 &&
            user?.publicMetadata?.custom?.includes("wizard")
              ? setAlpha(wrongColors[0], 0.2)
              : setAlpha(editColors[0], 0.2),
          tension: 2,
        });
      }

      let errorDatasets = [];
      // Errors
      for (let i = 0; i < dataForChart.errors.length; i++) {
        errorDatasets.push({
          label: dataForChart.errorsLabels[i],
          data: dataForChart.values[0].map(
            (
              value,
              index //Se vogliamo più segnali bisogna aggiungere un ciclo
            ) =>
              dataForChart.errors[i][index] == 1
                ? value == 0
                  ? 0.1
                  : value
                : null
          ),
          borderColor: wrongColors[i],
          borderWidth: 3,
          pointRadius: 0,
          fill: "start",
          tension: 2,
          backgroundColor: setAlpha(wrongColors[i], 0.2),
        });
      }

      // Disabled errors
      for (let i = 0; i < dataForChart.errors.length; i++) {
        errorDatasets.push({
          label: i == 0 ? "Errore rimosso" : "thr",
          data: dataForChart.values[0].map(
            (
              value,
              index //Se vogliamo più segnali bisogna aggiungere un ciclo
            ) =>
              dataForChart.errors[i][index] == 2 &&
              dataForChart.addedErrors[i][index] == 0
                ? value
                : null
          ),
          borderColor: user?.publicMetadata?.custom?.includes("wizard")
            ? rightColors[0]
            : disabledColors[0],
          borderWidth: 3,
          pointRadius: 0,
          fill: "start",
          tension: 2,
          backgroundColor: user?.publicMetadata?.custom?.includes("wizard")
            ? "transparent"
            : setAlpha(disabledColors[0], 0.2),
        });
      }
      // Added errors
      for (let i = 0; i < dataForChart.errors.length; i++) {
        errorDatasets.push({
          label: "Added " + dataForChart.errorsLabels[i],
          data: dataForChart.values[0].map(
            (
              value,
              index //Se vogliamo più segnali bisogna aggiungere un ciclo
            ) => (dataForChart.addedErrors[i][index] == 1 ? value : null)
          ),
          borderColor: user?.publicMetadata?.custom?.includes("wizard")
            ? wrongColors[i]
            : editColors[i],
          borderWidth: 3,
          pointRadius: 0,
          fill: "start",
          tension: 2,
          backgroundColor: user?.publicMetadata?.custom?.includes("wizard")
            ? setAlpha(wrongColors[i], 0.2)
            : setAlpha(editColors[i], 0.2),
        });
      }

      // Adding error
      errorDatasets.push({
        label: "Adding errors",
        data: dataForChart.values[0].map(
          (
            value,
            index //Se vogliamo più segnali bisogna aggiungere un ciclo
          ) => (dataForChart.addingError[index] == 1 ? value : null)
        ),
        borderColor: user?.publicMetadata?.custom?.includes("wizard")
          ? wrongColors[0]
          : editColors[0],
        borderWidth: 3,
        pointRadius: 0,
        fill: "start",
        tension: 2,
        backgroundColor: user?.publicMetadata?.custom?.includes("wizard")
          ? setAlpha(wrongColors[0], 0.2)
          : setAlpha(editColors[0], 0.2),
      });

      let thrDatasets = [];
      for (let i = 0; i < dataForChart.thr.length; i++) {
        thrDatasets.push({
          data: dataForChart.thr[i],
          borderColor: wrongColors[i],
          label: "thr",
          borderWidth: 2,
          fill: false,
          pointRadius: 0,
        });
      }

      let datasets = rightDatasets.concat(errorDatasets).concat(thrDatasets);

      if (chartInstance.current) {
        // Aggiorna il grafico esistente
        chartInstance.current.data.labels = dataForChart.labels;
        chartInstance.current.data.datasets = datasets;

        chartInstance.current.options.scales.y = {
          min: data.graph[selectedChart].y_min,
          max: data.graph[selectedChart].y_max,
          title: {
            display: true,
            text: data.graph[selectedChart].y_label,
            font: {
              size: 12,
              family: "Atkinson Hyperlegible", // Imposta la font family dei tick
            },
          },
          ticks: {
            color: colors.neutral,
            font: {
              size: 12,
              family: "Atkinson Hyperlegible", // Imposta la font family dei tick
            },
          },
        };

        let startPoint = maxSpan.current * selectedSpan.current;
        let endPoint = maxSpan.current * (selectedSpan.current + 1);

        chartInstance.current.options.scales.x = {
          type: "linear",
          title: {
            display: true,
            text: data.graph[selectedChart].x_label,
            padding: { top: 0, left: 0, right: 0, bottom: 0 },
            font: {
              size: 12,
              family: "Atkinson Hyperlegible", // Imposta la font family dei tick
            },
          },
          min: startPoint,
          max: endPoint, // dataForChart.labels[dataForChart.labels.length - 1]
          ticks: {
            callback: formatXAxisLabel,
            color: colors.neutral,
            font: {
              size: 12,
              family: "Atkinson Hyperlegible", // Imposta la font family dei tick
            },
          },
        };
        // Disabilita le animazioni per l'aggiornamento
        chartInstance.current.options.animation = false;

        // Ridisegna il grafico con i nuovi dati
        chartInstance.current.update();
      } else {
        chartInstance.current = new Chart(ctx, {
          plugins: [],
          type: "line",
          lineColor: lineColor,
          data: {
            labels: dataForChart.labels,
            datasets: datasets,
          },
          options: {
            plugins: {
              tooltip: {
                callbacks: {
                  // Modifica la label per aggiungere 'Secondi: ' prima del valore di x
                  title: function (context) {
                    return null; // o `return [];` per un array vuoto
                  },
                  label: function (context) {
                    let labelParts = [];
                    let label = context.dataset.label || "";

                    if (label.includes("thr")) {
                      labelParts.push(`Soglia ${context.parsed.y.toFixed(2)}`);
                      return labelParts;
                    }

                    if (context.parsed.y !== null) {
                      // Prima aggiungi il valore di y
                      if (label) {
                        labelParts.push(label);
                      } else {
                        labelParts.push(context.parsed.y.toFixed(2));
                      }
                    }
                    // Poi aggiungi "Secondi: " seguito dal valore di x approssimato
                    // Assumendo che il valore di x sia numerico e richieda l'approssimazione
                    let xValue = context.parsed.x;
                    labelParts.push(`Valore: ${context.parsed.y.toFixed(2)}`);
                    labelParts.push(`Secondi: ${xValue.toFixed(2)}`);

                    return labelParts; // Questo ritorna un array di stringhe, ogni stringa su una nuova riga nel tooltip
                  },
                },
              },
              legend: {
                display: false,
                // position: "right",
                // labels: {
                //   filter: (item) => item.text !== "thr",
                // },
              },
              // title: {
              //   display: true,
              //   text: data.graph[selectedChart].label,
              // },
            },
            animation: true,
            scales: {
              y: {
                min: data.graph[selectedChart].y_min,
                max: data.graph[selectedChart].y_max,
                title: {
                  display: true,
                  text: data.graph[selectedChart].y_label,
                  font: {
                    size: 12,
                    family: "Atkinson Hyperlegible", // Imposta la font family dei tick
                  },
                },
                ticks: {
                  color: colors.neutral,
                  font: {
                    size: 12,
                    family: "Atkinson Hyperlegible", // Imposta la font family dei tick
                  },
                },
              },
              x: {
                type: "linear",
                title: {
                  display: true,
                  text: data.graph[selectedChart].x_label,
                  padding: { top: 0, left: 0, right: 0, bottom: 0 },
                  font: {
                    size: 12,
                    family: "Atkinson Hyperlegible", // Imposta la font family dei tick
                  },
                },
                min: 0,
                max: maxSpan.current, // dataForChart.labels[dataForChart.labels.length - 1],

                ticks: {
                  callback: formatXAxisLabel,
                  color: colors.neutral,
                  font: {
                    size: 12,
                    family: "Atkinson Hyperlegible", // Imposta la font family dei tick
                  },
                },
              },
            },
            // responsive: true, // Imposta il grafico come responsive

            maintainAspectRatio: false, // Non mantenere un rapporto di aspetto fisso
          },
        });

        // CONVERTI ARRAY INIZIALE IN BATCH FATTI IN QUESTO MODO E PASSALI ALLA FUNZIONE PER DISEGNARE
        chartInstance.current.visibility = dataForChart.visibility;
        chartInstance.current.update();
      }

      return dataForChart.values[0].length;
    };

    function markErrorsInSignal(totalFrames, errorArrays, peaks) {
      // Funzione per trovare il prossimo picco dopo un errore
      function findNextPeak(startFrame, peaks) {
        for (let peak of peaks) {
          if (peak > startFrame) {
            return peak;
          }
        }
        return totalFrames; // se non ci sono picchi dopo l'errore
      }

      // Elaborazione per ogni array di errori
      return errorArrays.map((errors) => {
        let signal = new Array(totalFrames).fill(0); // Inizializza l'array di segnale

        for (let errorStart of errors) {
          let nextPeak = findNextPeak(errorStart, peaks);

          for (let i = errorStart; i < nextPeak && i < totalFrames; i++) {
            signal[i] = 1; // Imposta 1 tra l'errore e il picco successivo
          }
        }

        return signal; // Restituisce l'array di segnale per questo set di errori
      });
    }

    function formatXAxisLabel(value) {
      return Math.round(parseFloat(value)).toString();
    }

    function formatTime(seconds) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = Math.floor(seconds % 60);
      return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
        .toString()
        .padStart(2, "0")}`;
    }

    function aggiornaGrafico(currentTime, chart) {
      let currentMin = selectedSpan.current * maxSpan.current;
      let currentMax = (selectedSpan.current + 1) * maxSpan.current;

      if (currentTime < currentMin) {
        changeSpan(selectedSpan.current - 1);
      } else if (currentTime > currentMax) {
        changeSpan(selectedSpan.current + 1);
      }

      chart.options.plugins.lineaVerticale = {
        currentTime: currentTime,
      };
      chart.update("none");
      setRefresh(!refresh);
    }

    function aggiungiPuntiSoglia(
      datiInput,
      erroriInput,
      visibilityInput,
      soglie,
      tempiInput,
      enable
    ) {
      let puntiDaAggiungere = [];
      let dati = [...datiInput]; // Copia superficiale di 'datiInput'
      let tempi = [...tempiInput]; // Copia superficiale di 'tempiInput'
      let errori = erroriInput.map((errore) => [...errore]); // Copia superficiale di ogni array in 'erroriInput'
      let visibility = [...visibilityInput];

      console.log("enable", enable);

      // Prima, identifica tutte le posizioni in cui aggiungere i nuovi punti
      for (let i = 0; i < soglie.length; i++) {
        for (let j = 0; j < dati.length - 1; j++) {
          if (
            (dati[j] < soglie[i] && dati[j + 1] >= soglie[i]) ||
            (dati[j] >= soglie[i] && dati[j + 1] < soglie[i])
          ) {
            let rapporto = (soglie[i] - dati[j]) / (dati[j + 1] - dati[j]);
            let nuovoTempo = tempi[j] + rapporto * (tempi[j + 1] - tempi[j]);

            puntiDaAggiungere.push({
              indice: j,
              tempo: nuovoTempo,
              value: soglie[i],
            });
          }
        }
      }

      puntiDaAggiungere.sort((a, b) => a.indice - b.indice);

      let comulative = 0;
      // Poi, aggiungi i punti identificati
      for (let punto of puntiDaAggiungere) {
        let j = punto.indice;

        let tempo = (tempi[j + comulative] + tempi[j + 1 + comulative]) / 2;

        // Aggiungi il primo punto che eredita le caratteristiche del punto precedente
        dati.splice(j + 1 + comulative, 0, punto.value);
        tempi.splice(j + 1 + comulative, 0, tempo);
        if (enable) {
          for (let k = 0; k < errori.length; k++) {
            errori[k].splice(j + 1 + comulative, 0, errori[k][j + comulative]);
          }
        }
        if (visibility?.length > 0)
          visibility.splice(j + 1 + comulative, 0, visibility[j + comulative]);

        // Aggiungi il secondo punto che eredita le caratteristiche del punto successivo
        dati.splice(j + 2 + comulative, 0, punto.value);
        tempi.splice(j + 2 + comulative, 0, tempo);
        if (enable) {
          for (let k = 0; k < errori.length; k++) {
            errori[k].splice(
              j + 2 + comulative,
              0,
              errori[k][j + 3 + comulative]
            );
          }
        }
        if (visibility?.length > 0)
          visibility.splice(
            j + 2 + comulative,
            0,
            visibility[j + 3 + comulative]
          );
        comulative = comulative + 2;
      }

      return { dati, errori, tempi, visibility };
    }

    function setAlpha(color, alpha) {
      // Verifica se il colore è in formato RGBA
      if (/^rgba\(/.test(color)) {
        // Estrai i valori R, G, B e A dal colore
        const matches = color.match(/(\d+(\.\d+)*)/g);
        if (matches.length === 4) {
          const r = matches[0];
          const g = matches[1];
          const b = matches[2];
          // Imposta la nuova trasparenza
          return `rgba(${r}, ${g}, ${b}, ${alpha})`;
        }
      }
      // Se il colore non è in formato RGBA o non ha il formato corretto, restituisci il colore originale
      return color;
    }

    function resetBatchIndex() {
      setBatchLevel(batchLevel.slice(batchIndex));
      setBatchIndex(0);
    }

    if (videoLoaded)
      return (
        <>
          <LineChartWrapper
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "1vw",
              paddingRight: "0vw",
              boxSizing: "border-box",
              userSelect: "none",
            }}
          >
            {/* <div>
            <Selector />
            {data.graph.length > 1 &&
              Object.keys(data.graph).map((x) => (
                <button
                  onClick={() => {
                    setSelectedChart(x);
                  }}
                >
                  {data.graph[x].label}
                </button>
              ))}
          </div> */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "98%",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
                borderRight: "0.14vw solid " + colors.background,
                boxSizing: "border-box",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginRight: "1vw",
                  boxSizing: "border-box",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "33%",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Selector
                    value={optionsGraph[selectedChart]}
                    onChange={(x) => {
                      setBatchLevel([]);
                      setSelectedChart(x.value);
                      setSelectedGraph(x.value);
                    }}
                    single={false}
                    visibility={true}
                    noBorder={true}
                    options={optionsGraph}
                  />
                </div>
                <p
                  style={{
                    color: colors.text,
                    fontFamily: "Atkinson Hyperlegible",
                    fontSize: "1vw",
                    fontWeight: "400",
                    boxSizing: "border-box",
                    textAlign: "center",
                    letterSpacing: "normal",
                    display: "flex", // aggiunto per il layout flessibile
                    justifyContent: "center", // centra tutto nel contenitore
                    alignItems: "center", // allinea verticalmente al centro
                  }}
                >
                  {/* Contenitore per il tempo variabile */}
                  <span
                    style={{
                      color: colors.main,
                      fontWeight: "800",
                      minWidth: "50px", // Imposta una larghezza minima adeguata
                      textAlign: "right", // allinea il testo a destra nel contenitore
                      marginRight: "0.2vw",
                    }}
                  >
                    {formatTime(time)}
                  </span>
                  {" - "}
                  <span
                    style={{
                      textAlign: "left", // allinea il testo a sinistra nel contenitore
                      marginLeft: "0.2vw",
                      marginRight: "0.2vw",
                    }}
                  >
                    {videoDuration ? formatTime(videoDuration) : formatTime(0)}
                  </span>
                  {"|"}
                  {/* Testo Statico */}
                  <span
                    style={{
                      textAlign: "left", // allinea il testo a sinistra nel contenitore
                      marginLeft: "0.2vw",
                      marginRight: "0.2vw",
                    }}
                  >
                    <b>{" " + t("Seconds")}</b>
                  </span>
                </p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "33%",
                    justifyContent: "end",
                    alignItems: "center",
                  }}
                >
                  <Selector noBorder={true} visibility={"hidden"} />
                </div>
              </div>
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  width: "100%",
                }}
              >
                <canvas
                  key={id}
                  id={id}
                  ref={chartRef}
                  style={{ width: "99%", height: "100%" }}
                  onClick={handleClick}
                  onDoubleClick={handleDoubleClick}
                  onMouseOut={handleMouseOut}
                  onMouseMove={handleMouseMove}
                />
                <div
                  ref={buttonsContainer}
                  style={{
                    position: "absolute",
                    left: 0,
                    bottom: "-0.5vw",
                    width: "99%",
                    boxSizing: "border-box",
                    display: "flex",
                    justifyContent: "space-between",
                    // padding: "10px 60px 10px 60px", // Aggiusta questo padding per allineare i bottoni agli assi
                  }}
                >
                  {batchLevel?.[batchIndex]?.length > 0 &&
                    dataForChart?.current?.originalBatchLevel &&
                    batchLevel[batchIndex].map((value, id) => {
                      if (
                        id >= currentBatchLevel[0] &&
                        id <= currentBatchLevel[1]
                      )
                        return (
                          <div
                            style={{
                              display: "flex",
                              flex: 1,
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <TextBox
                              width={"5vw"}
                              ai={
                                user?.publicMetadata?.custom?.includes("wizard")
                                  ? true
                                  : value ==
                                    dataForChart?.current?.originalBatchLevel[
                                      id
                                    ]
                              }
                              edited={
                                value !=
                                dataForChart.current.originalBatchLevel[id]
                              }
                              value={Math.round(value)}
                              onChange={(value) => {
                                if (
                                  (dataForChart.current.type == 2 &&
                                    value <= 100) ||
                                  dataForChart.current.type != 2
                                ) {
                                  let tempBatchLevel =
                                    batchLevel.slice(batchIndex);
                                  // setBatchLevel(batchLevel.slice(batchIndex));
                                  setBatchIndex(0);

                                  let newLevel = [...tempBatchLevel[0]];
                                  if (value == "") {
                                    newLevel[id] = 0;
                                    setBatchLevel([
                                      newLevel,
                                      ...tempBatchLevel,
                                    ]);
                                    // setRefresh(!refresh);
                                  } else if (
                                    value !== "" &&
                                    !isNaN(value) &&
                                    Number.isInteger(Number(value))
                                  ) {
                                    newLevel[id] = parseInt(value, 10);
                                    setRefresh(!refresh);
                                    setBatchLevel([
                                      newLevel,
                                      ...tempBatchLevel,
                                    ]);
                                  }
                                }
                              }}
                              locked={!editingBatch}
                              err={false}
                              unit={dataForChart.current.type == 2 ? "%" : ""}
                            />
                          </div>
                        );
                    })}
                  {6 - batchLevel[batchIndex]?.length + currentBatchLevel[0] >
                    0 && (
                    <div
                      style={{
                        display: "flex",
                        flex:
                          6 -
                          batchLevel[batchIndex]?.length +
                          currentBatchLevel[0],
                      }}
                    ></div>
                  )}
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                // gap: "0.5vw",
                width: "17%",
                height: "100%",
                paddingLeft: "0.5vw",
                paddingRight: "0.5vw",
              }}
            >
              <div style={{ width: "100%" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: "0.1vw",
                    width: "100%",
                    marginBottom: "0.5vw",
                  }}
                >
                  <InfoButton
                    ai={false}
                    std={true}
                    err={false}
                    info={
                      data.graph[selectedChart].type == 0
                        ? tips[document.language].edit_completo
                        : tips[document.language].edit_parziale
                    }
                  />
                  <p
                    style={{
                      color: colors.text,
                      fontFamily: "Atkinson Hyperlegible",
                      fontSize: "0.8vw",
                      fontWeight: "800",
                      boxSizing: "border-box",
                      margin: 0,
                    }}
                  >
                    {t("EDIT")}
                  </p>
                </div>
                <DividerSmall />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "0.5vw",
                    gap: "0.1vw",
                    marginBottom: "0.5vw",
                  }}
                >
                  <ButtonTextIconSquare
                    noBorder={true}
                    label={
                      data.graph[selectedChart].type >= 1
                        ? editingBatch
                          ? t("lock")
                          : t("edit")
                        : t("add")
                    }
                    disabled={
                      data.graph[selectedChart].type >= 1
                        ? false
                        : findClosestIndexAndCheckError(
                            dataForChart.current,
                            time
                          )
                    }
                    icon={
                      editingBatch
                        ? process.env.REACT_APP_RESOURCES_BUCKET+"Ignore.png"
                        : process.env.REACT_APP_RESOURCES_BUCKET+"Add.png"
                    }
                    iconH={
                      editingBatch
                        ? process.env.REACT_APP_RESOURCES_BUCKET+"Ignorehw.png"
                        : process.env.REACT_APP_RESOURCES_BUCKET+"Addhw.png"
                    }
                    backgroundH={
                      editingBatch ? colors.neutral : colors.negative2
                    }
                    background={"white"}
                    width={"7.3vw"}
                    onClick={
                      data.graph[selectedChart].type >= 1
                        ? () => {
                            if (editingBatch) {
                              setBatchLevel(batchLevel.slice(batchIndex));
                              setBatchIndex(0);
                            }
                            setEditingBatch(!editingBatch);
                          }
                        : () => {
                            // Lock mouse for an instance
                            enableMouseLocation.current = [
                              chartInstance.current.scales.x.getPixelForValue(
                                videoLoaded.currentTime
                              ),
                              (chartInstance.current.chartArea.top +
                                chartInstance.current.chartArea.bottom) /
                                2,
                            ];
                            // Call function update
                            handleChartUpdate(videoLoaded.currentTime);
                          }
                    }
                  />
                  {data.graph[selectedChart].type == 0 && (
                    <ButtonTextIconSquare
                      noBorder={true}
                      label={t("ignore")}
                      disabled={
                        !findClosestIndexAndCheckError(
                          dataForChart.current,
                          time
                        )
                      }
                      icon={
                        process.env.REACT_APP_RESOURCES_BUCKET+"Ignore.png"
                      }
                      iconH={
                        process.env.REACT_APP_RESOURCES_BUCKET+"Ignorehw.png"
                      }
                      backgroundH={colors.neutral}
                      background={"white"}
                      width={"7.3vw"}
                      onClick={() => {
                        // Lock mouse for an instance
                        enableMouseLocation.current = [
                          chartInstance.current.scales.x.getPixelForValue(
                            videoLoaded.currentTime
                          ),
                          (chartInstance.current.chartArea.top +
                            chartInstance.current.chartArea.bottom) /
                            2,
                        ];
                        // Call function update
                        handleChartUpdate(videoLoaded.currentTime);
                      }}
                    />
                  )}
                </div>
                {dataForChart?.current?.visibility?.length ? (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "start",
                        alignItems: "center",
                        gap: "0.1vw",
                        width: "100%",
                        marginBottom: "0.5vw",
                      }}
                    >
                      <InfoButton
                        ai={false}
                        std={false}
                        err={false}
                        war={t("uncertaintyData")}
                        info={
                          false
                            ? tips[document.language].dati_incerti
                            : tips[document.language].dati_incerti_trigger
                        }
                        trigger={visibilityScore > WARNING_VISIBILITY}
                      />
                      <p
                        style={{
                          color: colors.text,
                          fontFamily: "Atkinson Hyperlegible",
                          fontSize: "0.8vw",
                          fontWeight: "800",
                          boxSizing: "border-box",
                          margin: 0,
                        }}
                      >
                        {t("uncertaintyData")}
                        <span
                          style={{
                            color:
                              visibilityScore > WARNING_VISIBILITY
                                ? colors.riskUnsureBase
                                : colors.text,
                          }}
                        >
                          {" - " + Math.round(visibilityScore) + "%"}
                        </span>
                      </p>
                    </div>
                    <DividerSmall />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "start",
                        alignItems: "center",
                        gap: "0.4vw",
                        width: "100%",
                        marginBottom: "0.5vw",
                        marginTop: "0.5vw",
                      }}
                    >
                      <img
                        src={
                          showVisisbility
                            ? process.env.REACT_APP_RESOURCES_BUCKET+"toggleTrue.png"
                            : process.env.REACT_APP_RESOURCES_BUCKET+"toggleFalse.png"
                        }
                        onClick={() => setShowVisisbility(!showVisisbility)}
                        style={{
                          width: "1.8vw",
                          cursor: "pointer",
                        }}
                      />
                      <p
                        style={{
                          color: colors.text,
                          fontFamily: "Atkinson Hyperlegible",
                          fontSize: "0.8vw",
                          fontWeight: "400",
                          boxSizing: "border-box",
                          margin: 0,
                        }}
                      >
                        {t("show")}
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "0.4vw",
                        width: "100%",
                        marginBottom: "0.5vw",
                      }}
                    >
                      {" "}
                      <ButtonIcon
                        icon={
                          process.env.REACT_APP_RESOURCES_BUCKET+"leftArrowSmall.png"
                        }
                        iconH={
                          process.env.REACT_APP_RESOURCES_BUCKET+"leftArrowSmallHW.png"
                        }
                        onClick={() => {
                          if (visibilityIndexRef.current < 0) {
                            videoLoaded.currentTime =
                              dataForChart.current.visibility[0].start;
                            setVisibilityIndex(0);
                          } else if (visibilityIndexRef.current > 0) {
                            videoLoaded.currentTime =
                              dataForChart.current.visibility[
                                visibilityIndexRef.current - 1
                              ].start;
                            setVisibilityIndex(visibilityIndexRef.current - 1);
                          } else {
                            videoLoaded.currentTime =
                              dataForChart.current.visibility[
                                dataForChart.current.visibility.length - 1
                              ].start;
                            setVisibilityIndex(
                              dataForChart.current.visibility.length - 1
                            );
                          }
                        }}
                        style={{ width: "1.875vw", height: "1.875vw" }}
                      />
                      <p
                        style={{
                          color: colors.text,
                          fontFamily: "Atkinson Hyperlegible",
                          fontSize: "0.8vw",
                          fontWeight: "400",
                          boxSizing: "border-box",
                          margin: 0,
                        }}
                      >
                        {(visibilityIndex == -1 ? "-" : visibilityIndex + 1) +
                          " " +
                          t("of") +
                          " " +
                          (dataForChart?.current?.visibility
                            ? dataForChart?.current?.visibility?.length
                            : "0")}
                      </p>
                      <ButtonIcon
                        icon={
                          process.env.REACT_APP_RESOURCES_BUCKET+"leftArrowSmall.png"
                        }
                        iconH={
                          process.env.REACT_APP_RESOURCES_BUCKET+"leftArrowSmallHW.png"
                        }
                        onClick={() => {
                          if (visibilityIndexRef.current < 0) {
                            videoLoaded.currentTime =
                              dataForChart.current.visibility[0].start;
                            setVisibilityIndex(0);
                          } else if (
                            visibilityIndex <
                            dataForChart?.current?.visibility?.length - 1
                          ) {
                            videoLoaded.currentTime =
                              dataForChart.current.visibility[
                                visibilityIndexRef.current + 1
                              ].start;
                            setVisibilityIndex(visibilityIndexRef.current + 1);
                          } else {
                            videoLoaded.currentTime =
                              dataForChart.current.visibility[0].start;
                            setVisibilityIndex(0);
                          }
                        }}
                        style={{
                          width: "1.875vw",
                          height: "1.875vw",
                          transform: "rotate(180deg)",
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5vw",
                  width: "70%",
                  alignItems: "center",
                }}
              >
                <DividerSmall />
                <div
                  style={{
                    display: "flex",
                    gap: "0.5vw",
                    marginBottom: "0vw",
                  }}
                >
                  <ButtonIcon
                    disabled={
                      data.graph[selectedChart].type >= 1
                        ? batchIndex >= batchLevel.length - 1
                        : dataForChartHistory.current.length -
                            historyIndex.current <=
                          1
                    }
                    icon={
                      process.env.REACT_APP_RESOURCES_BUCKET+"returnViolet.png"
                    }
                    iconH={
                      process.env.REACT_APP_RESOURCES_BUCKET+"returnVioletHW.png"
                    }
                    iconDisabled={
                      process.env.REACT_APP_RESOURCES_BUCKET+"returnVioletDB.png"
                    }
                    onClick={
                      data.graph[selectedChart].type >= 1
                        ? () => {
                            setBatchIndex(batchIndex + 1);
                          }
                        : () => {
                            moveHistory(-1);
                          }
                    }
                    style={{ width: "1.875vw", height: "1.875vw" }}
                  />
                  <ButtonIcon
                    icon={
                      process.env.REACT_APP_RESOURCES_BUCKET+"returnViolet.png"
                    }
                    iconH={
                      process.env.REACT_APP_RESOURCES_BUCKET+"returnVioletHW.png"
                    }
                    iconDisabled={
                      process.env.REACT_APP_RESOURCES_BUCKET+"returnVioletDB.png"
                    }
                    disabled={
                      data.graph[selectedChart].type >= 1
                        ? batchIndex == 0
                        : historyIndex.current == 0
                    }
                    onClick={
                      data.graph[selectedChart].type >= 1
                        ? () => {
                            setBatchIndex(batchIndex - 1);
                          }
                        : () => {
                            moveHistory(1);
                          }
                    }
                    style={{
                      width: "1.875vw",
                      height: "1.875vw",
                      transform: "scaleX(-1)",
                    }}
                  />
                </div>
              </div>
            </div>
          </LineChartWrapper>
          <PopupError
            ref={popupErrorRef}
            errors={dataForChart.current?.errorsLabels}
            onSelection={(id) => {
              for (
                let i = editingRef.current.start;
                i <= editingRef.current.end;
                i++
              ) {
                dataForChart.current.addedErrors[id][i] = 1;
              }
              dataForChart.current.addingError = Array.from(
                { length: dataForChart.current.addingError.length },
                () => 0
              );
              setEditing(null);
              checkpoint();
              createChart(
                dataForChart.current,
                chartRef.current.getContext("2d")
              );
              popupErrorRef.current.close();
            }}
          />
        </>
      );
  }
);

LineChart.propTypes = {
  graph: PropTypes.object,
};

LineChart.defaultProps = {
  data: {
    label: "",
    side: "",
    graph: {
      values: [""],
      errors: [""],
    },
  },
};

export default LineChart;
