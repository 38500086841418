import { useClerk } from "@clerk/clerk-react";
import { TreeView } from "@mui/x-tree-view";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { colors } from "../../config/style";
import { tips } from "../../config/tips";
import {
  handleLoadDepartment,
  handleLoadDepartments,
} from "../../remote/utils";
import { LOAD_LOCATION } from "../../stores/actions/DataAction";
import { DATA_A } from "../../stores/actions/TypeActions";
import { useStateValue } from "../../stores/services/StateProvider";
import { PopupCreate } from "../Popup/Popup";
import {
  PopupDepartment,
  PopupJob,
  PopupLine,
  PopupShift,
  PopupWorkstation,
} from "../Popup/PopupContent";
import ButtonTextIcon from "../buttons/ButtonTextIcon/ButtonTextIcon";
import { StyledTreeItem } from "./Tree";

export const TreeStructure = ({
  width,
  setData,
  setDepartmentDetail,
  departmentDetail,
  setCurrentDepartment,
}) => {
  const [state, dispatch] = useStateValue();
  const [expanded, setExpanded] = useState([]);
  const [popupContent, setPopupContent] = useState({
    info: "",
    children: "",
    actionLabel: "",
  });

  const popupRef = useRef();

  const { session } = useClerk();

  const { t } = useTranslation();

  const [selected, setSelected] = useState(null);

  const handleTreeSelect = (event, nodeIds) => {
    if (
      !nodeIds.startsWith("dep-") &&
      !nodeIds.startsWith("shifts-") &&
      !nodeIds.startsWith("jobs-") &&
      !nodeIds.startsWith("areas-") &&
      !nodeIds.startsWith("line-")
    ) {
      setSelected(nodeIds);
    } else {
      event.target.blur();
    }
  };

  const handleToggle = (event, nodeIds) => {
    event.stopPropagation();
    setExpanded(nodeIds);
  };

  const handleFocus = (event, nodeIds) => {};

  const renderWorkspaces = (workspaces, line, depId) => {
    const sortedKeys = Object.keys(workspaces).sort((a, b) => {
      const nameA = workspaces[a]?.name?.toUpperCase();
      const nameB = workspaces[b]?.name?.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    const workspaceItems = sortedKeys.map((workspaceId) => {
      const workspace = workspaces[workspaceId];
      return (
        <StyledTreeItem
          key={`ws-${workspace.id}`}
          nodeId={`ws-${workspace.id}`}
          labelText={workspace.name}
          bgColor={colors.mainOpaque}
          font={{
            color: colors.text,
            fontFamily: "Atkinson Hyperlegible",
            fontSize: "0.95vw",
            fontWeight: "400",
            marginLeft: "0.3vw",
          }}
          labelIcon={
            process.env.REACT_APP_RESOURCES_BUCKET+"Table.png"
          }
          onClick={() => {
            setCurrentDepartment(workspace.department);
            setData({
              type: "ws",
              id: workspace.id,
              line: workspace.line,
            });
          }}
        ></StyledTreeItem>
      );
    });

    workspaceItems.push(
      <StyledTreeItem
        key={"newWs-" + depId + line}
        nodeId={"newWs-" + depId + line}
        labelText={t("workspace")}
        bgColor={colors.mainOpaque}
        font={{
          color: colors.main,
          fontFamily: "Atkinson Hyperlegible",
          fontSize: "0.95vw",
          fontWeight: "400",
          marginLeft: "0.3vw",
        }}
        labelIcon={
          process.env.REACT_APP_RESOURCES_BUCKET+"Tablehw.png"
        }
        extraIcon={
          <img
            src={
              process.env.REACT_APP_RESOURCES_BUCKET+"Crosshw.png"
            }
            style={{
              width: "1.875vw",
              height: "1.875vw",
              marginRight: "0.2vw",
            }}
          />
        }
        // onClick={()=>{let }}
        // onClick={() => {
        //   setPopupContent(PopupWorkstation());
        //   popupRef.current.open();
        // }}

        onClick={() => {
          setCurrentDepartment(depId);
          setPopupContent({
            info: tips[document.language].postazione,
            title: t("workspacePopupTitle"),
            children: (
              <PopupWorkstation
                MainOwnerCompany={state.current_location.MainOwnerCompany}
                location={state.current_location.id}
                department={depId}
                line={line}
                onSuccess={(request) => {
                  handleLoadDepartment(
                    depId,
                    () => {
                      popupRef.current.close();
                      setData({
                        type: "ws",
                        id: request.id,
                        line: request.line,
                      });
                      // setExpanded([
                      //   ...expanded,
                      //   `line-${request.line + request.department}`,
                      // ]);
                      setSelected("ws-" + request.id);
                    },
                    departmentDetail,
                    session,
                    state.current_location.id,
                    (x) => setDepartmentDetail(x)
                  );
                }}
              />
            ),
          });
          popupRef.current.open();
        }}
      ></StyledTreeItem>
    );

    return workspaceItems;
  };

  // Render lines direttamente sotto il dipartimento
  const renderLines = (departmentDetail, depId) => {
    // renderLines(
    //   departmentDetail[department.id]["lines"],
    //   department.id
    // )

    let lines = departmentDetail?.[depId]?.["lines"];

    let lineItems;

    if (!lines) {
      lineItems = [];
    } else {
      const sortedKeys = Object.keys(lines).sort((a, b) => {
        const nameA = a?.toUpperCase();
        const nameB = b?.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      lineItems = sortedKeys.map((lineId) => {
        return (
          <StyledTreeItem
            key={`line-${lineId + depId}`}
            nodeId={`line-${lineId + depId}`}
            labelText={lineId}
            bgColor={colors.mainOpaque}
            font={{
              color: colors.text,
              fontFamily: "Atkinson Hyperlegible",
              fontSize: "0.95vw",
              fontWeight: "400",
              marginLeft: "0.3vw",
            }}
          >
            {renderWorkspaces(lines[lineId], lineId, depId)}
          </StyledTreeItem>
        );
      });
    }

    // Aggiungi un nuovo StyledTreeItem alla fine per "Nuova" linea
    lineItems.push(
      <StyledTreeItem
        key={"newLine-" + depId}
        nodeId={"newLine-" + depId}
        labelText={t("area")}
        bgColor={colors.mainOpaque}
        font={{
          color: colors.main,
          fontFamily: "Atkinson Hyperlegible",
          fontSize: "0.95vw",
          fontWeight: "400",
          marginLeft: "0.3vw",
        }}
        labelIcon={
          process.env.REACT_APP_RESOURCES_BUCKET+"AreaHw.png"
        }
        extraIcon={
          <img
            src={
              process.env.REACT_APP_RESOURCES_BUCKET+"Crosshw.png"
            }
            style={{
              width: "1.875vw",
              height: "1.875vw",
              marginRight: "-0.1vw",
            }}
          />
        }
        onClick={() => {
          setCurrentDepartment(depId);
          setPopupContent({
            info: tips[document.language].area,
            title: t("areaPopupTitle"),
            children: (
              <PopupLine
                MainOwnerCompany={state.current_location.MainOwnerCompany}
                location={state.current_location.id}
                department={depId}
                onSuccess={(request) => {
                  handleLoadDepartment(
                    depId,
                    () => {
                      popupRef.current.close();
                      setData({
                        type: "ws",
                        id: request.id,
                        line: request.line,
                      });
                      setExpanded([
                        ...expanded,
                        `line-${request.line + request.department}`,
                      ]);
                      setSelected("ws-" + request.id);
                    },
                    departmentDetail,
                    session,
                    state.current_location.id,
                    (x) => setDepartmentDetail(x)
                  );
                }}
              />
            ),
          });
          popupRef.current.open();
        }}
      ></StyledTreeItem>
    );

    return lineItems;
  };

  const renderShifts = (departmentDetail, depId) => {
    let shiftsItems;

    let shifts = departmentDetail?.[depId]?.["workshifts"];

    if (!shifts) {
      shiftsItems = [];
    } else {
      const sortedKeys = Object.keys(shifts).sort((a, b) => {
        const nameA = shifts[a]?.name?.toUpperCase();
        const nameB = shifts[b]?.name?.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });

      shiftsItems = sortedKeys.map((shiftId) => {
        const shift = shifts[shiftId];
        return (
          <StyledTreeItem
            key={`shift-${shift.id}`}
            nodeId={`shift-${shift.id}`}
            labelText={shift.name}
            bgColor={colors.mainOpaque}
            font={{
              color: colors.text,
              fontFamily: "Atkinson Hyperlegible",
              fontSize: "0.95vw",
              fontWeight: "400",
              marginLeft: "0.3vw",
            }}
            onClick={() => {
              setCurrentDepartment(depId);
              setData({ type: "shift", id: shift.id });
            }}
          ></StyledTreeItem>
        );
      });
    }

    shiftsItems.push(
      <StyledTreeItem
        key={"newShift-" + depId}
        nodeId={"newShift-" + depId}
        labelText={t("shift")}
        bgColor={colors.mainOpaque}
        font={{
          color: colors.main,
          fontFamily: "Atkinson Hyperlegible",
          fontSize: "0.95vw",
          fontWeight: "400",
          marginLeft: "0.3vw",
        }}
        labelIcon={
          process.env.REACT_APP_RESOURCES_BUCKET+"Timetables2hw.png"
        }
        extraIcon={
          <img
            src={
              process.env.REACT_APP_RESOURCES_BUCKET+"Crosshw.png"
            }
            style={{
              width: "1.875vw",
              height: "1.875vw",
              marginRight: "-0.1vw",
            }}
          />
        }
        onClick={() => {
          setCurrentDepartment(depId);
          setPopupContent({
            info: tips[document.language].turno,
            title: t("shiftPopupTitle"),
            children: (
              <PopupShift
                MainOwnerCompany={state.current_location.MainOwnerCompany}
                location={state.current_location.id}
                department={depId}
                onSuccess={(newId) => {
                  handleLoadDepartment(
                    depId,
                    () => {
                      popupRef.current.close();
                      setData({
                        type: "shift",
                        id: newId,
                      });
                      setSelected("shift-" + newId);
                    },
                    departmentDetail,
                    session,
                    state.current_location.id,
                    (x) => setDepartmentDetail(x)
                  );
                }}
              />
            ),
          });
          popupRef.current.open();
        }}
      ></StyledTreeItem>
    );

    return shiftsItems;
  };

  const renderJobs = (departmentDetail, depId) => {
    let jobsItems;
    let jobs = departmentDetail?.[depId]?.["jobs"];
    let shift = departmentDetail?.[depId]?.["workshifts"];

    if (!jobs) {
      jobsItems = [];
    } else {
      const sortedKeys = Object.keys(jobs).sort((a, b) => {
        const nameA = jobs[a]?.name?.toUpperCase();
        const nameB = jobs[b]?.name?.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      jobsItems = sortedKeys.map((jobId) => {
        const job = jobs[jobId];
        return (
          <StyledTreeItem
            key={`job-${job.id}`}
            nodeId={`job-${job.id}`}
            labelText={job.name}
            bgColor={colors.mainOpaque}
            font={{
              color: colors.text,
              fontFamily: "Atkinson Hyperlegible",
              fontSize: "0.95vw",
              fontWeight: "400",
              marginLeft: "0.3vw",
            }}
            onClick={() => {
              setCurrentDepartment(depId);
              setData({ type: "job", id: job.id });
            }}
          ></StyledTreeItem>
        );
      });
    }

    jobsItems.push(
      <StyledTreeItem
        key={"newJob-" + depId}
        nodeId={"newJob-" + depId}
        labelText={t("job")}
        bgColor={colors.mainOpaque}
        font={{
          color: colors.main,
          fontFamily: "Atkinson Hyperlegible",
          fontSize: "0.95vw",
          fontWeight: "400",
          marginLeft: "0.3vw",
        }}
        labelIcon={
          process.env.REACT_APP_RESOURCES_BUCKET+"Workerhw.png"
        }
        extraIcon={
          <img
            src={
              process.env.REACT_APP_RESOURCES_BUCKET+"Crosshw.png"
            }
            style={{
              width: "1.875vw",
              height: "1.875vw",
              marginRight: "-0.1vw",
            }}
          />
        }
        // onClick={() => {
        //   setPopupContent(PopupJob());
        //   popupRef.current.open();
        // }}

        onClick={() => {
          let firstShift = Object.keys(departmentDetail[depId].workshifts)[0];

          if (firstShift) {
            setCurrentDepartment(depId);
            setPopupContent({
              info: tips[document.language].mansione,
              title: t("shiftPopupJob"),
              children: (
                <PopupJob
                  MainOwnerCompany={state.current_location.MainOwnerCompany}
                  location={state.current_location.id}
                  department={depId}
                  shift={firstShift}
                  onSuccess={(request) => {
                    handleLoadDepartment(
                      depId,
                      () => {
                        setData({
                          type: "job",
                          id: request.id,
                        });
                        // setExpanded([
                        //   ...expanded,
                        //   `line-${request.line + request.department}`,
                        // ]);
                        setSelected("job-" + request.id);
                        popupRef.current.close();
                      },
                      departmentDetail,
                      session,
                      state.current_location.id,
                      (x) => setDepartmentDetail(x)
                    );
                  }}
                />
              ),
            });
            popupRef.current.open();
          } else {
            alert(t("jobPopupAlert"));
          }
        }}
      ></StyledTreeItem>
    );

    return jobsItems;
  };

  const renderDepartments = (departments) => {
    const sortedDepartmentKeys = Object.keys(departments).sort((a, b) => {
      const nameA = departments[a]?.name?.toUpperCase();
      const nameB = departments[b]?.name?.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    const departmentItems = sortedDepartmentKeys.map((departmentId) => {
      let department = departments[departmentId];
      return (
        <StyledTreeItem
          key={`dep-${departmentId}`}
          nodeId={`dep-${departmentId}`}
          labelText={department.name}
          bgColor={colors.mainOpaque}
          font={{
            color: colors.text,
            fontFamily: "Atkinson Hyperlegible",
            fontSize: "0.95vw",
            fontWeight: "800",
            marginLeft: "0.3vw",
          }}
          labelIcon={
            process.env.REACT_APP_RESOURCES_BUCKET+"Factory.png"
          }
          onClick={() => {
            handleLoadDepartment(
              departmentId,
              () => {},
              departmentDetail,
              session,
              state.current_location.id,
              (x) => setDepartmentDetail(x)
            );
          }}
        >
          <StyledTreeItem
            nodeId={`shifts-${department.name}`}
            labelText={t("shifts")}
            bgColor={colors.mainOpaque}
            font={{
              color: colors.text,
              fontFamily: "Atkinson Hyperlegible",
              fontSize: "0.95vw",
              fontWeight: "800",
              marginLeft: "0.3vw",
            }}
            labelIcon={
              process.env.REACT_APP_RESOURCES_BUCKET+"Timetables2.png"
            }
          >
            {renderShifts(departmentDetail, department.id)}
          </StyledTreeItem>
          <StyledTreeItem
            nodeId={`jobs-${department.name}`}
            labelText={t("jobs")}
            bgColor={colors.mainOpaque}
            font={{
              color: colors.text,
              fontFamily: "Atkinson Hyperlegible",
              fontSize: "0.95vw",
              fontWeight: "800",
              marginLeft: "0.3vw",
            }}
            labelIcon={
              process.env.REACT_APP_RESOURCES_BUCKET+"Worker.png"
            }
          >
            {renderJobs(departmentDetail, department.id)}
          </StyledTreeItem>
          <StyledTreeItem
            nodeId={`areas-${department.name}`}
            labelText={t("areas")}
            bgColor={colors.mainOpaque}
            font={{
              color: colors.text,
              fontFamily: "Atkinson Hyperlegible",
              fontSize: "0.95vw",
              fontWeight: "800",
              marginLeft: "0.3vw",
            }}
            labelIcon={
              process.env.REACT_APP_RESOURCES_BUCKET+"Area.png"
            }
          >
            {renderLines(departmentDetail, department.id)}
          </StyledTreeItem>
          <div style={{ height: "1vw" }}></div>{" "}
          {/* Ajusta 'height' come necessario */}
        </StyledTreeItem>
      );
    });

    // Aggiungi un nuovo StyledTreeItem alla fine per "Nuovo Dipartimento"
    departmentItems.push(
      <StyledTreeItem
        id="newDepartment"
        key="newDepartment"
        nodeId="dept-new"
        labelText={t("department")}
        bgColor={colors.mainOpaque}
        font={{
          color: colors.main,
          fontFamily: "Atkinson Hyperlegible",
          fontSize: "0.95vw",
          fontWeight: "400",
          marginLeft: "0.3vw",
        }}
        labelIcon={
          process.env.REACT_APP_RESOURCES_BUCKET+"Factoryhw.png"
        }
        extraIcon={
          <img
            src={
              process.env.REACT_APP_RESOURCES_BUCKET+"Crosshw.png"
            }
            style={{
              width: "1.875vw",
              height: "1.875vw",
              marginRight: "-0.5vw",
            }}
          />
        }
        onClick={() => {
          setSelected([]);
          setPopupContent({
            info: tips[document.language].dipartimento,
            title: t("departmentPopupTitle"),
            children: (
              <PopupDepartment
                MainOwnerCompany={state.current_location.MainOwnerCompany}
                location={state.current_location.id}
                onSuccess={(newId) => {
                  handleLoadDepartments(
                    state.current_location.id,
                    (locationUpdated) => {
                      dispatch({
                        type: DATA_A,
                        subtype: LOAD_LOCATION,
                        action: locationUpdated,
                      });
                      popupRef.current.close();
                      setSelected("dep-" + newId);
                    },
                    session
                  );
                }}
              />
            ),
          });
          popupRef.current.open();
        }}
      ></StyledTreeItem>
    );

    return departmentItems;
  };

  return (
    <>
      <TreeView
        expanded={expanded}
        onNodeToggle={handleToggle}
        selected={selected}
        onNodeSelect={handleTreeSelect}
        onNodeFocus={handleFocus}
        aria-label="departments navigator"
        defaultCollapseIcon={
          <img
            src={
              process.env.REACT_APP_RESOURCES_BUCKET+"Arrow.png"
            }
            style={{
              width: "1.875vw",
              height: "1.875vw",
              margin: "0px",
              marginLeft: "0.5vw",
            }}
          />
        }
        defaultExpandIcon={
          <img
            src={
              process.env.REACT_APP_RESOURCES_BUCKET+"Arrow.png"
            }
            style={{
              width: "1.875vw",
              height: "1.875vw",
              margin: "0px",
              transform: "rotate(-90deg)",
              marginLeft: "0.5vw",
            }}
          />
        }
        sx={{
          //   height: "100%",
          flexGrow: 1,
          maxWidth: width,
          width: width,
          height: "1vw",
          paddingRight: "0.5vw",
          marginBottom: "1vw",
          overflowY: "scroll",

          "& .MuiTreeItem-content": {
            "& .MuiTreeItem-iconContainer": {
              marginRight: "0px",
            },
            "& .MuiTreeItem-label": {
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              maxWidth: "100%",
              marginLeft: "0px",
              paddingLeft: "0.5vw",
            },
          },
        }}
      >
        {renderDepartments(state.current_location.departments)}
      </TreeView>
      <ButtonTextIcon
        label={t("compressAll")}
        icon={
          process.env.REACT_APP_RESOURCES_BUCKET+"CompressMain.png"
        }
        backgroundH={colors.mainOpaque}
        background={""}
        color={colors.main}
        onClick={() => setExpanded([])}
        margin={"0vw 0vw 1vw 0vw"}
        style={{ boxShadow: "0 0 0", padding: "0.5vw" }}
      />

      <PopupCreate
        ref={popupRef}
        info={popupContent.info}
        title={popupContent.title}
        actionLabel={popupContent.actionLabel}
        children={popupContent.children}
      />
    </>
  );
};
