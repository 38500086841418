import React from "react";
import PropTypes from "prop-types";
import { BodySchemaWrapper, Cell, Row, ValueSpan } from "./BodySchema.styled";
import { colors } from "../../config/style";
import { getOcraColor } from "../../utils/Protocols/OCRA/OCRA";
import { useTranslation } from "react-i18next";

const BodySchema = ({ dataTestId, items }) => {
  const flatItems = items.flatMap((x) =>
    Object.keys(x.analysis).map((y) => ({
      label: x.analysis[y].label.replace(/SX|DX/g, ""),
      map: x.analysis[y].map,
      value:
        x.analysis[y].value < 0
          ? -1
          : Math.round(x.analysis[y].value * 10) / 10,
    }))
  );

  const { t } = useTranslation();

  return (
    <BodySchemaWrapper
      style={{
        marginTop: flatItems.length == 2 && "4vw",
      }}
    >
      <Row>
        <Cell
          style={{
            justifyContent: "start",
          }}
        >
          <b>{t("RIGHT")}</b>
        </Cell>
        <Cell
          style={{
            justifyContent: "end",
          }}
        >
          <b>{t("LEFT")}</b>
        </Cell>
      </Row>
      {flatItems.reduce((acc, current, idx, array) => {
        if (idx % 2 === 0) {
          const next = array[idx + 1] || {};
          const isLastRow = array.length > 2 && idx >= array.length - 2;

          acc.push(
            <Row key={idx} style={{ marginTop: isLastRow ? "1vw" : "0.2vw" }}>
              <Cell
                style={{
                  justifyContent: "start",
                  // Applica il backgroundColor solo se non è l'ultima riga
                  backgroundColor: !isLastRow
                    ? getOcraColor(current.value, current.map)[1]
                    : "transparent",
                }}
              >
                <ValueSpan
                  style={{
                    marginRight: "0.2vw",
                    width: "2vw",
                    textAlign: "start",
                    color: getOcraColor(current.value, current.map)[0],
                  }}
                >
                  {current.value >= 0 ? current.value : "-"}
                </ValueSpan>
                {current.label}
              </Cell>

              <Cell
                style={{
                  justifyContent: "end",
                  // Applica il backgroundColor solo se non è l'ultima riga
                  backgroundColor: !isLastRow
                    ? getOcraColor(next.value, next.map)[1]
                    : "transparent",
                }}
              >
                {next.label}{" "}
                <ValueSpan
                  style={{
                    marginLeft: "0.2vw",
                    width: "2vw",
                    textAlign: "end",
                    color: getOcraColor(next.value, next.map)[0],
                  }}
                >
                  {next.value >= 0 ? next.value : "-"}
                </ValueSpan>
              </Cell>
            </Row>
          );
        }
        return acc;
      }, [])}
      <img
        src={
          process.env.REACT_APP_RESOURCES_BUCKET+"Body.png"
        }
        style={{
          width: "10vw",
          position: "relative",
          top: flatItems.length === 2 ? "-8vw" : "-13vw",
          // height: "1.9vw",
        }}
      />
    </BodySchemaWrapper>
  );
};

BodySchema.propTypes = {
  dataTestId: PropTypes.string, // Definisci il tipo di prop "dataTestId"
};

BodySchema.defaultProps = {
  dataTestId: "default-data-testid", // Imposta un valore predefinito per "dataTestId"
};

export default BodySchema;
