import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// Importa le risorse di traduzione
import translationEN from "./locales/en/en.json";
import translationIT from "./locales/it/it.json";
import { DEFAULT_LANGUAGE } from "./config/generalConst";

const resources = {
  en: {
    translation: translationEN,
  },
  it: {
    translation: translationIT,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: DEFAULT_LANGUAGE,
    interpolation: {
      escapeValue: false,
    },
    // detection: {
    //   order: ["querystring", "cookie", "localStorage", "navigator", "htmlTag"],
    //   caches: ["localStorage", "cookie"],
    // },
  });

export default i18n;
