import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { colors } from "../../config/style";
import MagicIcon from "../../icons/MagicIcon";
import { setAlpha } from "../../utils/generalFunctions";

const PillSelector = ({
  options,
  option,
  setOption,
  protocol,
  setProtocol,
  style,
  width,
  rightComponent,
  color,
  colorBackground,
}) => {
  const { t } = useTranslation();

  const padding = 0.5;
  const height = 2.5;

  const PillElement = ({ on, option, onClick, width, color }) => {
    const [hover, setHover] = useState(false);

    function getColor() {
      if (option.disabled) {
        return colors.disabledText;
      }
      if (on) {
        return "white";
      }
      if (hover) return color;
      else return colors.text;
    }

    return (
      <div
        onClick={option.disabled ? () => {} : () => onClick()}
        onMouseEnter={option.disabled ? () => {} : () => setHover(true)}
        onMouseLeave={() => setHover(false)}
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          background: on ? color : "transparent",
          width: width,
          height: height - 2 * padding + "vw",
          borderRadius: height - 2 * padding + "vw",
          cursor: option.disabled ? "not-allowed" : on ? "default" : "pointer",
          gap: "0.1vw",
          boxShadow: on
            ? "0vw 0.2vw 0.45vw 0vw " + setAlpha(color, 0.25)
            : "0vw 0vw 0vw 0vw " + setAlpha(color, 0.25),
        }}
      >
        <p
          style={{
            color: getColor(),
            fontFamily: "Atkinson Hyperlegible",
            fontSize: "0.7vw",
            fontWeight: "400",
            boxSizing: "border-box",
            margin: "0",
          }}
        >
          {option.label}
        </p>
        {option.icon && option.icon(getColor(), "0.93vw")}
        {/* <MagicIcon fill={"red"} /> */}
      </div>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        background: colorBackground,
        padding: padding + "vw",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "2vw",
        gap: "0vw",
        width: width + "vw",
        height: height + "vw",
        boxSizing: "border-box",
      }}
    >
      {options &&
        options.map((x, id) => {
          return (
            <PillElement
              option={x}
              on={id == option}
              onClick={() => setOption(id)}
              width={(width - 2 * padding) / options.length + "vw"}
              color={color}
            />
          );
        })}
      {rightComponent ? (
        <>
          <div
            style={{
              background: colors.mainOpaque,
              width: "0.14vw",
              height: "100%",
              marginLeft: "0.2vw",
              marginRight: "0.2vw",
            }}
          ></div>
          {rightComponent}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

PillSelector.propTypes = {
  dataTestId: PropTypes.string, // Definisci il tipo di prop "dataTestId"
};

PillSelector.defaultProps = {
  dataTestId: "default-data-testid", // Imposta un valore predefinito per "dataTestId"
};

export default PillSelector;
