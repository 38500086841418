import { PDFDocument } from "pdf-lib";
import {
  shift_global,
  single_task_global,
} from "../../utils/Protocols/OCRA/singleTask";
import { getResults } from "../../remote/structure";
import { getToken } from "../../userManagement/utilities";
import { fetchDataLeftRight, getInspectionTaskData } from "../../remote/tasks";
import { getAvailableAnalysis } from "../../config/availableAnalysis";
import { useTranslation } from "react-i18next";
import { manageAiResult } from "../AnalysisContainer/AnalysisContainerFunctions";

async function addPdfPagesFromUrl(
  pdfDoc,
  pdfUrl,
  data = null,
  flatten = false
) {
  const pdfBytes = await downloadPdf(pdfUrl);
  const sourcePdfDoc = await PDFDocument.load(pdfBytes);
  const pages = await pdfDoc.copyPages(
    sourcePdfDoc,
    sourcePdfDoc.getPageIndices()
  );
  // console.log("page", pdfUrl);

  if (data) {
    const form = sourcePdfDoc.getForm();
    // console.log("form", form.getFields());

    for (const field of form.getFields()) {
      const fieldName = field.getName();
      // console.log("fieldName", fieldName);
      if (data[fieldName] != undefined) {
        let textField = form.getTextField(fieldName);
        textField.setText(String(data[fieldName]));
        // console.log("Filled", String(data[fieldName]));
        field.enableReadOnly();
      }
    }

    // Rende le modifiche permanenti
    const modifiedPagesBytes = await sourcePdfDoc.save();
    const modifiedSourcePdfDoc = await PDFDocument.load(modifiedPagesBytes);
    const modifiedPages = await pdfDoc.copyPages(
      modifiedSourcePdfDoc,
      modifiedSourcePdfDoc.getPageIndices()
    );
    modifiedPages.forEach((page) => pdfDoc.addPage(page));
  } else {
    pages.forEach((page) => pdfDoc.addPage(page));
  }
}

async function customizeAndCreatePdf(pages, fileName, flatten, onSuccess) {
  try {
    const pdfDoc = await PDFDocument.create();
    // Funzione helper per aggiungere pagine da un URL PDF

    for (const page of pages) {
      //PAGE GROUP CASE
      if (Array.isArray(page.data)) {
        for (const data of page.data) {
          await addPdfPagesFromUrl(pdfDoc, page.url, data, flatten);
        }
      } //SINGLE PAGE CASE
      else {
        await addPdfPagesFromUrl(pdfDoc, page.url, page.data, flatten);
      }
    }
    const pdfBytesUpdated = await pdfDoc.save();
    downloadBlob(
      pdfBytesUpdated,
      sanitizeFilename(fileName) + ".pdf",
      "application/pdf"
    );
  } catch (error) {
    console.error("Errore durante la personalizzazione del PDF:", error);
  }
  onSuccess();
}

function sanitizeFilename(filename) {
  return filename.replace(/[^a-zA-Z0-9.]/g, "").replace(/\s+/g, "");
}

async function downloadPdf(pdfUrl) {
  const response = await fetch(pdfUrl);
  if (!response.ok) throw new Error(`Errore nel download del PDF da ${pdfUrl}`);
  return response.arrayBuffer();
}

const generateReport = async (company, generalData, fileName, onSuccess) => {
  let generalPath = process.env.REACT_APP_RESOURCES_BUCKET + "report/";
  let defaultPath = "default/";
  let reportPath;

  try {
    reportPath = generalPath + company + "/";

    const response = await fetch(reportPath + "reportConfig.json");

    const config = await response.json();

    let pages = config.pages.map((page) => {
      if (Object.keys(page).includes("data")) {
        return { url: reportPath + page.page, data: generalData[page.data] };
      }
      return { url: reportPath + page.page, data: generalData };
    });

    customizeAndCreatePdf(pages, fileName, config.flatten, onSuccess);
  } catch (error) {
    reportPath = generalPath + defaultPath;

    const response = await fetch(reportPath + "reportConfig.json");

    const config = await response.json();

    let pages = config.pages.map((page) => {
      if (Object.keys(page).includes("data")) {
        return { url: reportPath + page.page, data: generalData[page.data] };
      }
      return { url: reportPath + page.page, data: generalData };
    });

    customizeAndCreatePdf(pages, fileName, config.flatten, onSuccess);
  }
};

// Funzione per creare e scaricare un blob
function downloadBlob(data, fileName, mimeType) {
  const blob = new Blob([data], { type: mimeType });
  const url = window.URL.createObjectURL(blob);
  const downloadLink = document.createElement("a");
  downloadLink.href = url;
  downloadLink.download = fileName;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
  window.URL.revokeObjectURL(url);
}

async function ReportGenerator({
  job,
  shift,
  tasks,
  multitask,
  singleTask,
  department,
  location,
  user,
  session,
  setGenerating,
  t,
}) {
  setGenerating(true);

  let res = await getResults(
    tasks[0].task.last_result,
    await getToken(session)
  );

  // CREO DATI PER COMPILAZIONE
  const dataCorrente = new Date();

  let shiftRes = shift_global(shift);

  // console.log("tasks---", tasks);

  let generalData = {
    Autore: user.fullName,
    Data:
      ("0" + dataCorrente.getDate()).slice(-2) +
      "/" +
      ("0" + (dataCorrente.getMonth() + 1)).slice(-2) +
      "/" +
      dataCorrente.getFullYear(),
    Mansione: job.name,
    ["Mansione*"]: job.name,
    Azienda: location.company,
    Sede: location.name,
    Dipartimento: department.name,
    ["Denominazione del turno"]: shift.name,
    Dipartimento: department.name,
    Turno: shift.durata,
    ["Durata del turno"]: shift.durata,
    DurataNetta: shiftRes.output.durata_media_netta,
    OreSenzaRecupero: shiftRes.output.numero_ore_senza_recupero_automatico,
    Moltiplicatore: shiftRes.output.moltiplicatore_recupero,
    CalcoloManuale: shift.d_no_rec,
    NumeroPause: shift.n_pause_ufficiali,
    PauseUfficiali: shift.n_pause_effettive,
    EffettivaPause: shift.d_pause,
    PausaMensa: shift.d_pause_mensa,
    Interruzioni: shift.n_pause_extra,
    DurataComplessivaPause: shiftRes.output.durata_complessiva_pause_mensa,
    TaskNonRep: shift.task_non_ripetitivi ? "Si" : "No",
    IndossareDPI: shift.t_vestirsi,
    Pulizie: shift.t_pulizie,
    TempoNetto: shift.t_ragg_post,
    Altro: shift.t_altro,
    MinutiTotali: shiftRes.output.durata_non_ripetitivi,
    Dipartimento: department.name,
    Turno: shiftRes.output.durata_media_netta,
    MinoreDX: tasks.length > 1 ? multitask.mtUnder90.dx : "-",
    MinoreSX: tasks.length > 1 ? multitask.mtUnder90.sx : "-",
    MaggioreDX: tasks.length > 1 ? multitask.mtOver90.dx : "-",
    MaggioreSX: tasks.length > 1 ? multitask.mtOver90.sx : "-",
    IntrinsecoDX: tasks.length == 1 ? singleTask.dx.intrinseco : "-",
    IntrinsecoSX: tasks.length == 1 ? singleTask.sx.intrinseco : "-",
    PonderatoSX: tasks.length == 1 ? singleTask.sx.ponderato : "-",
    PonderatoDX: tasks.length == 1 ? singleTask.dx.ponderato : "-",
    tasksData: [],
  };

  // console.log("generalDatageneralData", generalData);

  tasks.map((x, id) => {
    generalData = {
      ...generalData,
      [id + 1]: x.task.name,
      [String(id + 1) + "Perc"]: x.durata,
      [String(id + 1) + "PunteggioDX"]: x.task.scores.dx,
      [String(id + 1) + "PunteggioSX"]: x.task.scores.sx,
    };
  });

  let i = 0;
  for (const task of tasks) {
    //LOAD TASK SPECIFIC DATA
    let res = await getResults(task.task.last_result, await getToken(session));
    let current_data = await fetchDataLeftRight(
      res.ai.LEFT,
      res.ai.RIGHT,
      res.ai.HANDS,
      res
    );
    const availableAnalysis = getAvailableAnalysis(current_data, t);
    let aiResult = manageAiResult(res.ai.edit, availableAnalysis);
    let ocraRes = single_task_global({ ...res.checklist, ...aiResult });
    // console.log("ocraRes", ocraRes);
    //CONVERT IN LABEL
    generalData.tasksData.push({
      ["Postazione"]: task.task.stationObj.name,
      ["Denominazione del compito"]: task.task.name,
      ["Denominazione del compito*"]: task.task.name,
      Dipartimento: department.name,
      Cicli:
        tasks.length == 1
          ? singleTask.cycleNumber
          : task.task.ocra.sx.otherTemp.numeroCicli,
      ["Tempo netto"]: task.task.ocra.sx.otherTemp.tempoCiclo,
      ParzialeDX: task.task.ocra.dx.parziale,
      IntrinsecoDX:
        tasks.length == 1
          ? singleTask.dx.intrinseco
          : task.task.ocra.dx.intrinseco,
      FinalePonderatoDX: task.task.ocra.dx.finale,
      ParzialeSX: task.task.ocra.sx.parziale,
      IntrinsecoSX:
        tasks.length == 1
          ? singleTask.sx.intrinseco
          : task.task.ocra.sx.intrinseco,
      PonderatoSX: task.task.ocra.sx.finale,
      SpallaDX: task.task.ocra.dx.postura.spalla,
      GomitoDX: task.task.ocra.dx.postura.gomito,
      PolsoDX: task.task.ocra.dx.postura.polso,
      ManoDX: task.task.ocra.dx.postura.mano,
      SpallaSX: task.task.ocra.sx.postura.spalla,
      GomitoSX: task.task.ocra.sx.postura.gomito,
      PolsoSX: task.task.ocra.sx.postura.polso,
      ManoSX: task.task.ocra.sx.postura.mano,
      PostureDX: task.task.ocra.dx.totale_postura,
      AzioniDX: task.task.ocra.dx.frequenza,
      BorgDX: task.task.ocra.dx.forza,
      ComplementariDX: task.task.ocra.dx.complementari,
      PostureSX: task.task.ocra.sx.totale_postura,
      AzioniSX: task.task.ocra.sx.frequenza,
      BorgSX: task.task.ocra.sx.forza,
      ComplementariSX: task.task.ocra.sx.complementari,
      StereoSX: task.task.ocra.sx.stereotipia,
      StereoDX: task.task.ocra.dx.stereotipia,
      ["Veri e propri cicli"]: res.checklist.veri_cicli ? "SI" : "NO",
      ["Tempo totale di ciclo osservato"]: res.checklist.tempo_ciclo_osservato,
      ["Presenza di tempi di recupero interni al ciclo"]: res.checklist
        .flag_tempi_recupero
        ? "SI"
        : "NO",
      ["T tot attività osservato"]: res.checklist.flag_tempi_recupero
        ? res.checklist.totale_tempo_attivo
        : "-",
      ["Perc attività"]: res.checklist.flag_tempi_recupero
        ? ocraRes.output.percentuale_tempo_attivo
        : "-",
      ["T tot inattività osservato"]: res.checklist.flag_tempi_recupero
        ? ocraRes.output.totale_secondi_tempo_passivo
        : "-",
      ["Perce inattività"]: res.checklist.flag_tempi_recupero
        ? ocraRes.output.percentuale_tempo_passivo
        : "-",
      ["Coeff controllo"]: res.checklist.flag_tempi_recupero
        ? ocraRes.output.rapporto_tempo_attivo_passivo
        : "-",
      ["Differenza tra T osservato e raccomandato"]:
        ocraRes.output.percentuale_differenza_ciclo + " %",
      ["NumeroAzioniDX"]: ocraRes.input.numero_azioni_dinamiche_dx,
      ["NumeroAzioniSX"]: ocraRes.input.numero_azioni_dinamiche_sx,
    });
    i++;
  }
  //---FINE CREAZIONE DATI PER REPORT

  //COMPILO IL REPORT

  generateReport(
    user?.publicMetadata?.company,
    generalData,
    job.name +
      "_" +
      ("0" + dataCorrente.getDate()).slice(-2) +
      ("0" + (dataCorrente.getMonth() + 1)).slice(-2) +
      dataCorrente.getFullYear(),
    () => setGenerating(false)
  );
}

export default ReportGenerator;
