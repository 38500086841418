import { NONE } from "../../config/generalConst";
import {
  CLEAR_ALL,
  CLEAR_DATA,
  CLEAR_INSPECTIONS,
  CLEAR_LOCATION,
  LOAD_DATA,
  LOAD_INSPECTIONS,
  LOAD_LOCATION,
} from "../actions/DataAction";

const initialState = {};

const DataReducer = (state = initialState, action) => {
  switch (action.subtype) {
    case LOAD_DATA:
      return { ...state, current_data: action.action };
    case CLEAR_DATA:
      return { ...state, current_data: NONE };
    case LOAD_INSPECTIONS:
      return { ...state, current_inspections: action.action };
    case CLEAR_INSPECTIONS:
      return { ...state, current_inspections: NONE };
    case LOAD_LOCATION:
      return { ...state, current_location: action.action };
    case CLEAR_LOCATION:
      return { ...state, current_location: NONE };
    case CLEAR_ALL:
      return {
        ...state,
        current_location: NONE,
        current_inspections: NONE,
        current_data: NONE,
      };

    default:
      return state;
  }
};

export default DataReducer;
