import React, { useState } from "react";
import PropTypes from "prop-types";
import { ButtonToggleWrapper } from "./ButtonToggle.styled";
import { colors } from "../../../config/style";

const ButtonToggle = ({ onClick, icon, iconH, toggle, label, disabled }) => {
  const [hover, setHover] = useState(false);

  const getBackground = () => {
    if (toggle) return colors.main;
    if (hover || disabled) return colors.details;
    return "white";
  };

  const getBorder = () => {
    if (toggle) return colors.main;
    return colors.details;
  };

  const getColor = () => {
    if (toggle) return "white";
    return colors.text;
  };

  return (
    <ButtonToggleWrapper
      src={hover ? iconH : icon}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={disabled ? () => {} : onClick}
      style={{
        cursor: disabled ? "normal" : "pointer",
        background: getBackground(),
        border: "0.1vw solid" + getBorder(),
        color: getColor(),
      }}
    >
      {label}
    </ButtonToggleWrapper>
  );
};

ButtonToggle.propTypes = {
  dataTestId: PropTypes.string, // Definisci il tipo di prop "dataTestId"
  onClick: PropTypes.func, // Definisci il tipo di prop "onClick"
  children: PropTypes.node, // Definisci il tipo di prop "children"
};

ButtonToggle.defaultProps = {
  dataTestId: "default-data-testid", // Imposta un valore predefinito per "dataTestId"
  onClick: () => {}, // Imposta una funzione vuota come valore predefinito per "onClick"
};

export default ButtonToggle;
