const initialState = {};

const PoseReducer = (state = initialState, action) => {
  switch (action.subtype) {
    default:
      return state;
  }
};

export default PoseReducer;
