export function getAvailableAnalysis(current_data, t) {
  return [
    {
      category: t("posturalWrong"),
      analysis: {
        rightShoulder: {
          label: t("shoulder") + " " + t("RI"),
          id_local: "rightShoulder",
          value: current_data?.right_video?.ocra?.shoulder?.right_wrong_time,
          unit: "s",
          side: "right_video",
          component: "shoulder",
          graph: [
            {
              label: t("rightShouldAngle"),
              type: 0,
              values: ["right_shoulder_angle"],
              valuesLabels: ["Angolo spalla destra"],
              errors: ["right_flexion_errors", "right_abduction_errors"],
              errorsLabels: ["Errore di flessione", "Errore di abduzione"],
              peaks: [],
              stdDuration: 0,
              thresholds: [
                current_data?.config?.shoulders_params?.angle_flexion,
                current_data?.config?.shoulders_params?.angle_abduction,
              ],
              y_min: 0,
              y_max: 180,
              abs: true,
              y_label: t("angle") + " [°]",
              x_label: t("time") + " [s]",
              legend: [
                { img: "Graph.png", label: t("shoulderTorsoAngle") },
                { img: "GraphE1.png", label: t("flexion") },
                { img: "GraphTh1.png", label: t("more80") },
                { img: "GraphE2.png", label: t("abduction") },
                { img: "GraphTh2.png", label: t("more45") },
                { img: "GraphAd.png", label: t("addedWrong") },
                { img: "GraphIg.png", label: t("ignoredWrong") },
              ],
              visibility: [
                { joint: "visibility_right_shoulder", thr: 0.5, cutoff: 1 },
                { joint: "visibility_nose", thr: 0.5, cutoff: 1 },
                { joint: "visibility_right_elbow", thr: 0.5, cutoff: 1 },
                { joint: "visibility_right_hip", thr: 0.5, cutoff: 1 },
              ],
            },
          ],
        },
        leftShoulder: {
          label: t("shoulder") + " " + t("LE"),
          id_local: "leftShoulder",
          value: current_data?.left_video.ocra?.shoulder?.left_wrong_time,
          unit: "s",
          side: "left_video",
          component: "shoulder",
          graph: [
            {
              label: t("leftShouldAngle"),
              type: 0,
              values: ["left_shoulder_angle"],
              valuesLabels: ["Angolo spalla sinistra"],
              errors: ["left_flexion_errors", "left_abduction_errors"],
              errorsLabels: ["Errore di flessione", "Errore di abduzione"],
              peaks: [],
              stdDuration: 0,
              thresholds: [
                current_data.config.shoulders_params.angle_flexion,
                current_data.config.shoulders_params.angle_abduction,
              ],
              abs: true,
              y_label: t("angle") + " [°]",
              x_label: t("time") + " [s]",
              y_min: 0,
              y_max: 180,
              legend: [
                { img: "Graph.png", label: t("shoulderTorsoAngle") },
                { img: "GraphE1.png", label: t("flexion") },
                { img: "GraphTh1.png", label: t("more80") },
                { img: "GraphE2.png", label: t("abduction") },
                { img: "GraphTh2.png", label: t("more45") },
                { img: "GraphAd.png", label: t("addedWrong") },
                { img: "GraphIg.png", label: t("ignoredWrong") },
              ],
              visibility: [
                { joint: "visibility_left_shoulder", thr: 0.5, cutoff: 1 },
                { joint: "visibility_left_elbow", thr: 0.5, cutoff: 1 },
                { joint: "visibility_left_hip", thr: 0.5, cutoff: 1 },
                { joint: "visibility_nose", thr: 0.5, cutoff: 1 },
              ],
            },
          ],
        },
        rightElbow: {
          label: t("elbow") + " " + t("RI"),
          id_local: "rightElbow",
          value: current_data?.right_video?.ocra?.elbow?.right_wrong_time,
          unit: "s",
          side: "right_video",
          component: "elbow",
          graph: [
            {
              label: t("elbowAngle"),
              type: 0,
              values: ["right_arm_forearm_angle"],
              valuesLabels: ["Angolo gomito sinistro"],
              errors: ["ascent_movements_right", "descent_movements_right"],
              errorsLabels: ["Errore di estensione", "Errore di flessione"],
              peaks: ["retained_peaks_right"],
              thresholds: [],
              stdDuration: 0.5,
              y_min: 0,
              y_max: 180,
              abs: true,
              y_label: t("angle") + " [°]",
              x_label: t("time") + " [s]",
              legend: [
                { img: "Graph.png", label: t("elbowAngleSh") },
                { img: "GraphE1.png", label: t("fastEst") },
                { img: "GraphE2.png", label: t("fastFlex") },
                { img: "GraphAd.png", label: t("addedWrong") },
                { img: "GraphIg.png", label: t("ignoredWrong") },
              ],
              visibility: [
                { joint: "visibility_right_elbow", thr: 0.5, cutoff: 1 },
                { joint: "visibility_nose", thr: 0.5, cutoff: 1 },
              ],
            },
            {
              label: t("pronoDx"),
              type: 1,
              batch: 10,
              values: ["right_fingers_distance"],
              valuesLabels: ["Numero prono supinazioni"],
              errors: [
                "ascent_movements_right_prono",
                "descent_movements_right_prono",
              ],
              errorsLabels: ["Errore di flessione", "Errore di estensione"],
              peaks: ["retained_peaks_right_prono"],
              thresholds: [],
              stdDuration: 0.5,
              y_min: 0,
              y_max: 20,
              abs: true,
              y_label: t("angle") + " [°]",
              x_label: t("time") + " [s]",
              legend: [
                { img: "GraphSq1.png", label: t("numberPronoSup") },
                { img: "GraphSq2.png", label: t("editedNumber") },
              ],
              visibility: [
                { joint: "visibility_right_elbow", thr: 0.5, cutoff: 1 },
                { joint: "visibility_nose", thr: 0.5, cutoff: 1 },
              ],
            },
          ],
        },
        leftElbow: {
          label: t("elbow") + " " + t("LE"),
          id_local: "leftElbow",
          value: current_data?.left_video?.ocra?.elbow?.left_wrong_time,
          unit: "s",
          side: "left_video",
          component: "elbow",
          graph: [
            {
              label: t("elbowAngle"),
              type: 0,
              values: ["left_arm_forearm_angle"],
              valuesLabels: ["Angolo gomito sinistro"],
              errors: ["ascent_movements_left", "descent_movements_left"],
              errorsLabels: ["Errore di estensione", "Errore di flessione"],
              peaks: ["retained_peaks_left"],
              thresholds: [],
              stdDuration: 0.5,
              y_min: 0,
              y_max: 180,
              abs: true,
              y_label: t("angle") + " [°]",
              x_label: t("time") + " [s]",
              legend: [
                { img: "Graph.png", label: t("elbowAngleSh") },
                { img: "GraphE1.png", label: t("fastEst") },
                { img: "GraphE2.png", label: t("fastFlex") },
                { img: "GraphAd.png", label: t("addedWrong") },
                { img: "GraphIg.png", label: t("ignoredWrong") },
              ],
              visibility: [
                { joint: "visibility_left_elbow", thr: 0.5, cutoff: 1 },
                { joint: "visibility_nose", thr: 0.5, cutoff: 1 },
              ],
            },
            {
              label: t("pronoSx"),
              type: 1,
              batch: 10,
              values: ["left_fingers_distance"],
              valuesLabels: ["Numero prono supinazioni"],
              errors: [
                "ascent_movements_left_prono",
                "descent_movements_left_prono",
              ],
              errorsLabels: ["Errore di flessione", "Errore di estensione"],
              peaks: ["retained_peaks_left_prono"],
              thresholds: [],
              stdDuration: 0.5,
              y_min: 0,
              y_max: 20,
              abs: true,
              y_label: t("angle") + " [°]",
              x_label: t("time") + " [s]",
              legend: [
                { img: "GraphSq1.png", label: t("numberPronoSup") },
                { img: "GraphSq2.png", label: t("editedNumber") },
              ],
              visibility: [
                { joint: "visibility_left_elbow", thr: 0.5, cutoff: 1 },
                { joint: "visibility_nose", thr: 0.5, cutoff: 1 },
              ],
            },
          ],
        },
        rightWrist: {
          label: t("wrist") + " " + t("RI"),
          id_local: "rightWrist",
          value: current_data?.right_video?.ocra?.wrist?.right_wrong_time,
          unit: "s",
          side: "right_video",
          component: "wrist",
          graph: [
            {
              label: t("rightWristAngle"),
              type: 0,
              values: ["right_wrist_angle_index2D"],
              valuesLabels: ["Angolo polso destro"],
              errors: ["ascent_movements_right", "descent_movements_right"],
              errorsLabels: ["Errore di flessione", "Errore di estensione"],
              peaks: ["retained_peaks_right"],
              thresholds: [],
              stdDuration: 0.5,
              y_label: t("angle") + " [°]",
              x_label: t("time") + " [s]",
              legend: [
                { img: "Graph.png", label: t("wristAngle") },
                { img: "GraphE1.png", label: t("fastFlex") },
                { img: "GraphE2.png", label: t("fastEst") },
                { img: "GraphAd.png", label: t("addedWrong") },
                { img: "GraphIg.png", label: t("ignoredWrong") },
              ],
              visibility: [
                { joint: "visibility_right_wrist", thr: 0.8, cutoff: 1 },
                { joint: "visibility_nose", thr: 0.5, cutoff: 1 },
              ],
            },
          ],
        },
        leftWrist: {
          label: t("wrist") + " " + t("LE"),
          id_local: "leftWrist",
          value: current_data?.left_video?.ocra?.wrist?.left_wrong_time,
          unit: "s",
          side: "left_video",
          component: "wrist",
          graph: [
            {
              label: t("leftWristAngle"),
              type: 0,
              values: ["left_wrist_angle_index2D"],
              valuesLabels: ["Angolo polso sinistro"],
              errors: ["ascent_movements_left", "descent_movements_left"],
              errorsLabels: ["Errore di flessione", "Errore di estensione"],
              peaks: ["retained_peaks_left"],
              thresholds: [],
              stdDuration: 0.5,
              y_label: t("angle") + " [°]",
              x_label: t("time") + " [s]",
              legend: [
                { img: "Graph.png", label: t("wristAngle") },
                { img: "GraphE1.png", label: t("fastFlex") },
                { img: "GraphE2.png", label: t("fastEst") },
                { img: "GraphAd.png", label: t("addedWrong") },
                { img: "GraphIg.png", label: t("ignoredWrong") },
              ],
              visibility: [
                { joint: "visibility_left_wrist", thr: 0.8, cutoff: 1 },
                { joint: "visibility_nose", thr: 0.5, cutoff: 1 },
              ],
            },
          ],
        },
        rightHand: {
          label: t("hand") + " " + t("RI"),
          id_local: "rightHand",
          value:
            (current_data?.hands?.ocra?.grabs?.right_wrong_percentage *
              current_data?.hands?.debug?.grabs?.video_length) /
            100,
          unit: "s",
          side: "hands",
          component: "grabs",
          graph: [
            {
              label: t("wrongRightHold"),
              type: 2,
              batch: 10,
              values: ["signals"],
              valuesLabels: ["Percentuale prese incongrue"],
              errors: ["right_wrong_percentages"],
              errorsLabels: ["% prese incongrue"],
              peaks: [],
              thresholds: [],
              stdDuration: 1,
              y_min: 0,
              y_max: 100,
              abs: true,
              y_label: t("percWrong2"),
              x_label: t("time") + " [s]",
              legend: [
                { img: "GraphSq1.png", label: t("percWrong") },
                { img: "GraphSq2.png", label: t("editedNumber") },
              ],
            },
          ],
        },
        leftHand: {
          label: t("hand") + " " + t("LE"),
          id_local: "leftHand",
          value:
            (current_data?.hands?.ocra?.grabs?.left_wrong_percentage *
              current_data?.hands?.debug?.grabs?.video_length) /
            100,
          unit: "s",
          side: "hands",
          component: "grabs",
          graph: [
            {
              label: t("wrongLeftHold"),
              type: 2,
              batch: 10,
              values: ["signals"],
              valuesLabels: ["Percentuale prese incongrue"],
              errors: ["left_wrong_percentages"],
              errorsLabels: ["% prese incongrue"],
              peaks: [],
              thresholds: [],
              stdDuration: 1,
              y_min: 0,
              y_max: 100,
              abs: true,
              y_label: t("percWrong2"),
              x_label: t("time") + " [s]",
              legend: [
                { img: "GraphSq1.png", label: t("percWrong") },
                { img: "GraphSq2.png", label: t("editedNumber") },
              ],
            },
          ],
        },
      },
    },
    {
      category: t("freqDynAction"),
      analysis: {
        dynamicRight: {
          label: t("azDyn") + " " + t("RI"),
          id_local: "dynamicRight",
          value:
            current_data?.right_video?.ocra?.technical_actions
              ?.right_dynamic_actions,
          unit: "",
          side: "right_video",
          component: "technical_actions",
          graph: [
            {
              label: t("dynRight"),
              type: 1,
              batch: 10,
              values: ["signals"],
              valuesLabels: ["N. azioni tecniche"],
              errors: ["right_instants_frames"],
              errorsLabels: ["N. azioni tecniche"],
              peaks: [],
              thresholds: [],
              stdDuration: 1,
              y_min: 0,
              y_max: 20,
              abs: true,
              y_label: "Numero di azioni",
              x_label: t("time") + " [s]",
              legend: [
                { img: "GraphSq1.png", label: t("actNumber") },
                { img: "GraphSq2.png", label: t("editedNumber") },
              ],
              visibility: [
                { joint: "visibility_right_wrist", thr: 0.5, cutoff: 1 },
                { joint: "visibility_nose", thr: 0.5, cutoff: 1 },
              ],
            },
          ],
        },
        dynamicLeft: {
          label: t("azDyn") + " " + t("LE"),
          id_local: "dynamicLeft",
          value:
            current_data?.left_video?.ocra?.technical_actions
              ?.left_dynamic_actions,
          unit: "",
          side: "left_video",
          component: "technical_actions",
          graph: [
            {
              label: t("dynLeft"),
              type: 1,
              batch: 10,
              values: ["signals"],
              valuesLabels: ["Numero azioni tecniche"],
              errors: ["left_instants_frames"],
              errorsLabels: ["N. azioni tecniche"],
              peaks: [],
              thresholds: [],
              stdDuration: 1,
              y_min: 0,
              y_max: 20,
              abs: true,
              y_label: "Numero di azioni",
              x_label: t("time") + " [s]",
              legend: [
                { img: "GraphSq1.png", label: t("actNumber") },
                { img: "GraphSq2.png", label: t("editedNumber") },
              ],
              visibility: [
                { joint: "visibility_left_wrist", thr: 0.5, cutoff: 1 },
                { joint: "visibility_nose", thr: 0.5, cutoff: 1 },
              ],
            },
          ],
        },
      },
    },
  ];
}
