import styled from "styled-components";
import { colors } from "../../config/style";

export const AnalysisContainerWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  flex-direction: row;
  background: ${colors.mainBackground};
  z-index: 999;
`;

export const LeftColumn = styled.div`
  height: 100vh;
  width: 24vw;
  max-width: 24vw;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0vw 0.6vw 0.6vw 0vw;
  box-shadow: 0.4vw 0px 1.1vw 0px rgba(113, 109, 242, 0.15);
  padding: 1vw;
  box-sizing: border-box;
  border: 0.5vw solid white;
  overflow: hidden;
`;

export const RightColumn = styled.div`
  height: 100vh;
  width: 76vw;
  display: flex;
  flex-direction: column;
  padding: 1.9vw;
  box-size: border-box;
`;

export const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 0.65vw;
`;

export const ChartContainer = styled.div`
  width: 73vw;

  overflow-y: hidden;
  flex: 0.9;
  background: white;
  border-radius: 0.3vw;
  box-shadow: 0vw 0.2vw 0.7vw 0.2vw rgba(113, 109, 242, 0.1);

  box-sizing: border-box;
`;
