import { useClerk } from "@clerk/clerk-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Spinner from "../components/Spinner/Spinner";
import ButtonTextIcon from "../components/buttons/ButtonTextIcon/ButtonTextIcon";
import { colors } from "../config/style";
import { useStateValue } from "../stores/services/StateProvider";
import { getToken } from "../userManagement/utilities";
import { getInspections, getVideoByStatus } from "../remote/structure";
import { InspectionTable } from "./MainInspections";
import ButtonToggle from "../components/buttons/ButtonToggle/ButtonToggle";

export const MainReview = ({ dataTestId }) => {
  const [state, dispatch] = useStateValue();
  const [records, setRecords] = useState();
  const [inspections, setInspections] = useState();
  const [filteredInspections, setFilteredInspections] = useState();

  const [groupedRecords, setGroupedRecords] = useState();
  const [selectedCompany, setSelectedCompany] = useState(0);
  const [selectedDates, setSelectedDates] = useState(0);

  const [loaded, setLoaded] = useState(false);

  const { session } = useClerk();

  const { t } = useTranslation();

  const ownerCompanies = ["admin", "Beko", "Ergodesign", "gruppoiren"];

  const datesSelector = [
    { label: "Tutte le date", days: -1 },
    { label: "Ultimi 7 giorni", days: 7 },
    { label: "Ultimi 30 giorni", days: 30 },
  ];

  const companiesSelector = ["Tutte le aziende"];

  useEffect(() => {
    loadRecords();
  }, []);

  useEffect(() => {
    if (inspections) filterInspections();
  }, [selectedCompany, selectedDates, inspections]);

  const loadRecords = async (init) => {
    //Load all locations

    let jwt = await getToken(session);

    let records = await getVideoByStatus("COMPLETED", jwt);

    let inspections = await getInspections(jwt);

    const filteredRecords = records.result.filter((record) =>
      ownerCompanies.includes(record.ownerCompany)
    );

    // Conta le occorrenze di ogni inspection
    const inspectionCounts = filteredRecords.reduce((acc, record) => {
      acc[record.inspection] = (acc[record.inspection] || 0) + 1;
      return acc;
    }, {});

    // Filtra le inspection che appaiono almeno tre volte
    const uniqueInspections = Object.keys(inspectionCounts).filter(
      (inspection) => inspectionCounts[inspection] >= 3
    );

    const filteredInspections = inspections.filter((inspection) =>
      uniqueInspections.includes(inspection.id)
    );

    setInspections(ordinaPerDataCreazione(filteredInspections));
  };

  const filterInspections = () => {
    let filteredInspections = inspections;

    // Company
    if (selectedCompany != "")
      filteredInspections = inspections.filter(
        (inspection) => inspection.ownerCompany === selectedCompany
      );

    // Dates
    if (selectedDates != 0) {
      const now = new Date();
      filteredInspections = filteredInspections.filter((record) => {
        const creationDate = new Date(record.creationDate);
        const differenceInTime = now - creationDate;
        const differenceInDays = differenceInTime / (1000 * 3600 * 24);
        return differenceInDays <= datesSelector[selectedDates].days;
      });
    }

    setFilteredInspections(filteredInspections);
  };

  function ordinaPerDataCreazione(array) {
    return array.sort((a, b) => {
      // Converti le date in oggetti Date per il confronto
      let dataA = new Date(a.creationDate);
      let dataB = new Date(b.creationDate);

      // Ordina in modo crescente
      return dataB - dataA;
    });
  }

  return (
    <div
      style={{
        width: "88vw",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "start",
      }}
    >
      <div
        style={{
          width: "73.5vw",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            height: "10%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            paddingRight: "0.8vw",
            boxSizing: "border-box",
          }}
        >
          <p
            style={{
              color: colors.text,
              fontFamily: "Atkinson Hyperlegible",
              fontSize: "1.6vw",
              fontWeight: "700",

              marginTop: "1.9vw",
              boxSizing: "border-box",
            }}
          >
            {"Revisione"}
          </p>

          <ButtonTextIcon
            icon={process.env.REACT_APP_RESOURCES_BUCKET + "RefreshWh.png"}
            label={t("refresh")}
            backgroundH={colors.mainH}
            background={colors.main}
            color={"white"}
            onClick={() => {
              setInspections();
              setFilteredInspections();
              loadRecords();
            }}
          />
        </div>
        {inspections ? (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "0.2vw",
                marginBottom: "1vw",
                width: "100%",
              }}
            >
              {[
                ...companiesSelector,
                ...new Set(inspections.map((record) => record.ownerCompany)),
              ].map((company) => {
                return (
                  <ButtonToggle
                    onClick={() => {
                      if (company == companiesSelector[0])
                        setSelectedCompany("");
                      else setSelectedCompany(company);
                    }}
                    label={company}
                    toggle={
                      company == companiesSelector[0]
                        ? selectedCompany == ""
                        : selectedCompany == company
                    }
                    disabled={false}
                  />
                );
              })}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "0.2vw",
                marginBottom: "1vw",
                width: "100%",
              }}
            >
              {datesSelector.map((dates, id) => {
                return (
                  <ButtonToggle
                    onClick={() => {
                      setSelectedDates(id);
                    }}
                    label={dates.label}
                    toggle={selectedDates == id}
                    disabled={false}
                  />
                );
              })}
            </div>
          </>
        ) : (
          <></>
        )}
        <div
          style={{
            overflowY: "scroll",
            width: "73.5vw",
            height: "88vh",
            // padding: "2vw",
          }}
        >
          {" "}
          {true && loaded ? (
            <div
              style={{
                width: "72.8vw",
                height: "100%",
                borderRadius: "0.3vw",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                background: "white",
              }}
            >
              <img
                src={
                  process.env.REACT_APP_RESOURCES_BUCKET + "InspectionPh.png"
                }
                style={{
                  width: "23.5vw",
                }}
              />{" "}
              <p
                style={{
                  color: colors.text,
                  fontFamily: "Atkinson Hyperlegible",
                  fontSize: "1.25vw",
                  fontWeight: "400",
                  marginLeft: "0vw",
                  marginTop: "0vw",
                  boxSizing: "border-box",
                  textAlign: "center",
                  width: "25vw",
                  marginTop: "1vw",
                }}
              >
                {t("mainInspectionNoIsp")}
              </p>
              <ButtonTextIcon
                icon={process.env.REACT_APP_RESOURCES_BUCKET + "RefreshWh.png"}
                label={t("refresh")}
                backgroundH={colors.tertiaryH}
                background={colors.tertiary}
                color={"white"}
                onClick={() => {}}
              />
            </div>
          ) : inspections ? (
            <div style={{}}>
              {filteredInspections?.map((inspection, index) => {
                return (
                  <InspectionTable
                    key={inspection.id}
                    inspection={inspection}
                    inspectionIndex={index}
                    onSave={() => {}}
                    owner={true}
                  />
                );
              })}
            </div>
          ) : (
            <Spinner />
          )}
          {/* <InspectionTable inspections={inspections} /> */}
        </div>
      </div>
    </div>
  );
};
