import { useSignIn } from "@clerk/clerk-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import LoginForm from "../../components/LoginForm/LoginForm";

export default function SignIn() {
  const { isLoaded, signIn, setActive } = useSignIn();

  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState("");

  const { t } = useTranslation();

  // start the sign In process.
  const handleSubmit = async (email, password, conditions) => {
    if (!isLoaded) {
      return;
    }
    if (!conditions) {
      setErrorMsg(t("conditions"));
      return;
    }
    try {
      const result = await signIn.create({
        identifier: email,
        password,
      });

      if (result.status === "complete") {
        await setActive({ session: result.createdSessionId });
      } else {
        /*Investigate why the login hasn't completed */
        setErrorMsg(t("form_wrong_credentials"));
      }
    } catch (err) {
      setErrorMsg(t(err.errors[0].code));
    }
  };

  return (
    <div>
      <LoginForm
        handleSignInSubmit={handleSubmit}
        onReset={() => navigate("/auth/reset")}
        errorMsg={errorMsg}
        setErrorMsg={(x) => setErrorMsg(x)}
      />
    </div>
  );
}
