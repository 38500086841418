import PropTypes from "prop-types";
import React from "react";
import { BounceLoader } from "react-spinners";
import { colors } from "../../config/style";

const Spinner = ({ size }) => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "100%",
    }}
  >
    <BounceLoader
      color={colors.main}
      loading={true}
      size={size ? size : "5vw"}
      aria-label="Loading Spinner"
      data-testid="loader"
    />
  </div>
);

Spinner.propTypes = {
  dataTestId: PropTypes.string, // Definisci il tipo di prop "dataTestId"
};

Spinner.defaultProps = {
  dataTestId: "default-data-testid", // Imposta un valore predefinito per "dataTestId"
};

export default Spinner;
