import { colors } from "../../../config/style";

export const mappingOcra = {
  dist: [
    { value: 0, colors: [colors.neutral, colors.disabled] },
    { value: 3, colors: [colors.positive, colors.positivePale] },
    { value: 4, colors: [colors.riskUnsure, colors.riskUnsurePale] },
    { value: 7, colors: [colors.riskNegative, colors.riskNegativePale] },
    {
      value: 100,
      colors: [colors.riskVeryNegative, colors.riskVeryNegativePale],
    },
  ],
  stereo: [
    { value: 0, colors: [colors.neutral, colors.disabled] },
    { value: 1, colors: [colors.positive, colors.positivePale] },
    { value: 1.5, colors: [colors.riskUnsure, colors.riskUnsurePale] },
    { value: 3, colors: [colors.riskNegative, colors.riskNegativePale] },
    {
      value: 100,
      colors: [colors.riskVeryNegative, colors.riskVeryNegativePale],
    },
  ],
  prec: [
    { value: 0, colors: [colors.neutral, colors.disabled] },
    { value: 7.6, colors: [colors.positive, colors.positivePale] },
    { value: 11, colors: [colors.riskUnsure, colors.riskUnsurePale] },
    { value: 22.6, colors: [colors.riskNegative, colors.riskNegativePale] },
    {
      value: 100,
      colors: [colors.riskVeryNegative, colors.riskVeryNegativePale],
    },
  ],
  index: [
    { value: 0, colors: [colors.neutral, colors.disabled] },
    { value: 2.2, colors: [colors.positive, colors.positivePale] },
    { value: 3.5, colors: [colors.riskUnsure, colors.riskUnsurePale] },
    { value: 9, colors: [colors.riskNegative, colors.riskNegativePale] },
    {
      value: 100,
      colors: [colors.riskVeryNegative, colors.riskVeryNegativePale],
    },
  ],
};

const mappingOld = {
  dist: [0, 3, 4, 7, 100],
  stereo: [0, 1, 1.5, 3, 100],
  prec: [0, 7.6, 11, 22.6, 100],
  index: [0, 2.2, 3.5, 9, 100],
};

export const getOcraColor = (value, map) => {
  let currentMapping;

  if (map) currentMapping = mappingOld[map];
  else currentMapping = mappingOld.dist;

  if (value <= currentMapping[0]) return [colors.neutral, colors.disabled];
  if (value <= currentMapping[1]) return [colors.positive, colors.positivePale];
  if (value <= currentMapping[2])
    return [colors.riskUnsure, colors.riskUnsurePale];
  if (value <= currentMapping[3])
    return [colors.riskNegative, colors.riskNegativePale];
  if (value <= currentMapping[4])
    return [colors.riskVeryNegative, colors.riskVeryNegativePale];
  else return [colors.main, colors.mainPale];
};
