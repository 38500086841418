import React from "react";

const FlagIcon = ({ fill, size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill={fill}
      style={{ minWidth: size, minHeight: size }}
    >
      <path d="M8.63462 11.095L9.0405 10.5787C11.9944 6.82163 17.3397 5.95233 21.3352 8.57926C25.3666 11.2299 30.7646 10.3183 33.6985 6.49128L34.2789 5.73429C34.7944 5.06182 35.8587 5.27252 36.0782 6.09052L39.9574 20.5445C40.0579 20.919 39.9785 21.3189 39.7424 21.6268L37.9599 23.9519C35.1608 27.6029 30.0833 28.6145 26.095 26.3155C22.3916 24.1808 17.7429 24.8986 14.8545 27.9472L18.6856 42.2222C18.8878 42.9755 18.44 43.7499 17.6855 43.9517C16.931 44.1536 16.1554 43.7065 15.9532 42.9531L12.1448 28.7628L12.1441 28.76L8.10139 13.6967C7.85908 12.794 8.05664 11.8301 8.63462 11.095Z" />
    </svg>
  );
};

export default FlagIcon;
