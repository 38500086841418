import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { colors } from "../../config/style";
import { CheckBox } from "../InfoTab/InfoTab";
import TextField from "../TextField/TextField";
import ButtonTextIcon from "../buttons/ButtonTextIcon/ButtonTextIcon";

const LoginForm = ({ handleSignInSubmit, onReset, errorMsg, setErrorMsg }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [conditions, setConditions] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    setErrorMsg("");
  }, [email, password, conditions]);

  const handleSubmit = () => {
    handleSignInSubmit(email, password, conditions);
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "start",
        flexDirection: "column",
        justifyContent: "space-between",
        background: "white",
        width: "26vw",
        height: "33.1vw",
        borderRadius: "0.62vw",
        boxShadow: "0px 0.2vw 2.4vw 0.6vw rgba(113, 109, 242, 0.10)",
        padding: "1.87vw",
        boxSizing: "border-box",
        position: "relative",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <img
          src={
            process.env.REACT_APP_RESOURCES_BUCKET+"logo.png"
          }
          style={{ width: "10vw", marginBottom: "1.8vw" }}
        />

        <p
          style={{
            color: colors.text,
            fontFamily: "Atkinson Hyperlegible",
            fontSize: "1.64vw",
            margin: "0",
            fontWeight: "800",
          }}
        >
          {t("loginWelcome")}
        </p>
        <p
          style={{
            color: colors.text,
            fontFamily: "Atkinson Hyperlegible",
            fontSize: "0.9vw",
            fontWeight: "400",
            margin: "0",
            width: "100%",
            textAlign: "start",
            marginBottom: "3vw",
            marginTop: "0.5vw",
          }}
        >
          {t("loginSubtext")}
        </p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "end",
          alignItems: "start",
          gap: "0.9vw",
          width: "100%",
        }}
      >
        <p
          style={{
            color: colors.negative,
            fontFamily: "Atkinson Hyperlegible",
            fontSize: "0.8vw",
            fontWeight: "800",
            margin: "0",
            textAlign: "end",
            marginTop: "0vw",
            width: "100%",
            boxSizing: "border-box",
            visibility: errorMsg ? "" : "hidden",
          }}
        >
          {errorMsg}
        </p>
        <TextField
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          icon={
            process.env.REACT_APP_RESOURCES_BUCKET+"Letter.png"
          }
          title={t("loginEmail")}
          value={email}
          type={"email"}
          placeholder={t("loginInsertEmail")}
          error={errorMsg}
        />
        <TextField
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          icon={
            process.env.REACT_APP_RESOURCES_BUCKET+"Key2.png"
          }
          title={t("loginPsw")}
          type={"password"}
          placeholder={t("loginInsertPsw")}
          value={password}
          error={errorMsg}
        />
        <ButtonTextIcon
          backgroundH={colors.mainH}
          background={colors.main}
          label={t("loginContinue")}
          onClick={handleSubmit}
          disabled={!email || !password}
          style={{
            width: "100%",
            justifyContent: "center",
            height: "2.5vw",
            marginBottom: "0.5vw",
          }}
        />

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            gap: "0.5vw",
          }}
        >
          {" "}
          <p
            onClick={onReset}
            style={{
              color: colors.main,
              fontFamily: "Atkinson Hyperlegible",
              fontSize: "0.83vw",
              fontWeight: "700",
              margin: "0",
              width: "100%",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            {t("loginPswLost")}
          </p>
          <p
            style={{
              color: colors.text,
              fontFamily: "Atkinson Hyperlegible",
              fontSize: "0.83vw",
              fontWeight: "400",
              margin: "0",
              width: "100%",
            }}
          >
            {t("loginRegister")}
            <a href="mailto:info@gymnasio.it">
              <span
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                  fontWeight: "700",
                  color: colors.main,
                }}
              >
                {t("loginRequestHere")}
              </span>
            </a>
          </p>
        </div>
        <div
          style={{
            position: "absolute",
            top: "34vw",
            display: "flex",
            width: "100%",
            marginLeft: "-1.2vw",
            alignItems: "center",
            justifyContent: "start",
          }}
        >
          <CheckBox
            label={"\u00A0"}
            value={conditions}
            onClick={() => {
              setConditions(!conditions);
            }}
            err={errorMsg}
          />
          <p
            style={{
              color: colors.text,
              fontFamily: "Atkinson Hyperlegible",
              fontSize: "0.7vw",
              fontWeight: "400",
              margin: "0",
              marginLeft: "-4vw",
              width: "100%",
            }}
          >
            {t("loginConditionsP1")}
            <a
              href={
                "https://gymnasio.notion.site/POSTURA-Ergonomics-_-Condizioni-d-uso-2e60fc3808094ae4a4e94dcc82207cd9"
              }
              target="_blank"
            >
              <span
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                  fontWeight: "700",
                  color: colors.main,
                }}
              >
                {t("loginConditionsP2")}
              </span>{" "}
            </a>
            {t("loginConditionsP3")}
          </p>
        </div>
      </div>
    </div>
  );
};

LoginForm.propTypes = {
  email: PropTypes.string, // Definisci il tipo di prop "dataTestId"
  password: PropTypes.string,
};

LoginForm.defaultProps = {
  email: "", // Imposta un valore predefinito per "dataTestId"
  password: "",
};

export default LoginForm;
