import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { colors } from "../../config/style";
import TextField from "../TextField/TextField";
import ButtonTextIcon from "../buttons/ButtonTextIcon/ButtonTextIcon";

const EmailForm = ({ handleSubmit, errorMsg, setEmail, email }) => {
  // const [email, setEmail] = useState("");
  const { t } = useTranslation();

  const handleSubmitEmail = () => {
    handleSubmit(email);
  };
  const navigate = useNavigate();

  return (
    <div
      style={{
        display: "flex",
        alignItems: "start",
        flexDirection: "column",
        justifyContent: "space-between",
        background: "white",
        width: "26vw",
        height: "33.1vw",
        borderRadius: "0.62vw",
        boxShadow: "0px 0.2vw 2.4vw 0.6vw rgba(113, 109, 242, 0.10)",
        padding: "1.87vw",
        boxSizing: "border-box",
        position: "relative",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <img
          src={
            process.env.REACT_APP_RESOURCES_BUCKET+"logo.png"
          }
          style={{ width: "10vw", marginBottom: "1.8vw" }}
        />

        <p
          style={{
            color: colors.text,
            fontFamily: "Atkinson Hyperlegible",
            fontSize: "1.64vw",
            margin: "0",
            fontWeight: "800",
          }}
        >
          {t("resetTitle")}
        </p>
        <p
          style={{
            color: colors.text,
            fontFamily: "Atkinson Hyperlegible",
            fontSize: "0.9vw",
            fontWeight: "400",
            margin: "0",
            width: "100%",
            textAlign: "start",
            marginBottom: "3vw",
            marginTop: "0.5vw",
            width: "80%",
          }}
        >
          {t("resetSubtitle")}
        </p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "end",
          alignItems: "start",
          gap: "0.9vw",
          width: "100%",
        }}
      >
        <p
          style={{
            color: colors.negative,
            fontFamily: "Atkinson Hyperlegible",
            fontSize: "0.8vw",
            fontWeight: "800",
            margin: "0",
            textAlign: "end",
            marginTop: "0vw",
            width: "100%",
            boxSizing: "border-box",
            visibility: errorMsg ? "" : "hidden",
          }}
        >
          {t("resetWrongMail")}
        </p>
        <TextField
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          icon={
            process.env.REACT_APP_RESOURCES_BUCKET+"Letter.png"
          }
          title={t("loginEmail")}
          value={email}
          type={"email"}
          placeholder={t("loginInsertEmail")}
          error={errorMsg}
        />

        <ButtonTextIcon
          backgroundH={colors.mainH}
          background={colors.main}
          disabled={!email}
          label={t("resetSendCode")}
          onClick={handleSubmitEmail}
          style={{
            width: "100%",
            justifyContent: "center",
            height: "2.5vw",
            marginBottom: "0.5vw",
          }}
        />

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            gap: "0.5vw",
          }}
        >
          {" "}
          <p
            onClick={() => navigate("/auth/sign")}
            style={{
              color: colors.main,
              fontFamily: "Atkinson Hyperlegible",
              fontSize: "0.83vw",
              fontWeight: "700",
              margin: "0",
              width: "100%",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            {t("resetGo2Login")}
          </p>
          <p
            style={{
              color: colors.text,
              fontFamily: "Atkinson Hyperlegible",
              fontSize: "0.83vw",
              fontWeight: "400",
              margin: "0",
              width: "100%",
            }}
          >
            {t("loginRegister")}
            <a href="mailto:info@gymnasio.it">
              <span
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                  fontWeight: "700",
                  color: colors.main,
                }}
              >
                {t("loginRequestHere")}
              </span>
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

EmailForm.propTypes = {};

EmailForm.defaultProps = {};

export default EmailForm;
