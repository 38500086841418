async function getToken(session) {
  if (session.user) {
    let token = await session.getToken();

    return token;
  }
  return null;
}

export { getToken };
