import { Navigate, Route, Routes } from "react-router-dom";
import SignIn from "./signIn/signIn";
import ResetPwd from "./resetPwd/resetPwd";
import { LogoWrapper } from "../components/LateralNavigation/LateralNavigation.styled";

export default function Auth() {
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "10vw",
        backgroundImage: `url(${process.env.REACT_APP_RESOURCES_BUCKET}BackLogin.png)`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Routes>
        <Route path="/" element={<SignIn />} />
        <Route path="signin" element={<SignIn />} />
        <Route path="reset" element={<ResetPwd />} />
        <Route path="*" element={<Navigate to="/" replace={true} />} />
      </Routes>
    </div>
  );
}
