import React from "react";
import PropTypes from "prop-types";
import { colors } from "../../config/style";

const TextFieldIcon = ({
  dataTestId,
  icon,
  title,
  value,
  error,
  onChange,
  type,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        border: error ? "0.15vw solid " + colors.error : "0.15vw solid white",
        borderRadius: "0.5vw",
        background: "white",
        margin: "0.25vw",
        boxSizing: "border-box",
        width: "100%",
      }}
    >
      <img style={{ width: "8%", padding: "0.7vw" }} src={icon} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          justifyContent: "center",
          width: "84%",
        }}
      >
        <p
          style={{
            color: colors.neutral,
            fontFamily: "Atkinson Hyperlegible",
            fontSize: "1vw",
            fontWeight: "800",
            margin: "0",
          }}
        >
          {title}
        </p>
        <input
          style={{
            border: "0px",
            color: colors.text,
            fontFamily: "Atkinson Hyperlegible",
            fontSize: "1vw",
            fontWeight: "400",
            margin: "0px",
            padding: "0px",
            width: "100%",
          }}
          onFocus={(e) => (e.target.style.outline = "none")}
          placeholder=""
          type={type}
          value={value}
          onChange={(e) => onChange(e)}
        />
      </div>
      <img
        style={{
          width: "8%",
          padding: "0.7vw",
          visibility: error ? "" : "hidden",
        }}
        src={
          process.env.REACT_APP_RESOURCES_BUCKET+"attentionField.png"
        }
      />
    </div>
  );
};

TextFieldIcon.propTypes = {
  dataTestId: PropTypes.string, // Definisci il tipo di prop "dataTestId"
};

TextFieldIcon.defaultProps = {
  dataTestId: "default-data-testid", // Imposta un valore predefinito per "dataTestId"
};

export default TextFieldIcon;
