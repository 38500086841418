import { useClerk, useUser } from "@clerk/clerk-react";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { getAvailableAnalysis } from "../../config/availableAnalysis";
import { protocols } from "../../config/availableProtocols";
import {
  compressObjectLZ,
  compressObjectLZURI,
} from "../../remote/compressionFunctions";
import { setReviewed, updateTaskResult } from "../../remote/tasks";
import { handleLoadDepartment } from "../../remote/utils";
import { useStateValue } from "../../stores/services/StateProvider";
import { getToken } from "../../userManagement/utilities";
import { single_task_global } from "../../utils/Protocols/OCRA/singleTask";
import { PopupCreate } from "../Popup/Popup";
import { PopupAlert } from "../Popup/PopupContent";
import Spinner from "../Spinner/Spinner";
import { AnalysisContainerWrapper } from "./AnalysisContainer.styled";
import { checklists_menu } from "./AnalysisContainerComponents";
import { manageAiResult } from "./AnalysisContainerFunctions";
import { AnalysisContainerLeftColumn } from "./AnalysisContainerLeftColumn";
import { AnalysisContainerRightColumn } from "./AnalysisContainerRightColumn";
import { updateWeightCatVli, vliGlobal } from "../../utils/Protocols/NIOSH/vli";
import { compareArrayOfObjects } from "../../utils/generalFunctions";
import { updateTaskNiosh } from "../../remote/structure";

const AnalysisContainer = ({ dataTestId, onClose, selectedTask }) => {
  const [state, dispatch] = useStateValue();
  const [analysis, setAnalysis] = useState(null);
  const [checklist, setChecklist] = useState(null);
  const [screen, setScreen] = useState(0);
  const [selectedProtocol, setSelectedProtocol] = useState(0);
  const [availableProtocols, setAvailableProtocols] = useState();
  const [refresh, setRefresh] = useState(false);
  const [selectedGraph, setSelectedGraph] = useState(0);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [graphMemory, setGraphMemory] = useState(selectedTask?.ai?.edit);
  const [saving, setSaving] = useState(false);
  const [reviewedState, setReviewedState] = useState(
    selectedTask?.taskDetail?.last_result_obj?.ai?.LEFT?.status == "REVIEWED"
  );
  const [reviewing, setReviewing] = useState(false);
  const [availableAnalysis, setAvailableAnalysis] = useState();
  // OCRA
  const [ocraMemory, setOcraMemory] = useState();
  const [ocraRes, setOcraRes] = useState();
  // NIOSH
  const [vliMemory, setVliMemory] = useState();
  const [vliRes, setVliRes] = useState(vliGlobal(vliMemory));

  const [savingEnabled, setSavingEnabled] = useState(false);
  const [allowRecover, setAllowRecover] = useState(false);
  const [departmentDetail, setDepartmentDetail] = useState();

  const checklistRef = useRef();
  const graphMemoryRef = useRef();
  const lineChartRef = useRef();
  const videoLoadedRef = useRef();
  const popupRef = useRef();

  const { user } = useUser();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  checklistRef.current = checklist;
  graphMemoryRef.current = graphMemory;
  videoLoadedRef.current = videoLoaded;

  console.log("selectedTaskkkk", selectedTask);

  const { session } = useClerk();

  const videoId = "videoId";
  // const availableAnalysis = getAvailableAnalysis(state.current_data, t);
  console.log("selected", selectedTask);
  useEffect(() => {
    navigate({ search: "analysis" }, { replace: true });

    // PROTOCOLS
    // let localAvailableProtocols = protocols.filter((protocol) => {
    //   if (protocol.ref == protocols[0].ref) return selectedTask?.ocraValid;
    //   else return selectedTask.taskDetail.hasOwnProperty(protocol.ref);
    // });
    let localAvailableProtocols = [];

    protocols.forEach((protocol, id) => {
      // OCRA
      if (id == 0 && selectedTask?.ocraValid) {
        localAvailableProtocols.push(protocol); //Aggiungi OCRA a elenco protocolli
        let availableAnalysisLocal = getAvailableAnalysis(
          state.current_data,
          t
        );
        setAvailableAnalysis(availableAnalysisLocal);
        setAnalysis(
          availableAnalysisLocal[0].analysis[
            Object.keys(availableAnalysisLocal[0].analysis)[0]
          ]
        );
        setOcraMemory({
          //Inizializza memoria
          ...ocraMemory,
          ...selectedTask.checklist,
          uso_martelli_dx:
            selectedTask.checklist.uso_martelli_dx <= 0 ? false : true,
          uso_mani_colpi_dx:
            selectedTask.checklist.uso_mani_colpi_dx <= 0 ? false : true,
          strumenti_vibranti_dx:
            selectedTask.checklist.strumenti_vibranti_dx <= 0 ? false : true,
          altro_dx: selectedTask.checklist.altro_dx <= 0 ? false : true,
          uso_martelli_sx:
            selectedTask.checklist.uso_martelli_sx <= 0 ? false : true,
          uso_mani_colpi_sx:
            selectedTask.checklist.uso_mani_colpi_sx <= 0 ? false : true,
          strumenti_vibranti_sx:
            selectedTask.checklist.strumenti_vibranti_sx <= 0 ? false : true,
          altro_sx: selectedTask.checklist.altro_sx <= 0 ? false : true,
          targa_ciclo: selectedTask.checklist.targa_ciclo
            ? selectedTask.checklist.targa_ciclo
            : Math.round((400 * 60) / selectedTask.checklist.numero_cicli),
          ...manageAiResult(graphMemory, availableAnalysisLocal),
        });
      }
      // CASO NIOSH
      else if (
        id == 1 &&
        selectedTask.taskDetail.hasOwnProperty(protocol.ref)
      ) {
        localAvailableProtocols.push(protocol);
        console.log("selected", selectedTask);
        setVliMemory(selectedTask.taskDetail.niosh);
      }
    });

    console.log("localAvailableProtocols", localAvailableProtocols);
    setAvailableProtocols(localAvailableProtocols);

    setChecklist(
      checklists_menu({
        ocraRes: ocraRes,
        ocraMemory: ocraMemory,
        vliRes: vliRes,
        vliMemory: vliMemory,
        t: t,
        selectedProtocol: localAvailableProtocols[selectedProtocol],
      })[0] //EDITTTT
    );
    setSavingEnabled(false);

    return () => {
      navigate({ search: "" }, { replace: true });
    };
  }, []);

  console.log("state", state);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = "";
    };

    // Aggiungi l'evento
    if (savingEnabled && !window.location.hostname.includes("localhost"))
      window.addEventListener("beforeunload", handleBeforeUnload);
    else window.removeEventListener("beforeunload", handleBeforeUnload);
    // Rimuovi l'evento quando il componente viene smontato o quando `enabled` cambia
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [savingEnabled]);

  useEffect(() => {
    if (ocraMemory) {
      setOcraRes(single_task_global(ocraMemory));
      setAllowRecover(true);
    }
  }, [ocraMemory]);

  useEffect(() => {
    if (vliMemory) {
      setSavingEnabled(true);
      setVliRes(vliGlobal(vliMemory));
    }
  }, [vliMemory]);

  useEffect(() => {
    if (vliMemory) {
      setSavingEnabled(true);
      setVliRes(vliGlobal(vliMemory));
      if (
        !compareArrayOfObjects(vliMemory.elenco_pesi, vliMemory.elenco_pesi_old)
      ) {
        console.log("Aggiorno categorie elenco pesi");
        let elenco_pesi_ordinato = vliMemory.elenco_pesi.sort(
          (a, b) => parseFloat(a.peso) - parseFloat(b.peso)
        );
        setVliMemory({
          ...vliMemory,
          elenco_pesi: elenco_pesi_ordinato,
          elenco_pesi_old: JSON.parse(JSON.stringify(vliMemory.elenco_pesi)),
          categorie_peso: updateWeightCatVli(elenco_pesi_ordinato),
        });
      }
    }
  }, [checklist]);

  useEffect(() => {
    if (analysis && ocraMemory) {
      setSavingEnabled(true);
      setAllowRecover(true);
    }
  }, [graphMemory, ocraMemory]);

  useEffect(() => {
    if (ocraMemory) {
      setOcraMemory({
        ...ocraMemory,
        ...manageAiResult(graphMemory, availableAnalysis),
      });
    }
  }, [graphMemory]);

  useEffect(() => {
    if (availableProtocols) {
      setChecklist(
        checklists_menu({
          ocraRes: ocraRes,
          ocraMemory: ocraMemory,
          vliRes: vliRes,
          vliMemory: vliMemory,
          t: t,
          selectedProtocol: availableProtocols[selectedProtocol],
        })[0] //EDITTTT
      );
      setScreen(0);
    }
  }, [selectedProtocol]);

  const save = (onSuccess) => {
    setSaving(true);
    updateResults(() => {
      onSuccess && onSuccess();
      setSavingEnabled(false);
      setSaving(false);
    });
  };

  // Funzione per comprimere un array utilizzando RLE
  function rleEncode(array) {
    if (array.length === 0) return [];

    const compressed = [];
    let count = 1;
    let current = array[0];

    for (let i = 1; i < array.length; i++) {
      if (array[i] === current) {
        count++;
      } else {
        compressed.push([current, count]);
        current = array[i];
        count = 1;
      }
    }
    compressed.push([current, count]);

    return compressed;
  }

  // Funzione per comprimere gli array in un oggetto
  function compressObject(obj) {
    const compressedObj = {};

    for (const key in obj) {
      if (Array.isArray(obj[key])) {
        compressedObj[key] = rleEncode(obj[key]);
      } else if (typeof obj[key] === "object" && obj[key] !== null) {
        compressedObj[key] = compressObject(obj[key]);
      } else {
        compressedObj[key] = obj[key];
      }
    }

    return compressedObj;
  }

  const updateResults = async (onSuccess) => {
    // OCRA
    if (availableProtocols.some((prot, id) => prot.ref == protocols[0].ref)) {
      console.log("Saving OCRA");
      // Save results

      const compressedDataLZ = compressObjectLZURI(graphMemory);

      let updatedResults = {
        id: selectedTask.id,
        checklist: rimuoviChiaviESostituisci(ocraMemory),
        ai: { ...selectedTask.ai, edit: compressedDataLZ },
        inspection: selectedTask.inspection,
      };

      // Update task
      let scores = {
        sx: ocraRes.output.punteggio_parziale_sx,
        dx: ocraRes.output.punteggio_parziale_dx,
      };
      let ocra = {
        sx: {
          otherTemp: {
            numeroCicli: ocraRes.output.numero_cicli,
            totale_secondi_tempo_passivo:
              ocraRes.output.totale_secondi_tempo_passivo,
            moltiplicatore_recupero: ocraRes.output.moltiplicatore_recupero,
            tempoCiclo: ocraRes.output.tempo_ciclo_posture_forza,
            stationName: selectedTask.taskDetail?.ocra?.sx?.otherTemp
              ?.stationName
              ? selectedTask.taskDetail?.ocra?.sx?.otherTemp?.stationName
              : selectedTask.taskDetail.stationName,
            ocraRes: ocraRes,
          },
          frequenza: ocraRes.output.rischio_AT_sx,
          forza: ocraRes.output.risk_score_borg_sx,
          postura: {
            spalla: ocraRes.output.rischi_totali.spalla_sx,
            gomito: ocraRes.output.rischi_totali.gomito_sx,
            polso: ocraRes.output.rischi_totali.polso_sx,
            mano: ocraRes.output.rischi_totali.mano_sx,
          },
          stereotipia: ocraRes.output.stereotipia_sx,
          totale_postura: ocraRes.output.rischio_posture_sx,
          complementari: ocraRes.output.complementare_sx,
          parziale: ocraRes.output.punteggio_parziale_sx,
          intrinseco: ocraRes.output.punteggio_parziale_intrinseco_sx,
          finale: ocraRes.output.punteggio_finale_sx,
        },
        dx: {
          frequenza: ocraRes.output.rischio_AT_dx,
          forza: ocraRes.output.risk_score_borg_dx,
          postura: {
            spalla: ocraRes.output.rischi_totali.spalla_dx,
            gomito: ocraRes.output.rischi_totali.gomito_dx,
            polso: ocraRes.output.rischi_totali.polso_dx,
            mano: ocraRes.output.rischi_totali.mano_dx,
          },
          stereotipia: ocraRes.output.stereotipia_dx,
          totale_postura: ocraRes.output.rischio_posture_dx,
          complementari: ocraRes.output.complementare_dx,
          parziale: ocraRes.output.punteggio_parziale_dx,
          intrinseco: ocraRes.output.punteggio_parziale_intrinseco_dx,
          finale: ocraRes.output.punteggio_finale_dx,
        },
      };
      let body = { result: updatedResults, scores: scores, ocra: ocra };

      // CALL API PER UPDATE TASK
      let res = await updateTaskResult(
        selectedTask.task,
        body,
        await getToken(session)
      );
    }
    // NIOSH
    if (availableProtocols.some((prot, id) => prot.ref == protocols[1].ref)) {
      console.log("Saving NIOSH");
      let updateNiosh = await updateTaskNiosh(
        selectedTask.taskDetail.id,
        { ...vliMemory, m: vliRes.m, f: vliRes.f },
        await getToken(session)
      );
      console.log("updateNiosh", updateNiosh);
    }

    onSuccess && onSuccess();
  };

  function isDeepEqual(obj1, obj2) {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    // Verifica se il numero di proprietà è lo stesso
    if (keys1.length !== keys2.length) {
      return false;
    }

    // Controlla se i valori di ciascuna proprietà sono uguali
    for (const key of keys1) {
      const val1 = obj1[key];
      const val2 = obj2[key];
      const areObjects = isObject(val1) && isObject(val2);

      // Se sono oggetti, effettua un controllo ricorsivo
      if (
        (areObjects && !isDeepEqual(val1, val2)) ||
        (!areObjects && val1 !== val2)
      ) {
        return false;
      }
    }

    return true;
  }

  function isObject(object) {
    return object != null && typeof object === "object";
  }

  function rimuoviChiaviESostituisci(oggettoOriginale) {
    const chiaviDaRimuovere = [
      "numero_azioni_dinamiche_dx",
      "numero_azioni_dinamiche_sx",
      "secondi_mano_incongrua_dx",
      "secondi_mano_incongrua_sx",
      "secondi_spalla_incongrua_dx",
      "secondi_spalla_incongrua_sx",
      "secondi_polso_incongrua_dx",
      "secondi_polso_incongrua_sx",
      "secondi_gomito_incongrua_dx",
      "secondi_gomito_incongrua_sx",
    ];

    // Crea una copia dell'oggetto originale
    let copiaOggetto = { ...oggettoOriginale };

    // Rimuovi le chiavi specificate dalla copia
    chiaviDaRimuovere.forEach((chiave) => {
      delete copiaOggetto[chiave];
    });

    return copiaOggetto;
  }

  const checkRecovery = (graphMemory) => {
    if (screen == 1) {
      if (graphMemory[analysis.id_local]) return false;
      else return true;
    } else {
      if (
        isDeepEqual(rimuoviChiaviESostituisci(ocraMemory), {
          ...selectedTask.checklist,
          uso_martelli_dx: 0,
          uso_mani_colpi_dx: 0,
          strumenti_vibranti_dx: 0,
          altro_dx: 0,
          uso_martelli_sx: 0,
          uso_mani_colpi_sx: 0,
          strumenti_vibranti_sx: 0,
          altro_sx: 0,
        })
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  function formattaData(dataISO) {
    const mesi = [
      t("gen"),
      t("feb"),
      t("mar"),
      t("apr"),
      t("may"),
      t("jun"),
      t("jul"),
      t("aug"),
      t("sep"),
      t("oct"),
      t("nov"),
      t("dec"),
    ];
    const data = new Date(dataISO);

    // Estrai i componenti della data
    const giorno = data.getDate();
    const mese = mesi[data.getMonth()];
    const anno = data.getFullYear().toString().slice(-2); // Estrai gli ultimi due numeri dell'anno

    // Formatta la data nel formato desiderato
    return `${giorno} ${mese} ‘${anno}`;
  }

  const setReviewedBtn = async () => {
    setReviewing(true);
    let res = await setReviewed(
      selectedTask.inspection,
      selectedTask.task,
      selectedTask.ai.LEFT,
      await getToken(session)
    );
    res = await setReviewed(
      selectedTask.inspection,
      selectedTask.task,
      selectedTask.ai.RIGHT,
      await getToken(session)
    );
    res = await setReviewed(
      selectedTask.inspection,
      selectedTask.task,
      selectedTask.ai.HANDS,
      await getToken(session)
    );
    setReviewing(false);
    setReviewedState(true);
    console.log("heeee", selectedTask);
  };

  return (
    <AnalysisContainerWrapper data-testid={dataTestId}>
      {!availableProtocols ? (
        <Spinner />
      ) : (
        <>
          <AnalysisContainerLeftColumn
            saving={saving}
            savingEnabled={savingEnabled}
            popupRef={popupRef}
            onClose={onClose}
            selectedTask={selectedTask}
            availableProtocols={availableProtocols}
            selectedProtocol={selectedProtocol}
            setSelectedProtocol={setSelectedProtocol}
            t={t}
            screen={screen}
            setScreen={setScreen}
            formattaData={formattaData}
            ocraRes={ocraRes}
            ocraMemory={ocraMemory}
            checklistRef={checklistRef}
            setChecklist={setChecklist}
            checklists_menu={checklists_menu}
            analysis={analysis}
            setAnalysis={setAnalysis}
            setSelectedGraph={setSelectedGraph}
            availableAnalysis={availableAnalysis}
            vliMemory={vliMemory}
            setVliMemory={setVliMemory}
            vliRes={vliRes}
          />
          <AnalysisContainerRightColumn
            selectedTask={selectedTask}
            screen={screen}
            analysis={analysis}
            checklist={checklist}
            user={user}
            reviewedState={reviewedState}
            reviewing={reviewing}
            setReviewedBtn={setReviewedBtn}
            state={state}
            ocraRes={ocraRes}
            savingEnabled={savingEnabled}
            save={save}
            saving={saving}
            t={t}
            checkRecovery={checkRecovery}
            graphMemory={graphMemory}
            lineChartRef={lineChartRef}
            setOcraMemory={setOcraMemory}
            setAllowRecover={setAllowRecover}
            videoLoaded={videoLoaded}
            setVideoLoaded={setVideoLoaded}
            videoId={videoId}
            setSelectedGraph={setSelectedGraph}
            refresh={refresh}
            setRefresh={setRefresh}
            ocraMemory={ocraMemory}
            availableProtocols={availableProtocols}
            selectedProtocol={selectedProtocol}
            selectedGraph={selectedGraph}
            videoLoadedRef={videoLoadedRef}
            setGraphMemory={setGraphMemory}
            vliMemory={vliMemory}
            setVliMemory={setVliMemory}
            vliRes={vliRes}
          />
        </>
      )}
      <PopupCreate
        ref={popupRef}
        title={t("exitPopup")}
        children={
          <PopupAlert
            message={t("exitPopupText")}
            negativeLabel={t("exitPopupNegative")}
            positiveLabel={t("exitPopupPositive")}
            positive={() => save(onClose)}
            negative={onClose}
          />
        }
      />
    </AnalysisContainerWrapper>
  );
};

AnalysisContainer.propTypes = {
  dataTestId: PropTypes.string, // Definisci il tipo di prop "dataTestId"
  data: PropTypes.object,
  file: PropTypes.string,
};

AnalysisContainer.defaultProps = {
  dataTestId: "default-data-testid", // Imposta un valore predefinito per "dataTestId"
  data: { PROVA_ANGLE: { labels: [], values: [] } },
  file: null,
};

export default AnalysisContainer;
