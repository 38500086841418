export const rightColors = [
  "#716DF2",
  "rgba(75, 172, 162, 1)",
  "rgba(75, 152, 152, 1)",
];
export const wrongColors = ["rgba(226, 0, 116, 1)", "rgba(250, 137, 175, 1)"];
export const editColors = ["rgba(232, 136, 70, 1)", "rgba(232, 170, 49, 1)"];
export const disabledColors = ["rgba(71,	71,	81, 1)"];

export const lineColor = "rgba(0, 0, 0, 1)";

export const barColor = "#716DF2";
