import styled from "styled-components";

export const VideoContainerWrapper = styled.div`
  background: black;
  border-radius: 0.3vw;
  box-shadow: 0vw 0.2vw 0.7vw 0.2vw rgba(113, 109, 242, 0.1);
  box-sizing: border-box;
  margin-bottom: 0.65vw;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 36vw;
  padding: 0.5vw;
  position: relative;
`;

export const Video = styled.video`
  width: 100%;
  max-height: 18.5vw;
  min-height: 18.5vw;
  border-radius: 0.35vw;
  border: none; /* Rimuovi il bordo */
  background: black;
  box-sizing: border-box;
`;

export const Canvas = styled.canvas`
  position: relative;
  object-fit: cover;
  border: none; /* Rimuovi il bordo */
  background-color: #fff999;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4); /* Aggiungi ombra in tutte le direzioni */
  display: ${(props) => props.display};
`;

export const CanvasDiv = styled.div`
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  zindex: 1;
  object-fit: contain;
  background-repeat: no-repeat;
`;
