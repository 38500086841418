import Typography from "@mui/material/Typography";
import { styled, useTheme } from "@mui/material/styles";
import * as React from "react";

import { TreeItem, treeItemClasses } from "@mui/x-tree-view/TreeItem";
import { colors } from "../../config/style";

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
  // marginRight: "0.5vw",
  [`& .${treeItemClasses.content}`]: {
    paddingLeft: "0vw",
    borderRadius: "0.3vw",
    height: "1.875vw",

    marginTop: "0.2vw",
    "&:hover": {
      backgroundColor: colors.mainOpaque + "!important", // Imposta il colore di sfondo per hover e active su rosso
    },
    " &.Mui-selected, &.Mui-selected.Mui-focused": {
      backgroundColor: colors.mainOpaque, // Imposta il colore di sfondo per hover e active su rosso
    },
    " &.Mui-focused": {
      backgroundColor: "transparent", // Imposta il colore di sfondo per hover e active su rosso
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 0,
    boxSizing: "border-box",
    [`& .${treeItemClasses.content}`]: {
      paddingLeft: "1.875vw",
    },
  },
  [`& .${treeItemClasses.group} .${treeItemClasses.group}`]: {
    [`& .${treeItemClasses.content}`]: {
      paddingLeft: `calc(1.875vw + 1.875vw)`,
    },
  },
  [`& .${treeItemClasses.group} .${treeItemClasses.group} .${treeItemClasses.group}`]:
    {
      [`& .${treeItemClasses.content}`]: {
        paddingLeft: `calc(1.875vw + 2.8vw)`,
      },
    },
}));

export const StyledTreeItem = React.forwardRef(function StyledTreeItem(
  props,
  ref
) {
  const theme = useTheme();
  const {
    bgColor,
    color,
    font,
    labelIcon: LabelIcon,
    labelInfo,
    labelText,
    colorForDarkMode,
    bgColorForDarkMode,
    extraIcon, // Passa l'URL dell'immagine extra
    ...other
  } = props;

  const styleProps = {
    "--tree-view-color": color,
    "--tree-view-bg-color": bgColor,
  };

  const handleClick = () => {
    if (props.onClick && typeof props.onClick === "function") {
      props.onClick();
    }
  };

  return (
    <StyledTreeItemRoot
      endIcon={extraIcon}
      label={
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            position: "relative", // importante per il posizionamento dell'immagine extra
          }}
        >
          {LabelIcon && (
            <img
              src={LabelIcon}
              style={{
                width: "1.875vw",
                height: "1.875vw",
                marginLeft: "0", // sposta l'icona a destra se c'è l'immagine extra
              }}
            />
          )}
          <Typography variant="body2" sx={{ ...font }}>
            {labelText}
          </Typography>
        </div>
      }
      style={styleProps}
      onClick={handleClick}
      {...other}
      ref={ref}
    />
  );
});
