import React from "react";

const TimeTable2Icon = ({ fill, size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill={fill}
      style={{ minWidth: size, minHeight: size }}
    >
      <path d="M30 18C32.85 18 35.4 19.05 37.5 20.7V13.5C37.5 12.75 36.75 12 36 12H34.5V10.5C34.5 9.6 33.9 9 33 9C32.1 9 31.5 9.6 31.5 10.5V12H16.5V10.5C16.5 9.6 15.9 9 15 9C14.1 9 13.5 9.6 13.5 10.5V12H10.5C9.75 12 9 12.6 9 13.5V34.5C9 35.4 9.75 36 10.5 36H19.65C18.6 34.2 18 32.1 18 30C18 23.4 23.4 18 30 18Z" />
      <path d="M30 21C25.05 21 21 25.05 21 30C21 34.95 25.05 39 30 39C34.95 39 39 34.95 39 30C39 25.05 34.95 21 30 21ZM33 31.5H30C29.1 31.5 28.5 30.9 28.5 30V25.5C28.5 24.6 29.1 24 30 24C30.9 24 31.5 24.6 31.5 25.5V28.5H33C33.9 28.5 34.5 29.1 34.5 30C34.5 30.9 33.9 31.5 33 31.5Z" />
    </svg>
  );
};

export default TimeTable2Icon;
